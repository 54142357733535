import React from "react";
import Modal from "react-modal";
// import fideos from '../../../assets/products/molitalia/molitalia1.png';
import arroz from "../../../assets/Arroz.svg";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const BodegasSugerenciasModal = ({
  isModalSugerencia,
  setIsModalSugerencia,
  data
}) => {
  const closeModal = () => {
    setIsModalSugerencia(false);
  };
  return (
    <Modal
      isOpen={isModalSugerencia}
      onRequestClose={closeModal}
      style={customStyles}
      className="modal modalsugerencia"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
    >
      <h5 className=" title__modal">{data.descripcion}</h5>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-xl-6">
            <div className="adminbodega__image">
              <img src={arroz} alt="" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-xl-6 adminbodega__cantidad">
            <div className="">
              <label className="">
                Número de unidades vendidas la ultima semana(Unid)
              </label>
              <input type="text" disabled value={data.cantidad} />
            </div>
            <div className="">
              <label className="">
                Ganancia obtenida la ultima semana por el producto(S/)
              </label>
              <input type="text" disabled value={data.ganancia ? data.ganancia : 0} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="adminbodega__sugerenciasmodals">
        <div className="adminbodega__image">
          <img src={fideos} alt="" />
        </div>
        <div className="">
          
        </div>
      </div> */}
    </Modal>
  );
};

export default BodegasSugerenciasModal;
