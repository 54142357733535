import React from "react";
import TiendaAdminGrafico from "../graficos/TiendaAdminGrafico";

const Tiendas = () => {
  return (
    <div className="misTiendas">
      {/* Contenedor 1 contiene los recuadros de evaluacion, periodo, tipos de unidades */}
      <div className="contenedor1">
        <div className="evaluacion sombra">
          <p>Evaluación de Número de Tiendas</p>
          <label htmlFor="">Ubicación</label>
          <div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Departamento
              </label>
            </div>
            <select class="form-select" aria-label="Default select example">
              <option selected>Todos</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          {/* --------- */}
          <div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Provincia
              </label>
            </div>
            <select class="form-select" aria-label="Default select example">
              <option selected>Todos</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          {/* --------- */}
          <div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Distrito
              </label>
            </div>
            <select class="form-select" aria-label="Default select example">
              <option selected>Todos</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
            ></textarea>
            <label for="floatingTextarea">Comments</label>
          </div>
        </div>

        <div className="periodo sombra">
          <label htmlFor="">Periodo de tiempo</label>
          <div className="dias">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" />
                <label class="form-check-label" for="flexCheckDefault">
                  Días
                </label>
              </div>
            <div className="diaSelect">
              <div>

              <label htmlFor="">Inicio</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Seleccione</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              </div>
              <div>

              <label htmlFor="">Fin</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Seleccione</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              </div>
            </div>
          </div>

          <div className="mes">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" />
              <label class="form-check-label" for="flexCheckDefault">
                Mes
              </label>
            </div>

            <div className="mesSelect">
              <div>

            <label htmlFor="">Inicio</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Seleccione</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
              </div>
            <div>

            <label htmlFor="">Fin</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Seleccione</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            </div>
            </div>
          </div>

          <div className="anios">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" />
              <label class="form-check-label" for="flexCheckDefault">
                Años
              </label>
            </div>
            <div className="aniosSelect">

            
            <select class="form-select" aria-label="Default select example">
              <option selected>Seleccione</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            </div>
          </div>
        </div>

        <div className="tipos sombra">
          <label htmlFor="">Tipos de unidades</label>
          <select class="form-select" aria-label="Default select example">
            <option selected>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
            ></textarea>
            <label for="floatingTextarea">Comments</label>
          </div>
        </div>
      </div>

      {/* Contenedor 2 contiene las estadisticas */}
      <div className="contenedor2">
        <div className="estadisticas sombra">
          <p>Evolucion de números de usuarios en el tiempo</p>
          <TiendaAdminGrafico />
        </div>
      </div>
    </div>
  );
};

export default Tiendas;
