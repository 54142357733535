import React from 'react';
import { Route, Switch } from 'react-router-dom';

const LayoutUser = ({ routes }) => {
  return (
    <div>
      <LoadRoutes routes={routes} />
    </div>
  );
};

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
}

export default LayoutUser;
