/*eslint-disable */
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { fetchConToken } from "../../../api/consultas";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
import Mapa from "../../../helpers/MultipleMarket";

import MSelectProductos from "./MSelectProductos";
import HistorialBodegaVenta from "./HistorialBodegaVenta";
import PedidoVenta from "./PedidoVenta";

// import Echo from 'laravel-echo';
window.io = require("socket.io-client");

const BodegaNuevaVenta = () => {
  // const dispatch = useDispatch();
  const [counter, setCounter] = useState(1);
  const [sale, setSale] = useState({
    numero_doc: "",
    tipo_documento_id: "",
    tipo_venta_id: "",
  });

  const [btn, setBtn] = useState("Validar");
  const [openModalSelectProducto, setOpenModalSelectProducto] = useState(false);
  // const history = useHistory();
  // const { data } = useSelector((state) => state.bodegaVentas);

  const [dataMapa, setDataMapa] = useState({
    lat: 0,
    lng: 0,
  });
  const [todasUbicaciones, setTodasUbicaciones] = useState([]);
  const [rows, setRows] = useState([]);
  // const [subTotal, setSubTotal] = useState(rows);
  const [ventaTotal, setVentaTotal] = useState(0);

  const {
    numero_doc,
    razon_social,
    apellido,
    tipo_venta_id,
    tipo_documento_id,
  } = sale;

  const arrayDetalle = () => {
    const newArray = [];
    data.map((item) => {
      newArray.push({
        producto_local_id: item.id,
        cantidad: item.cantidad,
        unidad_medida_id: item.unidad_medida.id,
        precio_id: item.precios[item.precios.length - 1].id,
      });
    });
    return newArray;
  };

  const getPedidosPendientes = () => {
    try {
      fetchConToken("pedidos/bodega/getPendientes")
        .then((data) => data.json())
        .then((resp) => {
          setTodasUbicaciones(resp.data);
        });
    } catch (err) {}
  };

  const handleClick = (index) => {
    setCounter(index);
  };

  const validarDocumento = async (e) => {
    e.preventDefault();
    try {
      setBtn("...");
      if (sale.tipo_documento_id === "1") {
        const data = await fetch(
          `https://dniruc.apisperu.com/api/v1/dni/${sale.numero_doc}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJpeWV5aW0zMTlAY2FycGV0ZC5jb20ifQ.NaXtG7Nokt0tybRlEkuffmsKPjX4Gk8k-2pX7Rgn3j0`
        ).then((data) => data.json());
        setSale({
          ...sale,
          razon_social: data.nombres,
          apellido: data.apellidoPaterno + " " + data.apellidoMaterno,
        });
        toast.success("Documento Validado", {
          position: toast.POSITION.TOP_RIGTH,
        });
        return data;
      }
      if (sale.tipo_documento_id === "6") {
        const data = await fetch(
          `https://dniruc.apisperu.com/api/v1/ruc/${sale.numero_doc}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJpeWV5aW0zMTlAY2FycGV0ZC5jb20ifQ.NaXtG7Nokt0tybRlEkuffmsKPjX4Gk8k-2pX7Rgn3j0`
        ).then((data) => data.json());
        setSale({
          ...sale,
          razon_social: data.razonSocial,
          apellido: "",
        });
      }
    } catch {
      toast.error("Documento no encontrado", {
        position: toast.POSITION.TOP_RIGTH,
      });
      setSale({
        ...sale,
        razon_social: "",
        apellido: "",
      });
    } finally {
      setBtn("Validar");
    }
  };

  const handleSelectProduct = () => {
    setOpenModalSelectProducto(true);
  };

  const handleOnChange = (e) => {
    if (e.target.name === "tipo_documento_id") {
    }
    setSale(() => ({
      ...sale,
      [e.target.name]: e.target.value,
    }));
  };

  const createVenta = (data) => {
    // console.log(data);
    // fetchConToken("ventas/Encargado/create", data, "POST")
    //   .then((data) => data.json())
    //   .then((resp) => {
    //     if (resp.error) {
    //       toast.error("Error al crear venta", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     } else {
    //       dispatch({
    //         type: "detailVenta",
    //         payload: resp,
    //       });
    //       toast.success("Venta creada!", {
    //         position: toast.POSITION.TOP_RIGTH,
    //       });
    //       setTimeout(() => {
    //         history.push("/bodega/ventas/finalizar");
    //       }, 2000);
    //       dispatch({
    //         type: "ventasBodega",
    //         payload: [],
    //       });
    //     }
    //   });
  };

  // console.log("esta mrd me llega =>", data);

  const handleSubmit = () => {
    const detailSale = {
      tipo_documento_id: sale.tipo_documento_id ? sale.tipo_documento_id : "",
      numero_doc: sale.numero_doc ? sale.numero_doc : "",
      razon_social: sale.razon_social,
      // valor_venta: reduceData(data).toFixed(2),
      op_gravada: 0,
      credito: 0,
      tipo_venta_id: sale.tipo_venta_id,
      tipo_moneda_id: 1,
      detalle: arrayDetalle(),
    };
    try {
      if (sale.tipo_venta_id === "") {
        toast.warning("Seleccione el tipo de venta!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (
        (sale.tipo_venta_id === "2" || sale.tipo_venta_id === "3") &&
        sale.numero_doc === "" &&
        sale.tipo_documento_id === "" &&
        sale.razon_social === ""
      ) {
        toast.warning("Rellenar todos los campos y verificar su documento!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (Object.keys(data).length === 0) {
        toast.warning("Seleccione productos!", {
          position: toast.POSITION.TOP_RIGTH,
        });
      } else {
        console.log("detailSale =>", detailSale);
        createVenta(detailSale);
      }
    } catch (error) {
      toast.error("Error al crear la venta", {
        position: toast.POSITION.TOP_RIGTH,
      });
    }
  };

  useEffect(() => {
    getPedidosPendientes();
  }, []);

  const reduceData = () => {
    let data = [];
    let suma = 0;
    rows.map((item) => {
      suma += item.calculoTotal;
      data = suma;
    });
    setVentaTotal(data);
    // for (let i = 0; i < data.length; i++) {
    //   let suma = 0;
    //   suma += data[i];
    //   console.log("data => ", data);
    // }

    // data.map((item) => {
    //   console.log("sumammamamama => ", item);
    // });
    // if (rows.length > 0 && rows.every((item) => item.precios.length > 0)) {
    //   return rows.reduce(
    //     (prev, actual) =>
    //       prev + actual.precios[0]?.precio_venta * actual.cantidad,
    //     0
    //   );

    // console.log("rows => ", data);
    // }
    // return 0;
  };
  // console.log("reduceData => ", reduceData);

  useEffect(() => {
    reduceData();
  }, [rows]);

  const eliminadoxd = (id) => {
    const valores = rows.filter((item) => item.id !== id);
    setRows(valores);
  };

  const handleDeleteClick = (e) => {
    eliminadoxd(e.id);
  };

  const handleChangeCount = (item, e) => {
    let funciona = rows.map((response) => {
      if (response.id === item.id) {
        let calculoTotal = item.precios[0].precio_venta * e.target.value;
        item.calculoTotal = calculoTotal;
      }
      return response;
    });
    setRows(funciona);
  };

  // console.log("rows => ", rows);

  return (
    <section>
      <div className="container mt-0">
        <nav className="adminestadisticas__nav">
          <div className="nav nav-tabs " id="nav-tab" role="tablist">
            <button
              className={counter === 1 ? "nav-link active" : "nav-link"}
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected={counter === 1 ? "true" : "false"}
              onClick={() => handleClick(1)}
            >
              Vender
            </button>
            <button
              className={counter === 2 ? "nav-link active" : "nav-link"}
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected={counter === 2 ? "true" : "false"}
              onClick={() => handleClick(2)}
            >
              Pedidos (delivery)
            </button>
            <button
              className={counter === 4 ? "nav-link active" : "nav-link"}
              id="nav-pedido-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-pedido"
              type="button"
              role="tab"
              aria-controls="nav-pedido"
              aria-selected={counter === 4 ? "true" : "false"}
              onClick={() => handleClick(4)}
            >
              Pedidos
            </button>
            <button
              className={counter === 3 ? "nav-link active" : "nav-link"}
              id="nav-historial-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-historial"
              type="button"
              role="tab"
              aria-controls="nav-historial"
              aria-selected={counter === 3 ? "true" : "false"}
              onClick={() => handleClick(3)}
            >
              Historial
            </button>
            {/* 
            <button
              className={counter === 4 ? 'nav-link active' : 'nav-link'}
              id='nav-profile-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-profile'
              type='button'
              role='tab'
              aria-controls='nav-profile'
              aria-selected={counter === 4 ? 'true' : 'false'}
              onClick={() => handleClick(4)}
            >
              Productos a crédito
            </button> */}
          </div>
        </nav>
        {/*  */}
        <div className="tab-content " id="nav-tabContent">
          <div
            className={
              counter === 1 ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="row sombra py-4 px-3">
              <div className="col-12 col-sm-4 bodega-ventasnueva">
                <ToastContainer />
                <div>
                  <label className="my-2">Escoger tipo de operación</label>
                  <select
                    className="form-select"
                    aria-label="Tipo Operación"
                    name="tipo_venta_id"
                    value={tipo_venta_id}
                    onChange={handleOnChange}
                  >
                    <option value={0}>Seleccionar</option>
                    <option value={1}>Ticket</option>
                    <option value={2}>Boleta</option>
                    <option value={3}>Factura</option>
                  </select>
                </div>
                {/*  */}
                <div className="bodega-ventasnueva__informacion">
                  <label className="my-2">
                    <strong>Información del cliente</strong>
                  </label>

                  {sale.tipo_venta_id !== "1" && (
                    <>
                      <div>
                        <label>Tipo de documento</label>
                        <select
                          className="form-select"
                          name="tipo_documento_id"
                          value={tipo_documento_id}
                          onChange={handleOnChange}
                        >
                          <option value="">Seleccionar</option>
                          <option value={1}>DNI</option>
                          <option value={2}>CE</option>
                          <option value={3}>PASAPORTE</option>
                          <option value={4}>CEDULA</option>
                          <option value={6}>RUC</option>
                          <option value={5}>OTROS</option>
                        </select>
                      </div>

                      <div>
                        <label>Numero de documento:</label>
                        <input
                          type="text"
                          name="numero_doc"
                          value={numero_doc}
                          onChange={handleOnChange}
                        />
                        <div className="todos__botones">
                          <button
                            className="botones"
                            onClick={validarDocumento}
                          >
                            {btn}
                          </button>
                        </div>
                      </div>
                      <div>
                        <label>Nombre:</label>
                        <input
                          type="text"
                          name="razon_social"
                          value={razon_social}
                          onChange={handleOnChange}
                          disabled
                        />
                      </div>
                      <div>
                        <label>Apellidos:</label>
                        <input
                          type="text"
                          name="apellido"
                          value={apellido}
                          onChange={handleOnChange}
                          disabled
                        />
                      </div>
                    </>
                  )}
                  {/*  */}
                  <div className="todos__botones">
                    <button className="botones" onClick={handleSelectProduct}>
                      Agregar Producto
                    </button>
                    {/* <i className="fas fa-camera "></i> */}
                  </div>
                  <div className="todos__botones justify-content-center">
                    <button
                      className="botones actualizar mt-5"
                      onClick={handleSubmit}
                    >
                      Finalizar venta
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-8">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Unidades</th>
                        <th scope="col">P. Unit</th>
                        <th scope="col">P. Total</th>
                        <th scope="col">Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.producto.descripcion}</td>
                            <td>
                              <input
                                defaultValue={item.cantidad}
                                type="number"
                                min="1"
                                name="cantidad"
                                onChange={(e) => handleChangeCount(item, e)}
                              />
                            </td>
                            <td>{item.precios[0].precio_venta}</td>
                            <td>{item.calculoTotal}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                              }}
                            >
                              <button
                                style={{
                                  margin: "8px",
                                }}
                                onClick={() => handleDeleteClick(item)}
                              >
                                <i
                                  className="fa-solid fa-trash-can"
                                  style={{
                                    color: "#fa3928",
                                    fontSize: "16px",
                                  }}
                                ></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>SubTotal</td>
                        <td>{ventaTotal}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{ventaTotal}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div
            className={
              counter === 2 ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            {/*  */}
            <div className="row sombra py-4 px-3">
              <div>
                <h4>Pedidos delivery</h4>
                <Mapa
                  dataMapa={dataMapa}
                  setDataMapa={setDataMapa}
                  todasUbicaciones={todasUbicaciones}
                />
              </div>
            </div>
          </div>
          {/*  */}
          <div
            className={
              counter === 3 ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="nav-historial"
            role="tabpanel"
            aria-labelledby="nav-historial-tab"
          >
            <div className="row sombra py-4 px-3">
              <HistorialBodegaVenta />
            </div>
          </div>
          <div
            className={
              counter === 4 ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="nav-pedido"
            role="tabpanel"
            aria-labelledby="nav-pedido-tab"
          >
            <div className="row sombra py-4 px-3">
              <PedidoVenta />
            </div>
          </div>
          {/*  */}
          {/* <div
            className={
              counter === 4 ? 'tab-pane fade show active' : 'tab-pane fade'
            }
            id='nav-profile'
            role='tabpanel'
            aria-labelledby='nav-profile-tab'
          >
            <div className='row sombra py-4 px-3'>
              <h4>Productos a crédito</h4>
            </div>
          </div> */}
          {openModalSelectProducto && (
            <MSelectProductos
              openModalSelectProducto={openModalSelectProducto}
              setOpenModalSelectProducto={setOpenModalSelectProducto}
              setRows={setRows}
              rows={rows}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default BodegaNuevaVenta;
