/* eslint-disable */
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { useForm } from '../hooks/useForm';

import logo from '../assets/logo.png';
import loginImage from '../assets/login/login.png';
import { useDispatch } from 'react-redux';
import { startLogin } from '../actions/auth';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const data = useSelector((state) => state);
  const [seePassword, setseePassword] = useState('password');

  const [formValue, handleInputChange] = useForm({
    username: '',
    password: '',
  });

  const changeStatePassword = (value, setValue) => {
    if (value === 'text') {
      setValue('password');
    } else {
      setValue('text');
    }
  };

  useEffect(() => {
    if (user === undefined || user === null) {
      history.push('/login');
      return true;
    }
    if (user.administrador.length > 0) {
      if (user.administrador[0].empresa.pagado === 1) {
        history.push('/adminbodega/producto');
      } else if (data.auth.user.administrador[0].empresa.pagado === 0) {
        history.push('/adminbodega/registro');
      } else if (data?.auth.user?.administrador[0].empresa.pagado === 1) {
        history.push('/adminbodega/tiendas');
      }
      return true;
    } else if (user.administrador_yasta.length > 0) {
      history.push('/admin/usuario');
      return true;
    } else if (user.encargado.length > 0) {
      history.push('/bodega/productos');
      return true;
    }
  }, [user, history]);

  const { username, password } = formValue;

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(startLogin(username, password));
    } catch (error) {
      if (username === '' || password === '') {
        toast.error(`Debe llenar los campos`, {
          position: toast.POSITION.TOP_RIGTH,
        });
      }
    }
  };

  return (
    <div className='container-fluid'>
      <ToastContainer />
      <div className='row'>
        <div className='col-sm-6 login__fondo'>
          <div className='login__wave'>
            <div>
              <span>Bien</span>
              <span className='ms-5'>venido.</span>
            </div>
            <small className='mt-1'>
              Al portal de línea <i>Kampanee</i>
            </small>
            <br />
            <small className='mt-3'>Inicie sesión y gestione su negocio</small>
          </div>
        </div>
        <div className='col-sm-6 login__formulario'>
          <div className='cambiandologo '>
            <div className='login__img'>
              <img src={logo} alt='yasta' />
            </div>
            <h4>Iniciar sesión</h4>
            <form onSubmit={handleSubmit}>
              <div className='login__form'>
                <input
                  type='text'
                  placeholder='Usuario'
                  name='username'
                  value={username}
                  onChange={handleInputChange}
                />
              </div>
              <div className='login__form'>
                <div className='login__valid'>
                  <input
                    type={seePassword}
                    placeholder='Contraseña'
                    name='password'
                    value={password}
                    onChange={handleInputChange}
                  />
                  <div
                    onClick={() => {
                      changeStatePassword(seePassword, setseePassword);
                    }}
                    className='login__button'
                  >
                    {seePassword === 'text' ? (
                      <i className='fas fa-eye-slash'></i>
                    ) : (
                      <i className='fas fa-eye'></i>
                    )}
                  </div>
                </div>
              </div>
              <div className='login__form'>
                <Link to='/registrar' className='login__session'>
                  Crear Local
                </Link>
                <Link to='/restartpassword' className='login__session'>
                  Olvidaste tu contraseña
                </Link>
                <button type='submit' className='pt-2 pb-2'>
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
