import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import DataTable from "react-data-table-component";
import MCantidadProductos from "./MCantidadProductos";
import { fetchConToken } from "../../../api/consultas";
import Swal from "sweetalert2";

const MSelectProductos = ({
  openModalSelectProducto,
  setOpenModalSelectProducto,
  setRows,
  rows,
}) => {
  const [products, setProducts] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [listCategorias, setListCategorias] = useState([]);
  const [listSubcategorias, setListSubcategorias] = useState([]);
  const [listProductos, setListProductos] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [data, setData] = useState([]);
  const [openModalCantidadProductos, setOpenModalCantidadProductos] =
    useState(false);

  const closeModal = () => {
    setOpenModalSelectProducto(false);
  };

  const getCategorias = () => {
    fetchConToken("categorias/get")
      .then((data) => data.json())
      .then((resp) => setListCategorias(resp.data));
  };

  const getSubcategorias = () => {
    fetchConToken("subcategorias/get")
      .then((data) => data.json())
      .then((resp) => setListSubcategorias(resp.data));
  };

  const getProductosLocal = () => {
    fetchConToken("productos/mis_productos_local/get")
      .then((data) => data.json())
      .then((data) => {
        setProducts(data.data);
      });
  };

  const handleOnChange = (e) => {
    setBusqueda(([e.target.name] = e.target.value));
  };

  const handleOnChangeData = (e) => {
    if (e.target.name === "categoria") {
      const data = listSubcategorias.filter(
        (item) => item.categoria.id === parseInt(e.target.value)
      );
      setSubcategorias(data);
    }
  };

  const handleCantidadProductos = () => {
    if (data.length === 0) {
      Swal.fire({
        icon: "error",
        title: "!Error¡",
        text: "No hay ningun producto añadido.",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cerrar",
      });
    } else {
      setOpenModalCantidadProductos(true);
    }
  };

  useEffect(() => {
    getProductosLocal();
    getCategorias();
    getSubcategorias();
  }, []);

  const columnas = [
    {
      name: "Item",
      selector: (row, index) => (index += 1),
      sortable: true,
      center: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
      width: "60px",
    },
    {
      name: "Categoria",
      compact: true,
      selector: (row) =>
        row && row.producto.subcategoria
          ? row.producto.subcategoria.categoria.nombre
          : "",
      sortable: true,
      center: true,
      grow: 0,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Marca",
      selector: (row) =>
        row && row.producto.marca_id ? row.producto.marca.nombre : "",
      sortable: true,
      grow: 1,
      center: true,
      compact: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Presentación",
      selector: (row) =>
        row && row.producto.descripcion ? row.producto.descripcion : "",
      sortable: true,
      compact: true,
      grow: 1.5,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    /* {
      name: 'Agregar',
      button: true,
      cell: (item) => (
        <div class="form-check">
          <input 
            class="form-check-input" 
            type="checkbox" 
            name = "checkbtn_product"
            onChange={(e) => handleAddProduct(e, item)}
          />
        </div>
      ),
      style: {
        borderBotton: 'none',
      },
    }, */
  ];

  useEffect(() => {
    const filtrarElemento = () => {
      const search =
        products &&
        products.filter((data) => {
          return (
            data.producto.subcategoria.categoria.nombre
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.producto.subcategoria.nombre
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.producto.marca.nombre
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.producto.descripcion
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda)
          );
        });
      setListProductos(search);
    };
    filtrarElemento();
  }, [busqueda, products]);

  // console.log("ratatatatatata xd => ", rows);
  // const obtenerRows = (e) => {
  //   console.log("obtenerRows", e);
  // };
  return (
    <Modal
      isOpen={openModalSelectProducto}
      onRequestClose={closeModal}
      className="modal modal__addproduct"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
      ariaHideApp={false}
    >
      <div>
        {/* Contenedor de title y del boton close */}
        <p className="title__modal">
          <span>AGREGAR PRODUCTOS</span>
          {/* <br />
          Crear productos */}
        </p>
        <button className="close" onClick={closeModal}>
          X
        </button>
      </div>
      <div className="content-search__modal">
        <h2>Datos principales</h2>
        <div className="search">
          <i className="fas fa-search"></i>
          <input
            type="text"
            className="search__input"
            placeholder="Buscar productos por nombre..."
            name="busqueda"
            value={busqueda}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="content__filters">
        <div>
          <label>Categoría:</label>
          <select className="" name="categoria" onChange={handleOnChangeData}>
            <option key={0} value="">
              Seleccionar
            </option>
            {listCategorias.map((data) => {
              return (
                <option key={data.id} value={data.id}>
                  {data.nombre}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <label className="space">Sub-categoría:</label>
          <select
            className=""
            name="subcategoria"
            onChange={handleOnChangeData}
          >
            <option key={0} value="">
              Seleccionar
            </option>
            {subcategorias.map((data) => {
              return (
                <option key={data.id} value={data.id}>
                  {data.nombre}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="container mt-2">
        <DataTable
          columns={columnas}
          data={listProductos}
          //pagination
          // paginationComponentOptions={paginacionOpciones}
          fixedHeader
          fixedHeaderScrollHeight="400px"
          noDataComponent={<i className="fas fa-inbox table__icono"></i>}
          onSelectedRowsChange={(e) => setData(e.selectedRows)}
          // selectableRowsComponentProps={(e) => obtenerRows(e)}
          selectableRows
        />
        <div className="todos__botones">
          <button
            className="botones actualizar"
            onClick={handleCantidadProductos}
          >
            Siguiente
          </button>
        </div>
        {openModalCantidadProductos && (
          <MCantidadProductos
            openModalCantidadProductos={openModalCantidadProductos}
            setOpenModalCantidadProductos={setOpenModalCantidadProductos}
            setOpenModalSelectProducto={setOpenModalSelectProducto}
            datos={data}
            setRows={setRows}
          />
        )}
      </div>
    </Modal>
  );
};

export default MSelectProductos;
