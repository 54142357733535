import React, { useEffect, useState } from 'react';
import { fetchConToken } from '../../../api/consultas';
import { ToastContainer } from 'react-toastify';
import DataTable from 'react-data-table-component';
import MCreateProducto from './Modales/MCreateProducto';
import MProductoImpulsado from './Modales/MProductoImpulsado';
import MCreatePromocion from './Modales/MCreatePromocion';
import { paginacionOpciones } from '../../../helpers/TableConst';
import MProductCamera from './Modales/MProductCamera';
//import MAdminBodegaProductos from './MAdminBodegaProductos';

import MAddProduct from './Modales/MAddProduct';
export default function AdminBodegaProductoChild() {
  const [busqueda, setBusqueda] = useState('');
  const [listProductos, setlistProductos] = useState([]);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalProductoImpulsado, setOpenModalProductoImpulsado] = useState(false);
  const [openModalEditarProducto, setOpenModalEditarProducto] = useState(false);
  const [openModalCreatePromocion, setOpenModalCreatePromocion] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);
  const [editarProducto, setEditarProducto] = useState([]);
  const [product, setProduct] = useState([]);
  const [supercategorias, setSupercategorias] = useState([]);
  const [products, setProducts] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [impulsados, setImpulsados] = useState([]);
  const [item, setItem] = useState([]);

  const getCategorias = () => {
    fetchConToken('categorias/get')
      .then((data) => data.json())
      .then((data) => {
        setCategorias(data.data);
      });
  };

  const getSubcategorias = () => {
    fetchConToken('subcategorias/get')
      .then((data) => data.json())
      .then((data) => {
        setSubcategorias(data.data);
      });
  };

  const getProductosLocal = () => {
    fetchConToken('productos/mis_productos_locales/get')
      .then((data) => data.json())
      .then((data) => {
        setProducts(data.data);
      });
  };

  const getProductos = () => {
    fetchConToken('productos/get')
      .then((data) => data.json())
      .then((resp) => {
        setItem(resp.data);
        const data = resp.data.filter((item) => item.impulsar === '1');
        setImpulsados(data);
      });
  };

  const getSupercategorias = () => {
    fetchConToken('supercategorias/get')
      .then((data) => data.json())
      .then((data) => setSupercategorias(data.data));
  };

  useEffect(() => {
    try {
      getSupercategorias();
      getProductos();
      getProductosLocal();
      getCategorias();
      getSubcategorias();
    } catch (err) {}
  }, []);

  const columnas = [
    {
      name: 'Item',
      selector: (row, index) => (index += 1),
      sortable: true,
      style: {
        color: '#707070',
        borderBotton: 'none',
      },
      width: '80px',
    },
    {
      name: 'Supercategoría',
      selector: (row) =>
        row && row.producto.subcategoria.categoria.supercategoria.nombre
          ? row.producto.subcategoria.categoria.supercategoria.nombre
          : '',
      sortable: true,
      grow: 1,
      compact: true,
      style: {
        color: '#707070',
        borderBotton: 'none',
      },
    },
    {
      name: 'Categoría',
      selector: (row) => (row && row.producto.categoria ? row.producto.categoria.nombre : ''),
      sortable: true,
      grow: 1,
      compact: true,
      center: true,
      style: {
        color: '#707070',
        borderBotton: 'none',
      },
    },
    {
      name: 'Subcategoría',
      selector: (row) =>
        row && row.producto.subcategoria_id ? row.producto.subcategoria.nombre : '',
      sortable: true,
      compact: true,
      center: true,
      grow: 1,
      style: {
        color: '#707070',
        borderBotton: 'none',
      },
    },
    {
      name: 'Marca',
      selector: (row) => (row && row.producto.marca.nombre ? row.producto.marca.nombre : ''),
      sortable: true,
      compact: true,
      center: true,
      grow: 1,
      style: {
        color: '#707070',
        borderBotton: 'none',
      },
    },
    {
      name: 'Presentación',
      selector: (row) => (row && row.producto.descripcion ? row.producto.descripcion : ''),
      sortable: true,
      compact: true,
      grow: 2,
      style: {
        color: '#707070',
        borderBotton: 'none',
      },
    },
    {
      name: 'Stock',
      selector: (row) => (row && row.stock ? row.stock : ''),
      sortable: true,
      center: true,
      compact: true,
      grow: 0,
      style: {
        color: '#707070',
        borderBotton: 'none',
      },
    },
    {
      name: 'Unidad',
      selector: (row) => (row && row.unidad_medida.nombre ? row.unidad_medida.nombre : ''),
      sortable: true,
      center: true,
      compact: true,
      grow: 0,
      style: {
        color: '#707070',
        borderBotton: 'none',
      },
    },
    {
      name: 'Editar',
      button: true,
      compact: true,
      width: '70px',
      cell: (e) => (
        <button onClick={() => handleEditar(e)} className='table__tablebutton editar'>
          <i className='fas fa-pencil-alt'></i>
        </button>
      ),
      style: {
        borderBotton: 'none',
      },
    },
  ];

  const handleEditar = (e) => {
    setProduct(e.producto);
    setEditarProducto(e);
    setOpenModalEditarProducto(true);
  };

  useEffect(() => {
    const filtrarElemento = () => {
      const search =
        products &&
        products.filter((data) => {
          return (
            data.producto.categoria.nombre
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.producto.subcategoria.nombre
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.producto.marca.nombre
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.producto.descripcion
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.unidad_medida.nombre
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLocaleLowerCase()
              .includes(busqueda)
          );
        });
      setlistProductos(search);
    };
    filtrarElemento();
  }, [busqueda, products]);

  const handleOnChange = (e) => {
    setBusqueda(([e.target.name] = e.target.value));
  };

  const handleCreateProducto = () => {
    setOpenModalCreate(true);
  };

  const handleAddProducto = () => {
    setOpenModalCamera(true);
  };

  const handlePromocion = () => {
    setOpenModalProductoImpulsado(true);
  };

  const handleCrearPromocion = () => {
    setOpenModalCreatePromocion(true);
  };

  var d = new Date();

  const formatDate = (date) => {
    const formatted_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return formatted_date;
  };

  const diferenciaDias = (dia1, dia2) => {
    const fechaInicio = new Date(dia1).getTime();
    const fechaFin = new Date(dia2).getTime();
    const dias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
    return dias;
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.lotes &&
        diferenciaDias(formatDate(d), row.lotes[row.lotes.length - 1]?.vencimiento) <=
          row.alerta_vencimiento,
      style: {
        backgroundColor: 'rgba(240, 185, 182, 0.3) !important',
        color: 'rgba(240, 255, 255) !important',
      },
    },
    {
      when: (row) => row.lotes && row.stock <= row.alerta_stock,
      style: {
        backgroundColor: 'rgba(246, 225, 203, 0.3) !important',
        color: 'rgba(255, 255, 255) !important',
      },
    },
  ];

  return (
    <div className='container'>
      <ToastContainer />
      <div className='row'>
        <div className='table-responsive'>
          <div className='table__search'>
            <div className='search'>
              <i className='fas fa-search'></i>
              <input
                type='text'
                className='search__input'
                placeholder='Buscar productos por nombre...'
                name='busqueda'
                value={busqueda}
                onChange={handleOnChange}
              />
            </div>
            <div className='adminproductos__actions'>
              <div className='adminproductos__alert'>
                <label>
                  <div className='alert-date'></div>Alerta por fecha de vencimiento
                </label>

                <label>
                  <div className='alert-stock'></div>Alerta por falta de stock
                </label>
              </div>
              <div>
                <div className='adminproductos__btnactions'>
                  <p>Agregar nuevo producto</p>
                  <div>
                    <i className='fas fa-plus-circle' onClick={handleCreateProducto}></i>
                    <i
                      className='fas fa-camera'
                      style={{ marginLeft: '5px' }}
                      onClick={handleAddProducto}
                    ></i>
                  </div>
                </div>
                <div className='adminproductos__btnactions'>
                  <p>Productos impulsados</p>
                  <i className='fas fa-star' onClick={handlePromocion}></i>
                </div>
                <div className='adminproductos__btnactions'>
                  <p>Crear promoción</p>
                  <i className='fas fa-ticket-alt' onClick={handleCrearPromocion}></i>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columnas}
            data={listProductos}
            pagination
            paginationComponentOptions={paginacionOpciones}
            fixedHeader
            fixedHeaderScrollHeight='100%'
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent={
              <div className='spinner'>
                <i className='fas fa-inbox table__icono'></i>
                <p style={{ color: 'lightgrey' }}>Cargando ...</p>
              </div>
            }
          />
        </div>
      </div>
      {openModalCreate && (
        <MCreateProducto
          getProductosLocal={getProductosLocal}
          openModalCreate={openModalCreate}
          setOpenModalCreate={setOpenModalCreate}
          supercategorias={supercategorias}
          productos={item}
          subproductos={subcategorias}
          pruebas={categorias}
        />
      )}
      {openModalCamera && (
        <MProductCamera openModalCamera={openModalCamera} setOpenModalCamera={setOpenModalCamera} />
      )}
      {openModalEditarProducto && (
        <MAddProduct
          openModalAdd={openModalEditarProducto}
          setOpenModalAdd={setOpenModalEditarProducto}
          product={product}
          editarProducto={editarProducto}
        />
      )}

      {openModalProductoImpulsado && (
        <MProductoImpulsado
          openModalProductoImpulsado={openModalProductoImpulsado}
          setOpenModalProductoImpulsado={setOpenModalProductoImpulsado}
          productos={impulsados}
        />
      )}
      {openModalCreatePromocion && (
        <MCreatePromocion
          openModalCreatePromocion={openModalCreatePromocion}
          setOpenModalCreatePromocion={setOpenModalCreatePromocion}
          productos={products}
          categorias={categorias}
        />
      )}
    </div>
  );
}
