import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { startChecking } from '../actions/auth';

import { routes } from '../config/routes';

const AppRoute = () => {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(startChecking());
   }, [dispatch]);

   return (
      <Router>
         <Switch>
            {routes.map((route, index) => (
               <RouteWithSubRoutes key={index} {...route} />
            ))}
         </Switch>
      </Router>
   );
};

function RouteWithSubRoutes(route) {
   return (
      <Route
         path={route.path}
         exact={route.exact}
         render={(props) => {
            return <route.component routes={route.routes} {...props} />;
         }}
      />
   );
}

export default AppRoute;
