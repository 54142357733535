import React from 'react';
import ProviderBodegaProducto from './ProviderBodegaProducto'
import BodegaProductoChild from './BodegaProductoChild';

const BodegaProductos = () => {

  return (
    <ProviderBodegaProducto>
      <BodegaProductoChild />
    </ProviderBodegaProducto>
  );
};

export default BodegaProductos;
