import { useState } from "react";

const useForm = (initialState = {}) => {
  const [formValue, setFormValue] = useState(initialState);

  // const reset = () => {
  //   setFormValue(initialState);
  // };

  const handleInputChange = ({ target }) => {
    if (target.name === "condiciones") {
      setFormValue({ ...formValue, [target.name]: target.checked });
    } else {
      setFormValue({ ...formValue, [target.name]: target.value });
    }
  };

  return [formValue, handleInputChange];
};

export { useForm };
