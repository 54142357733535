/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Scanner from '../../../helpers/Scanner';
import Select from 'react-select';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { customStyles } from '../../../helpers/TableConst';
import { UploadAvatar } from '../../../helpers/UploadImage';
import {
  fetchConToken,
  fetchConFornDataToken,
  fetchSinToken,
} from '../../../api/consultas';
import Swal from 'sweetalert2';
Modal.setAppElement('#root');
const AdminProductosCrear = ({
  creatMProducto,
  setCreatMProducto,
  getProductos,
}) => {
  const [listSupercategoria, setListSupercategoria] = useState([]);
  const [listCategoria, setListCategoria] = useState([]);
  const [listSubcategoria, setListSubcategoria] = useState([]);
  const [listMarca, setListMarca] = useState([]);
  const [listUnidadMedida, setListUnidadMedida] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [subcategoria, setSubcategoria] = useState([]);
  const [selectedSupercategoria, setSelectedSupercategoria] = useState(null);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [foto, setAvatar] = useState(null);
  const [camera, setCamera] = useState(false);
  // { proveedores: [{ empresa_id: 0 }] }
  const [proveedor, setproveedor] = useState(0);

  const [listProveedores, setlistProveedores] = useState([]);
  // const [listadoProveedor, setListadoProveedor] = useState([]);
  const [producto, setProducto] = useState({
    descripcion: '',
    codebar: '',
    foto: '',
    subcategoria_id: '',
    marca_id: '',
    unidad_medida: '',
    cantidad: 1,
  });

  const {
    descripcion,
    codebar,
    subcategoria_id,
    marca_id,
    unidad_medida,
    cantidad,
  } = producto;

  const onDetected = (result) => {
    setProducto({
      codebar: result,
    });
  };

  const getProveedores = () => {
    fetchConToken('empresa/get')
      .then((data) => data.json())
      .then((resp) => setlistProveedores(resp.data));
  };

  useEffect(() => {
    if (foto) {
      setProducto({ ...producto, foto });
    }
  }, [foto, setProducto]);

  const getSupercategoria = () => {
    fetchConToken('supercategorias/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.data.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
          };
        });
        setListSupercategoria(listdatos);
      });
  };

  const getCategoria = () => {
    fetchConToken('categorias/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.data.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
            supercategoria_id: data.supercategoria_id,
          };
        });
        setListCategoria(listdatos);
      });
  };

  const getSubcategoria = () => {
    fetchConToken('subcategorias/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.data.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
            categoria_id: data.categoria_id,
          };
        });
        setListSubcategoria(listdatos);
      });
  };

  const getMarca = () => {
    fetchConToken('marcas/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.data.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
          };
        });
        setListMarca(listdatos);
      });
  };

  const getUnidadMedida = () => {
    fetchSinToken('unidad_medida/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.resp.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
          };
        });
        setListUnidadMedida(listdatos);
      });
  };

  useEffect(() => {
    getProveedores();
    getCategoria();
    getSubcategoria();
    getMarca();
    getSupercategoria();
    getUnidadMedida();
  }, []);

  const handleOnChangeCambios = (selectedOption, name) => {
    if (name === 'selectSupercategoria' && selectedOption) {
      setSelectedSupercategoria({
        label: selectedOption.label,
        value: selectedOption.value,
      });
      const data = listCategoria.filter(
        (item) => item.supercategoria_id === parseInt(selectedOption.value)
      );
      setCategoria(data);
    } else if (name === 'selectCategoria' && selectedOption) {
      setSelectedCategoria(parseInt(selectedOption.value));
      const data = listSubcategoria.filter(
        (item) => item.categoria_id === parseInt(selectedOption.value)
      );
      setSubcategoria(data);
    }
    if (selectedOption) {
      setProducto({
        ...producto,
        [name]: selectedOption.value,
      });
    }
  };

  const handleOnChange = (e) => {
    setProducto(() => ({
      ...producto,
      [e.target.name]: e.target.value,
    }));
  };

  const closeModal = () => {
    setCreatMProducto(false);
  };

  const handleCamera = () => {
    // e.preventDefault();
    setCamera(!camera);
  };

  useEffect(() => {
    handleCamera();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('descripcion', producto.descripcion || '');
    formData.set('codebar', producto.codebar || '');
    formData.set('foto', foto ? foto.file : '');
    formData.set('subcategoria_id', producto.subcategoria_id || '');
    formData.set('marca_id', producto.marca_id || '');
    formData.set('unidad_medida_id', producto.unidad_medida || '');
    formData.set('cantidad', producto.cantidad || '');

    if (
      descripcion === '' ||
      codebar === '' ||
      foto === null ||
      subcategoria_id === '' ||
      marca_id === '' ||
      unidad_medida === '' ||
      cantidad === ''
    ) {
      Swal.fire({
        icon: 'error',
        title: '!Error¡',
        text: 'Los campos no deben estar vacios.',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Cerrar',
      });
    } else {
      fetchConFornDataToken('productos/create', formData, 'POST')
        .then((res) => {
          toast.success('Producto creado!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          closeModal();
          getProductos();
          return res.json();
        })
        .then((response) => {
          if (response.data.id) {
            fetchConToken(
              `productos/addProveedor/${response.data.id}`,
              { proveedores: [{ empresa_id: proveedor }] },
              'POST'
            ).then((res) => {
              if (res.status === 200) {
                toast.success('Proveedor Agregado!', {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            });
          }
        });
    }
  };

  return (
    <Modal
      isOpen={creatMProducto}
      onRequestClose={closeModal}
      style={customStyles}
      className='modal modal__producto'
      overlayClassName='modal-fondo'
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className='container'>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='adminproductos__creartitle'>
              <h3>CREAR PRODUCTO</h3>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <div className='adminproductos__numero'>
                <div className='adminproductos__selectreact'>
                  <label>Super-Categoría</label>
                  <div>
                    <Select
                      placeholder='Seleccionar'
                      defaultValue={selectedSupercategoria}
                      onChange={(e) =>
                        handleOnChangeCambios(e, 'selectSupercategoria')
                      }
                      isClearable
                      options={listSupercategoria}
                      //   style={{
                      //     height: '20px !important',
                      //   }}
                    />
                  </div>
                </div>
                <div className='adminproductos__selectreact'>
                  <label>Categoría</label>
                  <div>
                    <Select
                      placeholder='Seleccionar'
                      defaultValue={selectedCategoria}
                      onChange={(e) =>
                        handleOnChangeCambios(e, 'selectCategoria')
                      }
                      options={categoria}
                      isClearable
                      style={{
                        height: '20px !important',
                      }}
                    />
                  </div>
                </div>
                <div className='adminproductos__selectreact'>
                  <label>Sub Categoría</label>
                  <div>
                    <Select
                      placeholder='Seleccionar'
                      defaultValue={subcategoria}
                      onChange={(e) =>
                        handleOnChangeCambios(e, 'subcategoria_id')
                      }
                      options={subcategoria}
                      isClearable
                      style={{
                        height: '20px !important',
                      }}
                    />
                  </div>
                </div>
                <div className='adminproductos__selectreact'>
                  <label>Marca</label>
                  <div>
                    <Select
                      placeholder='Seleccionar'
                      defaultValue={marca_id}
                      onChange={(e) => handleOnChangeCambios(e, 'marca_id')}
                      options={listMarca}
                      isClearable
                      style={{
                        height: '20px !important',
                      }}
                    />
                  </div>
                </div>
                <div
                  className=''
                  style={{
                    width: '100%',
                    height: '300px',
                  }}
                >
                  <label className=''>Imagen del producto</label>
                  <UploadAvatar avatar={foto} setAvatar={setAvatar} />
                </div>
              </div>
            </div>

            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <div className='adminproductos__numero'>
                <div
                  className='adminproductos__selectreact'
                  style={{
                    height: '50px',
                    marginBottom: '20px',
                  }}
                >
                  <p
                    className='adminproductos__pa'
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    Tipo de unidades
                  </p>
                  <Select
                    placeholder='Seleccionar'
                    defaultValue={unidad_medida}
                    onChange={(e) => handleOnChangeCambios(e, 'unidad_medida')}
                    options={listUnidadMedida}
                    isClearable
                    style={{
                      marginTop: '-50px; !important',
                    }}
                  />
                </div>
                <div className='adminproductos__selectoption'>
                  <textarea
                    placeholder='Descripcion'
                    value={descripcion}
                    onChange={handleOnChange}
                    name='descripcion'
                  ></textarea>
                </div>
                {/* <div className='adminproductos__selectoption'>
                  <p className='adminproductos__pa'>Cantidad</p>
                  <input
                    type='text'
                    value={cantidad}
                    onChange={handleOnChange}
                    name='cantidad'
                  />
                </div> */}
                {/*  */}
                <div className='adminproductos__selectoption'>
                  <label>Proveedor (opcional)</label>

                  {/* <div className='adminproductos__selectreact'>
                    <label>Proveedor (opcional)</label>
                    <div>
                      <Select
                        placeholder='Seleccionar'
                        defaultValue={selectedSupercategoria}
                        // onChange={(e) =>
                        //   handleOnChangeCambios(e, 'selectSupercategoria')
                        // }
                        onChange={(e) => {
                          setproveedor(e.target.value);
                        }}
                        isClearable
                        options={listProveedores}
                        //   style={{
                        //     height: '20px !important',
                        //   }}
                      />
                    </div>
                  </div> */}
                  <select
                    className='form-select'
                    onChange={(e) => {
                      setproveedor(e.target.value);
                    }}
                    name='proveedor'
                    defaultValue={proveedor}
                    isClearable
                  >
                    <option key={0} value=''>
                      Seleccionar
                    </option>
                    {listProveedores &&
                      listProveedores.map((data) => {
                        return (
                          <option key={data.id} value={data.id}>
                            {data.razon_social}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className='nosotros__imagenes'>
                <label>
                  Cargar barra del producto
                  <button type='button'>
                    <i
                      className={camera ? 'fas fa-camera' : 'fas fa-camera'}
                    ></i>
                  </button>
                </label>
                <div className='adminproductos__selectoption'>
                  <input
                    type='text'
                    className='adminproductos__inputs mt-3'
                    value={codebar}
                    onChange={handleOnChange}
                    name='codebar'
                  />
                  <div
                    className='camera'
                    style={{
                      height: '250px',
                    }}
                  >
                    {camera && <Scanner onDetected={onDetected} />}
                  </div>
                </div>
              </div>
            </div>
            <div className='todos__botones'>
              <button className='botones cancelar ' onClick={closeModal}>
                Cancelar
              </button>
              <button
                className='botones actualizar'
                type='submit'
                onChange={handleSubmit}
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AdminProductosCrear;
