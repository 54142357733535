import React from 'react';
import AdminEstadisticaGrafico from '../../graficos/AdminEstadisticaGrafico';
//import EstadisticasLugar from '../../select/EstadisticasLugar';
import Tiempo from '../../select/Tiempo';
import Lugares from '../../select/Lugares';

const AdminEstadisticasTotal = () => {
  return (
    <div className='container mb-3'>
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <div class='accordion' id='accordionExample'>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='headingOne'>
                <button
                  class='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  Periodo de tiempo
                </button>
              </h2>
              {/* <div className='adminusurio__contenedor sombra'> */}
              <div
                id='collapseOne'
                class='accordion-collapse collapse show'
                aria-labelledby='headingOne'
                data-bs-parent='#accordionExample'
              >
                <div class='accordion-body'>
                  {/* <p className='adminusuario__p'>Periodo de tiempo</p> */}
                  <Tiempo />
                </div>
              </div>
              {/* </div> */}
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='headingTwo'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseTwo'
                  aria-expanded='false'
                  aria-controls='collapseTwo'
                >
                  Ubicacion
                </button>
              </h2>
              <div
                id='collapseTwo'
                class='accordion-collapse collapse'
                aria-labelledby='headingTwo'
                data-bs-parent='#accordionExample'
              >
                <div class='accordion-body'>
                  <Lugares />
                </div>
              </div>
            </div>
            <div class='accordion-item'>
              <h2 class='accordion-header' id='headingThree'>
                <button
                  class='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseThree'
                  aria-expanded='false'
                  aria-controls='collapseThree'
                >
                  Tipo de tienda
                </button>
              </h2>
              <div
                id='collapseThree'
                class='accordion-collapse collapse'
                aria-labelledby='headingThree'
                data-bs-parent='#accordionExample'
              >
                <div class='accordion-body'>
                  <div className='adminestadisticas__tipotienda'>
                    <label>Tipo tienda</label>
                    <select className='form-select'>
                      <option value=''>Seleccionar</option>
                      <option value='1'>One</option>
                      <option value='2'>Two</option>
                      <option value='3'>Three</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 adminestadisticas__graficos d-grid'>
          {/* <div className='adminusurio__contenedorgrafico sombra mb-3'> */}
          <div className='adminusurio__contenedor sombra'>
            {/* <p className='adminusuario__p'>Dato a analizar</p> */}
            <div className='adminestadisticas__botones'>
              <button>Volumen de venta</button>
              <button>Ganancias</button>
              <button>Cantidad de productos</button>
              <button>Exportar a excel</button>
            </div>
            {/* </div> */}
            <div className='grafico'>
              <AdminEstadisticaGrafico />
            </div>
            <button className=''>Actualizar</button>
          </div>
          {/* <div className='adminusurio__contenedor sombra'>
            <p className='adminusuario__p'>Dato a analizar</p>
            <div className='adminestadisticas__botones'>
              <button>Volumen de venta</button>
              <button>Ganancias</button>
              <button>Cantidad de productos</button>
              <button>Exportar a excel</button>
            </div>
          </div> */}
        </div>
      </div>
      <div className='row'>
        {/* <div className='col-12 col-sm-12 col-md-12 col-lg-6 '> */}
        {/* <div className='adminusurio__contenedor sombra'>
            <div
              id='collapseOne'
              class='accordion-collapse collapse show'
              aria-labelledby='headingOne'
              data-bs-parent='#accordionExample'
            >
              <div class='accordion-body'>
                <p className='adminusuario__p'>Periodo de tiempo</p>
                <Tiempo />
              </div>
            </div>
          </div> */}
        {/* <div className='adminusurio__contenedor sombra'>
            <p className='adminusuario__p'>Ubicación</p>
            <EstadisticasLugar />
            <textarea
              className='adminestadisticas__textarea'
              readOnly
            ></textarea>
          </div> */}

        {/* <div className='adminusurio__contenedor sombra'>
            <div className='adminestadisticas__tipotienda'>
              <label>Tipo tienda</label>
              <select className='form-select'>
                <option value=''>Seleccionar</option>
                <option value='1'>One</option>
                <option value='2'>Two</option>
                <option value='3'>Three</option>
              </select>
            </div>

            <textarea
              className='adminestadisticas__textarea'
              readOnly
            ></textarea>
          </div> */}
        {/* </div> */}
        {/* <div className='col-12 col-sm-12 col-md-12 col-lg-6 adminestadisticas__graficos d-grid'>
          <div className='adminusurio__contenedorgrafico sombra mb-3'>
            <div className='grafico'>
              <AdminEstadisticaGrafico />
            </div>
            <button className=''>Actualizar</button>
          </div>
          <div className='adminusurio__contenedor sombra'>
            <p className='adminusuario__p'>Dato a analizar</p>
            <div className='adminestadisticas__botones'>
              <button>Volumen de venta</button>
              <button>Ganancias</button>
              <button>Cantidad de productos</button>
              <button>Exportar a excel</button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AdminEstadisticasTotal;
