const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    padding: '0px 0px 15px 0px',
  },
};

const paginacionOpciones = {
  rowsPerPageText: 'Fila por pagina',
  rangerSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

export { customStyles, paginacionOpciones };
