import React from 'react';
import cerveza from '../../../assets/cerveza/pilsen.jpeg';

const AdminBodegaProductoPromocion = ({ history }) => {
  const handleCrearProducto = () => {
    history.push('/adminbodega/productocrear');
  };
  return (
    <div className="container">
      <div className="row ">
        <div className="col-sm-12">
          <h3 className="adminbodega__productosh3">
            Mis productos - Crear productos
          </h3>
          <div className="adminbodega__productosmisprod my-4">
            <input type="text" placeholder="search" />
            <div>
              <input type="checkbox" class="form-check-input" />
              <label>En promoción</label>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <p>Tús productos que generaron mas ganancias son las siguientes:</p>
        <div className="col-12 adminbodega__producto-ventas">
          {/*  */}
          <div className="card sombra" onClick={handleCrearProducto}>
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="span1">1</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="span1">2</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">3</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">4</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          {/*  */}
        </div>
      </div>
      {/*  */}
      <div className="row mb-5">
        <p>Tús productos que generaron menos ganancias son las siguientes:</p>
        <div className="col-12 adminbodega__producto-ventas">
          {/*  */}
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">1</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">2</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">3</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">4</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          {/*  */}
        </div>
      </div>
      {/*  */}
      <div className="row mb-5">
        <p>Tús productos más vendidos son las siguientes:</p>
        <div className="col-12 adminbodega__producto-ventas">
          {/*  */}
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">1</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">2</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">3</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">4</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          {/*  */}
        </div>
      </div>
      {/*  */}
      <div className="row mb-5">
        <p>Tús productos menos vendidos son las siguientes:</p>
        <div className="col-12 adminbodega__producto-ventas">
          {/*  */}
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">1</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">2</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">3</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          <div className="card sombra">
            <img src={cerveza} class="card-img-top" alt="..." />
            <span className="">4</span>
            <p class="card-text text-center">Cervaza pilse callo 350ml</p>
          </div>
          {/*  */}
        </div>
      </div>
      {/* container */}
    </div>
  );
};

export default AdminBodegaProductoPromocion;
