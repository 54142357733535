import React, { useState } from 'react';
//import { toast } from 'react-toastify';
import { fetchConFornDataToken } from '../../../api/consultas';

const AdminBodegaCrearPromocion = () => {
  const [counter, setCounter] = useState(1);
  const [promocion, setPromocion] = useState({});
  const {
    nombre,
    precio_real,
    precio_promocion,
    ganancia_promocion,
    ganancia_real,
    stock,
  } = promocion;
  const createPromocion = () => {
    let newData = {
      local_id: 1,
      nombre: promocion.nombre,
      precio_real: promocion.precio_real,
      precio_promocion: promocion.precio_promocion,
      ganancia_promocion: promocion.ganancia_promocion,
      ganancia_real: promocion.ganancia_real,
      stock: promocion.stock,
      detalle: '',
    };
    fetchConFornDataToken('promociones/createPromocionBodega', newData, 'POST');
  };
  const handleOnChange = (e) => {
    setPromocion(() => ({
      ...promocion,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createPromocion();
  };
  const handleClick = (index) => {
    setCounter(index);
  };
  return (
    <section>
      <div className='container'>
        <nav className='adminestadisticas__nav'>
          <div className='nav nav-tabs ' id='nav-tab' role='tablist'>
            <button
              className={counter === 1 ? 'nav-link active' : 'nav-link'}
              id='nav-home-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-home'
              type='button'
              role='tab'
              aria-controls='nav-home'
              aria-selected={counter === 1 ? 'true' : 'false'}
              onClick={() => handleClick(1)}
            >
              Evaluación de totales
            </button>
            <button
              className={counter === 2 ? 'nav-link active' : 'nav-link'}
              id='nav-profile-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-profile'
              type='button'
              role='tab'
              aria-controls='nav-profile'
              aria-selected={counter === 2 ? 'true' : 'false'}
              onClick={() => handleClick(2)}
            >
              Productos top
            </button>
          </div>
        </nav>
        <div className='tab-content ' id='nav-tabContent'>
          <div
            className={
              counter === 1 ? 'tab-pane fade show active' : 'tab-pane fade'
            }
            id='nav-home'
            role='tabpanel'
            aria-labelledby='nav-home-tab'
          >
            {/*  */}
            <div className='row sombra py-4 px-3'>
              <h3 className='adminestadisticas__h3'>
                Mis productos -Crear promocion
              </h3>
              <div className='col-sm-6 '>
                <p className='adminestadisticas__p'>
                  Lista de productos proximos a la fecha de vencimiento
                </p>
                <div className='adminestadisticas__selectoption'>
                  <div className='adminestadisticas__time'>
                    <p>Categoría</p>
                  </div>
                  <div className='adminestadisticas__date'>
                    <p>Seleccionar:</p>
                    <select className='form-select'>
                      <option selected>Bebidas</option>
                      <option value='1'>One</option>
                      <option value='2'>Two</option>
                      <option value='3'>Three</option>
                    </select>
                  </div>
                </div>
                <hr />
                {/*  */}
                <table class='table'>
                  <thead>
                    <tr>
                      <th scope='col'>Marca</th>
                      <th scope='col'>Presentación</th>
                      <th scope='col'>Stock</th>
                      <th scope='col'>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Pilse</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>
                        <i class='fas fa-arrows-alt-h'></i>
                      </td>
                    </tr>
                    <tr>
                      <th>Ron</th>
                      <td>Larry the Bird</td>
                      <td>@twitter</td>
                      <td>
                        <i class='fas fa-arrows-alt-h'></i>
                      </td>
                    </tr>
                    <tr>
                      <th>Cristal</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>
                        <i class='fas fa-arrows-alt-h'></i>
                      </td>
                    </tr>
                    <tr>
                      <th>Cusqueña</th>
                      <td>Larry the Bird</td>
                      <td>@twitter</td>
                      <td>
                        <i class='fas fa-arrows-alt-h'></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/*  */}
                {/*  */}
                {/*  */}
              </div>
              <div className='col-sm-6 adminestadisticas__graficos'>
                <p className='adminestadisticas__p'>Unir productos</p>
                <div className=''>
                  <div className='adminbodega__promocion'>
                    <div className='adminestadisticas__promocion-add'>
                      <label>Nombre: </label>
                      <input
                        type='text'
                        name='nombre'
                        value={nombre}
                        onChange={handleOnChange}
                        className='form-control'
                      />
                    </div>
                    <div className='adminestadisticas__promocion-add'>
                      <label>stock: </label>
                      <input
                        type='text'
                        name='stock'
                        value={stock}
                        onChange={handleOnChange}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='adminbodega__promocion'>
                    <div className='adminestadisticas__promocion-add'>
                      <label>Precio real de ventas: </label>
                      <input
                        type='text'
                        name='precio_real'
                        value={precio_real}
                        onChange={handleOnChange}
                        className='form-control'
                      />
                    </div>
                    <div className='adminestadisticas__promocion-add'>
                      <label>Ganancias: </label>
                      <input
                        type='text'
                        name='ganancia_real'
                        value={ganancia_real}
                        onChange={handleOnChange}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='adminbodega__promocion'>
                    <div className='adminestadisticas__promocion-add'>
                      <label>Precio de la promoción: </label>
                      <input
                        type='text'
                        name='precio_promocion'
                        value={precio_promocion}
                        onChange={handleOnChange}
                        className='form-control'
                      />
                    </div>
                    <div className='adminestadisticas__promocion-add'>
                      <label>Ganancia: </label>
                      <input
                        type='text'
                        name='ganancia_promocion'
                        value={ganancia_promocion}
                        onChange={handleOnChange}
                        className='form-control'
                      />
                    </div>
                  </div>
                </div>
                <div>{/* <img src={pilsen} alt="" /> */}</div>
                <button onSubmit={handleSubmit}>Actualizar</button>
              </div>
            </div>
            {/*  */}
          </div>
          <div
            className={
              counter === 2 ? 'tab-pane fade show active' : 'tab-pane fade'
            }
            id='nav-profile'
            role='tabpanel'
            aria-labelledby='nav-profile-tab'
          >
            {/*  */}
            <div className='row sombra py-4 px-3'>
              <h3 className='adminestadisticas__h3'>
                Mis productos - Crear promoción
              </h3>
              <div className='col-sm-12 '>
                <div class='card col-sm-5'>
                  <h6 className='px-3 mt-2'>Productos:</h6>
                  <div class='card-body'>
                    <div class='card-text'>
                      <table class='table'>
                        <tbody>
                          <tr>
                            <td>Local</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td>Dirección</td>
                            <td>Mz Aaaaaa</td>
                          </tr>
                          <tr>
                            <td>Representante lega:</td>
                            <td>pepe</td>
                          </tr>
                          <tr>
                            <td>Delivery</td>
                            <td>kike</td>
                          </tr>
                          <tr>
                            <td>Horario de atención</td>
                            <td>9am</td>
                          </tr>
                          <tr>
                            <td>Días de atención</td>
                            <td>L - V</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class='todos__botones'>
                      <button className='botones eliminar'>Eliminar</button>
                      <button className='botones actualizar'>Editar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminBodegaCrearPromocion;
