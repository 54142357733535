/* eslint-disable */
import React from "react";

const DetallePedido = ({ pedido }) => {
  return (
    <div>
      <div className="contendi__ctmr__uu">
        {pedido.detalle &&
          pedido.detalle.map((item) =>
            item.producto ? (
              <div className="sub__contenido__ctmr__uu sombra">
                <div className="foto_producto">
                  <img src={item.producto.foto} loading="lazy" alt="" />
                </div>
                <h3>{item.producto.marca.nombre}</h3>
                <p>{item.producto.descripcion}</p>
                <div className="todos__botones">
                  <button className="botones actualizar">
                    {item.cantidad + " " + "UNIDAD(ES)"}
                  </button>
                </div>
              </div>
            ) : (
              item.promocion.detalle.map((data) => {
                return (
                  <div className="sub__contenido__ctmr__uu sombra">
                    <div className="foto_producto">
                      <img
                        src={data.producto_local.producto.foto}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <h3>{data.producto_local.producto.marca.nombre}</h3>
                    <p>{data.producto_local.producto.descripcion}</p>
                    <div className="todos__botones">
                      <button className="botones actualizar">
                        {data.cantidad + " " + "UNIDAD(ES)"}
                      </button>
                    </div>
                  </div>
                );
              })
            )
          )}
      </div>
      <p>Tipo de Comprobante: {pedido.tipo_recibo}</p>
      <p>Monto a pagar: {pedido.total}</p>
    </div>
  );
};

export default DetallePedido;
