import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { fetchConToken } from '../../../api/consultas';
import { customStyles } from '../../../helpers/TableConst';
const AdminCategoria = ({ crearCategoriaM, setcrearCategoriaM }) => {
  const [superCategoria, setSuperCategoria] = useState([]);
  const [crearsuperCategoria, setCrearsuperCategoria] = useState({
    nombre: '',
  });
  const { nombre } = crearsuperCategoria;
  const [obtenerID, setObtenerID] = useState(null);
  const [superCategoriaID, setSuperCategoriaID] = useState(null);
  const [deleteNivel, setDeleteNivel] = useState(null);
  const [listarCategoria, setListarCategoria] = useState([]);
  const [crearCategoria, setCrearCategoria] = useState([]);
  const [postCategoria, setPostCategoria] = useState({
    nombre: '',
    supercategoria_id: null,
  });
  const [categoriaID, setCategoriaID] = useState(null);

  const [listarSubCategoria, setListarSubCategoria] = useState([]);
  const [listaSubCategoria, setListaSubCategoria] = useState([]);

  const [crearSubCategoria, setCrearSubCategoria] = useState({
    nombre: '',
    categoria_id: null,
  });
  const [subCategoriaID, setsubCategoriaID] = useState(null);

  const [crearMarca, setcrearMarca] = useState({ nombre: '' });

  const listaSuperCategoria = () => {
    fetchConToken('supercategorias/get').then((data) => {
      data.json().then((data) => setSuperCategoria(data.data));
    });
  };

  const listaCategoria = () => {
    fetchConToken('categorias/get')
      .then((data) => data.json())
      .then((data) => setListarCategoria(data.data));
  };

  const listasubcategoria = () => {
    fetchConToken('subcategorias/get')
      .then((data) => data.json())
      .then((data) => setListarSubCategoria(data.data));
  };

  useEffect(() => {
    listaSuperCategoria();
    listaCategoria();
    listasubcategoria();
  }, []);

  const handleOnChange = (e) => {
    if (e.target.name === 'subcategoria_id') {
      setDeleteNivel(e.target.value);
      setSuperCategoriaID(parseInt(e.target.value));
      setObtenerID(e.target.value);
      const lista = listarCategoria.filter(
        (item) => item.supercategoria_id === parseInt(e.target.value)
      );
      setCrearCategoria(lista);
    } else if (e.target.name === 'subcategoria_id1') {
      setDeleteNivel(e.target.value);
      setCategoriaID(parseInt(e.target.value));
      const lista = listarSubCategoria.filter(
        (item) => item.categoria_id === parseInt(e.target.value)
      );
      setListaSubCategoria(lista);
    } else if (e.target.name === 'subcategoria_id2') {
      setsubCategoriaID(parseInt(e.target.value));
    }
  };

  // TODOsupercategoria
  const handleSuperCategoria = (e) => {
    setCrearsuperCategoria({
      ...crearsuperCategoria,
      [e.target.name]: e.target.value,
    });
  };

  const hanldeAddSuperCategoria = () => {
    if (nombre.length > 3) {
      fetchConToken('supercategorias/create', crearsuperCategoria, 'POST')
        .then((data) => data.json())
        .then(() => {
          toast.success('SuperCategoria creado!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          listaSuperCategoria();
          setCrearsuperCategoria({ nombre: '' });
        });
    } else {
      toast.error('Error, llenar el dato correctamente!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDelelteSuperCategoria = () => {
    if (deleteNivel > 0) {
      fetchConToken(
        'supercategorias/delete/' + deleteNivel,
        { deleteNivel },
        'DELETE'
      )
        .then((data) => data.json())
        .then(() => {
          toast.success('Nivel 1 Eliminado', {
            position: toast.POSITION.TOP_RIGHT,
          });
          listaSuperCategoria();
        });
    } else {
      toast.error('Elegir una opción', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // TODOcategoria
  const handleOnChangeCategoria = (e) => {
    setPostCategoria({
      ...postCategoria,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnClickCategoria = () => {
    postCategoria.supercategoria_id = obtenerID;
    if (postCategoria.nombre.length > 3 && deleteNivel > 0) {
      fetchConToken('categorias/create', postCategoria, 'POST')
        .then((data) => data.json())
        .then((data) => {
          toast.success('Categoria creado!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          listaSuperCategoria();
          listaCategoria();
          listasubcategoria();
          setPostCategoria({
            nombre: '',
            supercategoria_id: null,
          });
        });
    } else {
      toast.error('Error! Asignar nivel 1 / llenar campos correctamente ', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDelelteCategoria = () => {
    if (deleteNivel > 0) {
      fetchConToken(
        'categorias/delete/' + deleteNivel,
        { deleteNivel },
        'DELETE'
      )
        .then((data) => data.json())
        .then(() => {
          toast.success('Nivel 2 Eliminado', {
            position: toast.POSITION.TOP_RIGHT,
          });
          listaSuperCategoria();
        });
    } else {
      toast.error('Elegir una opción', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // TODO subcategoria
  const handleOnSubCategoria = (e) => {
    setCrearSubCategoria({
      ...crearSubCategoria,
      [e.target.name]: e.target.value,
    });
  };
  const handleClickSubCategoria = () => {
    crearSubCategoria.categoria_id = categoriaID;
    if (crearSubCategoria.nombre.length > 3 && deleteNivel > 0) {
      fetchConToken('subcategorias/create', crearSubCategoria, 'POST').then(
        (data) =>
          data.json().then((data) => {
            toast.success('Subcategoria creado correctamente', {
              position: toast.POSITION.TOP_RIGHT,
            });
            listaSuperCategoria();
            listaCategoria();
            listasubcategoria();
          })
      );
    } else {
      toast.error('Error! Asignar nivel 2 / llenar campos correctamente ', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDelelteSubategoria = () => {
    if (deleteNivel > 0) {
      fetchConToken(
        'subcategorias/delete/' + deleteNivel,
        { deleteNivel },
        'DELETE'
      )
        .then((data) => data.json())
        .then(() => {
          toast.success('Nivel 3 Eliminado', {
            position: toast.POSITION.TOP_RIGHT,
          });
          listaSuperCategoria();
        });
    } else {
      toast.error('Elegir una opción', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // TODO marca
  const handleOnchaMarca = (e) => {
    setcrearMarca({ ...crearMarca, [e.target.name]: e.target.value });
  };

  const handleOnClickMarca = () => {
    if (crearMarca.nombre.length > 3) {
      const { nombre } = crearMarca;
      fetchConToken('marcas/create', { nombre }, 'POST')
        .then((data) => data.json())
        .then((data) => {
          toast.success('Marca creada', {
            position: toast.POSITION.TOP_RIGHT,
          });
          setcrearMarca({ nombre: '' });
          listaSuperCategoria();
          listaCategoria();
          listasubcategoria();
        });
    } else {
      toast.error('Ingrese datos correctamente', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const closeModal = () => {
    setcrearCategoriaM(false);
  };
  return (
    <Modal
      isOpen={crearCategoriaM}
      onRequestClose={closeModal}
      style={customStyles}
      className='modal modal__categoria'
      overlayClassName='modal-fondo'
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='adminproductos__creartitle'>
            <h3>CREAR CATEGORÍA</h3>
          </div>
          <div className='col-12 my-2'>
            <div className='adminproductos__categoria'>
              <div className='adminproductos__divcategoria'>
                <label>SuperCategoria: </label>
                <select
                  className='form-select'
                  onChange={(e) => handleOnChange(e)}
                  name='subcategoria_id'
                >
                  <option key={0} value=''>
                    Seleccionar
                  </option>
                  <option key={1} value='0'>
                    Crear otro
                  </option>
                  {superCategoria.map((data) => {
                    return (
                      <>
                        <option key={data.id + 1} value={data.id}>
                          {data.nombre}
                        </option>
                      </>
                    );
                  })}
                </select>
                <button
                  className='cancelar'
                  onClick={(e) => handleDelelteSuperCategoria(e)}
                >
                  Eliminar
                </button>
              </div>
              {superCategoriaID === 0 ? (
                <div
                  className='adminproductos__divcategoria'
                  id='supercategoria'
                >
                  <label>Nivel 1</label>
                  <input
                    type='text'
                    name='nombre'
                    value={nombre}
                    onChange={handleSuperCategoria}
                  />
                  <button
                    className='actualizar'
                    onClick={hanldeAddSuperCategoria}
                  >
                    Crear
                  </button>
                </div>
              ) : (
                ''
              )}

              <div className='adminproductos__divcategoria'>
                <label>categoria: </label>
                <select
                  className='form-select'
                  onChange={(e) => handleOnChange(e)}
                  name='subcategoria_id1'
                >
                  <option key={0} value='0'>
                    Seleccionar
                  </option>
                  <option key={1} value='1'>
                    Crear otro
                  </option>
                  {crearCategoria.map((data) => {
                    return (
                      <option key={data.id + 1} value={data.id}>
                        {data.nombre}
                      </option>
                    );
                  })}
                </select>
                <button className='cancelar' onClick={handleDelelteCategoria}>
                  Eliminar
                </button>
              </div>
              {categoriaID === 1 ? (
                <div className='adminproductos__divcategoria'>
                  <label>Nivel 2</label>
                  <input
                    type='text'
                    name='nombre'
                    value={postCategoria.nombre}
                    onChange={handleOnChangeCategoria}
                  />
                  <button
                    className='actualizar'
                    onClick={handleOnClickCategoria}
                  >
                    Crear
                  </button>
                </div>
              ) : (
                ''
              )}

              <div className='adminproductos__divcategoria'>
                <label>SubCategoria:</label>
                <select
                  className='form-select'
                  onChange={(e) => handleOnChange(e)}
                  name='subcategoria_id2'
                >
                  <option key={0} value='0'>
                    Seleccionar
                  </option>
                  <option key={1} value='1'>
                    Crear otro
                  </option>
                  {listaSubCategoria.map((data) => {
                    return (
                      <option key={data.id + 1} value={data.id}>
                        {data.nombre}
                      </option>
                    );
                  })}
                </select>
                <button className='cancelar' onClick={handleDelelteSubategoria}>
                  Eliminar
                </button>
              </div>
              {subCategoriaID === 1 ? (
                <div className='adminproductos__divcategoria'>
                  <label>Nivel 3</label>
                  <input
                    type='text'
                    name='nombre'
                    value={crearSubCategoria.nombre}
                    onChange={handleOnSubCategoria}
                  />
                  <button
                    className='actualizar'
                    onClick={handleClickSubCategoria}
                  >
                    Crear
                  </button>
                </div>
              ) : (
                ''
              )}

              <div className='adminproductos__divcategoria'>
                <label>Marca:</label>
                <input
                  type='text'
                  onChange={handleOnchaMarca}
                  name='nombre'
                  value={crearMarca.nombre}
                />
                <button className='actualizar' onClick={handleOnClickMarca}>
                  Crear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdminCategoria;
