/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { fetchConToken } from '../../../api/consultas';
import MCreateProducto from './modals/MCreateProducto';
import { BodegaProductoContext } from './ProviderBodegaProducto'
import MPromociones from './modals/MPromociones';
import MCreatePromocion from './modals/MCreatePromocion';
import { paginacionOpciones } from '../../../helpers/TableConst';
import { useSelector } from 'react-redux';
import MProductCamera from './modals/MProductCamera';
import MAddProduct from './modals/MAddProduct';
import Swal from 'sweetalert2';

const BodegaProductoChild = () => {

    const { setProductosContext } = React.useContext(BodegaProductoContext);

    const [busqueda, setBusqueda] = useState('');
    const [listProductos, setlistProductos] = useState([]);
    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [products, setProducts] = useState([]);
    const [openModalPromocion, setOpenModalPromocion] = useState(false);
    const [openModalCreatePromocion, setOpenModalCreatePromocion] =
        useState(false);
    const [openModalEditarProducto, setOpenModalEditarProducto] = useState(false);
    const [openModalCamera, setOpenModalCamera] = useState(false);
    const [impulsados, setImpulsados] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);
    const [supercategorias, setSupercategorias] = useState([]);
    const [item, setItem] = useState([]);
    const [editarProducto, setEditarProducto] = useState([]);
    const [product, setProduct] = useState([]);


    const data = useSelector((state) => state.auth.user.encargado[0])

    const getSupercategorias = () => {
        fetchConToken('supercategorias/get')
            .then((data) => data.json())
            .then((data) => setSupercategorias(data.data))
    }

    const getCategorias = () => {
        fetchConToken('categorias/get')
            .then((data) => data.json())
            .then((data) => {
                setCategorias(data.data);
            });
    };

    const getSubcategorias = () => {
        fetchConToken('subcategorias/get')
            .then((data) => data.json())
            .then((data) => {
                setSubcategorias(data.data);
            });
    };

    const getProductosLocal = () => {
        fetchConToken('productos/mis_productos_local/get')
            .then((data) => data.json())
            .then((data) => {
                setProducts(data.data)
                setProductosContext(data.data)
            });
    };

    const getProductos = () => {
        fetchConToken('productos/get')
            .then((data) => data.json())
            .then((resp) => {
                setItem(resp.data);
                const data = resp.data.filter((item) => item.impulsar === "1");
                setImpulsados(data);
            });
    };


    useEffect(() => {
        getSupercategorias();
        getProductos();
        getProductosLocal();
        getCategorias();
        getSubcategorias();
    }, []);

    const columnas = [
        {
            name: 'Item',
            selector: (row, index) => (index += 1),
            sortable: true,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
                // backgroundColor: value < 10 ? "#111" : "#444",
            },
            width: '80px',
        },
        {
            name: 'Supercategoría',
            compact: true,
            selector: (row) =>
                row && row.producto.subcategoria
                    ? row.producto.subcategoria.categoria.nombre
                    : '',
            sortable: true,
            grow: 0,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Categoría',
            compact: true,
            selector: (row) =>
                row && row.producto.supercategoria
                    ? row.producto.supercategoria.nombre
                    : '',
            sortable: true,
            grow: 0,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Subcategoría',
            compact: true,
            selector: (row) =>
                row && row.producto.subcategoria_id
                    ? row.producto.subcategoria.nombre
                    : '',
            sortable: true,
            center: true,
            grow: 1,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Marca',
            compact: true,
            selector: (row) =>
                row && row.producto.marca_id ? row.producto.marca.nombre : '',
            sortable: true,
            grow: 0,
            // right: true,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Presentación',
            compact: true,
            selector: (row) =>
                row && row.producto.descripcion ? row.producto.descripcion : '',
            sortable: true,
            grow: 1,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Stock',
            compact: true,
            selector: (row) => row.stock ? row.stock : '',
            sortable: true,
            center: true,
            grow: 0,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Unidad',
            compact: true,
            selector: (row) =>
                row && row.unidad_medida ? row.unidad_medida.nombre : '',
            sortable: true,
            center: true,
            grow: 0,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Editar',
            button: true,
            compact: true,
            width: '70px',
            cell: (e) => (
                <button
                    onClick={() => handleEditar(e)}
                    className='table__tablebutton editar'
                >
                    <i className='fas fa-pencil-alt'></i>
                </button>
            ),
            style: {
                borderBotton: 'none',
            },
        },
        /* {
          name: 'alerta vencimiento',
          compact: true,
          selector: (row) =>
            row.lotes && row.lotes ? row.lotes[0]?.alerta_vencimiento : '',
          sortable: true,
          center: true,
          grow: 0,
          style: {
            color: '#8f9196',
            borderBotton: 'none',
          },
        }, */
    ];

    var d = new Date();

    const formatDate = (date) => {
        const formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        return formatted_date;
    }

    const diferenciaDias = (dia1, dia2) => {
        const fechaInicio = new Date(dia1).getTime();
        const fechaFin = new Date(dia2).getTime();
        const dias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
        return dias;
    }

    const conditionalRowStyles = [
        {
            when: (row) => diferenciaDias(formatDate(d), row.lotes[row.lotes.length - 1]?.vencimiento) <= row.alerta_vencimiento,
            style: {
                backgroundColor: 'rgba(240, 185, 182, 0.3) !important',
                color: 'rgba(240, 255, 255) !important',
            },
        },
        {
            when: (row) => row.stock <= row.alerta_stock,
            style: {
                backgroundColor: 'rgba(246, 225, 203, 0.3) !important',
                color: 'rgba(255, 255, 255) !important',
            },
        }
    ];

    useEffect(() => {
        const filtrarElemento = () => {
            const search =
                products &&
                products.filter((data) => {
                    return (
                        data.producto.subcategoria.categoria.nombre
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase()
                            .includes(busqueda) ||
                        data.producto.subcategoria.nombre
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase()
                            .includes(busqueda) ||
                        data.producto.marca.nombre
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase()
                            .includes(busqueda) ||
                        data.producto.descripcion
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase()
                            .includes(busqueda) ||
                        data.unidad_medida.nombre
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase()
                            .includes(busqueda)
                    );
                });
            setlistProductos(search);
        };
        filtrarElemento();
    }, [busqueda, products]);

    const handleOnChange = (e) => {
        setBusqueda(([e.target.name] = e.target.value));
    };

    const handleCreateProducto = () => {
        if (data.ingreso_producto === 1) {
            setOpenModalCreate(true);
        } else {
            Swal.fire('Necesita permisos, favor de contactar con el administrador');
        }
    };

    const handlePromocion = () => {
        if (data.ingreso_producto === 1) {
            setOpenModalPromocion(true);
        } else {
            Swal.fire('Necesita permisos, favor de contactar con el administrador');
        }
    };

    const handleCrearPromocion = () => {
        if (data.creacion_promocion === 1) {
            setOpenModalCreatePromocion(true);
        } else {
            Swal.fire('Necesita permisos, favor de contactar con el administrador');
        }
    };

    const handleAddProducto = () => {
        if (data.ingreso_producto === 1) {
            setOpenModalCamera(true);
        } else {
            Swal.fire('Necesita permisos, favor de contactar con el administrador');
        }
    }

    const handleEditar = (e) => {
        setProduct(e.producto);
        setEditarProducto(e)
        setOpenModalEditarProducto(true);
    }

    return (
        <div className='container'>
            <ToastContainer />
            <div className='row'>
                <div className='table-responsive'>
                    <div className='table__search'>
                        <div className='search'>
                            <i className='fas fa-search'></i>
                            <input
                                type='text'
                                className='search__input'
                                placeholder='Buscar productos por nombre...'
                                name='busqueda'
                                value={busqueda}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className='adminproductos__actions'>
                            <div className='adminproductos__alert'>
                                <label>
                                    <div className='alert-date'></div>Alerta por fecha de
                                    vencimiento
                                </label>

                                <label>
                                    <div className='alert-stock'></div>Alerta por falta de stock
                                </label>
                            </div>
                            <div>
                                <div className='adminproductos__btnactions'>
                                    <p>Aumentar nuevo producto</p>
                                    <div>
                                        <i
                                            className='fas fa-plus-circle'
                                            onClick={handleCreateProducto}
                                        ></i>
                                        <i
                                            className='fas fa-camera'
                                            style={{ marginLeft: '5px' }}
                                            onClick={handleAddProducto}
                                        ></i>
                                    </div>
                                </div>
                                <div className='adminproductos__btnactions'>
                                    <p>Productos impulsados</p>
                                    <i className='fas fa-star' onClick={handlePromocion}></i>
                                </div>
                                <div className='adminproductos__btnactions'>
                                    <p>Crear promoción</p>
                                    <i
                                        className='fas fa-ticket-alt'
                                        onClick={handleCrearPromocion}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columnas}
                        data={listProductos}
                        pagination
                        paginationComponentOptions={paginacionOpciones}
                        fixedHeader
                        ixedHeaderScrollHeight='100%'
                        conditionalRowStyles={conditionalRowStyles}
                        noDataComponent={
                            <div className='spinner'>
                                <i className='fas fa-inbox table__icono'></i>
                                {/* <p style={{ color: 'lightgrey' }}>Cargando ...</p> */}
                            </div>
                        }
                    />
                </div>
            </div>
            {openModalCreate && (
                <MCreateProducto
                    getProductosLocal={getProductosLocal}
                    openModalCreate={openModalCreate}
                    setOpenModalCreate={setOpenModalCreate}
                    supercategorias={supercategorias}
                    productos={item}
                    subproductos={subcategorias}
                    pruebas={categorias}
                />
            )}
            {openModalCamera && (
                <MProductCamera
                    openModalCamera={openModalCamera}
                    setOpenModalCamera={setOpenModalCamera}
                />
            )}
            {openModalPromocion && (
                <MPromociones
                    openModalPromocion={openModalPromocion}
                    setOpenModalPromocion={setOpenModalPromocion}
                    productos={impulsados}
                />
            )}
            {openModalEditarProducto && (
                <MAddProduct
                    openModalAdd={openModalEditarProducto}
                    setOpenModalAdd={setOpenModalEditarProducto}
                    product={product}
                    editarProducto={editarProducto}
                />
            )}
            {openModalCreatePromocion && (
                <MCreatePromocion
                    openModalCreatePromocion={openModalCreatePromocion}
                    setOpenModalCreatePromocion={setOpenModalCreatePromocion}
                    productos={products}
                    categorias={categorias}
                />
            )}
        </div>
    );
};

export default BodegaProductoChild;