/* eslint-disable */
import React, { useState } from 'react';
import Modal from 'react-modal';
import DataTable from 'react-data-table-component';
//import image from "../../../../assets/producto1.svg";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { UploadAvatar } from '../../../../helpers/UploadImage';
import { fetchConFornDataToken } from '../../../../api/consultas';

const MCreatePromocion = ({
  openModalCreatePromocion,
  setOpenModalCreatePromocion,
  productos,
  categorias,
}) => {
  const [promocion, setPromocion] = useState({
    precio_real: '',
    ganancia_real: '',
    stock: '',
    ganancia_promocion: '',
  });
  const [arrayProducts, setArrayProducts] = useState([]);
  const [detalle, setDetalle] = useState([]);
  const [changeproducts, setChangeproducts] = useState(productos);
  const local_id = useSelector((state) => state.auth.user.encargado[0].local_id);
  const [foto, setAvatar] = useState(null);
  const { nombre, precio_real, precio_promocion, ganancia_promocion, ganancia_real, stock } =
    promocion;

  const handleOnChange = (e) => {
    setPromocion(() => ({
      ...promocion,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === 'precio_promocion') {
      const resta = parseInt(e.target.value) - promocion.precio_real;
      setPromocion(() => ({
        ...promocion,
        ganancia_promocion: e.target.value ? (resta > 0 ? resta : 0) : 0,
      }));
    }
  };

  const formatProducts = (array) => {
    const json = JSON.stringify(array);
    const data = JSON.parse(json);
    var precios_venta = 0;
    var precios_compra = 0;
    var cantidad = 0;
    for (let x in data) {
      cantidad += data[x].lotes[data[x].lotes.length - 1].cantidad_ingreso;
      precios_venta += data[x].precios[0].precio_venta;
      precios_compra += data[x].precios[0].precio_compra;
    }
    const ganancia_real = precios_venta - precios_compra;
    setPromocion({
      ...promocion,
      precio_real: precios_venta,
      stock: cantidad,
      ganancia_real: ganancia_real,
    });
  };

  const handleAddProduct = (e) => {
    const arreglos = [...arrayProducts];
    const detalles = [...detalle];
    detalles.push({
      producto_local_id: e.id,
      precio_id: e.precios[0].id,
      cantidad: 1,
    });
    arreglos.push({
      id: e.id,
      cantidad: 1,
      marca: e.producto.marca.nombre,
      foto: e.producto.foto,
      descripcion: e.producto.descripcion,
      stock: e.stock,
      precios: [
        {
          id: e.precios[0]?.id,
          precio_venta: e.precios[0]?.precio_venta,
          precio_compra: e.precios[0]?.precio_compra,
          producto_local_id: e.precios[0]?.producto_local_id,
        },
      ],
      lotes: [
        {
          vencimiento: e.lotes[e.lotes.length - 1]?.vencimiento,
          alerta_stock: e.lotes[e.lotes.length - 1]?.alerta_stock,
          alerta_vencimiento: e.lotes[e.lotes.length - 1]?.alerta_vencimiento,
        },
      ],
    });
    setArrayProducts([...arreglos]);
    setDetalle([...detalles]);
    formatProducts(arreglos);
    setChangeproducts(changeproducts.filter((item) => item.id !== e.id));
  };

  const handleRemove = (product) => {
    const position = arrayProducts.findIndex((element) => element.id === product.id);
    const removedProduct = arrayProducts.splice(position, 1);
    const arrayTabla = changeproducts || [];
    arrayTabla.push(removedProduct[0]);
    setChangeproducts([...arrayTabla]);
    formatProducts(arrayProducts);
  };

  const handleCantidad = (e, data) => {
    const arreglos = [...detalle];
    let position = arreglos.findIndex(
      (arreglo) => arreglo.producto_local_id === data.id
    );
    arreglos[position].cantidad = parseInt(e.target.value);
    setDetalle([...arreglos]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('local_id', parseInt(local_id) || 0);
    formData.set('nombre', promocion.nombre || '');
    formData.set('precio_real', promocion.precio_real || '');
    formData.set('precio_promocion', promocion.precio_promocion || '');
    formData.set('ganancia_promocion', promocion.ganancia_promocion || '');
    formData.set('ganancia_real', promocion.ganancia_real || '');
    formData.set('stock', promocion.stock || '');
    detalle.map((d, index) => {
      formData.set(`detalle[${index}][producto_local_id]`, Number(d.producto_local_id));
      formData.set(`detalle[${index}][precio_id]`, Number(d.precio_id));
      formData.set(`detalle[${index}][cantidad]`, Number(d.cantidad));
    });
    if (
      nombre === '' ||
      precio_real === '' ||
      precio_promocion === '' ||
      ganancia_promocion === '' ||
      ganancia_real === '' ||
      stock === ''
    ) {
      Swal.fire({
        icon: 'error',
        title: '!Error¡',
        text: 'Los campos no deben estar vacios.',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Cerrar',
      });
    } else {
      fetchConFornDataToken('promociones/createPromocionBodega', formData, 'POST').then(() => {
        toast.success('Promocion creada!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };

  const closeModal = () => {
    setOpenModalCreatePromocion(false);
  };

  const columnas = [
    {
      name: 'Marca',
      selector: (row) =>
        row && row.producto ? row.producto.marca.nombre : row.marca ? row.marca : '',
      sortable: true,
      style: {
        color: '#8f9196',
        borderBotton: 'none',
      },
      width: '15%',
    },
    {
      name: 'Presentación',
      selector: (row) =>
        row && row.producto ? row.producto.descripcion : row.descripcion ? row.descripcion : '',
      sortable: true,
      //right: true,
      style: {
        color: '#8f9196',
        borderBotton: 'none',
      },
      maxWidth: '55%',
    },
    {
      name: 'Stock',
      selector: (row) => (row && row.stock ? row.stock : ''),
      sortable: true,
      style: {
        color: '#8f9196',
        borderBotton: 'none',
      },
      center: true,
      width: '10%',
    },
    {
      name: 'Accion',
      button: true,
      grow: 1,
      cell: (e) => (
        <button onClick={() => handleAddProduct(e)} className='table__tablebutton editar'>
          <i className='fas fa-exchange-alt'></i>
        </button>
      ),
      style: {
        borderBotton: 'none',
      },
      width: '20%',
    },
  ];

  var d = new Date();

  const formatDate = (date) => {
    const formatted_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return formatted_date;
  };

  const diferenciaDias = (dia1, dia2) => {
    const fechaInicio = new Date(dia1).getTime();
    const fechaFin = new Date(dia2).getTime();
    const dias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
    return dias;
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.lotes &&
        diferenciaDias(formatDate(d), row.lotes[row.lotes.length - 1]?.vencimiento) <=
          row.lotes[row.lotes.length - 1]?.alerta_vencimiento,
      style: {
        backgroundColor: 'rgba(240, 185, 182, 0.3) !important',
        color: 'rgba(240, 255, 255) !important',
      },
    },
    {
      when: (row) => row.lotes && row.stock <= row.lotes[row.lotes.length - 1]?.alerta_stock,
      style: {
        backgroundColor: 'rgba(246, 225, 203, 0.3) !important',
        color: 'rgba(255, 255, 255) !important',
      },
    },
  ];

  return (
    <Modal
      isOpen={openModalCreatePromocion}
      onRequestClose={closeModal}
      className='modal modal__createpromocion'
      overlayClassName='modal-fondo'
      closeTimeoutMS={200}
      preventScroll={true}
      ariaHideApp={false}
    >
      <div>
        {/* Contenedor de title y del boton close */}
        <p className='title__modal'>
          <span>CREAR PROMOCIÓN</span>
          {/* <br />
          Crear Promoción */}
        </p>
        <button className='close' onClick={closeModal}>
          X
        </button>
      </div>
      <div className='content__createPromocion show' id='create-promocion'>
        <div className='content__primary'>
          <div>
            <h2>Lista de productos próximas a la fecha de vencimiento</h2>
            <div className='select'>
              <label>Categoría:</label>
              <select
                className='form-select'
                style={{ width: '100%' }}
                name='categoria'
                onChange={handleOnChange}
              >
                <option key={0} value=''>
                  Seleccionar
                </option>
                {categorias.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>
                      {data.nombre}
                    </option>
                  );
                })}
                ;
              </select>
            </div>
            <DataTable
              columns={columnas}
              data={changeproducts}
              style={{ overflowX: 'hidden' }}
              fixedHeader
              fixedHeaderScrollHeight='400px'
              conditionalRowStyles={conditionalRowStyles}
              noDataComponent={<i className='fas fa-inbox table__icono'></i>}
            />
          </div>
          <div>
            <h2>Unir productos</h2>
            <div className='select-25'>
              <label>Nombre:</label>
              <input type='text' name='nombre' value={promocion.nombre} onChange={handleOnChange} />
            </div>
            <div className='select-25'>
              <label>Stock:</label>
              <input type='number' name='stock' value={promocion.stock} onChange={handleOnChange} />
            </div>
            <div>
              <div className='d-grid-50'>
                <div className='select-50'>
                  <label>Precio real de venta:</label>
                  <input
                    type='number'
                    name='precio_real'
                    value={promocion.precio_real}
                    onChange={handleOnChange}
                  />
                </div>
                <div className='d-flex align-items-center justify-content-evenly'>
                  <label>Ganancia:</label>
                  <input
                    type='number'
                    name='ganancia_real'
                    value={promocion.ganancia_real}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <div className='d-grid-50'>
                <div className='select-50'>
                  <label>Precio de la promoción:</label>
                  <input
                    type='number'
                    name='precio_promocion'
                    value={promocion.precio_promocion}
                    onChange={handleOnChange}
                  />
                </div>
                <div className='d-flex align-items-center justify-content-evenly'>
                  <label>Ganancia:</label>
                  <input
                    type='number'
                    name='ganancia_promocion'
                    value={promocion.ganancia_promocion}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <div className='select-25 centrar'>
                <UploadAvatar avatar={foto} setAvatar={setAvatar} />
              </div>
            </div>
            <div className='content__cards-promocion '>
              {arrayProducts &&
                arrayProducts.map((data) => {
                  return (
                    <div
                      className='item-card sombra'
                      style={{
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <button onClick={() => handleRemove(data)}>x</button>
                      <h6>{data.descripcion}</h6>
                      <img
                        src={data.foto}
                        style={{ margin: 'auto', display: 'flex' }}
                        alt='Producto'
                      />
                      <input
                        type="text"
                        name="cantidad"
                        defaultValue={data.cantidad ? data.cantidad : 0}
                        onChange={(e) => handleCantidad(e, data)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className='todos__botones'>
          <button className='actualizar botones' onClick={handleSubmit} style={{ marginTop: '2%' }}>
            Finalizar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MCreatePromocion;
