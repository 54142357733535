import { useState } from 'react';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import { fetchConToken } from '../../../api/consultas';
import { customStyles } from '../../../helpers/TableConst';

const ProfileBodega = ({ modalProfile, setModalProfile }) => {
  const [form, setForm] = useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  });

  const { oldPassword, newPassword, repeatPassword } = form;

  const closeModal = () => {
    setModalProfile(false);
  };

  const handleOnChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (oldPassword === '' || newPassword === '') {
      toast.error('Debe completar todos los campos!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (repeatPassword !== newPassword) {
      toast.error('No coinciden las contraseñas', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    fetchConToken(
      'usuarios/administradorYasta/cambiarPasswordAdmin',
      {
        currentPassword: form.oldPassword,
        newPassword: form.newPassword,
      },
      'POST'
    )
      .then((data) => data.json())
      .then((resp) => {
      });
  };

  return (
    <Modal
      isOpen={modalProfile}
      onRequestClose={closeModal}
      style={customStyles}
      className='modal modal__tiendapassword'
      overlayClassName='modal-fondo'
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className='container'>
        <ToastContainer />
        <div>
          <form className='row'>
            <div className='adminproductos__creartitle'>
              <h3>Cambiar contraseña</h3>
            </div>
            <div className='cambiar-password'>
              <label>Antigua contraseña</label>
              <input
                type='password'
                name='oldPassword'
                value={oldPassword}
                onChange={handleOnChange}
              />
            </div>
            <div className='cambiar-password'>
              <label>Nueva contraseña</label>
              <input
                type='password'
                name='newPassword'
                value={newPassword}
                onChange={handleOnChange}
              />
            </div>
            <div className='cambiar-password'>
              <label>Repetir contraseña</label>
              <input
                type='password'
                name='repeatPassword'
                value={repeatPassword}
                onChange={handleOnChange}
              />
            </div>
            <div className='todos__botones'>
              <div>
                <button
                  className='botones actualizar mt-2'
                  onClick={handleSubmit}
                >
                  Cambiar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileBodega;
