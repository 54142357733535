import React, { useState } from 'react';
import { toast } from 'react-toastify'
import { fetchConToken } from '../../../../api/consultas';

const CostoEnvio = ({ pedido, history }) => {
    const [costo, setCosto] = useState({
        costo_envio: ''
    });

    const {
        costo_envio
    } = costo;

    const handleSubmit = () => {
        if(costo.costo_envio === ''){
          toast.warning('Debe rellenar el campo!',{
            position: toast.POSITION.TOP_RIGHT
          })
        }else{
            fetchConToken('pedidos/bodega/enviarCostoEnvioPedido/' + pedido.id,  costo.costo_envio,'POST')
            toast.success('Pedido en espera del cliente',{
              position: toast.POSITION.TOP_RIGHT
            })
            setTimeout(() => {
                history.push('/bodega/ventas');
             }, 2000);
        }
    }

    return(
        <div>
        <h4>Enviar costo de envio</h4>
          <div className='my-3 '>
            <span>
              Costo de envío (S/): <br />
              (*)recuerde que se cobrar <br />
              0.10 por cada delivery
            </span>
            <input type='number' name='envio' value={costo_envio} onChange={(e) => setCosto(e.target.value)} className='form-control' />
          </div>
          <div className='todos__botones m-4'>
            <button onClick={handleSubmit} className='botones actualizar'>Enviar</button>
          </div>
        </div>
    );

};
export default CostoEnvio;