/* eslint-disable */
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { BodegaProductoContext } from "../ProviderBodegaProducto";
//import image from '../../../../assets/producto1.svg';
import MAddProduct from "./MAddProduct";

const MProduct = ({
  openModalProducts,
  setOpenModalProducts,
  productoSelect,
  subProductoSelect,
  productos,
  setOpenModalCreate,
  getProductosLocal,
}) => {
  const { productosContext } = React.useContext(BodegaProductoContext);
  const [busqueda, setBusqueda] = useState("");
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [product, setProduct] = useState([]);
  const [detailModal, setDetailModal] = useState({ title: "", btn: "" });
  const closeModal = () => {
    setOpenModalProducts(false);
  };

  const handleAddProduct = (e, value) => {
    setOpenModalAdd(true);
    setProduct(e);
    switch (value) {
      case 1:
        setDetailModal({ title: "EDITAR PRODUCTO", btn: "Actualizar" });
        break;
      case 0:
        setDetailModal({ title: "AGREGAR PRODUCTO", btn: "Finalizar" });
        break;
      default:
    }
  };

  const handleOnChange = (e) => {
    setBusqueda(([e.target.name] = e.target.value));
  };

  useEffect(() => {
    const filtrarElemento = () => {
      const search =
        productos &&
        productos.filter((data) => {
          return data.descripcion
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLocaleLowerCase()
            .includes(busqueda);
        });
      productos = search;
    };
    filtrarElemento();
  }, [busqueda, productos]);

  return (
    <Modal
      isOpen={openModalProducts}
      onRequestClose={closeModal}
      className="modal modal__products"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
      ariaHideApp={false}
    >
      <div>
        {/* Contenedor de title y del boton close */}
        <p className="title__modal">
          <span>MIS PRODUCTOS</span>
          {/* <br />
          Crear productos */}
        </p>
        <button className="close" onClick={closeModal}>
          X
        </button>
      </div>
      <div className="content-search__modal">
        <h2>Datos principales</h2>
        <div className="search">
          <i className="fas fa-search"></i>
          <input
            type="text"
            className="search__input"
            placeholder="Buscar productos por nombre..."
            name="busqueda"
            value={busqueda}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="content__tags">
        <div>
          <span>{productoSelect.nombre}</span>
          <button onClick={closeModal}>X</button>
        </div>
        <div>
          <span>{subProductoSelect.nombre}</span>
          <button onClick={closeModal}>X</button>
        </div>
      </div>
      {/* <div className="content__alert">
        <label>
          <div className="alert-promocion"></div>En promoción
        </label>
      </div> */}
      <div className="contendi__ctmr__uu">
        {productos &&
          productos.map((product) => {
            const { foto, marca, descripcion, cantidad, unidad_medida } =
              product;
            return (
              <div className="sub__contenido__ctmr__uu sombra">
                <div className="todos__botones">
                  <button
                    className="botones actualizar"
                    onClick={() => {
                      const value = productosContext.find(
                        (item) => item.producto.id === product.id
                      )
                        ? 1
                        : 0;
                      handleAddProduct(product, value);
                    }}
                  >
                    {productosContext &&
                    productosContext.find(
                      (item) => item.producto.id === product.id
                    )
                      ? `Editar`
                      : `Agregar`}
                  </button>
                </div>
                <div className="foto_producto">
                  <img
                    src={product ? foto : null}
                    alt="Producto"
                    loading="lazy"
                  />
                </div>
                <h3>{marca.nombre}</h3>
                <p>{descripcion}</p>
                <p>
                  {cantidad + " " + unidad_medida ? unidad_medida.nombre : ""}{" "}
                </p>
              </div>
            );
          })}
      </div>
      {openModalAdd && (
        <MAddProduct
          getProductosLocal={getProductosLocal}
          openModalAdd={openModalAdd}
          setOpenModalAdd={setOpenModalAdd}
          product={product}
          setOpenModalProducts={setOpenModalProducts}
          setOpenModalCreate={setOpenModalCreate}
          detailModal={detailModal}
        />
      )}
    </Modal>
  );
};

export default MProduct;
