import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import MProduct from "./MProduct";

const MCreateProducto = ({
  openModalCreate,
  setOpenModalCreate,
  productos,
  pruebas,
  subproductos,
  supercategorias,
  getProductosLocal,
}) => {
  const [productoSelect, setProductoSelect] = useState({});
  const [subProductoSelect, setSubProductoSelect] = useState({});
  const [openModalProducts, setOpenModalProducts] = useState(false);
  const [productsFilter, setProductsFilter] = useState([]);
  //const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    setProductoSelect(pruebas[0]);
  }, [pruebas]);

  const selectProduct = (e, product) => {
    const links = document.getElementsByClassName("product-link");
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove("link-active");
    }
    e.target.classList.add("link-active");
    setProductoSelect(product);
  };

  const selectSubProduct = (subproduct) => {
    setSubProductoSelect(subproduct);
    setOpenModalProducts(true);
    let products = productos.filter(
      (item) => item.subcategoria_id === subproduct.id
    );
    setProductsFilter(products);
  };

  const closeModal = () => {
    setOpenModalCreate(false);
  };

  /* const handleToggleVisibility = () => {
    setVisibility(!visibility)
  } */

  const handlePrueba = (e) => {
  };

  return (
    <Modal
      isOpen={openModalCreate}
      onRequestClose={closeModal}
      className="modal modal__createproduct"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
      ariaHideApp={false}
    >
      <div>
        {/* Contenedor de title y del boton close */}
        <p className="title__modal">
          <span>CREAR PRODUCTOS</span>
          {/* <br />
          Crear productos */}
        </p>
        <button className="close" onClick={closeModal}>
          X
        </button>
      </div>
      <div className="content-search__modal">
        <h2>Datos principales</h2>
        <div className="search">
          <i className="fas fa-search"></i>
          <input
            type="text"
            className="search__input"
            placeholder="Buscar productos por nombre..."
            name="busqueda"
          />
        </div>
        <div className="content-list_buttons">
          <div className="list">
            <h3>Productos</h3>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              {supercategorias &&
                supercategorias.map((item) => {
                  const { nombre, id, categorias } = item;
                  return (
                    <div class="accordion-item">
                      <h2 class="accordion-header" id={`heading${id}`}>
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${id}`}
                          aria-expanded="false"
                          aria-controls={`collapse${id}`}
                          onChange={(e) => handlePrueba(e)}
                        >
                          {nombre}
                        </button>
                      </h2>
                      <div
                        id={`collapse${id}`}
                        class="accordion-collapse collapse"
                        aria-labelledby={`heading${id}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <ul>
                            {categorias.map((producto) => (
                              <li key={producto.id}>
                                <div
                                  className={
                                    producto.id === 1
                                      ? "product-link link-active"
                                      : "product-link"
                                  }
                                  onClick={(e) => selectProduct(e, producto)}
                                >
                                  {producto.nombre}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="buttons">
            {subproductos.map((subproducto) =>
              subproducto.categoria_id === productoSelect.id ? (
                <button onClick={() => selectSubProduct(subproducto)}>
                  <i className="fas fa-wine-bottle"></i>
                  <p>{subproducto.nombre}</p>
                </button>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>
      {openModalProducts && (
        <MProduct
          getProductosLocal={getProductosLocal}
          openModalProducts={openModalProducts}
          setOpenModalProducts={setOpenModalProducts}
          productoSelect={productoSelect}
          subProductoSelect={subProductoSelect}
          productos={productsFilter}
          setOpenModalCreate={setOpenModalCreate}
        />
      )}
    </Modal>
  );
};

export default MCreateProducto;
