import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { fetchConToken } from "../../../../api/consultas";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const GenerarVenta = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pedido = useSelector((state) => state.pedidoData.data);
  const [pedidoData, setPedidoData] = useState({
    pedido_id: pedido.id,
    tipo_venta_id: pedido.tipo_venta_id,
    tipo_moneda_id: 1,
  });

  const { tipo_venta_id } = pedidoData;

  const handleFinalizar = () => {
    try {
      fetchConToken("ventas/pedido/create", pedidoData, "POST")
        .then((resp) => resp.json())
        .then((resp) => {
          if (!resp.error) {
            dispatch({
              type: "detailVenta",
              payload: resp,
            });
            toast.success("Venta creada exitosamente", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              history.push("/bodega/ventas/finalizar");
            }, 2000);
          }
        });
    } catch (err) {}
  };

  const handleOnChange = (e) => {
    if (e.target.name === "tipo_venta_id") {
      setPedidoData({
        ...pedidoData,
        tipo_venta_id: parseInt(e.target.value),
      });
    }
  };

  return (
    <div className="row sombra p-3 m-0" style={{ height: "400px" }}>
      <div className="col-12 col-sm-4 bodega-ventasnueva justify-content-evenly d-flex flex-column">
        <ToastContainer />
        <div>
          <label className="my-2">Escoger tipo de operación</label>
          <select
            className="form-select"
            aria-label="Default select example"
            name="tipo_venta_id"
            value={tipo_venta_id}
            onChange={handleOnChange}
          >
            <option key={0}>Seleccionar</option>
            <option value={1}>Ticket</option>
            <option value={2}>Boleta</option>
            <option value={3}>Factura</option>
          </select>
        </div>
        <div className="bodega-ventasnueva__informacion">
          <label className="my-2">Información del cliente</label>
          <div>
            <label>Nombre:</label>
            <input
              type="text"
              name="nombres"
              value={pedido ? pedido.cliente.persona.nombre : ""}
              disabled
            />
          </div>
          <div>
            <label>Apellidos:</label>
            <input
              type="text"
              name="apellido"
              value={pedido ? pedido.cliente.persona.apellido_pat : ""}
              disabled
            />
          </div>
          {pedido && pedido.delivery === 1 ? (
            <div className="row">
              <label className="col-md-3">Dirección:</label>
              <input
                type="text"
                className="col-md-9"
                value={pedido.direccion_entrega}
                disabled
              />
            </div>
          ) : (
            ""
          )}
          <div className="todos__botones justify-content-center">
            <button
              className="botones actualizar mt-5"
              onClick={handleFinalizar}
            >
              Finalizar venta
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-8">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Nombre</th>
                <th scope="col">Unidades</th>
                <th scope="col">P.Unit</th>
                <th scope="col">P. Total</th>
              </tr>
            </thead>
            <tbody>
              {pedido.detalle.length > 0 &&
                pedido.detalle.map((item, index) =>
                  item.producto ? (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.producto.descripcion}</td>
                      <td>{item.cantidad}</td>
                      <td>{item.precio_unitario}</td>
                      <td>{item.valor_venta}</td>
                    </tr>
                  ) : (
                    item.promocion.detalle.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.producto_local.producto.descripcion}</td>
                        <td>{item.cantidad}</td>
                        <td>{item.precio_unitario}</td>
                        <td>{item.valor_venta}</td>
                      </tr>
                    ))
                  )
                )}
            </tbody>
          </table>

          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>SubTotal</td>
                <td>{pedido.subtotal}</td>
              </tr>
              <tr>
                <td>IGV(18%)</td>
                <td>{pedido.igv}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{pedido.total}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GenerarVenta;
