import { authReducer } from "./authReducer";
import bodegaVentasReducer from "./bodegaVentasReducer";
import pedidoReducer from "./pedidoReducer";
import localReducer from "./localReducer";
import ventaReducer from "./ventaReducer";

const { combineReducers } = require("redux");

const rootReducer = combineReducers({
  auth: authReducer,
  bodegaVentas: bodegaVentasReducer,
  pedidoData: pedidoReducer,
  localData: localReducer,
  detailVenta: ventaReducer,
});

export { rootReducer };
