import React from 'react';
import AdminEstadisticaGrafico from '../../graficos/AdminEstadisticaGrafico';
const AdminEstadisticasTodal = () => {
  return (
    <div className="container mb-3">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
          <div className="adminusurio__contenedor sombra">
            <p className="adminusuario__p">Periodo de tiempo</p>
            <div>
              <div className="adminusuario__tiempo">
                <div className="adminusuario__time">
                  <input type="checkbox" className="form-check-input" />
                  <p>Días</p>
                </div>
                <div className="adminusuario__date box_date">
                  <div>
                    <p>Inicio </p>
                    <input type="date" />
                  </div>
                  <div>
                    <p>Fin</p>
                    <input type="date" />
                  </div>
                </div>
              </div>
              <div className="adminusuario__tiempo">
                <div className="adminusuario__time">
                  <input type="checkbox" className="form-check-input" />
                  <p>Mes </p>
                </div>
                <div className="adminusuario__date">
                  <div>
                    <p>Inicio: </p>
                    <select className="form-select">
                      <option value="">2021</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div>
                    <p>Final: </p>
                    <select className="form-select">
                      <option value="">2021</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="adminusuario__tiempo">
                <div className="adminusuario__time">
                  <input type="checkbox" className="form-check-input" />
                  <p>Años</p>
                </div>
                <div className="adminusuario__date">
                  <p>Seleccionar:</p>
                  <select className="form-select">
                    <option value="">2021</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="adminusurio__contenedor sombra">
            <p className="adminusuario__p">Ubicación</p>
            <div className="adminestadisticas__ubicacion">
              <div>
                <div>
                  <input type="checkbox" className="form-check-input" />
                  <p>Departamento</p>
                </div>

                <select className="form-select">
                  <option value="">2021</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div>
                <div>
                  <input type="checkbox" className="form-check-input" />
                  <p>Distrito</p>
                </div>

                <select className="form-select">
                  <option value="">2021</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div>
                <div>
                  <input type="checkbox" className="form-check-input" />
                  <p>Provincia</p>
                </div>

                <select className="form-select">
                  <option value="">2021</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <textarea
              className="adminestadisticas__textarea"
              readOnly
            ></textarea>
          </div>
          {/*  */}
          <div className="adminusurio__contenedor sombra">
            <div className="adminestadisticas__tipotienda">
              <label>Tipo tienda</label>
              <select className="form-select">
                <option value="">Seleccionar</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            {/*  */}
            <textarea
              className="adminestadisticas__textarea"
              readOnly
            ></textarea>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 adminestadisticas__graficos d-grid">
          <div className="adminusurio__contenedorgrafico sombra mb-3">
            <div className="grafico">
              <AdminEstadisticaGrafico />
            </div>
            <button className="">Actualizar</button>
          </div>
          <div className="adminusurio__contenedor sombra">
            <p className="adminusuario__p">Dato a analizar</p>
            <div className="adminestadisticas__botones">
              <button>Volumen de venta</button>
              <button>Ganancias</button>
              <button>Cantidad de productos</button>
              <button>Exportar a excel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEstadisticasTodal;
