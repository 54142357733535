import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { fetchConToken } from "../../../../api/consultas";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
//import image from '../../../../assets/producto1.svg';

const MAddProduct = ({
  openModalAdd,
  setOpenModalAdd,
  product,
  editarProducto,
  detailModal,
  setOpenModalProducts,
  setOpenModalCreate,
  getProductosLocal,
}) => {
  const closeModal = () => {
    setOpenModalAdd(false);
  };
  const [producto, setProducto] = useState({
    local_id: editarProducto ? editarProducto?.local_id : 0,
    precio_compra: "",
    precio_venta: "",
    vencimiento: "",
    alerta_vencimiento: "",
    alerta_stock: "",
    cantidad_ingreso: "",
  });
  const [arrayLote, setArrayLote] = useState({});
  const [arrayPrice, setArrayPrice] = useState({});
  const [locales, setLocales] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const {
    local_id,
    precio_compra,
    precio_venta,
    vencimiento,
    alerta_vencimiento,
    alerta_stock,
    cantidad_ingreso,
  } = producto;

  const getLocales = () => {
    fetchConToken("empresa/locales/getLocalesBodega")
      .then((data) => data.json())
      .then((resp) => {
        const data = resp.data.filter((item) => item.registro === "A");

        setLocales(data);
      });
  };

  const handleFinalizar = (e) => {
    e.preventDefault();
    let newProducto = {
      producto_id: product.id,
      precios: [arrayPrice],
      lotes: [arrayLote],
      local_id: producto.local_id,
    };
    setSubmitted(true);
    if (
      precio_compra === "" ||
      precio_venta === "" ||
      vencimiento === "" ||
      alerta_vencimiento === "" ||
      alerta_stock === "" ||
      cantidad_ingreso === ""
    ) {
      setSubmitted(true);
      Swal.fire({
        icon: "error",
        title: "!Error¡",
        text: "Los campos no deben estar vacios.",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cerrar",
      });
    } else {
      if (editarProducto) {
        setSubmitted(false);
        fetchConToken(
          "productos/mis_productos_local/agregarProductoEncargado",
          newProducto,
          "POST"
        )
          .then((data) => data.json())
          .then(() => {
            toast.success("Producto actualizado!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            closeModal();
            setOpenModalProducts(false);
            setOpenModalCreate(false);
            getProductosLocal();
          });
      } else {
        setSubmitted(false);
        fetchConToken(
          "productos/mis_productos_local/agregarProductoAdministrador",
          newProducto,
          "POST"
        )
          .then((data) => data.json())
          .then(() => {
            toast.success("Producto creado!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            closeModal();
            setOpenModalProducts(false);
            setOpenModalCreate(false);
            getProductosLocal();
          });
      }
    }
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setProducto(() => ({
      ...producto,
      [e.target.name]: e.target.value,
    }));
    setArrayLote({
      vencimiento: vencimiento,
      alerta_vencimiento: alerta_vencimiento,
      alerta_stock: alerta_stock,
      cantidad_ingreso: cantidad_ingreso,
      precio_compra: precio_compra,
    });
    setArrayPrice({
      precio_compra: precio_compra,
      precio_venta: precio_venta,
      unidad_medida_id: product.unidad_medida_id,
    });
  };

  useEffect(() => {
    getLocales();
  }, []);

  return (
    <Modal
      isOpen={openModalAdd}
      onRequestClose={closeModal}
      className="modal modal__addproduct"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
      ariaHideApp={false}
    >
      <div>
        {/* Contenedor de title y del boton close */}
        <p className="title__modal">
          <span>{detailModal ? detailModal.title : "AGREGAR PRODUCTOS"}</span>
          {/* <br />
          Agregar Productos */}
        </p>
        <button className="close" onClick={closeModal}>
          X
        </button>
      </div>
      <div className="content__addproduct">
        <form
          className={submitted ? "was-validated" : ""}
          autocomplete="off"
          novalidate
        >
          <div className="content__box">
            <label>Locales: </label>
            <div className="adminregistro__datos">
              <label style={{ marginRight: "5px" }}>Seleccionar Local: </label>
              <select
                className="mt-2"
                name="local_id"
                value={local_id}
                onChange={handleOnChange}
                required
              >
                <option key={0} value="">
                  Seleccionar
                </option>
                {locales &&
                  locales.map((data) => {
                    return (
                      <option key={data.id} value={data.id}>
                        {data.local_nombre}
                      </option>
                    );
                  })}
              </select>
            </div>
            <label>Fecha de vencimiento: </label>
            <div className="adminregistro__datos">
              <label style={{ marginRight: "5px" }}>
                Generar alerta (Días):{" "}
              </label>
              <input
                //className="form-control"
                type="text"
                min="0"
                name="alerta_vencimiento"
                value={alerta_vencimiento}
                onChange={handleOnChange}
                required
              />
            </div>
            <label className="mt-2">Stock Generar alerta (Stock):</label>
            <div className="adminregistro__datos">
              <label>Cantidad:</label>
              <div className="box__stock">
                <div>
                  <input
                    type="text"
                    //className="form-control"
                    min="0"
                    name="alerta_stock"
                    value={alerta_stock}
                    onChange={handleOnChange}
                    required
                  />
                </div>
              </div>
            </div>
            <label className="mt-2">Fotografía</label>
            <div className="item-card card-singular">
              <img
                src={product ? product.foto : null}
                alt="Producto"
                loading="lazy"
                style={{ width: "70%", height: "100%" }}
              />
              <h6>{product.marca ? product.marca.nombre : ""}</h6>
              <p>{product ? product.descripcion : ""}</p>
              {/* <p>
                        {' '}
                        Cantidad :
                        {
                           product.cantidad
                        }
                     </p> */}
            </div>
          </div>
        </form>
        <div className="content_lotesadd mt-3">
          <div>
            <p>
              <button
                className="addlote"
                data-bs-toggle="collapse"
                data-bs-target="#collapseWidthExample"
                aria-expanded="false"
                aria-controls="collapseWidthExample"
              >
                Agregar Lote
              </button>
            </p>
            {/* --------------- */}
            <div style={{ "min-height": "120px;" }}>
              <div
                class="collapse collapse-horizontal"
                id="collapseWidthExample"
              >
                <div class="card card-body" style={{ width: "300px;" }}>
                  <div className="addlote-card">
                    <p>Fecha de vencimiento</p>
                    <input
                      //className="form-control"
                      type="date"
                      name="vencimiento"
                      value={vencimiento}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                  <div className="addlote-card">
                    <p>Stock actual</p>
                    <input
                      type="text"
                      defaultValue={editarProducto ? editarProducto.stock : ""}
                      disabled
                    />
                  </div>
                  <div className="addlote-card">
                    <p>Agregar stock</p>
                    <div className="addlote-div">
                      <div className="addlote-div">
                        <div className="addlote-div">
                          <div>
                            <p>Cantidad:</p>
                          </div>
                          <input
                            type="text"
                            name="cantidad_ingreso"
                            value={cantidad_ingreso}
                            onChange={handleOnChange}
                            required
                          />
                        </div>
                        {/*  <input
                            type='text'
                            name='cantidad_ingreso'
                            className='form-control'
                            value={cantidad_ingreso}
                            onChange={handleOnChange}
                            required
                          /> */}
                      </div>
                      {/* <div className='addlote-div'>
                          <div>
                            <input 
                              type='checkbox' 
                              class='form-check-input'
                              id='checkbox2'
                              onChange={(e) => handleCheckbox(e, 2)}
                            />
                            <p>Sixpack</p>
                          </div>
                          <input 
                            type='text' 
                            id='input2'
                            name={cantidad_ingreso}
                            value={cantidad_ingreso}
                            onChange={handleOnChange}
                            disabled 
                          />
                        </div> */}
                    </div>
                  </div>
                  <div className="addlote-card">
                    <div>
                      <p>Precio de compra</p>
                    </div>
                    <input
                      type="text"
                      name="precio_compra"
                      value={precio_compra}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                  <div className="addlote-card">
                    <div>
                      <p>Precio de venta</p>
                    </div>
                    <input
                      type="text"
                      name="precio_venta"
                      value={precio_venta}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                  {/* <input
                        type='text'
                        name='precio_compra'
                        className='form-control'
                        value={precio_compra}
                        onChange={handleOnChange}
                        required
                      /> */}
                </div>
                {/* <div className='addlote-card'>
                      <div>
                        <input 
                          type='checkbox' 
                          class='form-check-input'
                          id='checkbox4'
                          onChange={(e) => handleCheckbox(e, 4)}
                        />
                        <p>Precio de compra - sixpack</p>
                      </div>
                      <input 
                        type='text' 
                        id='input4'
                        name='precio_compra'
                        value={precio_compra}
                        onChange={handleOnChange}
                        disabled 
                      />
                    </div> */}

                {/* <input
                        type='text'
                        className='form-control'
                        name='precio_venta'
                        value={precio_venta}
                        onChange={handleOnChange}
                        required
                      /> */}
              </div>
              {/* <div className='addlote-card'>
                      <div>
                        <input 
                          type='checkbox' 
                          class='form-check-input'
                          id='checkbox6'
                          onChange={(e) => handleCheckbox(e, 6)}
                        />
                        <p>Precio de venta - sixpack</p>
                      </div>
                      <input 
                        type='text' 
                        id='input6'
                        name='precio_venta'
                        value={precio_venta}
                        onChange={handleOnChange}
                        disabled 
                      />
                    </div> */}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="todos__botones">
        <button className="botones actualizar" onClick={handleFinalizar}>
          {detailModal ? detailModal.btn : "Finalizar"}
        </button>
      </div>
    </Modal>
  );
};

export default MAddProduct;
