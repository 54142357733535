import React, { memo } from "react";
import { Bar } from "react-chartjs-2";
const AdminUsuarioGrafico = memo(({ dataChart = [] }) => {
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  var cantidad = [];
  dataChart.map(item => cantidad.push(item.cantidad));

  const datasets = [{
    label: 'Cantidad de usuarios',
    data: cantidad || '',
    borderWidth: 1,
    backgroundColor: "rgba(249, 162, 108, 0.5)"
  }]

  const labels = dataChart.length > 0 && dataChart.map((item) => {
    return item['departamento'] || item['provincia'] || item['distrito']
  })

  const data = {
    labels: labels,
    datasets: datasets
  };

  return (
    <div style={{ width: "100%", height: "50%" }}>
      <Bar
        data={data}
        width={500}
        height={400}
        options={options}
      />
    </div>
  );
});

export default AdminUsuarioGrafico;
