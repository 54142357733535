import React from 'react';

const PoliticaPrivacidad = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12 my-4'>
          <div>
            <h1 className='text-center'>Políticas de Privacidad de YAESTA</h1>
            <p>
              La presente política de privacidad de Datos Personales (la
              “Política de Privacidad”), tiene por finalidad informarle la
              manera en que YAESTA S.A.C. (en adelante, “YAESTA”), con R.U.C. N°
              20553618518, con domicilio en Distrito de San Borja, Provincia y
              Departamento de Lima, realiza el tratamiento de los Datos
              Personales que recopila de sus Usuarios a través de cuestionarios
              y consentimientos escritos u otorgados mediante el llenado de
              formularios electrónicos que se encuentran en el sitio web
              <a
                href='https://medicalroma.com.pe/'
                target='_blank'
                rel='noreferrer'
              >
                https://medicalroma.com.pe
              </a>
              (en adelante, “Sitio Web”) y la aplicación móvil YAESTA (en
              adelante, “App de YAESTA”), ambos de su titularidad de YAESTA
              S.A.C.
            </p>
            <p>
              Para efectos de esta Política de Privacidad toda referencia a
              “nosotros” se entiende a YAESTA S.A.C. y cuando se refiera al
              “Usuario” se entiende a todas aquellas personas que naveguen,
              utilicen y/o realicen compras a través del Sitio Web y App YAESTA.
              En YAESTA S.A.C. aseguramos la máxima seguridad y protección de
              los Datos Personales de titularidad de nuestros Usuarios.
            </p>
            <p>
              Le agradecemos leer esta Política de Privacidad antes de
              proporcionarnos sus datos personales de manera facultativa y, si
              está de acuerdo, marcar el recuadro de aceptación de manera previa
              y expresa. Estos datos son necesarios para cumplir con las
              finalidades descritas en la presente Política de Privacidad por lo
              que, al no permitir su tratamiento, impediría estar en condiciones
              para cumplir las mismas.
            </p>
          </div>
          <div>
            <h5>1. Marco normativo y principios rectores</h5>
            <p>
              Esta Política de Privacidad se encuentra regulada por la
              legislación peruana y en particular por:
            </p>
            <ol>
              <li>· Ley No. 29733 (en adelante, “Ley”).</li>
              <li>
                · Decreto Supremo No. 003-2013-JUS, que aprueba el Reglamento de
                la Ley No. 29733 (en adelante, “Reglamento”).
              </li>
              <li>
                · Directiva de Seguridad de la Información, aprobada por la
                Resolución Directoral No. 019-2013-JUS/DGPDP (en adelante,
                “Directiva”).
              </li>
              <li>
                · Ley No. 29571, Código de Protección y Defensa del Consumidor
              </li>
            </ol>
          </div>
          <div>
            <h5>2. ¿Qué información recolectamos?</h5>
            <p>YAESTA S.A.C. recopila la siguiente información:</p>
            <p>
              A. Datos personales proporcionados libremente por el Usuario al
              momento de registrarse, crearse una cuenta y hacer una compra.
              Cuando el Usuario se registra para hacer una compra mediante el
              Sitio web y App de YAESTA, debe completar formularios donde se le
              solicita proporcionar su nombre, apellidos, número de DNI/CE,
              fecha de nacimiento, sexo, número de celular, número de teléfono
              fijo, dirección de domicilio y correo electrónico.
            </p>
            <p>
              B. Información que se recolecta cuando el Usuario utiliza el Sitio
              Web y App de YAESTA, entre la que se incluye (i) información sobre
              las actividades realizadas por el Usuario mientras navega y
              utiliza el Sitio Web, tales como comportamientos y hábitos de uso,
              historial de búsquedas y compras del Usuario; (ii) la URL de la
              que proviene el Usuario (incluyendo las externas al Sitio Web);
              (iii) URLs más visitadas por el Usuario (incluyendo las externas
              al Sitio Web); (iv) direcciones IP; (v) navegador que utiliza el
              Usuario (vi) información sobre aspectos operativos del Sitio Web y
              App YAESTA, como análisis y estadísticas de navegación, tráfico,
              entre otros; (vii) Información sobre el modelo de teléfono celular
              del Usuario y el sistema operativo móvil; (viiii) información que
              permita elaborar un reporte sobre términos de búsqueda; (ix)
              Ubicación del Usuario, entre otros (en adelante, “Información de
              Uso”).
            </p>
          </div>
          <div>
            <h5>3. Sobre la veracidad de los Datos Personales</h5>
            <p>
              El Usuario declara que los Datos Personales proporcionados son
              verdaderos, completos y exactos. Cada Usuario es responsable por
              la veracidad, exactitud, vigencia y autenticidad de los Datos
              Personales suministrados y se compromete a mantenerlos debidamente
              actualizados. Sin perjuicio de lo anterior, el Usuario autoriza a
              YAESTA S.A.C. a verificar la veracidad de los Datos Personales
              facilitados a través de información obtenida de fuentes de acceso
              público o de entidades especializadas en la provisión de dicha
              información. YAESTA S.A.C. no se hace responsable de la veracidad
              de la información que no sea de elaboración propia, por lo que
              tampoco asume responsabilidad alguna por posibles daños o
              perjuicios que pudieran originarse por el uso de dicha
              información.
            </p>
          </div>
          <div>
            <h5>4. El banco de datos de titularidad de YAESTA S.A.C.</h5>
            <p>
              El Usuario otorga su consentimiento expreso para la inclusión de
              su información en el banco de datos denominado Delivery,
              debidamente inscrito con Código 972 en el Registro de Protección
              de Datos a cargo de la Autoridad de Protección de Datos Personales
              bajo titularidad de YAESTA S.A.C.
            </p>
          </div>
          <div>
            <h5>5. ¿Para qué utilizamos la Información del Usuario?</h5>
            <p>
              La Información del Usuario se utiliza para las siguientes
              finalidades:
            </p>
            <ul>
              <li>
                1. Atender el registro del Usuario y brindar soporte técnico.
              </li>
              <li>2. Verificar y validar la identidad del Usuario.</li>
              <li>
                3. Recopilar información sobre las actividades realizadas por el
                Usuario mientras navega en la plataforma y efectúa compras, con
                la finalidad de poder ofrecerle a dicho Usuario contenido
                personalizado sobre la base de sus intereses y necesidades. Por
                ejemplo, el Usuario podrá acceder a su historial de compras.
              </li>
              <li>
                4. Utilizar los Datos Personales para brindar el servicio de
                entrega a domicilio o delivery.
              </li>
              <li>
                5. Transferir la Información del Usuario a empresas que brinden
                servicios basado en infraestructura en la nube en el extranjero,
                actualmente YAESTA S.A.C. contrata los servicios de Amazon Web
                Services Inc. el cual se encuentra ubicado en Estados Unidos; a
                fin de poder alojar, almacenar y procesar la Información del
                Usuario. Cabe indicar que dicho tratamiento es necesario para la
                ejecución de la relación contractual de YAESTA S.A.C. con el
                Usuario y, por lo tanto, resulta indispensable para poder
                ofrecerle el servicio de YAESTA S.A.C.
              </li>
            </ul>
            <p>
              El Usuario manifiesta expresamente que ha sido debidamente
              informado de todas las finalidades antes mencionadas. Asimismo, a
              través de la aceptación de la presente Política de Privacidad, el
              Usuario autoriza y otorga su consentimiento, de manera previa,
              libre, expresa e inequívoca y gratuita, para el tratamiento de sus
              Datos Personales y para la inclusión de su información en lo Banco
              de Datos mencionado anteriormente de titularidad de YAESTA S.A.C.
              En caso no acepte el tratamiento adicional de sus datos personales
              descrito en documento aparte, esto no afectará la prestación del
              servicio contratado. Asimismo, el Usuario reconoce y acepta que
              cualquier tratamiento de la Información del Usuario que sea
              necesario para la ejecución de la relación contractual que vincula
              al Usuario con YAESTA S.A.C. no requieren de su consentimiento y
              sólo de ser informado al Usuario.
            </p>
          </div>
          <div>
            <h5>6. ¿Cómo resguardamos la Información del Usuario?</h5>
            <p>
              YAESTA S.A.C. adopta las medidas de seguridad necesarias para
              garantizar la protección de la información del Usuario para evitar
              su alteración, pérdida, tratamiento y/o acceso no autorizado,
              habida cuenta del estado de la técnica, la naturaleza de los datos
              almacenados y los riesgos a que están expuestos. En este sentido,
              YAESTA S.A.C. usará los estándares de la industria en materia de
              protección de la confidencialidad de la información del Usuario y
              declara emplear diversas medidas de seguridad para evitar accesos
              no autorizados. No obstante, YAESTA S.A.C. no se hace responsable
              por interceptaciones ilegales o violación de sus sistemas o bases
              de datos por parte de personas no autorizadas, así como la
              indebida utilización de la información obtenida por esos medios, o
              de cualquier intromisión ilegítima que escape al control y debida
              diligencia de YAESTA S.A.C. y que no le sea imputable. YAESTA
              S.A.C. tampoco se hace responsable de posibles daños o perjuicios
              que se pudieran derivar de interferencias, omisiones,
              interrupciones, virus informáticos, averías telefónicas o
              desconexiones en el funcionamiento operativo de este sistema
              electrónico, motivadas por causas ajenas a YAESTA S.A.C. y que no
              hayan podido evitarse con debida diligencia; de retrasos o
              bloqueos en el uso de la plataforma informática causados por
              deficiencias o sobrecargas en el Centro de Procesos de Datos, en
              el sistema de Internet o en otros sistemas electrónicos.
            </p>
          </div>
          <div>
            <h5>7. Divulgación</h5>
            <p>
              YAESTA S.A.C. se compromete a no divulgar o compartir los Datos
              Personales del Usuario, sin que haya prestado el debido
              consentimiento para ello, con excepción de los siguientes casos:
            </p>
            <ol>
              <li>
                · Solicitudes de información de autoridades públicas en
                ejercicio de sus funciones y el ámbito de sus competencias.
              </li>
              <li>
                · Solicitudes de información en virtud de órdenes judiciales.
              </li>
              <li>
                · Solicitudes de información en virtud de disposiciones legales.
              </li>
            </ol>
          </div>
          <div>
            <h5>8. Cookies</h5>
            <p>
              El Sitio Web y App de YAESTA utilizan cookies. Una “Cookie” es un
              pequeño archivo de texto que un sitio web almacena en el navegador
              del Usuario. Las cookies facilitan el uso y la navegación por un
              sitio web y son de importancia esencial para el buen
              funcionamiento de Internet, aportando innumerables ventajas en la
              prestación de servicios interactivos. A través de las cookies, los
              sitios web y aplicaciones móviles pueden utilizar la información
              de su visita para realizar evaluaciones y cálculos estadísticos
              sobre datos anónimos, así como para garantizar la continuidad del
              servicio o para realizar mejoras Asimismo, la información obtenida
              a través de las cookies también se utiliza para analizar los
              hábitos de navegación del Usuario y las búsquedas realizadas por
              éste, a fin de mejorar sus iniciativas comerciales y
              promocionales, mostrando publicidad que pueda ser de su interés, y
              personalizando los contenidos. Las cookies pueden borrarse del
              disco duro si el Usuario así lo desea. La mayoría de los
              navegadores aceptan las cookies de forma automática, pero le
              permiten al Usuario cambiar la configuración de su navegador para
              que rechace la instalación de cookies, sin que ello perjudique su
              acceso y navegación.
            </p>
            <p>
              En el supuesto que en el sitio web y app YAESTA se dispusieran
              enlaces o hipervínculos que te redireccione a otros lugares de
              Internet, que son de propiedad de terceros que utilicen cookies;
              YAESTA S.A.C. no se hace responsable del uso de cookies por parte
              de dichos terceros.
            </p>
          </div>
          <div>
            <h5>9. Alcance y Plazo de conservación</h5>
            <p>
              Esta Política de Privacidad se aplicará al tratamiento de los
              datos personales del Usuario contenidos en los respectivos bancos
              de datos que los almacenen, mientras no se solicite su cancelación
              por el titular del dato en cumplimiento con la Ley.
            </p>
          </div>
          <div>
            <h5>
              10. Derechos de Acceso, Rectificación, Cancelación y Oposición
            </h5>
            <p>
              YAESTA S.A.C. pone a conocimiento del Usuario que los derechos de
              acceso, rectificación, oposición y cancelación de la Información
              del Usuario, así como los demás derechos concedidos por la
              normativa de protección de datos personales, se podrán ejercer
              mediante petición dirigida a:
              <a
                href='https://www.YAESTA.com.pe/blog/'
                target='_blank'
                rel='noreferrer'
              >
                https://www.YAESTA.com.pe/blog
              </a>
            </p>
            <p>
              Sin perjuicio de lo anterior, YAESTA S.A.C. podrá conservar
              determinada Información de Usuario, de quien solicita la baja, a
              fin de que sirva de prueba ante una eventual reclamación contra
              YAESTA S.A.C. por responsabilidades derivadas del tratamiento de
              dicha información, pero no realizará mayor tratamiento de los
              datos que el almacenamiento seguro. La duración de dicha
              conservación no podrá ser superior al plazo de prescripción legal
              de dichas responsabilidades.
            </p>
          </div>
          <div>
            <h5>11. Edad de Consentimiento</h5>
            <p>
              Al brindar sus datos personales a YAESTA S.A.C., el Usuario
              declara tener al menos dieciocho años de edad o ser tutor de un
              menor de edad para otorgar el consentimiento de forma válida de
              acuerdo a la Ley. YAESTA S.A.C. no llevará a cabo voluntariamente
              el tratamiento de Datos Personales relativos a menores de edad,
              salvo que se cuenten con el debido consentimiento de acuerdo a la
              norma aplicable. En el supuesto de que se tenga conocimiento que
              los Datos Personales recogidos corresponden a un menor de edad sin
              autorización de su tutor legal, se adoptarán las medidas oportunas
              para eliminarlos.
            </p>
          </div>
          <div>
            <h5>12. Cesión de Posición Contractual</h5>
            <p>
              El Usuario autoriza expresamente la cesión de esta Política y de
              su información en favor de cualquier persona que (i) quede
              obligada por la presente Política; y/o (ii) que sea el nuevo
              encargado del banco de datos que contenga la Información del
              Usuario. Luego de producida la cesión, YAESTA S.A.C. no tendrá
              ninguna responsabilidad con respecto de cualquier hecho que ocurra
              a partir de la fecha de la cesión. El nuevo responsable del banco
              de datos asumirá todas y cada una de las obligaciones establecidas
              en la presente Política respecto al tratamiento, resguardo y
              conservación de la información anteriormente detallada.
            </p>
          </div>
          <div>
            <h5>13. Modificaciones de la Política de Privacidad</h5>
            <p>
              YAESTA S.A.C. se reserva expresamente el derecho a modificar,
              actualizar o completar en cualquier momento la presente Política
              de Privacidad. Cualquier modificación, actualización o ampliación
              producida en la presente Política de Privacidad será
              inmediatamente publicada en el sitio web y app YAESTA, por lo cual
              el Usuario estará enterado de qué información recopilamos y bajo
              qué circunstancias, y se solicitará el consentimiento al acceder
              nuevamente a dichos servicios.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoliticaPrivacidad;
