import React, { useState } from "react";

import Modal from "react-modal";
import { toast } from "react-toastify";
import { customStyles } from "../../../helpers/TableConst";
import { fetchConToken } from "../../../api/consultas";
import { exportar } from "../../excel/ExportExcel";

const AdminProductosExportar = ({
  setExportarMProducto,
  exportarMProducto,
}) => {
  const [date, setDate] = useState({});

  const { startDate, endDate } = date;

  const closeModal = () => {
    setExportarMProducto(false);
  };

  const currentDate = () => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      return `${day}-0${month}-${year}`;
    } else {
      return `${day}-${month}-${year}`;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (startDate === "" || endDate === "") {
      toast.error("Los campos no deben estar vacíos", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      fetchConToken("productos/exportar/new",{startDate: date.startDate, endDate: date.endDate}, "POST")
        .then((data) => data.json())
        .then((data) => {
          if(data.data.length > 0 ){
            exportar(data.data,"productos_exportados_" + currentDate())
            toast.success("Productos exportados!", {
              position: toast.POSITION.TOP_RIGHT
            })
          }else{
            toast.warning('No hay productos en el rango de fechas solicitado',{
              position: toast.POSITION.TOP_RIGHT
            })
          }
        });
    }
  };

  return (
    <Modal
      isOpen={exportarMProducto}
      onRequestClose={closeModal}
      style={customStyles}
      className="modal modal_impulsar__producto"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="adminproductos__creartitle">
              <h3>EXPORTAR PRODUCTOS</h3>
              {/* <p>Crear productos</p> */}
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div className="d-flex justify-content-center flex-column">
                <div className="cambiar-password">
                  <label>Fecha de inicio</label>
                  <input
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => setDate({...date, startDate: e.target.value })}
                  />
                </div>
                <div className="cambiar-password">
                  <label>Fecha de fin</label>
                  <input
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setDate({...date, endDate: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="todos__botones">
            <button className="botones actualizar mt-2" type="submit">
              Guardar
            </button>
          </div>
        </form>
      </div>
      {/* {
        <ExportExcel
          csvData={products}
          fileName={"productos_exportados_" + currentDate()}
        />
      } */}
    </Modal>
  );
};

export default AdminProductosExportar;
