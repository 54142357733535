/*eslint-disable */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//Vistas
import AsignarTransportista from './delivery/AsignarTransportista';
import DetallePedido from './delivery/DetallePedido';
import CostoEnvio from './delivery/CostoEnvio';

const BodegaVentaDelivery1 = () => {
  const pedido = useSelector((state) => state.pedidoData.data);
  const history = useHistory();
  const [viewValue, setViewValue] = useState(1);

  const handleChangeView = (e) => {
    switch (e.target.name) {
      case 'details':
        setViewValue(1);
        break;
      case 'assignment':
        setViewValue(2);
        break;
      case 'costo':
        setViewValue(3);
        break;
    }
  };

  const handleVentas = () => {
    history.push('/bodega/ventas');
  };

  const handleNewVenta = () => {
    history.push('/bodega/ventas/nueva');
  };

  return (
    <div className='container sombra'>
      <ToastContainer />
      <h4 className='p-2'>Pedido {pedido.delivery === 1 ? 'Delivery' : ''} #{pedido.id}</h4>
      <div className='row'>
        <div className='col-4'>
        {pedido && pedido.delivery === 1 ? (
          <div className='botones__delivery'>
            <div className='todos__botones mt-4'>
              <button
                name='details'
                className='botones actualizar'
                onClick={handleChangeView}
              >
                Detalles del pedido
              </button>
            </div>
            <div className='todos__botones mt-4'>
              <button
                name='costo'
                className='botones actualizar'
                onClick={handleChangeView}
                disabled={pedido.estado === 1 ? false : true} 
              >
                Enviar costo de envio
              </button>
            </div>
            <div className='todos__botones mt-4'>
              <button
                name='assignment'
                className='botones actualizar'
                onClick={handleChangeView}
                disabled={pedido.estado === 2 ? false : true}
              >
                Asignar repartidor
              </button>
            </div>
            <div className='todos__botones mt-4'>
              <button
                name='details'
                className='botones actualizar'
                onClick={handleNewVenta}
                disabled={pedido.estado === 2 || pedido.estado === 3 ? false : true} 
              >
                Generar venta
              </button>
            </div>
            <div className='todos__botones mt-4'>
              <button
                name='details'
                className='botones cancelar'
                onClick={handleVentas}
              >
                Atrás
              </button>
            </div>
          </div>
        ):(
          <div className='botones__delivery'>
            <div className='todos__botones mt-4'>
              <button
                name='details'
                className='botones actualizar'
                onClick={handleChangeView}
              >
                Detalles del pedido
              </button>
            </div>
            <div className='todos__botones mt-4'>
              <button
                name='details'
                className='botones actualizar'
                onClick={handleNewVenta}
              >
                Generar venta
              </button>
            </div>
            <div className='todos__botones mt-4'>
              <button
                name='details'
                className='botones cancelar'
                onClick={handleVentas}
              >
                Atrás
              </button>
            </div>
          </div>
        )}
        </div>
        <div className='col-8'>
          {viewValue === 1 ? (
            <DetallePedido pedido={pedido} />
          ) : (viewValue === 2 ? (
            <AsignarTransportista pedido={pedido} />
          ) : (
            <CostoEnvio 
              pedido={pedido}
              history={history} 
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BodegaVentaDelivery1;
