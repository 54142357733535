import TiendaAdminGrafico from "../graficos/TiendaAdminGrafico";

const Usuarios = () => {
  return (
    <div className="tiendaAdmin">
      {/* <p>BIENVENIDO</p>
      <strong>Administrador</strong> */}
      <div className="divGeneral">
        <div className="div1">
          <div className="evaluacion sombra">
            <p>Evaluación de Numero de Usuarios</p>

            <label htmlFor="">Ubicación</label>
            <div className="contenedor">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Departamento
                </label>
              </div>
              <select class="form-select" aria-label="Default select example">
                <option selected>Todos</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>

            <div className="contenedor">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Provincia
                </label>
              </div>
              <select class="form-select" aria-label="Default select example">
                <option selected>Todos</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>

            <div className="contenedor">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Distrito
                </label>
              </div>
              <select class="form-select" aria-label="Default select example">
                <option selected>Todos</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>

            <div class="form-floating">
              <textarea
                class="form-control"
                placeholder="Leave a comment here"
                id="floatingTextarea"
              ></textarea>
            </div>
          </div>
          <div className="periodo sombra">
            <p>Período de tiempo</p>
            <div className="periodoDias">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Días
                </label>
              </div>
              <label className="etiqueta" htmlFor="">Inicio</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Todos</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>

              <label className="etiqueta" htmlFor="">Fin</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Todos</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            {/* ---------------- */}

            <div className="periodoMes">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Mes
                </label>
              </div>
              <label className="etiqueta" htmlFor="">Inicio</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Todos</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>

              <label className="etiqueta" htmlFor="">Fin</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Todos</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            {/* ---------------- */}
            <div className="periodoAnio">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Años
                </label>
              </div>
              <select class="form-select" aria-label="Default select example">
                <option selected>Seleccionar</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
        </div>

        <div className="div2">
          <div className="evolucion sombra">
            <p>Evolución de números de usuarios en el tiempo</p>
            <TiendaAdminGrafico />
            {/* <div className="botones">
            <button type="button" class="btn btn-primary">
              Actualizar
            </button>
            <button type="button" class="btn btn-primary">
              Exportar a excel
            </button>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usuarios;
