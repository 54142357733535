import React from 'react';
import img from '../assets/404/notfound.svg';

const Error404 = () => {
  return (
    <div className='404' style={{ textAlign: 'center' }}>
      <img src={img} alt='img' style={{ width: '60%' }}></img>
      <h3 className='mt-4'>Pagina no encontrada</h3>
    </div>
  );
};

export default Error404;
