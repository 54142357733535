import React from 'react';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import gloria from '../../../assets/marcas/gloria.jpg';
import { customStyles } from '../../../helpers/TableConst';

const NosotrosModal = ({ open, setOpen }) => {
   const closeModal = () => {
      setOpen(false);
   };

   return (
      <Modal
         isOpen={open}
         onRequestClose={closeModal}
         style={customStyles}
         className="modal modal-logosEmpresas"
         overlayClassName="modal-fondo"
         closeTimeoutMS={200}
         preventScroll={true}
      >
         <div className="container">
            <ToastContainer />
            <div className="row">
               <div className="nosotros__informacion">
                  <div className="nosotros__editar">
                     <h3>Marcas que trabajan con nosotros</h3>
                  </div>
                  <div className="nosotros__marcas">
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                     <div className="nosotros__marcasimg">
                        <img src={gloria} alt="gloria" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Modal>
   );
};

export default NosotrosModal;
