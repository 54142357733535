/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Scanner from '../../../helpers/Scanner';
import Select from 'react-select';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { customStyles } from '../../../helpers/TableConst';
import { UploadAvatar } from '../../../helpers/UploadImage';
import {
  fetchConToken,
  fetchConFornDataToken,
  fetchSinToken,
} from '../../../api/consultas';

Modal.setAppElement('#root');
const AdminProductoseditar = ({
  editarMProducto,
  editarProducto,
  setEditarMProducto,
  getProductos,
}) => {
  const [categoria, setCategoria] = useState([]);
  const [subcategoria, setSubcategoria] = useState([]);
  const [selectedSupercategoria,setSelectedSupercategoria] = useState(null);
  const [selectedCategoria,setSelectedCategoria] = useState(null);
  const [listCategoria, setListCategoria] = useState([]);
  const [listSubcategoria, setListSubcategoria] = useState([]);
  const [listMarca, setListMarca] = useState([]);
  const [listSupercategoria, setListSupercategoria] = useState([]);
  const [listUnidadMedida, setListUnidadMedida] = useState([]);
  const [foto, setAvatar] = useState(editarProducto.foto);
  const [listProveedores, setlistProveedores] = useState([]);
  const [proveedor, setproveedor] = useState(0);
  const [producto, setProducto] = useState({
    cantidad: editarProducto.cantidad,
    codebar: editarProducto.codebar,
    descripcion: editarProducto.descripcion,
    foto: editarProducto.foto,
    marca_id: editarProducto.marca_id,
    subcategoria_id: editarProducto.subcategoria_id,
    unidad_medida: editarProducto.unidad_medida_id,
    categoria_id: editarProducto.subcategoria.categoria_id,
    supercategoria_id: editarProducto.subcategoria.categoria.supercategoria_id,
  });

  const [camera, setCamera] = useState(false);
  const [nivel1] = useState({
    label: editarProducto.supercategoria.nombre,
    value: editarProducto.supercategoria.id,
  });
  const [nivel2] = useState({
    label: editarProducto.subcategoria.categoria.nombre,
    value: editarProducto.subcategoria.categoria.id,
  });
  const [nivel3] = useState({
    label: editarProducto.subcategoria.nombre,
    value: editarProducto.subcategoria.id,
  });
  const [nivel4] = useState({
    label: editarProducto.marca.nombre,
    value: editarProducto.marca_id,
  });
  const [nivel5] = useState({
    label: editarProducto.unidad_medida.nombre,
    value: editarProducto.unidad_medida_id,
  });

  const getSupercategoria = () => {
    fetchConToken('supercategorias/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.data.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
          };
        });
        setListSupercategoria(listdatos);
      });
  };

  const getCategoria = () => {
    fetchConToken('categorias/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.data.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
            supercategoria_id: data.supercategoria_id,
          };
        });
        setListCategoria(listdatos);
      });
  };

  const getSubcategoria = () => {
    fetchConToken('subcategorias/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.data.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
            categoria_id: data.categoria_id,
          };
        });
        setListSubcategoria(listdatos);
      });
  };

  const getMarca = () => {
    fetchConToken('marcas/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.data.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
          };
        });
        setListMarca(listdatos);
      });
  };

  const getUnidadMedida = () => {
    fetchSinToken('unidad_medida/get')
      .then((data) => data.json())
      .then((resp) => {
        const listdatos = resp.resp.map((data) => {
          return {
            ...data,
            value: data.id,
            label: data.nombre,
          };
        });
        setListUnidadMedida(listdatos);
      });
  };

  const getProveedores = () => {
    fetchConToken('empresa/get')
      .then((data) => data.json())
      .then((resp) => setlistProveedores(resp.data));
  };

  useEffect(() => {
    getProveedores();
    getUnidadMedida();
    getSupercategoria();
    getCategoria();
    getSubcategoria();
    getMarca();
  }, []);

  const updateProducto = () => {
    const formData = new FormData();
    formData.set('descripcion', producto.descripcion || '');
    formData.set('codebar', producto.codebar || '');
    formData.set('foto', foto ? foto.file : '');
    formData.set('subcategoria_id', producto.subcategoria_id || '');
    formData.set('marca_id', producto.marca_id || '');
    formData.set('unidad_medida_id', producto.unidad_medida_id || '');
    formData.set('cantidad', producto.cantidad || '');
    try {
      fetchConFornDataToken(
        'productos/update/' + editarProducto.id,
        formData,
        'POST'
      ).then(() => {
        toast.success('Producto Actualizado!', {
          position: toast.POSITION.TOP_RIGTH,
        });
        closeModal();
        getProductos();
      });
    } catch (error) {
      toast.error('Ocurrio un error al actualizar!', {
        position: toast.POSITION.TOP_RIGTH,
      });
    }
  };

  const onDetected = (result) => {
    setProducto({
      codebar: parseInt(result),
    });
  };

  const handleCamera = (e) => {
    e.preventDefault();
    setCamera(!camera);
  };

  useEffect(() => {
    if (foto) {
      setProducto({ ...producto, foto });
    }
  }, [foto, setProducto]);

  const handleOnChange = (selectedOption, name) => {
    if (name === 'supercategoria_id' && selectedOption) {
      setSelectedSupercategoria(parseInt(selectedOption.value));
      const data = listCategoria.filter(
        (item) => item.supercategoria_id === parseInt(selectedOption.value)
      );
      setCategoria(data);
    } else if (name === 'categoria_id' && selectedOption) {
      setSelectedCategoria(parseInt(selectedOption.value));
      const data = listSubcategoria.filter(
        (item) => item.categoria_id === parseInt(selectedOption.value)
      );
      setSubcategoria(data);
    }
    if (selectedOption) {
      setProducto({
        ...producto,
        [name]: selectedOption.value,
      });
    }
  };

  const handleChange = (e) => {
    setProducto({
      ...producto,
      [e.target.name]: e.target.value
    })
  }

  const closeModal = () => {
    setEditarMProducto(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProducto();
  };

  return (
    <Modal
      isOpen={editarMProducto}
      onRequestClose={closeModal}
      style={customStyles}
      className='modal modal__producto'
      overlayClassName='modal-fondo'
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className='container'>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='adminproductos__creartitle'>
              <h3>EDITAR PRODUCTO</h3>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <div className='adminproductos__numero'>
                <div className='adminproductos__selectreact'>
                  <label>Supercategoría</label>
                  <Select
                    placeholder='Seleccionar'
                    defaultValue={nivel1}
                    onChange={(e) => handleOnChange(e, 'supercategoria_id')}
                    options={listSupercategoria}
                    isClearable
                  />
                </div>
                <div className='adminproductos__selectreact'>
                  <label>Categoría</label>
                  <Select
                    placeholder='Seleccionar'
                    defaultValue={nivel2}
                    onChange={(e) => handleOnChange(e, 'categoria_id')}
                    options={categoria}
                    isClearable
                  />
                </div>
                <div className='adminproductos__selectreact'>
                  <label>Sub-Categoría</label>
                  <Select
                    placeholder='Seleccionar'
                    defaultValue={nivel3}
                    onChange={(e) => handleOnChange(e, 'subcategoria_id')}
                    options={subcategoria}
                    isClearable
                  />
                </div>
                <div className='adminproductos__selectreact'>
                  <label>Marca</label>
                  <Select
                    placeholder='Seleccionar'
                    defaultValue={nivel4}
                    onChange={(e) => handleOnChange(e, 'marca_id')}
                    options={listMarca}
                    isClearable
                  />
                </div>
              </div>
              <div className=''>
                <label>Cargar imagen del producto</label>
                <div
                  className='nosotros__img'
                  style={{
                    width: '100px',
                    margin: 'auto',
                    height: '300px',
                  }}
                >
                  <UploadAvatar avatar={foto} setAvatar={setAvatar} />
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <div className='adminproductos__numero'>
                <div className='adminproductos__selectreact'>
                  <p className='adminproductos__pa'>Tipo de unidades</p>
                  <Select
                    placeholder='Seleccionar'
                    defaultValue={nivel5}
                    onChange={(e) => handleOnChange(e, 'unidad_medida_id')}
                    options={listUnidadMedida}
                    isClearable
                  />
                </div>
                <div className='adminproductos__selectoption'>
                  <textarea
                    placeholder='Descripcion'
                    defaultValue={producto.descripcion}
                    onChange={(e) => handleChange(e)}
                    name='descripcion'
                  ></textarea>
                </div>
                {/* <div className='adminproductos__selectoption'>
                  <p className='adminproductos__pa'>Cantidad</p>
                  <input
                    type='text'
                    defaultValue={producto.cantidad}
                    onChange={handleOnChange}
                    name='cantidad'
                  />
                </div> */}
                <div className='adminproductos__selectoption'>
                  <label>Proveedor</label>
                  <select
                    className='form-select'
                    disabled
                    onChange={(e) => {
                      setproveedor(e.target.value);
                    }}
                    name='proveedor'
                    value={
                      editarProducto.proveedores[0] &&
                      editarProducto.proveedores[0].empresa.id
                    }
                    isClearable
                    required
                  >
                    <option key={0} value=''>
                      Seleccionar
                    </option>
                    {listProveedores &&
                      listProveedores.map((data) => {
                        return (
                          <option key={data.id} value={data.id}>
                            {data.razon_social}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className='nosotros__imagenes'>
                <label>
                  Cargar barra del producto
                  <button onClick={handleCamera} disabled>
                    <i
                      className={camera ? 'fas fa-camera' : 'fas fa-camera'}
                    ></i>
                  </button>
                </label>
                <div className='adminproductos__selectoption'>
                  <input
                    type='text'
                    className='adminproductos__inputs mt-3'
                    defaultValue={producto.codebar}
                    onChange={handleChange}
                    name='codebar'
                  />
                  <div className='camera'>
                    {camera && <Scanner onDetected={onDetected} />}
                  </div>
                </div>
              </div>
            </div>
            <div className='todos__botones'>
              <button className='botones cancelar me-1' onClick={closeModal}>
                Cancelar
              </button>
              <button
                className='botones actualizar ms-1'
                type='submit'
                onChange={handleSubmit}
              >
                Actualizar
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AdminProductoseditar;
