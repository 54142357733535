/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { fetchSinToken } from '../../api/consultas';
import { MultiSelect } from 'primereact/multiselect';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

const Lugares = () => {
  const [listDepartamentos, setListDepartamentos] = useState([]);
  const [listProvincias, setListProvincias] = useState([]);
  const [listDistritos, setListDistritos] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState(null);
  const [selectedProvincs, setSelectedProvincs] = useState([]);
  const [selectedDistrits, setSelectedDistrits] = useState([]);
  // input0
  const [input0, setInput0] = useState(true);
  const [input1, setInput1] = useState(true);
  const [input2, setInput2] = useState(true);

  const [lugares] = useState({
    departamento: false,
    provincia: false,
    distrito: false,
  });

  const { departamento, provincia, distrito } = lugares;

  const getDepartamento = () => {
    fetchSinToken('departamentos/get')
      .then((data) => data.json())
      .then((data) => {
        const listdatos = data.data.map((data) => {
          return {
            ...data,
            name: data.departamento,
            code: data.id,
          };
        });
        setListDepartamentos(listdatos);
      });
  };

  useEffect(() => {
    getDepartamento();
  }, []);

  const handleCheckbox = () => {
    const departament = document.getElementById('only-one-place0');
    const province = document.getElementById('only-one-place1');
    const district = document.getElementById('only-one-place2');

    if (departament.id === 'only-one-place0' && departament.name === 'departamento') {
      if (departament.checked === false) {
        document.getElementById('only-one-place0').checked = false;
        province.checked = false;
        district.checked = false;
        setInput0(true);
        province.setAttribute('disabled', 'disabled');
      } else if (departament.checked === true) {
        document.getElementById('only-one-place0').checked = true;
        setInput0(false);
        province.removeAttribute('disabled');
      }
    }

    if (province.id === 'only-one-place1' && province.checked === true) {
      setInput1(false);
      district.removeAttribute('disabled');
    } else if (province.id === 'only-one-place1' && province.checked === false) {
      setInput1(true);
      district.checked = false;
      district.setAttribute('disabled', 'disabled');
    }

    if (district.id === 'only-one-place2' && district.checked === true) {
      setInput2(false);
    } else if (district.id === 'only-one-place2' && district.checked === false) {
      setInput2(true);
    }
  };

  const handleChangeLugares = (e) => {
    if (e.target.name === 'selectDepartamento') {
      setSelectedDepartments(e.target.value);
    }
  };
  useEffect(() => {
    setSelectedProvincs(listProvincias);
    setSelectedDistrits(listDistritos);
  }, [listProvincias, listDistritos]);

  const groupedItemTemplate = (option) => {
    return (
      <div className='p-d-flex p-ai-center country-item'>
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <div className='adminusuario__numero'>
      <p className='adminusuario__p subtitle'>Ubicación:</p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignTtems: 'center',
        }}
      >
        <div>
          <input
            type='checkbox'
            className='form-check-input'
            name='departamento'
            id='only-one-place0'
            value={departamento}
            onChange={(e) => handleCheckbox(e, 0)}
          />
          <label>Departamento</label>
        </div>
        <div
          className=''
          style={{
            width: '70% ',
          }}
        >
          <MultiSelect
            id='input0'
            value={selectedDepartments}
            options={listDepartamentos}
            name='selectDepartamento'
            placeholder='Seleccionar '
            onChange={(e) => handleChangeLugares(e)}
            optionLabel='name'
            maxSelectedLabels={3}
            disabled={input0}
            style={{
              padding: 0,
              margin: 0,
              width: '100%',
              height: '30px',
              lineHeight: '5px',
              fontSize: '12px',
              border: '1px solid rgba(0,0,0, .12)',
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          'justify-content': 'space-between',
          'align-items': 'center',
        }}
      >
        <div>
          <input
            type='checkbox'
            className='form-check-input'
            name='provincia'
            id='only-one-place1'
            value={provincia}
            onChange={(e) => handleCheckbox(e, 1)}
          />
          <label>Provincia</label>
        </div>
        <div
          className=''
          style={{
            width: '70% ',
          }}
        >
          <MultiSelect
            id='input1'
            value={listProvincias}
            options={selectedDepartments === null ? [] : selectedDepartments}
            onChange={(e) => setListProvincias(e.value)}
            optionLabel='provincia'
            optionGroupLabel='departamento'
            optionGroupChildren='provincias'
            optionGroupTemplate={groupedItemTemplate}
            placeholder='Seleccionar'
            disabled={input1}
            style={{
              padding: 0,
              margin: 0,
              width: '100%',
              height: '30px',
              lineHeight: '5px',
              fontSize: '12px',
              border: '1px solid rgba(0,0,0, .12)',
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          'justify-content': 'space-between',
          'align-items': 'center',
        }}
      >
        <div>
          <input
            type='checkbox'
            className='form-check-input'
            name='distrito'
            id='only-one-place2'
            value={distrito}
            onChange={(e) => handleCheckbox(e, 2)}
          />
          <label>Distrito</label>
        </div>
        <div
          className=''
          style={{
            width: '70% ',
          }}
        >
          <MultiSelect
            id='input2'
            value={listDistritos}
            options={selectedProvincs === null ? [] : selectedProvincs}
            onChange={(e) => setListDistritos(e.value)}
            optionLabel='distrito'
            optionGroupLabel='provincia'
            optionGroupChildren='distritos'
            optionGroupTemplate={groupedItemTemplate}
            placeholder='Seleccionar'
            disabled={input2}
            style={{
              padding: 0,
              margin: 0,
              width: '100%',
              height: '30px',
              lineHeight: '5px',
              fontSize: '12px',
              border: '1px solid rgba(0,0,0, .12)',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Lugares;
