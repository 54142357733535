import React from 'react';
import Slider from 'react-slick';
import companylogo1 from '../assets/marcas/aje.png';
import companylogo2 from '../assets/marcas/ajinomoto.png';
import companylogo3 from '../assets/marcas/alicorp.svg';
import companylogo4 from '../assets/marcas/gloria.png';
import companylogo5 from '../assets/marcas/laive.png';
import companylogo6 from '../assets/marcas/backus.jpg';
import companylogo7 from '../assets/marcas/nestle.jpg';
import companylogo8 from '../assets/marcas/redondos.jpg';
import companylogo9 from '../assets/marcas/index3.jpg';
import { useState } from 'react';
import NosotrosModal from '../components/admin/nosotros/NosotrosModal';

const CompanyLogobasboard = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const settings = {
    dots: true,
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 5,
    centerPadding: '70px',
    slidesToScroll: 1,
    autoplay: true,
    speed: 900,
    autoplaySpeed: 3500,
    cssEase: 'linear',
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className=" contenedor-imagenes">
        <h5 className="my-4">
          En esta plataforma puedes encontrar los productos de las siguientes
          marcas:{' '}
          <i
            className="fas fa-pencil-alt"
            onClick={handleClick}
            style={{ cursor: 'pointer', color: '#FF8800' }}
          ></i>
        </h5>
        <Slider {...settings}>
          <div>
            <img
              src={companylogo1}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
          <div>
            <img
              src={companylogo2}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
          <div>
            <img
              src={companylogo3}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
          <div>
            <img
              src={companylogo4}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
          <div>
            <img
              src={companylogo5}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
          <div>
            <img
              src={companylogo6}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
          <div>
            <img
              src={companylogo7}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
          <div>
            <img
              src={companylogo8}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
          <div>
            <img
              src={companylogo9}
              alt=""
              style={{
                width: '170px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0px 2px 10px 0px rgba(80, 80, 80, 0.11)',
                padding: '12px',
                height: '120px',
              }}
            />
          </div>
        </Slider>
      </div>
      {open && <NosotrosModal open={open} setOpen={setOpen} />}
    </>
  );
};

export default CompanyLogobasboard;
