/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { customStyles } from "../../../helpers/TableConst";
import Mapa from "../../../helpers/Mapa";
import { fetchConFornDataToken } from "../../../api/consultas";
import { toast, ToastContainer } from "react-toastify";
import { ImageEncargado } from "./ImageEncargado";
import { ImageLocal } from "./ImageLocal";
import Swal from "sweetalert2";

const MAdminBodegasTiendas = ({ isModal, setIsModal, datos, getListLocal }) => {
  const auth = useSelector((state) => state.auth);
  const [seePassword, setseePassword] = useState("password");
  const [slider, setSlider] = useState(datos.distancia_delivery || 0);
  const { user } = auth;
  const { administrador } = user;
  const [foto_encargado, setAvatar] = useState(datos.foto_encargado);
  const [foto_local, setAvatars1] = useState(datos.foto_local);
  const [address, setAddress] = useState("");
  const [empresa] = useState(administrador[0].empresa);
  const [btn, setBtn] = useState("Validar");
  const [arrayDay, setArrayDay] = useState(
    datos?.atenciones?.map((item) => {
      return {
        dia_id: item.dia_id,
        apertura: item.apertura,
        cierre: item.cierre,
        estado: 1,
      };
    })
  );

  const [dataMapa, setDataMapa] = useState({
    lat: parseFloat(datos.latitud) || 0,
    lng: parseFloat(datos.longitud) || 0,
  });

  const [local, setLocal] = useState({
    direccion: datos.direccion ? datos.direccion : address ? address : "",
    numero_doc:
      datos && datos.encargados ? datos.encargados[0]?.persona.numero_doc : "",
    nombre:
      datos && datos.encargados ? datos.encargados[0]?.persona.nombre : "",
    apellido_pat:
      datos && datos.encargados
        ? datos.encargados[0]?.persona.apellido_pat
        : "",
    apellido_mat:
      datos && datos.encargados
        ? datos.encargados[0]?.persona.apellido_mat
        : "",
    email: datos && datos.encargados ? datos.encargados[0]?.persona.email : "",
    celular:
      datos && datos.encargados ? datos.encargados[0]?.persona.celular : "",
    telefono:
      datos && datos.encargados ? datos.encargados[0]?.persona.telefono : "",
    username:
      datos && datos.encargados ? datos.encargados[0]?.usuario.username : "",
    password: "",
    ingreso_producto:
      datos && datos.encargados ? datos.encargados[0]?.ingreso_producto : "",
    creacion_promocion:
      datos && datos.encargados ? datos.encargados[0]?.creacion_promocion : "",
    delivery: datos.delivery || "",
    local_name: datos.local_nombre || "",
    tipo_documento_id: 1,
    foto_encargado: datos.foto_encargado || "",
    foto_local: datos.foto_local || "",
  });

  const [cambiarValor, setCambiarValor] = useState(datos.delivery || 0);

  const {
    direccion,
    numero_doc,
    nombre,
    apellido_pat,
    apellido_mat,
    email,
    celular,
    telefono,
    tipo_documento_id,
    username,
    password,
    ingreso_producto,
    creacion_promocion,
    delivery,
    local_name,
  } = local;

  const validarDocumento = async (e) => {
    e.preventDefault();
    setBtn("...");
    try {
      const data = await fetch(
        `https://dniruc.apisperu.com/api/v1/dni/${local.numero_doc}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJpeWV5aW0zMTlAY2FycGV0ZC5jb20ifQ.NaXtG7Nokt0tybRlEkuffmsKPjX4Gk8k-2pX7Rgn3j0`
      ).then((data) => data.json());
      setLocal({
        ...local,
        nombre: data.nombres,
        apellido_pat: data.apellidoPaterno,
        apellido_mat: data.apellidoMaterno,
      });
      toast.success("Documento Validado", {
        position: toast.POSITION.TOP_RIGTH,
      });
      return data;
    } catch {
      toast.error("Documento no encontrado", {
        position: toast.POSITION.TOP_RIGTH,
      });
      setLocal({
        ...local,
        nombre: "",
        apellido_pat: "",
        apellido_mat: "",
      });
    } finally {
      setBtn("Validar");
    }
  };

  const handleOnChange = (e) => {
    setLocal(() => ({
      ...local,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === "delivery") {
      if (parseInt(e.target.value) === 1) {
        setCambiarValor(1);
      } else {
        setCambiarValor(0);
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("local_nombre", local.local_name || "");
    formData.set("direccion", local.direccion || "");
    formData.set("numero_doc", local.numero_doc || "");
    formData.set("nombre", local.nombre || "");
    formData.set("apellido_pat", local.apellido_pat || "");
    formData.set("apellido_mat", local.apellido_mat || "");
    formData.set("email", local.email || "");
    formData.set("celular", local.celular || "");
    formData.set("telefono", local.telefono || "");
    formData.set("foto_encargado", foto_local.file || "");
    formData.set("foto_local", foto_encargado.file || "");
    formData.set("username", local.username || "");
    formData.set("password", local.password || "");
    formData.set("ingreso_producto", local.ingreso_producto || "");
    formData.set("creacion_promocion", local.creacion_promocion || "");
    formData.set("delivery", local.delivery || "");
    if (local.delivery === "0") {
      formData.set("distancia_delivery", 0);
    } else {
      formData.set("distancia_delivery", slider || "");
    }
    formData.set("tipo_documento_id", 1);
    formData.set("latitud", dataMapa.lat || "");
    formData.set("longitud", dataMapa.lng || "");
    arrayDay.map((a, index) => {
      formData.set(`atenciones[${index}][dia_id]`, Number(a.dia_id));
      formData.set(`atenciones[${index}][apertura]`, a.apertura);
      formData.set(`atenciones[${index}][cierre]`, a.cierre);
      formData.set(`atenciones[${index}][estado]`, a.estado);
    });
    if (
      direccion === "" ||
      numero_doc === "" ||
      nombre === "" ||
      apellido_pat === "" ||
      apellido_mat === "" ||
      email === "" ||
      celular === "" ||
      telefono === "" ||
      tipo_documento_id === "" ||
      foto_encargado === null ||
      foto_local === null ||
      ingreso_producto === "" ||
      creacion_promocion === "" ||
      delivery === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "!Error¡",
        text: "Los campos no deben estar vacios.",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cerrar",
      });
    } else {
      if (datos.empresa_id) {
        fetchConFornDataToken(
          "empresa/locales/update/" + datos.id,
          formData,
          "POST"
        )
          .then((resp) => resp.json())
          .then(() => {
            closeModal();
            getListLocal();
            toast.success("Local actualizado!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        fetchConFornDataToken(
          "empresa/locales/createLocalBodega",
          formData,
          "POST"
        )
          .then((resp) => resp.json())
          .then((resp) => {
            if (resp.resp === "el encargado existe, asignar otro") {
              toast.warn("Las crendenciales existe, cambien otro", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (resp.resp === "Local Creado") {
              closeModal();
              getListLocal();
              toast.success("Local creado!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (
              resp.resp === "Excedio el limite de locales aperturados"
            ) {
              toast.warn("Excedio el limite de locales aperturados", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(() => {
            toast.success("Hable con el administrador", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const handleCheckbox = (e, numero) => {
    const arreglos = arrayDay || [];
    if (e.target.checked) {
      arreglos.push({
        dia_id: numero,
        apertura: "",
        cierre: "",
        estado: 1,
      });
      setArrayDay([...arreglos]);
    } else {
      if (arrayDay.length > 0) {
        const position = arrayDay.findIndex(
          (arreglos) => arreglos.dia_id === numero
        );
        arreglos[position].estado = 0;
        setArrayDay([...arreglos]);
      } else {
        setArrayDay([]);
        arreglos.pop();
      }
    }

    if (document.getElementById(`checkbox${numero}`).checked === true) {
      document.getElementById(`apertura${numero}`).removeAttribute("disabled");
      document.getElementById(`cierre${numero}`).removeAttribute("disabled");
    } else {
      document
        .getElementById(`apertura${numero}`)
        .setAttribute("disabled", "disabled");
      document
        .getElementById(`cierre${numero}`)
        .setAttribute("disabled", "disabled");
    }
  };

  const checkedDia = (numero) => {
    const value = datos.atenciones?.map((item) => item.dia.id).includes(numero);
    if (value === true) {
      document
        ?.getElementById(`apertura${numero}`)
        ?.removeAttribute("disabled");
      document?.getElementById(`cierre${numero}`)?.removeAttribute("disabled");
    }
    return value;
  };

  const checkedTime = (numero, type) => {
    if (datos && datos.atenciones) {
      const arreglos = [...arrayDay];
      const value = arreglos.filter((item) => item.dia_id === numero);
      if (type === "apertura") {
        return value[0]?.apertura.slice(0, -3) || "";
      } else {
        return value[0]?.cierre.slice(0, -3) || "";
      }
    }
  };

  const changeStatePassword = (value, setValue) => {
    if (value === 'text') {
       setValue('password');
    } else {
       setValue('text');
    }
 };

  const handleApertura = (e, id) => {
    const arreglos = [...arrayDay];
    let position = arreglos.findIndex((arreglo) => arreglo.dia_id === id);
    arreglos[position].apertura = e.target.value;

    setArrayDay([...arreglos]);
  };
  const handleCierre = (e, id) => {
    const arreglos = [...arrayDay];

    let position = arrayDay.findIndex((arreglo) => arreglo.dia_id === id);
    arreglos[position].cierre = e.target.value;

    setArrayDay([...arreglos]);
  };
  useEffect(() => {
    if (foto_encargado) {
      setLocal({ ...local, foto_encargado });
    }
  }, [foto_encargado, setLocal]);

  useEffect(() => {
    if (foto_local) {
      setLocal({ ...local, foto_local });
    }
  }, [foto_local, setLocal]);

  return (
    <Modal
      isOpen={isModal}
      onRequestClose={closeModal}
      style={customStyles}
      className="modal modal__tienda"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className="container">
        <ToastContainer />
        <div className="">
          <form className=" row sombra">
            <div className="adminproductos__creartitle">
              <h3 className="">
                {!datos.empresa_id ? "CREAR" : "EDITAR"} LOCAL
              </h3>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
              <div className="">
                <p className="adminusuario__p">Datos generales:</p>
                <div className="adminbodega__tiendasalinear">
                  <label>Nombre Comercial:</label>
                  <input
                    type="text"
                    defaultValue={empresa.nombre_comercial}
                    disabled
                  />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>RUC:</label>
                  <input type="text" defaultValue={empresa.ruc} disabled />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>Representante legal:</label>
                  <input
                    type="text"
                    defaultValue={empresa.representante_legal}
                    disabled
                  />
                </div>
              </div>
              {/*  */}
              <div className="mt-4">
                <p className="adminusuario__p">Crear Local:</p>
                <div className="adminbodega__tiendasalinear">
                  <label>Nombre del local:</label>
                  <input
                    type="text"
                    value={local_name}
                    onChange={handleOnChange}
                    name="local_name"
                  />
                </div>
              </div>
              {/*  */}
              <div className="mt-4">
                <p className="adminusuario__p">Crear usuario y contraseña:</p>
                <div className="adminbodega__tiendasalinear">
                  <label>Usuario:</label>
                  <input
                    type="text"
                    value={username}
                    onChange={handleOnChange}
                    name="username"
                    disabled={
                      datos.encargados && datos.encargados[0]?.usuario.username
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>Contraseña:</label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "342px",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <input
                      type={seePassword}
                      value={password}
                      onChange={handleOnChange}
                      name="password"
                    />
                    <div
                      onClick={() => {
                        changeStatePassword(seePassword, setseePassword);
                      }}
                      style={{
                        width: "100px",
                      }}
                    >
                      {seePassword === "text" ? (
                        <i className="fas fa-eye-slash"></i>
                      ) : (
                        <i className="fas fa-eye"></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="mt-4">
                <p className="adminusuario__p">Datos del encargado:</p>
                <div className="adminbodega__tiendasalinear">
                  <label>DNI:</label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "342px",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <input
                      type="text"
                      value={numero_doc}
                      onChange={handleOnChange}
                      name="numero_doc"
                      style={{
                        width: "250px !important",
                      }}
                    />
                    <div
                      className="todos__botones"
                      style={{
                        width: "100px",
                      }}
                    >
                      <button
                        className="actualizar botones"
                        onClick={validarDocumento}
                      >
                        {btn}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>Nombre:</label>
                  <input
                    type="text"
                    value={nombre}
                    onChange={handleOnChange}
                    name="nombre"
                    disabled
                  />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>Apellido Paterno:</label>
                  <input
                    type="text"
                    value={apellido_pat}
                    onChange={handleOnChange}
                    name="apellido_pat"
                    disabled
                  />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>Apellido Materno:</label>
                  <input
                    type="text"
                    value={apellido_mat}
                    onChange={handleOnChange}
                    name="apellido_mat"
                    disabled
                  />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>Email:</label>
                  <input
                    type="text"
                    value={email}
                    onChange={handleOnChange}
                    name="email"
                  />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>Celular:</label>
                  <input
                    type="text"
                    value={celular}
                    onChange={handleOnChange}
                    name="celular"
                  />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <label>Telefono:</label>
                  <input
                    type="text"
                    value={telefono}
                    onChange={handleOnChange}
                    name="telefono"
                  />
                </div>
              </div>
              {/*  */}
              <div className="mt-4">
                <p className="adminusuario__p">
                  Otorgar capacidad de decisión del encargado:
                </p>
                <div className="adminbodega__tiendasalineartres">
                  <label>Ingreso de productos: </label>
                  <div>
                    <label>Si</label>
                    <input
                      type="radio"
                      value={1}
                      // checked={ingreso_producto === 1}
                      defaultChecked={ingreso_producto === 1}
                      onChange={handleOnChange}
                      name="ingreso_producto"
                      className="form-check-input"
                    />
                  </div>
                  <div>
                    <label>No</label>
                    <input
                      type="radio"
                      value={0}
                      // checked={ingreso_producto === 0}
                      onChange={handleOnChange}
                      defaultChecked={ingreso_producto === 0}
                      name="ingreso_producto"
                      className="form-check-input"
                    />
                  </div>
                </div>
                <div className="adminbodega__tiendasalineartres">
                  <label>Creación de promociones: </label>
                  <div>
                    <label>Si</label>
                    <input
                      type="radio"
                      value={1}
                      onChange={handleOnChange}
                      defaultChecked={creacion_promocion === 1}
                      name="creacion_promocion"
                      className="form-check-input"
                    />
                  </div>
                  <div>
                    <label>No</label>
                    <input
                      type="radio"
                      value={0}
                      defaultChecked={creacion_promocion === 0}
                      onChange={handleOnChange}
                      name="creacion_promocion"
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="mt-4">
                <h6>Localización:</h6>
                <div className="adminbodega__tiendasalinear">
                  <label>Dirección</label>
                  <input
                    type="text"
                    name="direccion"
                    value={direccion}
                    onChange={handleOnChange}
                  />
                </div>
                <div>
                  {/* <label>Mi ubicación</label> */}
                  <div
                    className="adminbodega__tiendaimagen mt-3 "
                    // style={{ height: '200px' }}
                  >
                    <Mapa
                      dataMapa={dataMapa}
                      setDataMapa={setDataMapa}
                      setAddress={setAddress}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div>
                <p className="adminusuario__p">Información para la atención</p>
                <div className="adminbodega__tiendasalineartres">
                  <label>Delivery:</label>
                  <div>
                    <label>Si</label>
                    <input
                      type="radio"
                      value={1}
                      onChange={handleOnChange}
                      defaultChecked={delivery === 1}
                      name="delivery"
                      className="form-check-input"
                    />
                  </div>
                  <div>
                    <label>No</label>
                    <input
                      type="radio"
                      value={0}
                      defaultChecked={delivery === 0}
                      onChange={handleOnChange}
                      name="delivery"
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
              {/*  */}
              {cambiarValor === 1 ? (
                <div
                  className="mt-3"
                  id="delivery_display"
                  // style={{ display: 'none' }}
                >
                  <p className="adminusuario__p">Distancia de delivery:</p>
                  <div className="adminbodega__tiendasalinearinputs">
                    <label>
                      Delivery: <span>{slider} m</span>
                    </label>
                    <div>
                      <input
                        type="range"
                        min="0"
                        name="rangodelivery"
                        max="5000"
                        value={slider}
                        onChange={(e) => setSlider(e.target.value)}
                        class="form-range"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* --- DIAS ATENCION */}
              {/* ----------------- */}
              <div className="mt-4">
                <p className="adminusuario__p">Días de atención:</p>
                <div className="adminbodega__tiendatime">
                  <div>
                    <div>
                      <input
                        class="form-check-input"
                        id="checkbox1"
                        name="checkboxLunes"
                        onChange={(e) => handleCheckbox(e, 1)}
                        type="checkbox"
                        value={1}
                        defaultChecked={checkedDia(1)}
                      />
                      <label>Lunes</label>
                    </div>
                    <div className="time">
                      <div>
                        <label>Inicio:</label>
                        <input
                          type="time"
                          name="apertura"
                          disabled
                          id="apertura1"
                          defaultValue={checkedTime(1, "apertura")}
                          onChange={(e) => handleApertura(e, 1)}
                        />
                      </div>
                      <div>
                        <label className="mx-2">Fin:</label>
                        <input
                          type="time"
                          name="cierre"
                          disabled
                          id="cierre1"
                          defaultValue={checkedTime(1, "cierre")}
                          onChange={(e) => handleCierre(e, 1)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        class="form-check-input"
                        id="checkbox2"
                        name="checkboxMartes"
                        onChange={(e) => handleCheckbox(e, 2)}
                        type="checkbox"
                        value={2}
                        defaultChecked={checkedDia(2)}
                      />
                      <label>Martes</label>
                    </div>
                    <div className="time">
                      <label>Inicio:</label>
                      <input
                        type="time"
                        name="apertura"
                        disabled
                        id="apertura2"
                        defaultValue={checkedTime(2, "apertura")}
                        onChange={(e) => handleApertura(e, 2)}
                      />
                      <div>
                        <label className="mx-2">Fin:</label>
                        <input
                          type="time"
                          name="cierre"
                          disabled
                          id="cierre2"
                          defaultValue={checkedTime(2, "cierre")}
                          onChange={(e) => handleCierre(e, 2)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="checkboxMiercoles"
                        value={3}
                        id="checkbox3"
                        defaultChecked={checkedDia(3)}
                        onChange={(e) => handleCheckbox(e, 3)}
                      />
                      <label>Miercoles</label>
                    </div>
                    <div className="time">
                      <div>
                        <label className="">Inicio:</label>
                        <input
                          type="time"
                          name="apertura"
                          disabled
                          id="apertura3"
                          defaultValue={checkedTime(3, "apertura")}
                          onChange={(e) => handleApertura(e, 3)}
                        />
                      </div>
                      <div>
                        <label className="mx-2">Fin:</label>
                        <input
                          type="time"
                          name="cierre"
                          disabled
                          id="cierre3"
                          defaultValue={checkedTime(3, "cierre")}
                          onChange={(e) => handleCierre(e, 3)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="checkboxJueves"
                        value={4}
                        id="checkbox4"
                        defaultChecked={checkedDia(4)}
                        onChange={(e) => handleCheckbox(e, 4)}
                      />
                      <label>Jueves</label>
                    </div>
                    <div className="time">
                      <div>
                        <label className="">Inicio:</label>
                        <input
                          type="time"
                          name="apertura"
                          disabled
                          id="apertura4"
                          defaultValue={checkedTime(4, "apertura")}
                          onChange={(e) => handleApertura(e, 4)}
                        />
                      </div>
                      <div>
                        <label className="mx-2">Fin:</label>
                        <input
                          type="time"
                          name="cierre"
                          disabled
                          id="cierre4"
                          defaultValue={checkedTime(4, "cierre")}
                          onChange={(e) => handleCierre(e, 4)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="checkboxViernes"
                        value={5}
                        id="checkbox5"
                        defaultChecked={checkedDia(5)}
                        onChange={(e) => handleCheckbox(e, 5)}
                      />
                      <label>Viernes</label>
                    </div>
                    <div className="time">
                      <div>
                        <label className="">Inicio:</label>
                        <input
                          type="time"
                          name="apertura"
                          disabled
                          id="apertura5"
                          defaultValue={checkedTime(5, "apertura")}
                          onChange={(e) => handleApertura(e, 5)}
                        />
                      </div>
                      <div>
                        <label className="mx-2">Fin:</label>
                        <input
                          type="time"
                          name="cierre"
                          disabled
                          id="cierre5"
                          defaultValue={checkedTime(5, "cierre")}
                          onChange={(e) => handleCierre(e, 5)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="checkboxSabado"
                        value={6}
                        id="checkbox6"
                        defaultChecked={checkedDia(6)}
                        onChange={(e) => handleCheckbox(e, 6)}
                      />
                      <label>Sabado</label>
                    </div>
                    <div className="time">
                      <div>
                        <label className="">Inicio:</label>
                        <input
                          type="time"
                          name="apertura"
                          disabled
                          id="apertura6"
                          defaultValue={checkedTime(6, "apertura")}
                          onChange={(e) => handleApertura(e, 6)}
                        />
                      </div>
                      <div>
                        <label className="mx-2">Fin:</label>
                        <input
                          type="time"
                          name="cierre"
                          disabled
                          id="cierre6"
                          defaultValue={checkedTime(6, "cierre")}
                          onChange={(e) => handleCierre(e, 6)}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="checkboxDomingo"
                        value={7}
                        id="checkbox7"
                        defaultChecked={checkedDia(7)}
                        onChange={(e) => handleCheckbox(e, 7)}
                      />
                      <label className="">Domingo</label>
                    </div>
                    <div className="time">
                      <div>
                        <label className="">Inicio:</label>
                        <input
                          type="time"
                          name="apertura"
                          disabled
                          id="apertura7"
                          defaultValue={checkedTime(7, "apertura")}
                          onChange={(e) => handleApertura(e, 7)}
                        />
                      </div>
                      <div>
                        <label className="mx-2">Fin:</label>
                        <input
                          type="time"
                          name="cierre"
                          disabled
                          id="cierre7"
                          defaultValue={checkedTime(7, "cierre")}
                          onChange={(e) => handleCierre(e, 7)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}

              {/* FOTO */}
              {/* -------------- */}
              <div className="mt-4">
                <div className="adminbodega__tiendasalinear">
                  <p className="adminusuario__p">
                    Fotografía del encargado de atención:
                  </p>
                </div>
                <div className="adminbodega__tiendaimagen">
                  <ImageEncargado
                    avatar={foto_encargado}
                    setAvatar={setAvatar}
                    datos={datos}
                  />
                </div>
                <div className="adminbodega__tiendasalinear">
                  <p className="adminusuario__p">Fotografía del local:</p>
                </div>
                <div className="adminbodega__tiendaimagen mt-4">
                  <ImageLocal
                    avatar={foto_local}
                    setAvatar={setAvatars1}
                    datos={datos}
                  />
                </div>
                <div className="todos__botones mt-3">
                  <button
                    className="botones cancelar"
                    type="button"
                    onClick={closeModal}
                  >
                    Cancelar
                  </button>
                  <button className="botones actualizar" onClick={handleSubmit}>
                    {!datos.empresa_id ? "Crear" : "Actualizar"}
                  </button>
                </div>
              </div>
              {/*  */}
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default MAdminBodegasTiendas;
