/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { fetchConToken } from '../../../api/consultas';

function AdminBodegasTiendasNotificacion({ history }) {
  const { data } = useSelector((state) => state.localData);
  const [promociones, setPromociones] = useState([]);

  const getPromocionesPorLocal = () => {
    fetchConToken('promociones/local/get/' + data.id)
      .then((data) => data.json())
      .then((resp) => {
        /* const pendingpromocion = resp.data.filter((item)=> item.registro === 'I');
        setPromociones(pendingpromocion); */
        setPromociones(resp.data);
      })
  }

  const handleAceptar = (item) => {
    fetchConToken('promociones/aprobar/' + item.id)
      .then(() => {
        toast.success('Promoción aprobada exitosamente!', {
          position: toast.POSITION.TOP_RIGTH,
        })
        setTimeout(() => {
          getPromocionesPorLocal();
        }, 2000);
      })
      .catch(() => {
        toast.error('Se produjo un error', {
          position: toast.POSITION.TOP_RIGTH,
        });
      })
  };

  const handleRecharzar = (item) => {
    fetchConToken('promociones/rechazar/' + item.id)
      .then(() => {
        toast.warning('Promoción rechazada!', {
          position: toast.POSITION.TOP_RIGTH,
        });
      })
      .catch(() => {
        toast.error('Se produjo un error', {
          position: toast.POSITION.TOP_RIGTH,
        });
      })
  };

  const handleDeletePromocion = (item) => {
    fetchConToken('promociones/anular/' + item.id)
      .then(() => {
        toast.warning('Promoción anulada!', {
          position: toast.POSITION.TOP_RIGTH
        });
      })
      .catch(() => {
        toast.error('Se produjo un error', {
          position: toast.POSITION.TOP_RIGTH
        });
      })
  }

  useEffect(() => {
    getPromocionesPorLocal();
  }, [])

  return (
    <div className='container'>
      <ToastContainer />
      <div className='row justify-content-evenly'>
        {promociones && promociones.map((promocion) => {
          const { stock, ganancia_real, ganancia_promocion, precio_promocion, precio_real, estado } = promocion;
          return (
            <div className='card col-12 col-sm-12 col-md-5 col-lg-5 sombra mb-4'>
              <div className='card-body'>
                <h5 className='text-center'>{data.local_nombre}</h5>
                <table className='adminbodegatiendas__tiendas'>
                  <tbody>
                    <tr>
                      <td className=''>Dirección:</td>
                      <td className=''>{data.direccion}</td>
                    </tr>
                    <tr className='double'>
                      <td className=''>Responsable:</td>
                      <td className=''>{data.encargados[0].persona.nombre + ' ' + data.encargados[0].persona.apellido_pat}</td>
                    </tr>
                    <tr>
                      <td className=''>Stock:</td>
                      <td className=''>{stock}</td>
                    </tr>
                    <tr>
                      <td className=''>Precio esperado:</td>
                      <td className=''>{precio_real}</td>
                    </tr>
                    <tr>
                      <td className=''>Ganancia esperada:</td>
                      <td className=''>{ganancia_real}</td>
                    </tr>
                    <tr>
                      <td className=''>Precio de la promoción:</td>
                      <td className=''>{precio_promocion}</td>
                    </tr>
                    <tr>
                      <td className=''>Ganancia actual:</td>
                      <td className=''>{ganancia_promocion}</td>
                    </tr>
                  </tbody>
                </table>
                {estado && estado === 1 ?
                  <div className='todos__botones'>
                    <button className='botones actualizar' onClick={() => handleAceptar(promocion)}>
                      Aceptar
                    </button>
                    <button className=' botones delivery' onClick={() => handleRecharzar(promocion)}>
                      Rechazar
                    </button>
                  </div> :
                  <div className='todos__botones'>
                    <button className='eliminar' onClick={() => handleDeletePromocion(promocion)}>
                      <i className='far fa-trash-alt table__tablebutton eliminar'></i>
                    </button>
                  </div>
                }
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AdminBodegasTiendasNotificacion;
