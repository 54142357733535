// import React from 'react';
import bodega from '../../assets/estadisticas/Bodega.svg';
// import total from '../../assets/estadisticas/total.svg';
// import total1 from '../../assets';
// import ferreteria from '../../assets/estadisticas/ferreteria.svg';
// import farmacia from '../../assets/estadisticas/farmacia.svg';
// import ropa from '../../assets/estadisticas/Ropa.svg';
// import { MercadoPagoResponse } from 'mercadopago/utils/mercadopago-respose';

const Registro = ({ history }) => {
   // const handleTotales = () => {
   //   history.push('/admin/estadisticatotales');
   // };
   // const handleEvaluacion = () => {
   //   history.push('/admin/estadisticaevaluacion');
   // };

   const handleClick = () => {
      history.push('/adminbodega/planespago');
   };
   return (
      <div className="container">
         <div className="row">
            <div className=".cho-container">
               {/* <h2>modo de prueba</h2> */}
            </div>
            {/* <div className='col-sm-6 col-md-4 col-lg-3 adminestadistica__card '>
          <div
            className='card p-3'
            // onClick={handleTotales}
          >
            <div>
              <img src={total} className='card-img-top' alt='...' />
            </div>
            <h5 className='text-center'>Total</h5>
          </div>
        </div> */}
            <div
               className="col-sm-6 col-md-4 col-lg-3 adminestadistica__card "
               onClick={handleClick}
            >
               <div
                  className="card p-3"
                  // onClick={handleEvaluacion}
               >
                  <div>
                     <img src={bodega} className="card-img-top" alt="..." />
                  </div>
                  <h5 className="text-center">Bodega</h5>
               </div>
            </div>
            {/* <div
          className="col-sm-6 col-md-4 col-lg-3 adminestadistica__card"
          onClick={handleClick}
        >
          <div className="card p-3">
            <div>
              <img src={ferreteria} className="card-img-top" alt="..." />
            </div>
            <h5 className="text-center">Ferreteria</h5>
          </div>
        </div> */}
            {/* <div
          className="col-sm-6 col-md-4 col-lg-3 adminestadistica__card"
          onClick={handleClick}
        >
          <div className="card p-3">
            <div>
              <img src={farmacia} className="card-img-top" alt="..." />
            </div>
            <h5 className="text-center">Farmacia</h5>
          </div>
        </div> */}
            {/* <div
          className="col-sm-6 col-md-4 col-lg-3 adminestadistica__card"
          onClick={handleClick}
        >
          <div className="card p-3">
            <div>
              <img src={ropa} className="card-img-top" alt="..." />
            </div>
            <h5 className="text-center">Tienda de ropas</h5>
          </div>
        </div> */}
         </div>
      </div>
   );
};

export default Registro;
