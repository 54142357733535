/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import yasta from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import RegistroNegocioForm from './RegistroNegocioForm';

const RegistroNegocio = ({ history }) => {

   return (
      <>
         <nav className="navbar navbar-expand-lg navbar-light bg-light background__navbar">
            <div className="container-fluid">
               <a className="navbar-brand logo__navbar" href="/">
                  <img src={yasta} alt="logo" />
               </a>
               <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
               >
                  <span className="navbar-toggler-icon"></span>
               </button>
               <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
               >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                     <li>
                        <Link to="/" className="nav-item">
                           Inicio
                        </Link>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
         <RegistroNegocioForm history={history} />
      </>
   );
};

export default RegistroNegocio;
