import DataTable from "react-data-table-component";
/* import ExportExcel from "../excel/ExportExcel"; */
// import {  useDispatch } from "react-redux";

import { productos } from "../../data/productos";
import { paginacionOpciones } from "../../helpers/TableConst";
import { useEffect, useState } from "react";

const Productos = () => {
//   const dispatch = useDispatch();
  const [busqueda, setBusqueda] = useState("");
  const [listProductos, setlistProductos] = useState([]);

  // *nombre de excel
  // const fileName = "Modo prueba";

  // const handleAddProducto = () => {
  //   dispatch(uiCrearAbrirOpenModal());
  // };
  // const handleAddEditar = () => {
  //   dispatch(uiEditarAbrirOpenModal());
  // };
  // const handleImpulsarProducto = () => {
  //   dispatch(uiImpulsarAbrirOpenModal());
  // };

  const columnas = [
    {
      name: "Item",
      selector: "id",
      sortable: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Categoria",
      selector: "categoria",
      sortable: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Sub-Categoria",
      selector: "subcategoria",
      sortable: true,
      grow: 2,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Marca",
      selector: "marca",
      sortable: true,
      right: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Presentación",
      selector: "presentacion",
      sortable: true,
      right: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Editar",
      button: true,
      cell: (e) => (
        <button
          //   onClick={() => handleAddEditar()}
          className="table__tablebutton editar"
        >
          <i className="fas fa-pencil-alt"></i>
        </button>
      ),
    },
    {
      name: "Eliminar",
      button: true,
      cell: (e) => (
        <button
          //   onClick={() => handleDelete(e)}
          className="table__tablebutton eliminar"
        >
          <i className="far fa-trash-alt"></i>
        </button>
      ),
    },
  ];
  useEffect(() => {
    const filtrarElemento = () => {
      const search = productos.filter((data) => {
        return (
          data.categoria
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLocaleLowerCase()
            .includes(busqueda) ||
          data.subcategoria
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLocaleLowerCase()
            .includes(busqueda) ||
          data.marca
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLocaleLowerCase()
            .includes(busqueda) ||
          data.presentacion
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLocaleLowerCase()
            .includes(busqueda)
        );
      });
      setlistProductos(search);
    };
    filtrarElemento();
  }, [busqueda]);

  const handleSearch = (e) => {
    setBusqueda(([e.target.name] = e.target.value));
  };

  return (
    <div className="container">
      <div className="table-responsive">
        <div className="table__search">
          <input
            type="text"
            placeholder="Buscar"
            name="busqueda"
            value={busqueda}
            onChange={handleSearch}
          />
          <div className="adminproductos__actions">
            <div className="adminproductos__btnactions">
              <p>Crear nuevo producto</p>
              <i className="fas fa-plus-circle"></i>
            </div>
            <div className="adminproductos__btnactions">
              <p>Impulsar nuevo producto</p>
              <i className="fas fa-star"></i>
            </div>
            <div className="adminproductos__btnactions">
              <p>Exportar a excel</p>
              {/* {<ExportExcel csvData={productos} fileName={fileName} />} */}
            </div>
          </div>
        </div>
        <DataTable
          columns={columnas}
          data={listProductos}
          pagination
          paginationComponentOptions={paginacionOpciones}
          fixedHeader
          fixedHeaderScrollHeight="450px"
          noDataComponent={<i className="fas fa-inbox table__icono"></i>}
        />
      </div>
    </div>
  );
};

export default Productos;
