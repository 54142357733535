/* eslint-disable */
import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { fetchConToken } from '../../../../api/consultas';
import Scanner from '../../../../helpers/Scanner';
import { customStyles } from '../../../../helpers/TableConst';
import MAddProduct from './MAddProduct';

const MProductCamera = ({ openModalCamera, setOpenModalCamera }) => {
   const [camera, setCamera] = useState(false);
   const [codebar, setCodebar] = useState('');
   const [openModalAdd, setOpenModalAdd] = useState(false);
   const [product, setProduct] = useState([]);

   const closeModal = () => {
      setOpenModalCamera(false);
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      fetchConToken('productos/buscadorCodigoBarrasParaRegistrar/' + codebar)
         .then((response) => response.json())
         .then((data) => {
            if (data.message !== 'No se encuentra el producto') {
               setProduct(data);
               setOpenModalAdd(true);
            }else{
               toast.error(data.message,{
                  position: toast.POSITION.TOP_RIGHT
               })
            }
         });
   }

   const onDetected = (result) => {
      setCodebar(result);
      fetchConToken('productos/buscadorCodigoBarrasParaRegistrar/' + result)
         .then((response) => response.json())
         .then((data) => {
            if (data.message !== 'No se encuentra el producto') {
               setProduct(data);
               setOpenModalAdd(true);
            }
         });
   };

   const handleCamera = () => {
      setCamera(!camera);
   };

   useEffect(() => {
      handleCamera();
   }, []);

   return (
      <Modal
         isOpen={openModalCamera}
         onRequestClose={closeModal}
         style={customStyles}
         className="modal modal__cameraproduct"
         overlayClassName="modal-fondo"
         closeTimeoutMS={200}
         preventScroll={true}
         ariaHideApp={false}
      >
         <div className="container">
            <form className="row">
               <div className="adminproductos__creartitle">
                  <h3>CREAR PRODUCTO</h3>
               </div>
               <div className="cambiar-password">
                  <label>Código de barras </label>
                  <input type="text" name="codebar" value={codebar} onChange={(e) => setCodebar(e.target.value)}/>
               </div>
               {
                  camera ?(
                  <div className="camera">
                     {camera && <Scanner onDetected={onDetected} />}
                  </div>
                  ) :(
                     ''
                  )
               }
               <div className="todos__botones">
                     <div>
                        <button
                           className="botones actualizar"
                           onClick={handleSubmit}
                        >
                           Buscar
                        </button>
                     </div>
                  </div>
            </form>
         </div>
         {openModalAdd && (
            <MAddProduct
               openModalAdd={openModalAdd}
               setOpenModalAdd={setOpenModalAdd}
               product={product}
            />
         )}
      </Modal>
   );
};

export default MProductCamera;