import React from 'react';

const AdminBodegaProductosCrear = ({ history }) => {
  const handleCrearProducto = () => {
    history.push('/adminbodega/producto/promocion');
  };
  return (
    <div className="container">
      <div className="row ">
        <div className="adminproductos__content col-sm-12">
          <div className="adminproductos__misprod">
            <h3>Mis productos - Crear</h3>
            <input type="text" placeholder="search" />
          </div>
        </div>
      </div>
      <div className="row borde">
        <div className="col-4 borde py-3">
          <h5>Productos</h5>
          <div>
            <p>Abarrotes</p>
            <p>Bebidas</p>
            <p>Carnes, aves y pescados</p>
            <p>Congelados</p>
            <p>Cuidado personal</p>
            <p>Lácteos y huevos</p>
            <p>Limpieza</p>
            <p>Panaderia</p>
            <p>Queso y fiambres</p>
          </div>
        </div>
        <div className="col-8 adminbodegatiendas__productobotones borde">
          <div className="adminbodegatiendas__productobotones">
            <button onClick={handleCrearProducto}>Arroz</button>
            <button>Conservas</button>
            <button>Aceite</button>
            <button>Fideos, pasta, salsa</button>
            <button>Azucar</button>
            <button>Salsa, crema y condimentos</button>
            <button>Menestras</button>
            <button>Comidas instantaneas</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBodegaProductosCrear;
