/*eslint-disable */
import React, { useState } from 'react';
import { fetchConToken } from '../../../api/consultas';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const BodegaVentaFinalizar = () => {
   const [mail, setMail] = useState([]);
   const ventaDetail = useSelector((state) => state.detailVenta);

   const { name, email } = mail;

   const history = useHistory();

   const handleOnChange = (e) => {
      setMail({
         ...mail,
         [e.target.name]: e.target.value,
      });
   };

   const handleFinalizar = () => {
      history.push('/bodega/ventas');
   };

   /* const downloadPDF = () => { 
    var a = document.createElement('a');
    a.style = 'display: none';
    a.href = 'http://3.95.72.124/storage/Comprobantes/20551936903-00-TK001-16.pdf';
    a.download = 'download.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } */

   const handleSubmit = (e) => {
      e.preventDefault();
      const sendVentabyEmail = {
         nombre: mail.name,
         email: mail.email,
         venta_id: parseInt(ventaDetail.data.venta_id),
      };
      if (name === '' || email === '') {
         toast.warning('Debe rellenar todos los campos!', {
            position: toast.POSITION.TOP_RIGTH,
         });
      }else {
         fetchConToken(
            'ventas/Encargado/sendVenta',
            sendVentabyEmail,
            'POST'
         )
         .then((data) => data.json())
         .then((data) => {
            if (data.message) {
               toast.success('Correo enviado!', {
                  position: toast.POSITION.TOP_RIGTH,
               });
            }else{
               toast.error('Error al enviar, revisar el correo!', {
                  position: toast.POSITION.TOP_RIGTH,
               });
            }
         });
      }
   };

   return (
      <div className=" container row mt-5">
         <ToastContainer />
         <div className="col-sm-4">
            <h4>Venta generada #{ventaDetail.data.venta_id}</h4>
            <p>Información del cliente</p>
            <div>
               <div>
                  {/* <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='flexSwitchCheckDefault'
                onClick={handleSubmit}
                />
            </div> */}
                  <label>Correo</label>
                  <input
                     type="text"
                     name="email"
                     value={email}
                     onChange={handleOnChange}
                     className="form-control"
                  />
                  <label>Nombre del Cliente</label>
                  <input
                     type="text"
                     name="name"
                     value={name}
                     onChange={handleOnChange}
                     className="form-control"
                  />
               </div>
            </div>

            <div className="todos__botones" style={{ marginTop: '15px' }}>
               <button className="botones actualizar" onClick={handleSubmit}>
                  Enviar Correo
               </button>
               <button className="botones actualizar" onClick={handleFinalizar}>
                  Finalizar
               </button>
            </div>
         </div>
         <div className="col-sm-8">
            <iframe
               src={ventaDetail.data.comprobante}
               frameBorder={0}
               style={{ width: '100%', height: '400px' }}
            ></iframe>
         </div>

         {/*  */}

         {/*  */}
      </div>
   );
};

export default BodegaVentaFinalizar;
