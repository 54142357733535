import React from 'react';
import { NavLink } from 'react-router-dom';
import SidebarLogo from '../SidebarLogo';

const SidebarBodega = () => {
  return (
    <aside className='main-sidebar sidebar-dark-primary dashboard__aside'>
      <div className='sidebar'>
        <SidebarLogo />
        <nav className='mt-2'>
          <ul
            className='nav nav-pills nav-sidebar flex-column'
            data-widget='treeview'
            role='menu'
            data-accordion='false'
          >
            <li className='nav-item'>
              <NavLink to='/bodega/ventas' className='nav-link'>
                <i className='nav-icon far fa-chart-bar'></i>
                <p>
                  Mis ventas
                  {/* <i className='right fas fa-angle-left'></i> */}
                </p>
              </NavLink>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <NavLink to='/bodega/ventas/nueva' className='nav-link'>
                    <i className='nav-icon fas fa-store-alt'></i>
                    <p>Nueva Venta</p>
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink
                    to='/admin/estadisticaevaluacion'
                    className='nav-link'
                  >
                    <i className='nav-icon far fa-clipboard'></i>
                    <p>Pedido Delivery</p>
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to='/admin/estadisticatotales' className='nav-link'>
                    <i className='nav-icon fas fa-chart-line'></i>
                    <p>Historial</p>
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to='/admin/estadisticatotales' className='nav-link'>
                    <i className='nav-icon fas fa-chart-line'></i>
                    <p>Productos a credito</p>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className='nav-item'>
              <NavLink to='/bodega/productos' className='nav-link'>
                <i className='nav-icon fas fa-store'></i>
                <p>Mis productos</p>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/bodega/sugerencias' className='nav-link'>
                <i className='nav-icon fas fa-table'></i>
                <p>Indicador</p>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/bodega/perfil' className='nav-link'>
                <i className='nav-icon fas fa-table'></i>
                <p>Mi perfil</p>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/bodega/nosotros' className='nav-link'>
                <i className='nav-icon fas fa-users'></i>
                <p>Nosotros</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SidebarBodega;
