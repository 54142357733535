import React, { useState } from 'react';
const Tiempo = ({dateGrafico, setDateGrafico}) => {
  const [tiempo, setTiempo] = useState({
    Dia: false,
    Mes: false,
    Anio: false,
  });
  const [dataTiempo, setDataTiempo] = useState([]);

  const { Dia, Mes, Anio } = tiempo;

  const { inicioDia, finDia, inicioMes, finMes, inicioAnio, finAnio } = dataTiempo;



  const handleCheckbox = (e, num) => {
    switch(e.target.name){
      case 'Dia':
        setTiempo({...tiempo,[e.target.name]: true})
        break;
      case 'Mes':
        setTiempo({...tiempo,[e.target.name]: true})
        break;
      case 'Anio':
        setTiempo({...tiempo,[e.target.name]: true})
        break;
      default:
        setTiempo([]);
    }

    for(var i=0;i<3;i++){
      if(num===i){
        if(e.target.checked){
          document.getElementById(`inicio${i}`).removeAttribute('disabled');
          document.getElementById(`fin${i}`).removeAttribute('disabled');
        }else{
          document.getElementById(`inicio${i}`).setAttribute('disabled', 'disabled');
          document.getElementById(`fin${i}`).setAttribute('disabled', 'disabled');
        }
      }else{
        document.getElementById(`only-one${i}`).checked = false;
        document.getElementById(`inicio${i}`).setAttribute('disabled', 'disabled');
        document.getElementById(`fin${i}`).setAttribute('disabled', 'disabled');
      }
    }
  };

  const handleChangeData = (e) => {
    if (Dia || Mes || Anio) {
      const name = e.target.name;
      setDataTiempo({
        ...dataTiempo,
        [name]: e.target.value,
      });
      if(name.includes('inicio')){
        setDateGrafico({
          ...dateGrafico,
          initDate: e.target.value,
        })
      }else{
        setDateGrafico({
          ...dateGrafico,
          endDate: e.target.value
        })
      }
    } else {
      setDataTiempo([]);
    }
  };

  return (
    <div>
      <div className='adminusuario__tiempo'>
        <div className='adminusuario__time'>
          <input
            type='checkbox'
            className='form-check-input'
            id='only-one0'
            name='Dia'
            value={Dia}
            onChange={(e) => handleCheckbox(e, 0)}
          />
          <p>Días</p>
        </div>
        <div className='adminusuario__date box_date'>
          <div>
            <p>Inicio</p>
            <input
              type='date'
              id='inicio0'
              name='inicioDia'
              value={inicioDia}
              onChange={handleChangeData}
              disabled
            />
          </div>
          <div>
            <p>Fin</p>
            <input
              type='date'
              id='fin0'
              name='finDia'
              value={finDia}
              onChange={handleChangeData}
              disabled
            />
          </div>
        </div>
      </div>
      <div className='adminusuario__tiempo'>
        <div className='adminusuario__time'>
          <input
            type='checkbox'
            className='form-check-input'
            id='only-one1'
            name='Mes'
            value={Mes}
            onChange={(e) => handleCheckbox(e, 1)}
          />
          <p>Meses</p>
        </div>
        <div className='adminusuario__date box_date'>
          <div>
            <p>Inicio: </p>
            <input
              type='month'
              id='inicio1'
              name='inicioMes'
              value={inicioMes}
              onChange={handleChangeData}
              disabled
            />
          </div>
          <div>
            <p>Fin: </p>
            <input
              type='month'
              id='fin1'
              name='finMes'
              value={finMes}
              onChange={handleChangeData}
              disabled
            />
          </div>
        </div>
      </div>
      <div className='adminusuario__tiempo'>
        <div className='adminusuario__time mb-1'>
          <input
            type='checkbox'
            className='form-check-input'
            id='only-one2'
            name='Anio'
            value={Anio}
            onChange={(e) => handleCheckbox(e, 2)}
          />
          <p>Años</p>
        </div>
        <div className='adminusuario__date'>
          <div>
            <p>Inicio: </p>
            <select
              className='form-select'
              id='inicio2'
              name='inicioAnio'
              value={inicioAnio}
              onChange={handleChangeData}
              disabled
            >
              <option value=''>Seleccionar</option>
              <option value='2021'>2021</option>
              <option value='2022'>2022</option>
              <option value='2023'>2023</option>
            </select>
          </div>
          <div>
            <p>Fin: </p>
            <select
              className='form-select'
              id='fin2'
              name='finAnio'
              value={finAnio}
              onChange={handleChangeData}
              disabled
            >
              <option value=''>Seleccionar</option>
              <option value='2021'>2021</option>
              <option value='2022'>2022</option>
              <option value='2023'>2023</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiempo;
