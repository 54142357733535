import React from 'react';
import RegistroNegocioForm from '../../registro/RegistroNegocioForm';

const AdminProveedor = () => {

  return (
    <>
      <RegistroNegocioForm />
    </>
  );
};

export default AdminProveedor;
