import React, { useState } from 'react';
import Modal from 'react-modal';
import MAddProduct from './MAddProduct';
import MProveedorDetalle from '../../../../data/MProveedorDetalle';

const MPromociones = ({
  openModalPromocion,
  setOpenModalPromocion,
  productos,
}) => {
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [proveedores, setproveedores] = useState([]);
  const [openModalProveedorDetalle, setOpenModalProveedorDetalle] = useState(false);
  const [product, setProduct] = useState([]);
  const closeModal = () => {
    setOpenModalPromocion(false);
  };

  const handleAddProduct = (e) => {
    setOpenModalAdd(true);
    setProduct(e);
  };

  const handleInfoProveedor = (producto) => {
    setproveedores(producto.proveedores)
    setOpenModalProveedorDetalle(true)
  }
  return (
    <Modal
      isOpen={openModalPromocion}
      onRequestClose={closeModal}
      className='modal modal__addproduct'
      overlayClassName='modal-fondo'
      closeTimeoutMS={200}
      preventScroll={true}
      ariaHideApp={false}
    >
      <div>
        {/* Contenedor de title y del boton close */}
        <p className='title__modal'>
          <span>PRODUCTOS IMPULSADOS</span>
          {/* <br />
          Crear productos */}
        </p>
        <button className='close' onClick={closeModal}>
          X
        </button>
      </div>
      <div className='content-search__modal'>
        <h2>Datos principales</h2>
        <div className='search'>
          <i className='fas fa-search'></i>
          <input
            type='text'
            className='search__input'
            placeholder='Buscar productos por nombre...'
            name='busqueda'
          />
        </div>
      </div>
      <div className='content__filters'>
        <div>
          <label>Categoría:</label>
          <select className=''>
            <option>Todos</option>
            <option>Todos</option>
            <option>Todos</option>
          </select>
        </div>
        <div>
          <label className='space'>Sub-categoría:</label>
          <select className='l'>
            <option>Todos</option>
            <option>Todos</option>
            <option>Todos</option>
          </select>
        </div>
      </div>
      <div className='content__tags'>
        <div>
          <span>Productos</span>
          <button onClick={closeModal}>X</button>
        </div>
      </div>
      <div className='contendi__ctmr__uu'>
        {productos &&
          productos.map((product) => {
            const { foto, marca, descripcion, cantidad /* , unidad_medida */ } =
              product;
            return (
              <div className='sub__contenido__ctmr__uu sombra'>
                <div className='todos__botones'>
                  <button
                    className='botones actualizar'
                    onClick={() => handleInfoProveedor(product)}
                  >
                    Contacto
                  </button>
                  <button
                    className='botones actualizar'
                    onClick={() => handleAddProduct(product)}
                  >
                    Agregar
                  </button>
                </div>
                <div className='foto_producto'>
                  <img
                    src={product ? foto : null}
                    alt='Producto'
                    loading='lazy'
                  />
                </div>
                <h3>{marca.nombre}</h3>
                <p>{descripcion}</p>
                <p>{cantidad /* + ' ' + unidad_medida.nombre  */} </p>
              </div>
            );
          })}
      </div>
      {openModalAdd && (
        <MAddProduct
          openModalAdd={openModalAdd}
          setOpenModalAdd={setOpenModalAdd}
          product={product}
        />
      )}
      {openModalProveedorDetalle && (
        <MProveedorDetalle
          openModal={openModalProveedorDetalle}
          setOpenModal={setOpenModalProveedorDetalle}
          proveedores={proveedores}
        />
      )}
    </Modal>
  );
};

export default MPromociones;
