/* eslint-disable */
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useState, useEffect } from 'react';
import icono from '../assets/mapa/img_state_1.png';
const mapContainerStyle = {
  width: '100%',
  height: '390px',
};

const Mapa = ({ dataMapa, setDataMapa, setAddress=''}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC6KKz5ECwpUopPfQEQhuHe9y57FTJgdUk',
  });

  const ubicacion = {
    lng: dataMapa.lng,
    lat: dataMapa.lat,
  };

  const position = {
    lat: Number(-12.0084703),
    lng: Number(-76.972684),
  };

  const getAddress = () => {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${dataMapa.lat},${dataMapa.lng}&sensor=false&key=AIzaSyC6KKz5ECwpUopPfQEQhuHe9y57FTJgdUk`)
      .then((data) => data.json())
      .then((data) => {
        if(data['status'] === 'OK'){
          if(data['results'][0]?.formatted_address){
            let direccion = data['results'][1]?.formatted_address;
            setAddress(direccion);
          }else{
            setAddress('');
          }
        }else{
          setAddress('');
        }
      })
  }

  useEffect(() => {
    getAddress();
  }, [dataMapa]);

  return (
    isLoaded && (
      <GoogleMap
        id='transit-example'
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={position}
        onClick={(e) =>
          setDataMapa({ ...dataMapa, lat: e.latLng.lat(), lng: e.latLng.lng() })
        }
        // style={{
        //   height: '100px !important',
        // }}
      >
        <Marker position={ubicacion} icon={icono} />
      </GoogleMap>
    )
  );
};

export default Mapa;
