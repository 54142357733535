import React, { memo } from 'react';
import { Pie } from 'react-chartjs-2';

const AdminPie = memo(() => {
  return (
    <div style={{ width: '100%', height: '50%' }}>
      <Pie
        data={{
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [
            {
              label: 'evaluacion',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                '#f9a26c',
                '#f7763b',
                '#fcbb6d',
                '#eaab52',
                '#dd6a1c',
                '#e58a4e',
              ],
              borderColor: [
                '#f9a26c',
                '#f7763b',
                '#fcbb6d',
                '#eaab52',
                '#dd6a1c',
                '#e58a4e',
              ],
              borderWidth: 1,
            },
          ],
        }}
        width={500}
        height={400}
        options={{
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
});

export default AdminPie;
