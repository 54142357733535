import React, { useEffect, useState } from "react";

import Modal from "react-modal";
import { toast } from "react-toastify";
import { customStyles } from "../../../helpers/TableConst";
import { MultiSelect } from "primereact/multiselect";
import {
  fetchSinToken,
  fetchConToken,
} from "../../../api/consultas";

Modal.setAppElement("#root");
const AdminProductosImpulsar = ({
  setImpulsarMProducto,
  impulsarMProducto,
  idProducto,
  getProductos,
  impulsarData,
}) => {
  const [selectedDepartments, setSelectedDepartments] = useState(null);
  const [listDepartamentos, setListDepartamentos] = useState([]);
  const [listProvincias, setListProvincias] = useState([]);
  const [listDistritos, setListDistritos] = useState([]);
  const [selectedDistrits, setSelectedDistrits] = useState([]);
  
  const getDepartamento = () => {
    fetchSinToken("departamentos/get")
      .then((data) => data.json())
      .then((data) => {
        const listdatos = data.data.map((data) => {
          return {
            ...data,
            name: data.departamento,
            code: data.id,
          };
        });
        setListDepartamentos(listdatos);
      });
  };

  useEffect(() => {
    getDepartamento();
  }, []);
  useEffect(() => {
    setSelectedDistrits(listProvincias);
  }, [listProvincias]);

  const closeModal = () => {
    setImpulsarMProducto(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const arrayDistritos = {
      distritos: listDistritos.map((item) => ({ distrito_id: item.id })),
    };
    await fetchConToken(
      `productos/addDistritoImpulsar/${idProducto}`,
      arrayDistritos,
      "POST"
    )
      .then((resp) => {
        if (resp.status === 500) {
          toast.error(`Error al registrar`, {
            position: toast.POSITION.TOP_RIGTH,
          });
        } else {
          toast.success(`Producto Impulsado`, {
            position: toast.POSITION.TOP_RIGTH,
          });
        }
        setImpulsarMProducto(false);
        return resp.json();
      })
    getProductos();
  };
  const handleChangeLugares = (e) => {
    if (e.target.name === "selectDepartamento") {
      setSelectedDepartments(e.target.value);
    }
  };

  const groupedItemTemplate = (option) => {
    return (
      <div className="p-d-flex p-ai-center country-item">
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={impulsarMProducto}
      onRequestClose={closeModal}
      style={customStyles}
      className="modal modal_impulsar__producto"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="adminproductos__creartitle">
              <h3>{impulsarData && impulsarData.distritos_impulsados.length > 0 ? 'QUITAR DISTRITOS IMPULSADOS':'IMPULSAR DISTRITOS'} AL PRODUCTO</h3>
              {/* <p>Crear productos</p> */}
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div className="d-flex justify-content-center flex-column">
                <div className="adminproductos__selectoption">
                  <label>Departamentos</label>
                  <MultiSelect
                    value={selectedDepartments}
                    options={listDepartamentos}
                    name="selectDepartamento"
                    placeholder="Departamento"
                    onChange={(e) => handleChangeLugares(e)}
                    optionLabel="name"
                    maxSelectedLabels={4}
                    className="multiselect"
                    style={{
                      padding: 0,
                      margin: 0,
                      width: "100%",
                      height: "30px",
                      lineHeight: "5px",
                      fontSize: "12px",
                      border: "1px solid rgba(0,0,0, .12)",
                    }}
                  />
                </div>
                <div className="adminproductos__selectoption">
                  <label>Provincias</label>
                  <MultiSelect
                    value={listProvincias}
                    options={
                      selectedDepartments === null ? [] : selectedDepartments
                    }
                    onChange={(e) => setListProvincias(e.value)}
                    optionLabel="provincia"
                    optionGroupLabel="departamento"
                    optionGroupChildren="provincias"
                    optionGroupTemplate={groupedItemTemplate}
                    maxSelectedLabels={4}
                    placeholder="Provincia"
                    style={{
                      padding: 0,
                      margin: 0,
                      width: "100%",
                      height: "30px",
                      lineHeight: "5px",
                      fontSize: "12px",
                      border: "1px solid rgba(0,0,0, .12)",
                    }}
                  />
                </div>
                <div className="adminproductos__selectoption">
                  <label>Distritos</label>
                  <MultiSelect
                    value={listDistritos}
                    options={selectedDistrits === null ? [] : selectedDistrits}
                    onChange={(e) => setListDistritos(e.value)}
                    optionLabel="distrito"
                    optionGroupLabel="provincia"
                    optionGroupChildren="distritos"
                    maxSelectedLabels={4}
                    optionGroupTemplate={groupedItemTemplate}
                    placeholder="Distritos"
                    style={{
                      padding: 0,
                      margin: 0,
                      width: "100%",
                      height: "30px",
                      lineHeight: "5px",
                      fontSize: "12px",
                      border: "1px solid rgba(0,0,0, .12)",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="todos__botones">
            <button
              className="botones actualizar"
              type="submit"
              onChange={handleSubmit}
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AdminProductosImpulsar;
