const PEDIDO_DATA = 'pedidoData';

const initialState = {
    data: []
}
const pedidoReducer = (state = initialState, action) => {
    switch (action.type) {
        case PEDIDO_DATA:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};

export default pedidoReducer;