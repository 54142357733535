import React, { useState } from "react";
import Modal from "react-modal";
//import image from "../../../../assets/producto1.svg";
// import { useDispatch } from "react-redux";

const MCantidadProductos = ({
  openModalCantidadProductos,
  setOpenModalCantidadProductos,
  setOpenModalSelectProducto,
  datos,
  setRows,
}) => {
  // const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  const closeModal = () => {
    setOpenModalCantidadProductos(false);
  };

  const handleAddCantidad = (e, item) => {
    const data = datos;
    const position = data.findIndex((data) => data.id === item.id);
    // console.log("position xd =>", position);

    if (data[position]) {
      data[position].cantidad = parseFloat(e.target.value);
    }

    // console.log("datazo metro :v xd =>", data);
    data.map((item) => {
      // console.log("datazo metro :v xd =>", item.precios[0].precio_venta);
      let calculoTotal = item.precios[0].precio_venta * item.cantidad;
      return (item.calculoTotal = calculoTotal);
    });
    setProducts(data);
  };

  const handleFinalizar = () => {
    // dispatch({
    //   type: "ventasBodega",
    //   payload: products,
    // });
    setRows(products);
    closeModal();
    setOpenModalSelectProducto(false);
  };

  return (
    <Modal
      isOpen={openModalCantidadProductos}
      onRequestClose={closeModal}
      className="modal modal__addproduct"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
      ariaHideApp={false}
    >
      <div>
        <p className="title__modal">
          <span>PRODUCTOS</span>
        </p>
        <button className="close" onClick={closeModal}>
          X
        </button>
      </div>
      <div className="content__cards">
        {datos &&
          datos.map((data) => {
            // console.log(data);
            return (
              <div className="item-card" key={data.id}>
                <div>
                  {/* <div> */}
                  <p>
                    Nombre: <small>{data.producto.marca.nombre} </small>{" "}
                  </p>
                  <p>
                    Detalle:<small> {data.producto.descripcion}</small>
                  </p>
                  <p>
                    Stock:<small> {data.stock}</small>
                  </p>
                  <p>
                    Precio de venta:{" "}
                    <small>
                      {data.precios.length > 0 && data.precios[0].precio_venta}
                    </small>
                  </p>
                  <p>
                    Cantidad:
                    {/* <div> */}
                    <input
                      type="text"
                      onChange={(e) => handleAddCantidad(e, data)}
                    />
                    {/* </div> */}
                  </p>
                  {/* </div> */}
                </div>
                <div className="foto_producto">
                  <img
                    src={data.producto.foto}
                    alt={data.producto.descripcion}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className="todos__botones my-3">
        <button className="botones actualizar" onClick={handleFinalizar}>
          Siguiente
        </button>
      </div>
    </Modal>
  );
};

export default MCantidadProductos;
