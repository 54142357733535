import React from 'react';
import Modal from 'react-modal';

export default function MProveedorDetalle({
  openModal,
  setOpenModal,
  proveedores
}) {

  const closeModal = () => {
    setOpenModal(false);
  };

  return <Modal
    isOpen={openModal}
    onRequestClose={closeModal}
    className="modal modal__addproduct"
    overlayClassName="modal-fondo"
    closeTimeoutMS={200}
    preventScroll={true}
    ariaHideApp={false}
  >

    <div className='modal__proveedores__detalle'>
      <h3>Prooveedores</h3>
      <div className="row justify-content-evenly">
        {
          proveedores.map(proveedor => {
            const { razon_social, direccion_legal, telefono, email } = proveedor.empresa
            return (
              <div className='sub__contenido__ctmr__uu sombra col-md-5'>

                <h6>Razón Social: </h6>
                <p>{razon_social}</p>
                <h6>Dirección: </h6>
                <p>{direccion_legal}</p>
                <h6>Teléfono:</h6>
                <p>{telefono}</p>
                <h6>Correo:  </h6>
                <p>{email} </p>
              </div>
            );
          })}
      </div>
    </div>

  </Modal>;
}
