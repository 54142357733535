/* eslint-disable */
import React, { useState, useEffect } from 'react';
import AdminEstadisticaGrafico from '../../graficos/AdminEstadisticaGrafico';
import AdminPie from '../../graficos/AdminPie';
import Tiempo from '../../select/Tiempo';
import Lugares from '../../select/Lugares';
import { fetchConToken } from '../../../api/consultas';

//import { MultiSelect } from 'primereact/multiselect';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

const AdminBodegaEstadistica = () => {
  const [counter, setCounter] = useState(1);
  const handleClick = (index) => {
    setCounter(index);
  };
  /* const [setCategorias] = useState([]);
  const [setMarcas] = useState([]);
  const [setSubcategorias] = useState([]);


  const getCategoria = () => {
    fetchConToken('categorias/get')
      .then((data) => data.json())
      .then((data) => setCategorias(data.data))
  }

  const getMarca = () => {
    fetchConToken('marcas/get')
      .then((data) => data.json())
      .then((data) => setMarcas(data.data))
  }

  const getSubcategoria = () => {
    fetchConToken('subcategorias/get')
      .then((data) => data.json())
      .then((data) => setSubcategorias(data.data))
  }
  
  useEffect(() => {
    getCategoria();
    getMarca();
    getSubcategoria();
  }, []) */

  return (
    <div className="container">
      <nav className="adminestadisticas__nav">
        <div className="nav nav-tabs " id="nav-tab" role="tablist">
          <button
            className={counter === 1 ? "nav-link active" : "nav-link"}
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected={counter === 1 ? "true" : "false"}
            onClick={() => handleClick(1)}
          >
            Evaluación de totales
          </button>
          <button
            className={counter === 2 ? "nav-link active" : "nav-link"}
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected={counter === 2 ? "true" : "false"}
            onClick={() => handleClick(2)}
          >
            Productos top
          </button>
        </div>
      </nav>
      <div className="tab-content " id="nav-tabContent">
        <div
          className={
            counter === 1 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          {/*  */}
          <div className="row sombra py-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
              <h3 className="adminestadisticas__h3">Evaluación de totales</h3>
              <div>
                <div class='accordion' id='accordionExample'>
                  <div class='accordion-item'>
                    <h2 class='accordion-header' id='headingOne'>
                      <button
                        class='accordion-button'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseOne'
                        aria-expanded='true'
                        aria-controls='collapseOne'
                      >
                        Periodo de tiempo
                      </button>
                    </h2>
                    <div
                      id='collapseOne'
                      class='accordion-collapse collapse show'
                      aria-labelledby='headingOne'
                      data-bs-parent='#accordionExample'
                    >
                      <div class='accordion-body'>
                        <Tiempo />
                      </div>
                    </div>
                  </div>
                  <div class='accordion-item'>
                    <h2 class='accordion-header' id='headingTwo'>
                      <button
                        class='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseTwo'
                        aria-expanded='false'
                        aria-controls='collapseTwo'
                      >
                        Ubicación
                      </button>
                    </h2>
                    <div
                      id='collapseTwo'
                      class='accordion-collapse collapse'
                      aria-labelledby='headingTwo'
                      data-bs-parent='#accordionExample'
                    >
                      <div class='accordion-body'>
                        <Lugares />
                      </div>
                    </div>
                  </div>
                  <div class='accordion-item'>
                    <h2 class='accordion-header' id='headingThree'>
                      <button
                        class='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseThree'
                        aria-expanded='false'
                        aria-controls='collapseThree'
                      >
                        Producto
                      </button>
                    </h2>
                    <div
                      id='collapseThree'
                      class='accordion-collapse collapse'
                      aria-labelledby='headingThree'
                      data-bs-parent='#accordionExample'
                    >
                    <div class='accordion-body'>
                      <div>
                <div className="adminestadisticas__product">
                  <div>
                    <div className="adminestadisticas__productssg">
                      <div>
                        <input type="checkbox" className="form-check-input" />
                        <p>Categoría</p>
                      </div>

                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="adminestadisticas__productssg">
                      <div>
                        <input type="checkbox" className="form-check-input" />
                        <p>Sub categoría</p>
                      </div>

                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="adminestadisticas__productinputs">
                    
                    <div className="adminestadisticas__productssg">
                      <div>
                        <input type="checkbox" className="form-check-input" />
                        <p>Marca</p>
                      </div>

                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                    <div className="adminestadisticas__productssg">
                      <div>
                        <input type="checkbox" className="form-check-input" />
                        <p>Presentación</p>
                      </div>

                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 adminestadisticas__graficos">
              <div className='adminusurio__contenedorgrafico sombra mb-4'>
                <div className='adminestadisticas__botones'>
                  <button className='estadistica'>Volumen de venta</button>
                  <button className='estadistica'>Ganancias</button>
                  <button className='estadistica'>Cantidad de productos</button>
                  <button className='estadistica'>Exportar a excel</button>
                </div>
              </div>
              <div className='grafico'>
                <AdminEstadisticaGrafico />
              </div>
              <button>Actualizar</button>
            </div>
          </div>
          {/*  */}
        </div>
        <div
          className={
            counter === 2 ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          {/*  */}
          <div className="row sombra py-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
              <h3 className="adminestadisticas__h3">Evolución de totales</h3>
              <div>
                <p className="adminusuario__p">Periodo de tiempo</p>
                <div>
                  <div className="adminusuario__tiempo">
                    <div className="adminusuario__time">
                      <input type="checkbox" className="form-check-input" />
                      <p>Días</p>
                    </div>
                    <div className="adminusuario__date">
                      <p>Inicio: </p>
                      <input type="date" />
                      <p>Final: </p>
                      <input type="date" />
                    </div>
                  </div>
                  <div className="adminusuario__tiempo">
                    <div className="adminusuario__time">
                      <input type="checkbox" className="form-check-input" />
                      <p>Mes </p>
                    </div>
                    <div className="adminusuario__date">
                      <p>Inicio: </p>
                      <input type="date" />
                      <p>Final: </p>
                      <input type="date" />
                    </div>
                  </div>
                  <div className="adminusuario__tiempo">
                    <div className="adminusuario__time">
                      <input type="checkbox" className="form-check-input" />
                      <p>Años</p>
                    </div>
                    <div className="adminusuario__date">
                      <p>Seleccionar:</p>
                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adminusuario__numero">
                <p className="adminusuario__p">Ubicación</p>
                <div className="adminusuario__selectoption">
                  <div>
                    <input type="checkbox" className="form-check-input" />
                    <p>Departamento</p>
                  </div>

                  <select className="form-select">
                    <option selected>Todos</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="adminusuario__selectoption">
                  <div>
                    <input type="checkbox" className="form-check-input" />
                    <p>Provincia</p>
                  </div>

                  <select className="form-select">
                    <option selected>Todos</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="adminusuario__selectoption">
                  <div>
                    <input type="checkbox" className="form-check-input" />
                    <p>Distrito</p>
                  </div>

                  <select className="form-select">
                    <option selected>Todos</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <textarea className="adminestadisticas__textarea"></textarea>
              {/*  */}
              <div>
                <p className="adminusuario__p">Producto:</p>
                <div className="adminestadisticas__product">
                  {/*  */}
                  <div className="adminestadisticas__productinputs">
                    <div className="adminestadisticas__productsearch">
                      <p>Buscar:</p>
                      <input type="text" placeholder="search" />
                    </div>

                    
                    <div className="adminestadisticas__productssg">
                      <div>
                        <input type="checkbox" className="form-check-input" />
                        <p>Marca</p>
                      </div>

                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  {/*  */}
                  <div>
                    <div className="adminestadisticas__productssg">
                      <div>
                        <input type="checkbox" className="form-check-input" />
                        <p>Categoría</p>
                      </div>

                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="adminestadisticas__productssg">
                      <div>
                        <input type="checkbox" className="form-check-input" />
                        <p>Sub categoría</p>
                      </div>

                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="adminestadisticas__productssg">
                      <div>
                        <input type="checkbox" className="form-check-input" />
                        <p>Presentación</p>
                      </div>

                      <select className="form-select">
                        <option selected>2021</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
              <textarea className="adminestadisticas__textarea"></textarea>
              <div>
                <p className="adminestadisticas__p pt-3">Dato a analizar</p>
                <div className="adminestadisticas__botones">
                  <button>Volumen de venta</button>
                  <button>Ganancias</button>
                  <button>Cantidad de productos</button>
                  <button>Stock</button>
                  <button>Exportar a excel</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 adminestadisticas__graficos">
              <button>Actualizar</button>
              <div className="grafico">
                <AdminPie />
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default AdminBodegaEstadistica;
