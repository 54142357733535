import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useHistory } from 'react-router-dom';
import icono1 from '../assets/mapa/img_state_1.png';
import { memo } from 'react';
import { useDispatch } from 'react-redux';

const mapContainerStyle = {
   width: '100%',
   height: '475px',
};

const MultipleMarket = memo(({ todasUbicaciones }) => {
   const dispatch = useDispatch();
   const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: 'AIzaSyC6KKz5ECwpUopPfQEQhuHe9y57FTJgdUk',
   });

   const history = useHistory();

   const handleNewSale = (item) => {
      dispatch({
         type: 'pedidoData',
         payload: item,
      });
      history.push('/bodega/ventas/delivery');
   };

   return (
      isLoaded && (
         <GoogleMap
            id="transit-example"
            mapContainerStyle={mapContainerStyle}
            zoom={11}
            center={{ lng: -76.9116979, lat: -12.0006609 }}
            zoomControl={true}
         >
            {todasUbicaciones &&
               todasUbicaciones.map((data) => {
                  return (
                     <Marker
                        key={data.id}
                        position={{
                           lat:
                              Number(data.latitud_entrega) ||
                              Number(data.map_latitude),
                           lng:
                              Number(data.longitud_entrega) ||
                              Number(data.map_length),
                        }}
                        icon={icono1}
                        onClick={() => handleNewSale(data)}
                     />
                  );
               })}
         </GoogleMap>
      )
   );
});

export default MultipleMarket;
