/*eslint-disable */
import React from "react";
import Modal from "react-modal";
import { customStyles } from "../../../helpers/TableConst";

const MPdfVenta = ({ viewPdf, setViewPdf, pdf }) => {
  const closeModal = () => {
    setViewPdf(false);
  };

  return (
    <Modal
      isOpen={viewPdf}
      onRequestClose={closeModal}
      style={customStyles}
      className="modal modal__producto"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className="container">
        <div className="adminproductos__creartitle">
          <h3>PDF</h3>
        </div>
          <iframe
               src={pdf}
               frameBorder={0}
               style={{ width: '100%', height: '400px' }}
            ></iframe>
      </div>
    </Modal>
  );
};

export default MPdfVenta;
