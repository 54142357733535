/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { UploadAvatar } from '../../../helpers/UploadImage';
import { paginacionOpciones } from '../../../helpers/TableConst';
import { fetchConFornDataToken, fetchConToken } from '../../../api/consultas';

const AdminBodegasTiendasRepartidor = () => {
  const [addRepartidor, setAddRepartidor] = useState({
    id: null,
    tipo_documento_id: null,
    numero_doc: '',
    nombre: '',
    apellido_pat: '',
    apellido_mat: '',
    telefono: '',
    celular: '',
    email: '',
    username: '',
    password: '',
    domicilio: '',
    //foto: '',
    local_id: null,
  });

  const {
    numero_doc,
    nombre,
    apellido_pat,
    apellido_mat,
    telefono,
    celular,
    email,
    username,
    password,
    domicilio,
  } = addRepartidor;
  const [btn, setBtn] = useState(false);
  const [boton, setBoton] = useState('Validar');
  const [repartidores, setRepartidores] = useState([]);
  const [counter, setCounter] = useState(1);
  // * para la imagen
  const [foto, setAvatar] = useState(null);
  // * fin
  const { data } = useSelector((state) => state.localData);
  const getRepartidores = () => {
    fetchConToken('usuarios/transportista/local/get/' + data.id)
      .then((data) => data.json())
      .then((resp) => setRepartidores(resp.data));
  };
  const handleClick = (index) => {
    setCounter(index);
  };

  const handleAddCambios = (e) => {
    setAddRepartidor({ ...addRepartidor, [e.target.name]: e.target.value });
  };
  const validarDocumento = async (e) => {
    e.preventDefault();
    setBoton('...');
    try {
      const data = await fetch(
        `https://dniruc.apisperu.com/api/v1/dni/${addRepartidor.numero_doc}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJpeWV5aW0zMTlAY2FycGV0ZC5jb20ifQ.NaXtG7Nokt0tybRlEkuffmsKPjX4Gk8k-2pX7Rgn3j0`
      ).then((data) => data.json());
      setAddRepartidor({
        ...addRepartidor,
        nombre: data.nombres,
        apellido_pat: data.apellidoPaterno,
        apellido_mat: data.apellidoMaterno,
      });
      toast.success('Documento Validado', {
        position: toast.POSITION.TOP_RIGTH,
      });
      return data;
    } catch {
      toast.error('Documento no encontrado', {
        position: toast.POSITION.TOP_RIGTH,
      });
      setAddRepartidor({
        ...addRepartidor,
        nombre: '',
        apellido_pat: '',
        apellido_mat: '',
      });
    } finally {
      setBoton('Validar');
    }
  };
  // console.log(foto);
  const handleAgregarRepartidor = () => {
    addRepartidor.tipo_documento_id = 1;
    // addRepartidor.local_id = 1;
    const formData = new FormData();
    formData.set('tipo_documento_id', addRepartidor.tipo_documento_id);
    formData.set('numero_doc', addRepartidor.numero_doc);
    formData.set('nombre', addRepartidor.nombre);
    formData.set('apellido_pat', addRepartidor.apellido_pat);
    formData.set('apellido_mat', addRepartidor.apellido_mat);
    formData.set('telefono', addRepartidor.telefono);
    formData.set('celular', addRepartidor.celular);
    formData.set('email', addRepartidor.email);
    formData.set('username', addRepartidor.username);
    formData.set('password', addRepartidor.password);
    formData.set('domicilio', addRepartidor.domicilio);
    formData.set('foto', foto ? foto.file : '');
    formData.set('local_id', addRepartidor.id);
    if (
      numero_doc === '' ||
      nombre === '' ||
      apellido_pat === '' ||
      apellido_mat === '' ||
      telefono === '' ||
      celular === '' ||
      email === '' ||
      username === '' ||
      password === '' ||
      domicilio === ''
    ) {
      toast.error('Llenar los datos correctamente', {
        position: toast.POSITION.TOP_RIGTH,
      });
    } else {
      if (btn) {
        fetchConFornDataToken(
          'usuarios/transportista/update/' + addRepartidor.id,
          formData,
          'POST'
        ).then(() => {
          toast.success('Repartidor Actualizado', {
            position: toast.POSITION.TOP_RIGTH,
          });
          setAddRepartidor({
            tipo_documento_id: 1,
            numero_doc: '',
            nombre: '',
            apellido_pat: '',
            apellido_mat: '',
            telefono: '',
            celular: '',
            email: '',
            username: '',
            domicilio: '',
            foto: null,
            local_id: 1,
          });
          getRepartidores();
          setAvatar(null);
          setBtn(false);
          setCounter(1);
        });
      } else {
        fetchConFornDataToken(
          'usuarios/transportista/create',
          formData,
          'POST'
        ).then((item) => {
          toast.success('Creando repartidor', {
            position: toast.POSITION.TOP_RIGTH,
          });
          setAddRepartidor({
            tipo_documento_id: 1,
            numero_doc: '',
            nombre: '',
            apellido_pat: '',
            apellido_mat: '',
            telefono: '',
            celular: '',
            email: '',
            username: '',
            domicilio: '',
            foto: null,
            local_id: 1,
          });
          setAvatar(null);
          getRepartidores();
          setCounter(1);
        });
      }
    }
  };

  const handleDelete = (e) => {
  };

  const cancelarForm = () => {
    setBtn(false);
    setAvatar(null);
    setAddRepartidor({
      tipo_documento_id: 1,
      numero_doc: '',
      nombre: '',
      apellido_pat: '',
      apellido_mat: '',
      telefono: '',
      celular: '',
      email: '',
      username: '',
      domicilio: '',
      foto: null,
      local_id: 1,
    });
  };

  const handleEditar = (e) => {
    setCounter(2);
    setAddRepartidor({
      id: e.id,
      numero_doc: e.usuario.persona.numero_doc,
      nombre: e.usuario.persona.nombre,
      apellido_pat: e.usuario.persona.apellido_pat,
      apellido_mat: e.usuario.persona.apellido_mat,
      telefono: e.usuario.persona.telefono,
      celular: e.usuario.persona.celular,
      email: e.usuario.persona.email,
      username: e.usuario.username,
      domicilio: e.domicilio,
      foto: e.foto,
    });
    setAvatar(e.foto);
    setBtn(true);
  };

  useEffect(() => {
    getRepartidores();
  }, []);

  const columnas = [
    {
      name: 'Item',
      selector: (row, index) => (index += 1),
      sortable: true,
      style: {
        color: '#8f9196',
        borderBotton: 'none',
      },
      width: '60px',
    },
    {
      name: 'Nombre',
      selector: (row) =>
        row && row.usuario.persona_id ? row.usuario.persona.nombre : '',
      sortable: true,
      style: {
        color: '#8f9196',
        borderBotton: 'none',
      },
    },
    {
      name: 'Apellido',
      selector: (row) =>
        row && row.usuario.persona_id ? row.usuario.persona.apellido_pat : '',
      sortable: true,
      style: {
        color: '#8f9196',
        borderBotton: 'none',
      },
    },
    {
      name: 'Celular',
      selector: (row) =>
        row && row.usuario.persona_id ? row.usuario.persona.celular : '',
      sortable: true,
      right: true,
      style: {
        color: '#8f9196',
        borderBotton: 'none',
      },
    },
    {
      name: 'DNI',
      selector: (row) =>
        row && row.usuario.persona_id ? row.usuario.persona.numero_doc : '',
      sortable: true,
      style: {
        color: '#8f9196',
        borderBotton: 'none',
      },
      right: true,
    },
    {
      name: 'Editar',
      button: true,
      cell: (e) => (
        <button onClick={() => handleEditar(e)}>
          <i className='fas fa-pencil-alt table__tablebutton editar'></i>
        </button>
      ),
    },
    {
      name: 'Eliminar',
      button: true,
      cell: (e) => (
        <button onClick={() => handleDelete(e)}>
          <i className='far fa-trash-alt table__tablebutton eliminar'></i>
        </button>
      ),
    },
  ];

  return (
    <div className='container '>
      <ToastContainer />
      <nav className='adminestadisticas__nav'>
        <div className='nav nav-tabs ' id='nav-tab' role='tablist'>
          <button
            className={counter === 1 ? 'nav-link active' : 'nav-link'}
            id='nav-home-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-home'
            type='button'
            role='tab'
            aria-controls='nav-home'
            aria-selected={counter === 1 ? 'true' : 'false'}
            onClick={() => handleClick(1)}
          >
            Lista de repartidores
          </button>
          <button
            className={counter === 2 ? 'nav-link active' : 'nav-link'}
            id='nav-profile-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-profile'
            type='button'
            role='tab'
            aria-controls='nav-profile'
            aria-selected={counter === 2 ? 'true' : 'false'}
            onClick={() => handleClick(2)}
          >
            {btn ? 'Editar' : 'Crear'} repartidor
          </button>
        </div>
      </nav>

      <div className='tab-content ' id='nav-tabContent'>
        <div
          className={
            counter === 1 ? 'tab-pane fade show active' : 'tab-pane fade'
          }
          id='nav-home'
          role='tabpanel'
          aria-labelledby='nav-home-tab'
        >
          <div className='row sombra py-4 px-3'>
            <DataTable
              columns={columnas}
              data={repartidores}
              pagination
              paginationComponentOptions={paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='100%'
              noDataComponent={<i className='fas fa-inbox'></i>}
            />
          </div>
        </div>
        <div
          className={
            counter === 2 ? 'tab-pane fade show active' : 'tab-pane fade'
          }
          id='nav-profile'
          role='tabpanel'
          aria-labelledby='nav-profile-tab'
        >
          <div className='row sombra py-4 px-3'>
            <div className='col-12 col-sm-6 '>
              {/* <h3 className="adminusuario__h3">Datos personales</h3> */}
              {/* <div className='adminbodegas__tiendas-add'>
                <p>Tipo documento:</p>
                <select name='tipo_documento_id'>
                  <option key='0' value=''>
                    Seleccionar
                  </option>
                  <option key='1' value='1'>
                    DNI
                  </option>
                </select>
              </div> */}
              <div className='adminbodegas__tiendas-add'>
                <p>Tipo de documento:</p>
                <select
                  type='text'
                  //name="numero_doc"
                  //value={numero_doc}
                  className=''
                  //onChange={}
                >
                  <option value=''>Seleccionar</option>
                  <option value={1}>DNI</option>
                  <option value={2}>CE</option>
                  <option value={3}>PASAPORTE</option>
                  <option value={4}>CEDULA</option>
                  <option value={5}>OTROS</option>
                  <option value={6}>RUC</option>
                </select>
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Nro. documento:</p>
                <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '342px',
                      margin: '0px',
                      padding: '0px',
                    }}
                  >
                    <input
                      type='text'
                      name='numero_doc'
                      value={numero_doc}
                      className=''
                      onChange={handleAddCambios}
                      style={{
                        width: '250px !important',
                      }}
                    />
                    <div
                      className='todos__botones'
                      style={{
                        width: '100px',
                      }}
                    >
                      <button
                        className='actualizar botones'
                        onClick={validarDocumento}
                      >
                        {boton}
                      </button>
                    </div>
                  </div>
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Nombre:</p>
                <input
                  type='text'
                  name='nombre'
                  value={nombre}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Apellido P.:</p>
                <input
                  type='text'
                  name='apellido_pat'
                  value={apellido_pat}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Apellido M:</p>
                <input
                  type='text'
                  name='apellido_mat'
                  value={apellido_mat}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Telefono:</p>
                <input
                  type='text'
                  name='telefono'
                  value={telefono}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Celular:</p>
                <input
                  type='text'
                  name='celular'
                  value={celular}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
            </div>
            {/*  */}
            <div className='col-12 col-sm-6 adminestadisticas__graficos'>
              <div className='adminbodegas__tiendas-add'>
                <p>Username:</p>
                <input
                  type='text'
                  name='username'
                  value={username}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Contraseña:</p>
                <input
                  type='password'
                  name='password'
                  value={password}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Email:</p>
                <input
                  type='text'
                  name='email'
                  value={email}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
              <div className='adminbodegas__tiendas-add'>
                <p>Domicilio:</p>
                <input
                  type='text'
                  name='domicilio'
                  value={domicilio}
                  className=''
                  onChange={handleAddCambios}
                />
              </div>
              <div
                style={{
                  width: '100%',
                }}
              >
                <label>Foto</label>
                <UploadAvatar avatar={foto} setAvatar={setAvatar} />
              </div>
              <div className='todos__botones mt-3'>
                <button
                  className='botones cancelar'
                  type='button'
                  onClick={cancelarForm}
                >
                  Cancelar
                </button>
                <button
                  className='botones delivery'
                  onClick={handleAgregarRepartidor}
                >
                  {btn ? 'Editar' : 'Crear'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBodegasTiendasRepartidor;
