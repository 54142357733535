/* eslint-disable */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pruebametodo } from '../../api/consultas';

const FORM_ID = 'payment-form';

const PlanesPago = () => {
  // setTimeout(()=>{

  // }, 2000)
  const { user } = useSelector((state) => state.auth);
  const { empresa } = user.administrador[0];
  const [cantidad, setCantidad] = useState({
    plan1: 1,
    plan2: 1,
    plan3: 1,
  });

  const [datos, setDatos] = useState({
    currency: 'PEN',
    value: 10,
    mp_check_code: 'test code',
    mp_check_title: 'YASTA',
    empresa: empresa.id,
  })

  //const [mercadopago, setMercadopago] = useState({});

  //const { preference_code } = mercadopago;

  const { plan1, plan2, plan3} = cantidad;

  /* const makePayment = () => {
    pruebametodo('payments/pay', datos, 'POST')
      .then((resp) => resp.json())
      .then((resp) => setMercadopago(resp))
  }
  useEffect(() => {
    makePayment();
  }, [datos]); */

  const loadScript = (num) => {
    var data = {
      currency: 'PEN',
      value: num === 1 ?  plan1*10 : (num === 2 ? plan2 * 55: plan3*100),
      mp_check_code: 'test code',
      mp_check_title: 'YASTA',
      empresa: empresa.id,
    }
    pruebametodo('payments/pay', data, 'POST')
      .then((resp) => resp.json())
      .then((resp) => {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              handleScriptLoad(resp.preference_code);
            }
          };
        } else {
          script.onload = () => handleScriptLoad(resp.preference_code);
        }
        script.src = 'https://sdk.mercadopago.com/js/v2';
        document.getElementsByTagName('head')[0].appendChild(script);
      })
    /* setDatos({
      ...datos,
      value: num === 1 ?  plan1*10 : (num === 2 ? plan2 * 55: plan3*100)
    });
    let script = document.createElement('script');
    script.type = 'text/javascript';
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          handleScriptLoad();
        }
      };
    } else {
      script.onload = () => handleScriptLoad();
    }
    script.src = 'https://sdk.mercadopago.com/js/v2';
    document.getElementsByTagName('head')[0].appendChild(script); */
  };

  const handleScriptLoad = (preference_code) => {
    const mp = new window.MercadoPago('TEST-f6fc4a94-85f6-40c0-9787-523986416c4b', {
      locale: 'es-PE',
    });
    mp.checkout({
      preference: {
        id: `${preference_code}`,
      },
      autoOpen: true,
    });
  };

  const handleOnChange = (e) => {
    setCantidad({
       ...cantidad,
       [e.target.name]: parseInt(e.target.value),
    });
  };

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='m-t-160'>
            <div className='adminregistro__listcard '>
              <div className='card adminregistro__card sombra'>
                <h4 className='text-center'>Plan 1</h4>
                <div className='card-body'>
                  <h6 className=''>Beneficios</h6>
                  <p>. Acceso al sistema por 1 mes</p>
                  <p>. Alerta</p>
                  <h6>Costo:</h6>
                  <p>s/ 10.00 por bodega</p>
                  <div className='adminregistro__contador'>
                    <h6>Número de bodegas</h6>
                    <div>
                      <button onClick={() => setCantidad({ ...cantidad, plan1: plan1 === 1 ?  1 : plan1 - 1})}>
                        -
                      </button>
                      <input
                        type='text'
                        name='plan1'
                        value={plan1}
                        onChange={handleOnChange}
                        disabled
                      />
                      <button onClick={() => setCantidad({ ...cantidad, plan1: plan1 + 1 })}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <button className='adminregistro__pago ' id={FORM_ID} onClick={() => loadScript(1)}>
                  Realizar pago
                </button>
              </div>
              <div className='card adminregistro__card sombra'>
                <h4 className='text-center'>Plan 2</h4>
                <div className='card-body'>
                  <h6 className=''>Beneficios</h6>
                  <p>. Acceso al sistema por 6 mes</p>
                  <p>. Alerta</p>
                  <h6>Costo:</h6>
                  <p>s/ 55.00 por bodega</p>
                  <div className='adminregistro__contador'>
                    <h6>Número de bodegas</h6>
                    <div>
                      <button onClick={() => setCantidad({ ...cantidad, plan2: plan2 === 1 ?  1 : plan2 - 1})}>
                        -
                      </button>
                      <input
                        type='text'
                        name='plan2'
                        value={plan2}
                        onChange={handleOnChange}
                        disabled
                      />
                      <button onClick={() => setCantidad({ ...cantidad, plan2: plan2 + 1 })}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <button className='adminregistro__pago' id={FORM_ID} onClick={() => loadScript(2)}>
                  Realizar pago
                </button>
              </div>
              <div className='card adminregistro__card sombra'>
                <h4 className='text-center'>Plan 3</h4>
                <div className='card-body'>
                  <h6 className=''>Beneficios</h6>
                  <p>. Acceso al sistema por 12 mes</p>
                  <p>. Alerta</p>
                  <h6>Costo:</h6>
                  <p>s/ 100.00 por bodega</p>
                  <div className='adminregistro__contador'>
                    <h6>Número de bodegas</h6>
                    <div>
                      <button onClick={() => setCantidad({ ...cantidad, plan3: plan3 === 1 ?  1 : plan3 - 1})}>
                        -
                      </button>
                      <input
                        type='text'
                        name='plan3'
                        value={plan3}
                        onChange={handleOnChange}
                        disabled
                      />
                      <button onClick={() => setCantidad({ ...cantidad, plan3: plan3 + 1 })}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <button className='adminregistro__pago' id={FORM_ID} onClick={() => loadScript(3)}>
                  Realizar pago
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isOpen && <ModalPago isOpen={isOpen} setIsOpen={setIsOpen} />} */}
    </>
  );
};

export default PlanesPago;
