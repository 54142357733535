/* eslint-disable */
import { useEffect, useState } from "react";
import fideos from "../../../assets/products/molitalia/molitalia1.png";
import AdminBodegasSugerenciasModal from "./AdminBodegasSugerenciasModal";
import { fetchConToken } from "../../../api/consultas";

const AdminBodegasSugerencias = () => {
  const [isModalSugerencia, setIsModalSugerencia] = useState(false);
  const [categoria, setCategoria] = useState([]);
  const [subcategoria, setSubcategoria] = useState([]);
  const [supercategoria, setSupercategoria] = useState([]);
  const [listCategoria, setListCategoria] = useState([]);
  const [listSubcategoria, setListSubcategoria] = useState([]);
  const [mayorVenta, setMayorVenta] = useState([]);
  const [menorVenta, setMenorVenta] = useState([]);
  const [mayorGanancia, setMayorGanancia] = useState([]);
  const [menorGanancia, setMenorGanancia] = useState([]);
  const [data, setData] = useState([]);
  const [fecha, setFecha] = useState({
    fecha_inicio: "",
    fecha_fin: "",
  });

  const { fecha_inicio, fecha_fin } = fecha;

  const getSupercategoria = () => {
    fetchConToken("supercategorias/get")
      .then((data) => data.json())
      .then((data) => setSupercategoria(data.data));
  };

  const getSubcategoria = () => {
    fetchConToken("subcategorias/get")
      .then((data) => data.json())
      .then((data) => setSubcategoria(data.data));
  };

  const getCategoria = () => {
    fetchConToken("categorias/get")
      .then((data) => data.json())
      .then((data) => setCategoria(data.data));
  };

  const getMayorGanancia = (fecha_inicio = "", fecha_fin = "") => {
    if (fecha_inicio !== "" || fecha_fin !== "") {
      fetchConToken(
        "productos/mis_productos_local/masGanancias/" + 1,
        { fecha_inicio: fecha_inicio, fecha_fin: fecha_fin },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => setMayorGanancia(data));
    } else {
      fetchConToken(
        "productos/mis_productos_local/masGanancias/" + 1,
        { fecha_inicio: "", fecha_fin: "" },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => setMayorGanancia(data));
    }
  };

  const getMenorGanancia = (fecha_inicio = "", fecha_fin = "") => {
    if (fecha_inicio !== "" || fecha_fin !== "") {
      fetchConToken(
        "productos/mis_productos_local/menosGanancias/" + 1,
        { fecha_inicio: fecha_inicio, fecha_fin: fecha_fin },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => setMenorGanancia(data));
    } else {
      fetchConToken(
        "productos/mis_productos_local/menosGanancias/" + 1,
        { fecha_inicio: "", fecha_fin: "" },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => setMenorGanancia(data));
    }
  };

  const getMayorVenta = (fecha_inicio = "", fecha_fin = "") => {
    if (fecha_inicio !== "" || fecha_fin !== "") {
      fetchConToken(
        "productos/mis_productos_local/masVendidos/" + 1,
        { fecha_inicio: fecha_inicio, fecha_fin: fecha_fin },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => setMayorVenta(data));
    } else {
      fetchConToken(
        "productos/mis_productos_local/masVendidos/" + 1,
        { fecha_inicio: "", fecha_fin: "" },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => setMayorVenta(data));
    }
  };

  const getMenorVenta = (fecha_inicio = "", fecha_fin = "") => {
    if (fecha_inicio !== "" || fecha_fin !== "") {
      fetchConToken(
        "productos/mis_productos_local/menosVendidos/" + 1,
        { fecha_inicio: fecha_inicio, fecha_fin: fecha_fin },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => setMenorVenta(data));
    } else {
      fetchConToken(
        "productos/mis_productos_local/menosVendidos/" + 1,
        { fecha_inicio: "", fecha_fin: "" },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => setMenorVenta(data));
    }
  };

  const handleOnchange = (e) => {
    if (e.target.name === "supercategoria_id") {
      const data = categoria.filter(
        (item) => item.supercategoria_id === parseInt(e.target.value)
      );
      setListCategoria(data);
    }
    if (e.target.name === "categoria_id") {
      const data = subcategoria.filter(
        (item) => item.categoria_id === parseInt(e.target.value)
      );
      setListSubcategoria(data);
    }
  };

  const handleSubmit = () => {};

  useEffect(() => {
    getSupercategoria();
    getSubcategoria();
    getCategoria();
    getMayorGanancia();
    getMenorGanancia();
    getMayorVenta();
    getMenorVenta();
  }, []);

  const handleInformacion = () => {
    setIsModalSugerencia(true);
  };

  return (
    <div className="container">
      <div className="row adminbodega__sugerencia mb-2">
        <p>
          <span className="">¡YASTA! </span> analizó la ultima semana de tu
          negocio y te recomienda lo siguiente
        </p>
      </div>
      <div className="row mb-5">
        <div className="col-12 adminbodega__sugerencia-card sombra">
          <div className="adminbodega__sugerencialista">
            <label htmlFor="form-country">Nivel 1:</label>
            <select
              id="form-country"
              name="supercategoria_id"
              className="form-select"
              onChange={handleOnchange}
            >
              <option key={0} value="">
                Seleccionar
              </option>
              {supercategoria.map((data) => {
                return (
                  <option key={data.id} value={data.id}>
                    {data.nombre}
                  </option>
                );
              })}
              <option value="BD">Bebidas</option>
            </select>
          </div>
          <div className="adminbodega__sugerencialista">
            <label htmlFor="form-country">Nivel 2:</label>
            <select
              id="form-country"
              name="categoria_id"
              className="form-select"
              onChange={handleOnchange}
            >
              <option key={0} value="">
                Seleccionar
              </option>
              {listCategoria.map((data) => {
                return (
                  <option key={data.id} value={data.id}>
                    {data.nombre}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="adminbodega__sugerencialista">
            <label htmlFor="form-country">Nivel 3:</label>
            <select id="form-country" className="form-select">
              <option key={0} value="">
                Seleccionar
              </option>
              {listSubcategoria.map((data) => {
                return (
                  <option key={data.id} value={data.id}>
                    {data.nombre}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div
        className="sombra"
        style={{
          // backgroundColor: 'orange',
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            // backgroundColor: 'red',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "30%",
          }}
        >
          <label>Fecha Inicio</label>
          <input
            type="date"
            name="fecha_inicio"
            onChange={handleOnchange}
            value={fecha_inicio}
          />
        </div>
        <div
          style={{
            // backgroundColor: 'blue',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "30%",
          }}
        >
          <label>Fecha Fin</label>
          <input
            type="date"
            name="fecha_fin"
            onChange={handleOnchange}
            value={fecha_fin}
          />
        </div>
        <div
          className="todos__botones"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: "20%",
          }}
        >
          <button onClick={handleSubmit} className="botones actualizar">
            Actualizar
          </button>
        </div>
      </div>

      <div className="row mb-5">
        <p>
          Tus productos que generaron
          <span className="span__text"> más ganancias </span> son las
          siguientes:
        </p>
        <div className="col-12 adminbodega__sugerencia-ventas">
          {mayorGanancia.productos &&
            mayorGanancia.productos.slice(0, 4).map((mayor) => {
              return (
                <div
                  className="card sombra"
                  onClick={() => handleInformacion(mayor)}
                >
                  <img
                    src={
                      mayor
                        ? `http://3.95.72.124/yasta_api_dev/public/storage/products/${mayor.foto_producto}`
                        : ""
                    }
                    className="card-img-top"
                    alt="..."
                    loading="lazy"
                  />
                  <span className="">4</span>
                  <p className="card-text text-center">
                    {!mayor.descripcion
                      ? mayor.producto
                        ? mayor.producto.descripcion
                        : ""
                      : mayor.descripcion}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      {/*  */}
      <div className="row mb-5">
        <p>
          Tus productos que generaron
          <span className="span__text"> menos ganancias </span> son las
          siguientes:
        </p>
        <div className="col-12 adminbodega__sugerencia-ventas">
          {menorGanancia.productos &&
            menorGanancia.productos.slice(0, 4).map((menor) => {
              return (
                <div
                  className="card sombra"
                  onClick={() => handleInformacion(menor)}
                >
                  <img
                    src={
                      menor
                        ? `http://3.95.72.124/yasta_api_dev/public/storage/products/${menor.foto_producto}`
                        : ""
                    }
                    className="card-img-top"
                    alt="..."
                  />
                  <span className="">1</span>
                  <p className="card-text text-center">
                    {menor ? menor.descripcion : ""}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      {/*  */}
      <div className="row mb-5">
        <p>
          Tus productos <span className="span__text"> más vendidos </span> son
          las siguientes:
        </p>
        <div className="col-12 adminbodega__sugerencia-ventas">
          {mayorVenta.productos &&
            mayorVenta.productos.slice(0, 4).map((mas) => {
              return (
                <div
                  className="card sombra"
                  onClick={() => handleInformacion(mas)}
                >
                  <img
                    src={
                      mas
                        ? `http://3.95.72.124/yasta_api_dev/public/storage/products/${mas.foto_producto}`
                        : ""
                    }
                    className="card-img-top"
                    alt="..."
                  />
                  <span className="">1</span>
                  <p className="card-text text-center">
                    {mas ? mas.descripcion : ""}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      {/*  */}
      <div className="row mb-5">
        <p>
          Tus productos <span className="span__text"> menos vendidos </span> son
          las siguientes:
        </p>
        <div className="col-12 adminbodega__sugerencia-ventas">
          {menorVenta.productos &&
            menorVenta.productos.slice(0, 4).map((menos) => {
              return (
                <div
                  className="card sombra"
                  onClick={() => handleInformacion(menos)}
                >
                  <img
                    src={
                      menos
                        ? `http://3.95.72.124/yasta_api_dev/public/storage/products/${menos.foto_producto}`
                        : ""
                    }
                    className="card-img-top"
                    alt="..."
                  />
                  <span className="">1</span>
                  <p className="card-text text-center">
                    {menos ? menos.descripcion : ""}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      {/* container */}
      {isModalSugerencia && (
        <AdminBodegasSugerenciasModal
          isModalSugerencia={isModalSugerencia}
          setIsModalSugerencia={setIsModalSugerencia}
        />
      )}
    </div>
  );
};

export default AdminBodegasSugerencias;
