import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import icono from '../assets/mapa/img_state_1.png';
const mapContainerStyle = {
  width: '100%',
  height: '390px',
};

const Mapa = ({ dataMapa }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC6KKz5ECwpUopPfQEQhuHe9y57FTJgdUk',
  });

  const ubicacion = {
    lng: dataMapa.lng,
    lat: dataMapa.lat,
  };

  const position = {
    lat: Number(-12.0084703),
    lng: Number(-76.972684),
  };

  return (
    isLoaded && (
      <GoogleMap
        id='transit-example'
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={position}
      >
        <Marker 
          position={ubicacion} 
          icon={icono}
        />
      </GoogleMap>
    )
  );
};

export default Mapa;
