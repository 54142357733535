import { toast } from "react-toastify";
import {
  /* renovarToken,  */ fetchConToken,
  signInApi,
} from "../api/consultas";
import { types } from "../types/types";

export const startLogin = (username, password) => {
  return async (dispatch) => {
    const resp = await signInApi("login", { username, password }, "POST");
    const body = await resp.json();

    if (body.token === undefined) {
      toast.error(`${body.error}`, {
        position: toast.POSITION.TOP_RIGTH,
      });
    } else if (body.token.length > 0) {
      localStorage.setItem("token", body.token);
      dispatch(
        login({
          user: body,
        })
      );
    }
  };
};

export const startChecking = () => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    const resp = await fetchConToken("refresh", token, "POST");
    const body = await resp.json();
    if (body.token) {
      localStorage.setItem("token", body.token);
      dispatch(
        login({
          user: body,
        })
      );
    } else {
      toast.error(`${body.error}`, {
        position: toast.POSITION.TOP_RIGTH,
      });
      dispatch(dispatch(checkingFinish()));
    }
  };
};

const checkingFinish = () => {
  return {
    type: types.authChecking,
  };
};

const login = (user) => {
  return {
    type: types.authLogin,
    payload: user,
  };
};

export const startLogout = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch(logout());
  };
};
const logout = () => {
  return {
    type: types.authLoout,
  };
};
