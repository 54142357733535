/* eslint-disable */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { fetchConToken } from "../../../api/consultas";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

export default function HistorialBodegaVenta() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [pedidos, setPedidos] = useState([]);
  const [busqueda, setBusqueda] = useState("");

  const getPedidos = () => {
    fetchConToken("pedidos/bodega/getPendientes")
      .then((data) => data.json())
      .then((data) => {
        // console.log("funciona :/ => ", data.data);
        const filtrado = data.data.filter((item) => item.delivery === 0);
        setPedidos(filtrado);
      });
  };

  const handleOnChange = (e) => {
    setBusqueda(([e.target.name] = e.target.value));
  };

  const handleAtender = (item) => {
    dispatch({
      type: "pedidoData",
      payload: item,
    });
    history.push("/bodega/ventas/delivery");
  };

  useEffect(() => {
    getPedidos();
  }, []);

  const columnas = [
    {
      name: "Item",
      selector: (row, index) => (index += 1),
      sortable: true,
      center: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
      width: "60px",
    },
    {
      name: "Nombre",
      selector: (row) =>
        row && row.cliente ? row.cliente.persona.nombre : "CLIENTE",
      sortable: true,
      center: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Monto",
      selector: (row) => (row && row.total ? "S/." + " " + row.total : ""),
      sortable: true,
      center: true,
      compact: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Fecha de emisión",
      selector: (row) => (row && row.fecha_emision ? row.fecha_emision : ""),
      sortable: true,
      center: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Crédito",
      selector: (row) => (row && row.credito === 1 ? "Si" : "No"),
      sortable: true,
      compact: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
        justifyContent: "center",
      },
      width: "80px",
    },
    {
      name: "Acción",
      cell: (e) => (
        <div className="todos__botones">
          <button
            onClick={() => handleAtender(e)}
            className=" botones delivery"
          >
            <label>Atender</label> <i className="fas fa-angle-right "></i>
          </button>
        </div>
      ),
      center: true,
      compact: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
        justifyContent: "center",
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="row d-flex justify-content-between">
        <div className="col-md-4">
          <h2>Pedidos</h2>
          <div className="search" style={{ margin: "8% 0", width: "100%" }}>
            <i className="fas fa-search"></i>
            <input
              type="text"
              className="search__input"
              placeholder="Buscar productos por nombre..."
              name="busqueda"
              value={busqueda}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>

      <DataTable
        columns={columnas}
        data={pedidos}
        fixedHeader
        fixedHeaderScrollHeight="400px"
        noDataComponent={<i className="fas fa-inbox table__icono"></i>}
      />
    </React.Fragment>
  );
}
