import React from 'react';
import AdminPie from '../../graficos/AdminPie';
//import EstadisticasLugar from '../../select/EstadisticasLugar';
import Tiempo from '../../select/Tiempo';
import Lugares from '../../select/Lugares';

const AdminEstadisticasEvaluacionProductotop = () => {
  return (
    <div className='row'>
      <div className='col-12 col-sm-12 col-md-12 col-lg-6 '>
        <div class='accordion' id='accordionExamples'>
          <div class='accordion-item'>
            <h2 class='accordion-header' id='headingOnes'>
              <button
                class='accordion-button'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseOnes'
                aria-expanded='true'
                aria-controls='collapseOnes'
              >
                Período de tiempo
              </button>
            </h2>
            <div
              id='collapseOnes'
              class='accordion-collapse collapse show'
              aria-labelledby='headingOnes'
              data-bs-parent='#accordionExamples'
            >
              <div class='accordion-body'>
                <Tiempo />
              </div>
            </div>
          </div>
          {/*  */}
          <div class='accordion-item'>
            <h2 class='accordion-header' id='headingTwos'>
              <button
                class='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseTwos'
                aria-expanded='false'
                aria-controls='collapseTwos'
              >
                Ubicación
              </button>
            </h2>
            <div
              id='collapseTwos'
              class='accordion-collapse collapse'
              aria-labelledby='headingTwos'
              data-bs-parent='#accordionExamples'
            >
              <div class='accordion-body'>
                <Lugares />
              </div>
            </div>
          </div>
          {/*  */}
          <div class='accordion-item'>
            <h2 class='accordion-header' id='headingThrees'>
              <button
                class='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseThrees'
                aria-expanded='false'
                aria-controls='collapseThrees'
              >
                Productos
              </button>
            </h2>
            <div
              id='collapseThrees'
              class='accordion-collapse collapse'
              aria-labelledby='headingThrees'
              data-bs-parent='#accordionExamples'
            >
              <div class='accordion-body'>
                <div className='adminestadisticas__product'>
                  <div className='adminestadisticas__productinputs'>
                    <div className='adminestadisticas__productssg'>
                      <div>
                        <input type='checkbox' className='form-check-input' />
                        <p>Presentación</p>
                      </div>
                      <select className='form-select'>
                        <option selected>Seleccionar</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </select>
                    </div>
                    <div className='adminestadisticas__productssg'>
                      <div>
                        <input type='checkbox' className='form-check-input' />
                        <p>Marca</p>
                      </div>
                      <select className='form-select'>
                        <option selected>Seleccionar</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className='adminestadisticas__productssg'>
                      <div>
                        <input type='checkbox' className='form-check-input' />
                        <p>Categoría</p>
                      </div>
                      <select className='form-select'>
                        <option selected>Seleccionar</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </select>
                    </div>
                    <div className='adminestadisticas__productssg'>
                      <div>
                        <input type='checkbox' className='form-check-input' />
                        <p>Sub categoría</p>
                      </div>
                      <select className='form-select'>
                        <option selected>Seleccionar</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-sm-12 col-md-12 col-lg-6 adminestadisticas__graficos d-grid'>
        <div className='adminusurio__contenedor sombra '>
          <div className='adminestadisticas__botones'>
            <button className='estadistica'>Volumen de venta</button>
            <button className='estadistica'>Ganancias</button>
            <button className='estadistica'>Cantidad de productos</button>
            <button className='estadistica'>Stock</button>
            <button className='estadistica'>Exportar a excel</button>
          </div>
          <div className='grafico mb-3'>
            <AdminPie />
            <button>Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEstadisticasEvaluacionProductotop;
