import React from 'react';
import pilsen from '../../../assets/cerveza/pilsen.jpeg';
const AdminBodegaAgregarProducto = ({ history }) => {
  const handleFinalizar = () => {
    history.push('/adminbodega/producto');
  };
  return (
    <div className='container sombra pt-3'>
      <form className=''>
        <div className='row'>
          <h3>Mis productos - Agregar productos</h3>
          <div className='col-6'>
            <button>Agregar lote</button>
            <h3 className='text-center py-2 bg-success text-white my-3'>
              Lote 1
            </h3>
            <div className='adminbodega__productosadd'>
              <label>Fecha de vencimiento </label>
              <input type='date' />
            </div>
            <div className='adminbodega__productosadd'>
              <label>Stock actual</label>
              <input type='text' />
            </div>
            <div className='adminbodega__productosadd'>
              <label>Fecha de vencimiento </label>
              <div>
                <div className='adminbodega__productoscheck'>
                  <input type='checkbox' />
                  <label>Unid</label>
                  <input type='text' />
                </div>
                <div className='adminbodega__productoscheck'>
                  <input type='checkbox' />
                  <label>Sixpack</label>
                  <input type='text' />
                </div>
              </div>
            </div>
            <div className='adminbodega__productosadd'>
              <input type='checkbox' />
              <label>Precio de compra - unid </label>
              <input type='text' className='form-group' />
            </div>
            <div className='adminbodega__productosadd'>
              <input type='checkbox' />
              <label>Precio de compra - sixpack </label>
              <input type='text' className='form-group' />
            </div>
            <div className='adminbodega__productosadd'>
              <input type='checkbox' />
              <label>Precio de venta - unid </label>
              <input type='text' className='form-group' />
            </div>
            <div className='adminbodega__productosadd'>
              <input type='checkbox' />
              <label>Precio de venta - unid </label>
              <input type='text' className='form-group' />
            </div>
          </div>
          <div className='col-6'>
            <h4>Fecha de vencimiento</h4>
            <div className='adminbodega__productosadd'>
              <label>Generara alerta(días)</label>
              <input type='text' className='form-control' />
            </div>
            <h4>Stock</h4>
            <div className='adminbodega__productosadd'>
              <label>Fecha de vencimiento </label>
              <div>
                <div className='adminbodega__productoscheck'>
                  <input type='checkbox' />
                  <label>Unid</label>
                  <input type='text' />
                </div>
                <div className='adminbodega__productoscheck'>
                  <input type='checkbox' />
                  <label>Sixpack</label>
                  <input type='text' />
                </div>
              </div>
            </div>
            <h4>Fotografía</h4>
            <div className='adminbodega__productosgrafico'>
              <img src={pilsen} alt='' />
            </div>
            <button className='botones actualizar' onClick={handleFinalizar}>
              Finalizar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminBodegaAgregarProducto;
