import { useSelector } from 'react-redux';
import logo from '../../assets/logo.png';
const SidebarLogo = () => {
  const { user } = useSelector((state) => state.auth);
  const logoEmployess = user?.encargado[0]?.empresa?.logo;
  return (
    <div className='dashboard__logo'>
      <div>
        <img
          src={logoEmployess ? logoEmployess : logo}
          alt='yasta'
          className='brand-image img-circle '
        />
      </div>
    </div>
  );
};
// log
export default SidebarLogo;
