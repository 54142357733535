import React from 'react'

export const BodegaProductoContext = React.createContext()

const ProviderBodegaProducto = (props) => {

    const [productosContext, setProductosContext] = React.useState([])

    return (
        <BodegaProductoContext.Provider value={{ productosContext, setProductosContext }}>
            {props.children}
        </BodegaProductoContext.Provider>
    )
}

export default ProviderBodegaProducto