import bodega from '../../assets/estadisticas/Bodega.svg';
import total from '../../assets/estadisticas/total.svg';
import ferreteria from '../../assets/estadisticas/ferreteria.svg';
import farmacia from '../../assets/estadisticas/farmacia.svg';
import ropa from '../../assets/estadisticas/Ropa.svg';

const Estadisticas = () => {
  return (
    <div className="tiendaEstadisticas">
      <div className="card sombras">
        <img src={total} alt="" />
        <p>Total</p>
      </div>
      <div className="card sombras">
        <img src={bodega} alt="" />
        <p>Bodega</p>
      </div>
      <div className="card sombras">
        <img src={ferreteria} alt="" />
        <p>Ferreteria</p>
      </div>
      <div className="card sombras">
        <img src={farmacia} alt="" />
        <p>Farmacias</p>
      </div>
      <div className="card sombras">
        <img src={ropa} alt="" />
        <p>Tiendas de ropa</p>
      </div>
    </div>
  );
};

export default Estadisticas;
