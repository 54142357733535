import { Route, Switch } from 'react-router-dom';
// import { startChecking } from '../actions/auth';
import SidebarAdminbodega from '../components/dashboard/adminbodega/SidebarAdminbodega';
import Navbar from '../components/dashboard/Navbar';
import ViewRutas from '../components/dashboard/ViewRutas';
//import PlanesPago from '../components/registro/PlanesPago';

const LayoutAdminBodega = ({ routes }) => {
  return (
    <div className='wrapper'>
      <Navbar />
      <SidebarAdminbodega />
      <div className='content-wrapper'>
        <ViewRutas />
        <section className='content px-3'>
          <div className='container-fluid'>
            <div className='row'>
              <LoadRoutes routes={routes} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
}

export default LayoutAdminBodega;
