/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { fetchSinToken, fetchSinTokenFornData } from '../../api/consultas';
import { UploadAvatar } from '../../helpers/UploadImage';
import ValidDocumento from '../../hooks/ValidDocumento';

export default function RegistroNegocioForm({ history }) {
    const [logo, setAvatar] = useState(null);
    const [departamento, setDepartamento] = useState([]);
    const [provincia, setProvincia] = useState([]);
    const [dataProvincia, setDataProvincia] = useState([]);
    const [distrito, setDistrito] = useState([]);
    const [dataDistrito, setDataDistrito] = useState([]);
    const [rubro, setRubro] = useState([]);
    const [distrito_id /* setObtdistrito */] = useState('');
    const [, /* loginData */ setLoginData] = useState({});
    const [dniVendedor, setdniVendedor] = useState('');

    const [buttonDocumentoVendedor, setbuttonDocumentoVendedor] =
        useState('Validar');
    const [loadDocumentoVendedor, setloadDocumentoVendedor] = useState(false);
    const [openValidDocumento, setOpenValidDocumento] = useState(false);

    //Toggle Password
    const [seePassword, setseePassword] = useState('password');
    const [seeConfirmPassword, setseeConfirmPassword] = useState('password');

    const [createEmployess, setCreateEmployess] = useState({
        nombre_comercial: '',
        razon_social: '',
        ruc: '',
        tipo_documento_id: null,
        representante_legal_nombre: '',
        representante_legal_apellido_pat: '',
        representante_legal_apellido_mat: '',
        representante_legal_dni: '',
        direccion_legal: '',
        email: '',
        celular: '',
        telefono: '',
        rubro_id: null,
        tipo_empresa_id: '',
        logo: null,
        tiempo_acceso: '2022-06-06',
        tipo_negocio_id: null,
        distrito_id: '',
        username: '',
        password: '',
        red_social: null,
    });

    const {
        nombre_comercial,
        razon_social,
        ruc,
        representante_legal_nombre,
        representante_legal_apellido_pat,
        representante_legal_apellido_mat,
        representante_legal_dni,
        direccion_legal,
        email,
        celular,
        telefono,
        username,
        password,
        confirmPassword,
        tiempo_acceso,
        rubro_id,
        tipo_empresa_id,
        tipo_negocio_id,
        tipo_documento_id,
        red_social,
    } = createEmployess;

    useEffect(() => {
        if (logo) {
            setCreateEmployess({ ...createEmployess, logo });
        }
        if (distrito_id) {
            setCreateEmployess({ ...createEmployess, distrito_id });
        }
    }, [logo]);

    const getRubros = () => {
        fetchSinToken('empresa/rubros/get')
            .then((resp) => resp.json())
            .then((resp) => setRubro(resp.data));
    };

    const getDepartamentos = () => {
        fetchSinToken('departamentos/get')
            .then((data) => data.json())
            .then((resp) => setDepartamento(resp.data));
    };
    const getProvincias = () => {
        fetchSinToken('provincias/get')
            .then((data) => data.json())
            .then((resp) => {
                setProvincia(resp.data);
                setDataProvincia(resp.data);
            });
    };

    useEffect(() => {
        try {
            getRubros();
            fetchSinToken('distritos/get')
                .then((resp) => resp.json())
                .then((resp) => {
                    setDistrito(resp.data);
                    setDataDistrito(resp.data);
                });
            getDepartamentos();
            getProvincias();
        } catch (err) {
        }
    }, []);

    const handleObtenerData = (e) => {
        setLoginData({
            username,
            password,
        });
        if (e.target.name === 'selectDepartamento') {
            const provincias = dataProvincia.filter(
                (item) => parseInt(e.target.value) === item.departamento_id
            );
            setProvincia(provincias);
        }
        if (e.target.name === 'selectProvincia') {
            const distritos = dataDistrito.filter(
                (item) => parseInt(e.target.value) === item.provincia_id
            );
            setDistrito(distritos);
        }
        if (e.target.name === 'tipo_documento_id') {
            if (e.target.value === '1') {
                setOpenValidDocumento(true);
            } else {
                setOpenValidDocumento(false);
            }
        }
        setCreateEmployess({
            ...createEmployess,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'tipo_empresa_id') {
            if (e.target.value === 1) {
                document.getElementById(
                    'container_natural_business'
                ).style.display = '';
                document.getElementById('container_other_business').style.display =
                    'none';
            } else {
                document.getElementById(
                    'container_natural_business'
                ).style.display = 'none';
                document.getElementById('container_other_business').style.display =
                    '';
            }
        }
        if (e.target.name === 'red_social') {
            if (e.target.value === 4) {
                document.getElementById('vendedor_dni').removeAttribute('disabled');
                document.getElementById('container__hidden').style.display = '';
            } else {
                document
                    .getElementById('vendedor_dni')
                    .setAttribute('disabled', 'disabled');
                document.getElementById('container__hidden').style.display = 'none';
            }
        }
    };

    const validaciones = () => {
        if (
            ruc.trim().length === 0 ||
            nombre_comercial.trim().length === 0 ||
            razon_social.trim().length === 0 ||
            representante_legal_nombre.trim().length === 0 ||
            representante_legal_apellido_pat.trim().length === 0 ||
            representante_legal_apellido_mat.trim().length === 0 ||
            representante_legal_dni.trim().length === 0 ||
            direccion_legal.trim().length === '' ||
            email.trim().length === 0 ||
            celular.trim().length === 0 ||
            telefono.trim().length === 0 ||
            tiempo_acceso.trim().length === 0 ||
            username.trim().length === 0 ||
            password.trim().length === 0 ||
            // password.length > 8 ||
            rubro_id === null ||
            tipo_empresa_id === null ||
            tipo_negocio_id === null ||
            tipo_documento_id === null
        ) {
            toast.error('Los campos no deben estar vacíos', {
                position: toast.POSITION.TOP_RIGTH,
            });
            return;
        }
        if (logo === null) {
            toast.error('Agregue una imagen como Logo', {
                position: toast.POSITION.TOP_RIGTH,
            });
            return;
        }
        if (password !== confirmPassword) {
            toast.error('LAS CONTRASEÑAS NO COINCIDEN', {
                position: toast.POSITION.TOP_RIGTH,
            });
            return;
        }
        const formData = new FormData();
        formData.set('nombre_comercial', createEmployess.nombre_comercial || '');
        formData.set('razon_social', createEmployess.razon_social || '');
        formData.set('ruc', createEmployess.ruc || '');
        formData.set('tipo_documento_id', createEmployess.tipo_documento_id || '');
        formData.set('representante_legal_nombre', createEmployess.representante_legal_nombre|| '');
        formData.set('representante_legal_apellido_pat', createEmployess.representante_legal_apellido_pat || '');
        formData.set('representante_legal_apellido_mat', createEmployess.representante_legal_apellido_mat || '');
        formData.set('representante_legal_dni', createEmployess.representante_legal_dni || '');
        formData.set('direccion_legal', createEmployess.direccion_legal || '');
        formData.set('email', createEmployess.email || '');
        formData.set('celular', createEmployess.celular || '');
        formData.set('telefono', createEmployess.telefono || '');
        formData.set('rubro_id', createEmployess.rubro_id || '');
        formData.set('tipo_empresa_id', createEmployess.tipo_empresa_id || '');
        formData.set('logo', logo ? logo.file : '');
        formData.set('tiempo_acceso', createEmployess.tiempo_acceso || '');
        formData.set('tipo_negocio_id', createEmployess.tipo_negocio_id || '');
        formData.set('distrito_id', createEmployess.distrito_id || '');
        formData.set('username', createEmployess.username || '');
        formData.set('password', createEmployess.password || '');
        formData.set('red_social', createEmployess.red_social || '');
        fetchSinTokenFornData('empresa/create', formData, 'POST').then(
            (resp) => {
                if (resp.status === 500) {
                    toast.error(`Error al registrar`, {
                        position: toast.POSITION.TOP_RIGTH,
                    });
                    return resp.json();
                } else {
                    history.push('/login');
                    toast.success(`Creado correctamente`, {
                        position: toast.POSITION.TOP_RIGTH,
                    });
                }
            }
        );
    };

    /* const validarDocumentoValues = async (e) => {
        e.preventDefault();
        try {
            const data = await validarDocumento(
                setButtonDocumento,
                setloadDocumento,
                representante_legal_dni
            );
            setCreateEmployess({
                ...createEmployess,
                representante_legal_nombre: data.nombres,
                representante_legal_apellido_pat: data.apellidoPaterno,
                representante_legal_apellido_mat: data.apellidoMaterno,
            });
        } catch (err) {
            console.log(err);
        }
    }; */

    const validarDocumento = async (setBtn, setLoad, parametro) => {
        try {
            if (parametro.trim().length === 0) {
                toast.error('Complete el campo DNI', {
                    position: toast.POSITION.TOP_RIGTH,
                });
                return;
            }
            setBtn('...');
            setLoad(true);
            const data = await fetch(
                `https://dniruc.apisperu.com/api/v1/dni/${parametro}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJpeWV5aW0zMTlAY2FycGV0ZC5jb20ifQ.NaXtG7Nokt0tybRlEkuffmsKPjX4Gk8k-2pX7Rgn3j0`
            ).then((res) => res.json());
            toast.success('Documento Validado', {
                position: toast.POSITION.TOP_RIGTH,
            });
            return data;
        } catch (err) {
            toast.error('Documento no encontrado', {
                position: toast.POSITION.TOP_RIGTH,
            });
        } finally {
            setLoad(false);
            setBtn('Validar');
        }
    };


    const [submitted, setsubmitted] = useState(false);
    const handleRegistroLocal = (e) => {
        e.preventDefault();
        validaciones();
        setsubmitted(true);
    };

    //Toggle Password
    const changeStatePassword = (value, setValue) => {
        if (value === 'text') {
            setValue('password');
        } else {
            setValue('text');
        }
    };
    return <div className="container my-4">
        <ToastContainer />
        <form
            className={`sombra px-3 ${submitted && 'was-validated'}`}
            novalidate
        >
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-5">
                    <div>
                        <h5 className=" " style={{ color: '#4a4b4c' }}>
                            Tipo de empresa
                        </h5>
                        <div className="adminregistro__tipo">
                            <div className="adminregistro__datos">
                                <label>Tipo de Negocio:</label>
                                <select
                                    aria-label="Default select example"
                                    className="form-select"
                                    name="tipo_empresa_id"
                                    onChange={handleObtenerData}
                                    value={tipo_empresa_id}
                                    required
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="1">Persona Natural</option>
                                    <option value="2">Persona Juridica</option>
                                    <option value="3">
                                        Persona natural con negocio
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5 className=" " style={{ color: '#4a4b4c' }}>
                            Datos generales
                        </h5>
                        <div
                            id="container_natural_business"
                            style={{ display: 'none' }}
                        >
                            <div className="adminregistro__datos">
                                <label>Nombre del negocio</label>
                                <input type="text" required />
                            </div>
                            <div className="adminregistro__datos">
                                <label>DNI:</label>
                                <input type="text" required />
                            </div>
                            <div className="adminregistro__datos">
                                <label>Nombre:</label>
                                <input type="text" required />
                            </div>
                            <div className="adminregistro__datos">
                                <label>Apellido P.:</label>
                                <input type="text" required />
                            </div>
                            <div className="adminregistro__datos">
                                <label>Apellido M.:</label>
                                <input type="text" required />
                            </div>
                        </div>
                        <div id="container_other_business">
                            <div className="adminregistro__datos">
                                <label>RUC:</label>
                                <div className="adminregistro__valid">
                                    <input
                                        name="ruc"
                                        onChange={handleObtenerData}
                                        type="text"
                                        value={ruc}
                                        required
                                    />
                                    <ValidDocumento valueInput={ruc} tipo="RUC" setCreateEmployess={setCreateEmployess} createEmployess={createEmployess} />
                                </div>
                            </div>
                            <div className="adminregistro__datos">
                                <label>Nombre comercial</label>
                                <input
                                    name="nombre_comercial"
                                    onChange={handleObtenerData}
                                    type="text"
                                    value={nombre_comercial}
                                    required
                                />
                            </div>
                            <div className="adminregistro__datos">
                                <label>Razon social:</label>
                                <input
                                    name="razon_social"
                                    onChange={handleObtenerData}
                                    type="text"
                                    value={razon_social}
                                    required
                                />
                            </div>
                        </div>

                        <div className="adminregistro__datos">
                            <label>Tipo de Negocio:</label>
                            <select
                                aria-label="Default select example"
                                className="form-select"
                                name="tipo_negocio_id"
                                onChange={handleObtenerData}
                                required
                            >
                                <option key="0" value="">
                                    Seleccionar
                                </option>
                                <option key="1" value="1">
                                    Bodega
                                </option>
                            </select>
                        </div>

                        {/* DEPARTAMENTO */}
                        {/* -------- */}
                        <div className="adminregistro__datos">
                            <label>Departamento:</label>
                            <div className="autosearch">
                                <select
                                    className="form-select"
                                    onChange={(e) => handleObtenerData(e)}
                                    name="selectDepartamento"
                                    value={departamento.id}
                                    required
                                >
                                    <option key={0} value="">
                                        Seleccionar
                                    </option>
                                    {departamento.map((data) => {
                                        return (
                                            <option key={data.id} value={data.id}>
                                                {data.departamento}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        {/* PROVINCIA */}
                        {/* -------- */}
                        <div className="adminregistro__datos">
                            <label>Provincia:</label>
                            <div className="autosearch">
                                <select
                                    className="form-select"
                                    onChange={(e) => handleObtenerData(e)}
                                    name="selectProvincia"
                                    value={departamento.id}
                                    required
                                >
                                    <option key={0} value="">
                                        Seleccionar
                                    </option>
                                    {provincia.map((data) => {
                                        return (
                                            <option key={data.id} value={data.id}>
                                                {data.provincia}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        {/* DISTRITO */}
                        {/* -------- */}
                        <div className="adminregistro__datos">
                            <label>Distrito:</label>
                            <div className="autosearch">
                                <select
                                    className="form-select"
                                    onChange={(e) => handleObtenerData(e)}
                                    name="distrito_id"
                                    value={distrito.id}
                                    required
                                >
                                    <option key={0} value="">
                                        Seleccionar
                                    </option>
                                    {distrito.map((data) => {
                                        return (
                                            <option key={data.id} value={data.id}>
                                                {data.distrito}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="adminregistro__datos">
                            <label>Dirección:</label>
                            <input
                                name="direccion_legal"
                                onChange={handleObtenerData}
                                type="text"
                                value={direccion_legal}
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <h5 className=" " style={{ color: '#4a4b4c' }}>
                            Representante
                        </h5>
                        <div className="adminregistro__datos">
                            <label>Tipo de documento:</label>
                            <select
                                aria-label="Default select example"
                                className="form-select"
                                name="tipo_documento_id"
                                onChange={handleObtenerData}
                                required
                            >
                                <option key="0" value="">
                                    Seleccionar
                                </option>
                                <option key="1" value="1">
                                    DNI
                                </option>
                            </select>
                        </div>
                        <div className="adminregistro__datos">
                            <label>Número de Documento:</label>
                            {/* falta */}
                            <div className="adminregistro__valid">
                                <input
                                    name="representante_legal_dni"
                                    onChange={handleObtenerData}
                                    type="text"
                                    value={representante_legal_dni}
                                    required
                                />
                                {openValidDocumento && (
                                    <ValidDocumento valueInput={representante_legal_dni} tipo="DNI" setCreateEmployess={setCreateEmployess} createEmployess={createEmployess} />
                                )}
                            </div>
                        </div>
                        <div className="adminregistro__datos">
                            <label>Nombre:</label>
                            <input
                                name="representante_legal_nombre"
                                onChange={handleObtenerData}
                                type="text"
                                value={representante_legal_nombre}
                                required
                            />
                        </div>
                        <div className="adminregistro__datos">
                            <label>Apellido P. :</label>
                            <input
                                name="representante_legal_apellido_pat"
                                onChange={handleObtenerData}
                                type="text"
                                value={representante_legal_apellido_pat}
                                required
                            />
                        </div>
                        <div className="adminregistro__datos">
                            <label>Apellido M. :</label>
                            <input
                                name="representante_legal_apellido_mat"
                                onChange={handleObtenerData}
                                type="text"
                                value={representante_legal_apellido_mat}
                                required
                            />
                        </div>
                        <div className="adminregistro__datos">
                            <label>Correo:</label>
                            <input
                                name="email"
                                onChange={handleObtenerData}
                                type="text"
                                value={email}
                                required
                            />
                        </div>
                        <div className="adminregistro__datos">
                            <label>Celular:</label>
                            <input
                                name="celular"
                                onChange={handleObtenerData}
                                type="text"
                                value={celular}
                                required
                            />
                        </div>
                        <div className="adminregistro__datos">
                            <label>Teléfono:</label>
                            <input
                                name="telefono"
                                onChange={handleObtenerData}
                                type="text"
                                value={telefono}
                                required
                            />
                        </div>
                        <div className="adminregistro__datos">
                            <label>Rubro:</label>
                            <select
                                aria-label="Default select example"
                                className="form-select"
                                name="rubro_id"
                                onChange={handleObtenerData}
                                required
                            >
                                <option value="">Seleccionar</option>
                                {rubro.map((item) => {
                                    return (
                                        <option value={item.id}>
                                            {item.nombre}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-5">
                    <div>
                        <h5 className=" " style={{ color: '#4a4b4c' }}>
                            Crear usuario y contraseña:
                        </h5>
                        <div className="adminregistro__datos">
                            <label>Usuario</label>
                            <input
                                name="username"
                                onChange={handleObtenerData}
                                type="text"
                                value={username}
                                required
                            />
                        </div>
                        <div className="adminregistro__datos">
                            <label>Contraseña</label>
                            <div className="adminregistro__valid">
                                <input
                                    name="password"
                                    onChange={handleObtenerData}
                                    type={seePassword}
                                    value={password}
                                    required
                                />
                                <div
                                    onClick={() => {
                                        changeStatePassword(
                                            seePassword,
                                            setseePassword
                                        );
                                    }}
                                    className="buttonPassword__toggle"
                                >
                                    {seePassword === 'text' ? (
                                        <i className="fas fa-eye-slash"></i>
                                    ) : (
                                        <i className="fas fa-eye"></i>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="adminregistro__datos">
                            <label>Confirmar Contraseña</label>
                            <div className="adminregistro__valid">
                                <input
                                    name="confirmPassword"
                                    onChange={handleObtenerData}
                                    type={seeConfirmPassword}
                                    value={confirmPassword}
                                    required
                                />
                                <div
                                    onClick={() => {
                                        changeStatePassword(
                                            seeConfirmPassword,
                                            setseeConfirmPassword
                                        );
                                    }}
                                    className="buttonPassword__toggle"
                                >
                                    {seeConfirmPassword === 'text' ? (
                                        <i className="fas fa-eye-slash"></i>
                                    ) : (
                                        <i className="fas fa-eye"></i>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className=" " style={{ color: '#4a4b4c' }}>
                                ¿De dónde nos conoces?
                            </h5>
                            <div className="adminregistro__tipo">
                                <div className="adminregistro__datos">
                                    <label>Elige:</label>
                                    <select
                                        aria-label="Default select example"
                                        className="form-select"
                                        id="red_social"
                                        name="red_social"
                                        value={red_social}
                                        onChange={handleObtenerData}
                                    >
                                        <option value="0">Seleccionar</option>
                                        <option value="1">Facebook</option>
                                        <option value="2">Instagram</option>
                                        <option value="3">Tik Tok</option>
                                        <option value="4">Vendedor</option>
                                    </select>
                                </div>
                                <div
                                    className="adminregistro__datos"
                                    id="container__hidden"
                                    style={{ display: 'none' }}
                                >
                                    <label>DNI</label>
                                    <div className="adminregistro__valid">
                                        <input
                                            name="vendedor_dni"
                                            id="vendedor_dni"
                                            type="text"
                                            disabled
                                            onChange={(e) => {
                                                setdniVendedor(e.target.value);
                                            }}
                                        />
                                        <button
                                            className="adminvalidar__button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                validarDocumento(
                                                    setbuttonDocumentoVendedor,
                                                    setloadDocumentoVendedor,
                                                    dniVendedor
                                                );
                                            }}
                                            disabled={loadDocumentoVendedor}
                                        >
                                            {buttonDocumentoVendedor}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="adminregistro__datos">
                            <h6 className=" " style={{ color: '#4a4b4c' }}>
                                Logo de empresa
                            </h6>
                            {/* <i className="fas fa-camera"></i> */}
                        </div>
                        <div style={{ width: '100%', height: '300px' }}>
                            <UploadAvatar avatar={logo} setAvatar={setAvatar} />
                        </div>
                        {/* <div className="adminregistro__datos">
     <label>Confirmar Contraseña</label>
     <input
       type="text"
       className=""
       onChange={handleObtenerData}
     />
   </div> */}
                    </div>
                    <button
                        type="submit"
                        className="adminregistro__button"
                        onClick={handleRegistroLocal}
                    >
                        Crear
                    </button>
                </div>
            </div>
        </form>;
    </div>
}
