import React, { useEffect, useState, useRef } from "react";

import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";

import DataTable from "react-data-table-component";
import AdminProductosCrear from "./AdminProductosCrear";
import AdminProductosImpulsar from "./AdminProductosImpulsar";
import AdminProductosExportar from "./AdminProductosExportar";

import { paginacionOpciones } from "../../../helpers/TableConst";
import { fetchConToken, fetchConFornDataToken } from "../../../api/consultas";
import AdminProductoseditar from "./AdminProductosEditar";
import AdminCategoria from "./AdminCategoria";

const AdminProductos = () => {
  const [creatMProducto, setCreatMProducto] = useState(false);
  const [editarMProducto, setEditarMProducto] = useState(false);
  const [crearCategoriaM, setcrearCategoriaM] = useState(false);
  const [editarProducto, setEditarProducto] = useState({});
  const [impulsarMProducto, setImpulsarMProducto] = useState(false);
  const [exportarMProducto, setExportarMProducto] = useState(false);
  const [listProductos, setListProductos] = useState([]);
  const [products, setProducts] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [idProducto, setIdProducto] = useState(0);
  const [impulsarData, setImpulsarData] = useState({});

  const fileRef = useRef();

  const triggerClick = () => {
    console.log(fileRef.current.click());
  };

  const getProductos = () => {
    fetchConToken("productos/get")
      .then((data) => data.json())
      .then((data) => setProducts(data.data));
  };

  const importarExcel = (file) => {
    const formData = new FormData();
    formData.set("file", file);
    fetchConFornDataToken("productos/importar", formData, "POST")
      .then((data) => {
        if (data.status === 500) {
          toast.error("Error al importar!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        data.json();
      })
      .then(() => {
        getProductos();
      });
  };

  const subidaExcel = (e) => {
    if (e.target.files[0] !== undefined) {
      importarExcel(e.target.files[0]);
    }
  };

  useEffect(() => {
    getProductos();
  }, []);

  const columnas = [
    {
      name: "Ítem",
      selector: (row, index) => (index += 1),
      sortable: true,
      style: {
        borderBotton: "none",
        color: "#707070",
      },
      width: "80px",
    },
    {
      name: "Supercategoría",
      selector: (row) =>
        row && row.supercategoria ? row.supercategoria.nombre : "",
      sortable: true,
      grow: 1,
      compact: true,
      style: {
        color: "#707070",
        borderBotton: "none",
      },
    },
    {
      name: "Categoría",
      selector: (row) =>
        row && row.subcategoria ? row.subcategoria.categoria.nombre : "",
      sortable: true,
      grow: 1,
      compact: true,
      style: {
        color: "#707070",
        borderBotton: "none",
      },
    },
    {
      name: "Subcategoría",
      selector: (row) =>
        row && row.subcategoria ? row.subcategoria.nombre : "",
      sortable: true,
      compact: true,
      grow: 1,
      style: {
        color: "#707070",
        borderBotton: "none",
      },
    },
    {
      name: "Marca",
      selector: (row) => (row && row.marca ? row.marca.nombre : ""),
      sortable: true,
      center: true,
      compact: true,
      style: {
        color: "#707070",
        borderBotton: "none",
      },
    },
    {
      name: "Presentación",
      selector: (row) => (row && row.descripcion ? row.descripcion : ""),
      sortable: true,
      grow: 2,
      center: true,
      style: {
        color: "#707070",
        borderBotton: "none",
      },
    },
    {
      name: "Editar",
      button: true,
      right: true,
      compact: true,
      width: "70px",
      cell: (e) => (
        <button
          onClick={() => {
            handleAddEditar(e);
            setIdProducto(e.id);
          }}
          className="table__tablebutton editar"
        >
          <i className="fas fa-pencil-alt"></i>
        </button>
      ),
    },
    {
      name: "Impulsar",
      button: (row) => (row.impulsar === 1 ? true : false),
      right: true,
      compact: true,
      width: "70px",
      cell: (e) => (
        <button
          onClick={() => {
            handleImpulsar(e);
            setIdProducto(e.id);
          }}
          className="table__tablebutton editar"
        >
          <i
            className={
              e.impulsar === "1" ? "fas fa-star-half-alt" : "fas fa-star"
            }
          ></i>
        </button>
      ),
    },
    {
      name: "Eliminar",
      button: true,
      right: true,
      compact: true,
      width: "70px",
      cell: (e) => (
        <button
          onClick={() => handleDelete(e)}
          className="table__tablebutton eliminar"
        >
          <i className="far fa-trash-alt"></i>
        </button>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.impulsar === "1",
      style: {
        backgroundColor: "#fcebd9 !important",
        color: "rgba(255, 255, 255) !important",
      },
    },
  ];

  const handleAddProducto = () => {
    setCreatMProducto(true);
  };
  const handleAddEditar = (e) => {
    setEditarMProducto(true);
    setEditarProducto(e);
  };

  const handleImpulsar = (e) => {
    setImpulsarData(e);
    setImpulsarMProducto(true);
  };
  const handleAddCreateCategoria = () => {
    setcrearCategoriaM(true);
  };

  const handleExportar = () => {
    setExportarMProducto(true);
  };

  const handleDelete = (e) => {
    Swal.fire({
      title: "¿Desea eliminar?",
      text: `${e.marca.nombre} - ${e.descripcion}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchConToken("productos/delete/" + e.id, {}, "DELETE").then((result) =>
          result.json()
        );
        Swal.fire("Eliminado!", "Producto eliminado correctamente.", "success");
        getProductos();
      }
    });
  };

  useEffect(() => {
    const filtrarElemento = () => {
      const search =
        products &&
        products.filter((data) => {
          return (
            data.subcategoria.categoria.nombre
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.subcategoria.nombre
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.marca.nombre
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.descripcion
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda)
          );
        });
      setListProductos(search);
    };
    filtrarElemento();
  }, [busqueda, products]);

  const handleSearch = (e) => {
    setBusqueda(([e.target.name] = e.target.value));
  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="table-responsive">
          <div className="table__search">
            <input
              type="text"
              placeholder="Buscar"
              name="busqueda"
              value={busqueda}
              onChange={handleSearch}
            />
            <div className="adminproductos__actions">
              <div className="adminproductos__btnactions">
                <p>Crear nuevo producto</p>
                <i
                  className="fas fa-plus-circle"
                  onClick={handleAddProducto}
                ></i>
              </div>
              <div className="adminproductos__btnactions">
                <p>Crear nueva categoria</p>
                <i
                  className="far fa-clipboard"
                  onClick={handleAddCreateCategoria}
                ></i>
              </div>
              <div className="adminproductos__btnactions">
                <p>Exportar a excel</p>
                <i className="fas fa-file-excel mx-1" onClick={handleExportar}></i>
              </div>
              <div
                className="adminproductos__btnactions"
                onClick={triggerClick}
              >
                <p>Importar productos</p>
                <input
                  className="cargar"
                  type="file"
                  ref={fileRef}
                  id="file"
                  onChange={subidaExcel}
                  style={{ display: "none" }}
                />
                <i className="fas fa-upload mx-1"></i>
              </div>
            </div>
          </div>
          <DataTable
            columns={columnas}
            data={listProductos}
            pagination
            conditionalRowStyles={conditionalRowStyles}
            paginationComponentOptions={paginacionOpciones}
            fixedHeader
            fixedHeaderScrollHeight="100%"
            noDataComponent={<i className="fas fa-inbox table__icono"></i>}
          />
        </div>
      </div>

      {creatMProducto && (
        <AdminProductosCrear
          creatMProducto={creatMProducto}
          setCreatMProducto={setCreatMProducto}
          getProductos={getProductos}
        />
      )}
      {editarMProducto && (
        <AdminProductoseditar
          setEditarMProducto={setEditarMProducto}
          editarProducto={editarProducto}
          editarMProducto={editarMProducto}
          getProductos={getProductos}
          productLastId={products[products.length - 1].id}
        /> 
      )}
      {crearCategoriaM && (
        <AdminCategoria
          setcrearCategoriaM={setcrearCategoriaM}
          crearCategoriaM={crearCategoriaM}
        />
      )}
      {exportarMProducto && (
        <AdminProductosExportar
          setExportarMProducto={setExportarMProducto}
          exportarMProducto={exportarMProducto}
        />
      )}
      {impulsarMProducto && (
        <AdminProductosImpulsar
          impulsarData={impulsarData}
          setImpulsarMProducto={setImpulsarMProducto}
          impulsarMProducto={impulsarMProducto}
          idProducto={idProducto}
          getProductos={getProductos}
        />
      )}
    </>
  );
};

export default AdminProductos;
