import React from 'react';
import AdminEstadisticaGrafico from '../../graficos/AdminEstadisticaGrafico';
//import EstadisticasLugar from '../../select/EstadisticasLugar';
import Tiempo from '../../select/Tiempo';
import Lugares from '../../select/Lugares';

const AdminEstadisticasEvaluacionEvaluacion = () => {
  const handleCambios = () => {
    const presentation = document.getElementById('inputprod0');
    const marca = document.getElementById('inputprod1');
    const categorys = document.getElementById('inputprod2');
    const subcategorys = document.getElementById('inputprod3');

    if (presentation.id === 'inputprod0' && presentation.name === 'presentation') {
      if (presentation.checked === true) {
        document.getElementById('inputprodselect0').removeAttribute('disabled');
        marca.removeAttribute('disabled');
      } else if (presentation.checked === false) {
        document.getElementById('inputprodselect0').setAttribute('disabled', 'disabled');
        marca.setAttribute('disabled', 'disabled');
        marca.checked = false;
      }
    }

    if (marca.id === 'inputprod1' && marca.checked === true) {
      document.getElementById('inputprodselect1').removeAttribute('disabled');
    } else if (marca.id === 'inputprod1' && marca.checked === false) {
      document.getElementById('inputprodselect1').setAttribute('disabled', 'disabled');
      categorys.checked = false;
    }

    if (categorys.id === 'inputprod2' && categorys.checked === true) {
      document.getElementById('inputprodselect2').removeAttribute('disabled');
    } else if (categorys.id === 'inputprod2' && categorys.checked === false) {
      document.getElementById('inputprodselect2').setAttribute('disabled', 'disabled');
      subcategorys.checked = false;
    }

    if (subcategorys.id === 'inputprod3' && subcategorys.checked === true) {
      document.getElementById('inputprodselect3').removeAttribute('disabled');
    } else if (subcategorys.id === 'inputprod3' && subcategorys.checked === false) {
      document.getElementById('inputprodselect3').setAttribute('disabled', 'disabled');
    }
  };

  return (
    <div className='row'>
      <div className='col-12 col-sm-12 col-md-12 col-lg-6 '>
        <div class='accordion' id='accordionExample'>
          <div class='accordion-item'>
            <h2 class='accordion-header' id='headingOne'>
              <button
                class='accordion-button'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseOne'
                aria-expanded='true'
                aria-controls='collapseOne'
              >
                Periodo de tiempo
              </button>
            </h2>
            <div
              id='collapseOne'
              class='accordion-collapse collapse show'
              aria-labelledby='headingOne'
              data-bs-parent='#accordionExample'
            >
              <div class='accordion-body'>
                <Tiempo />
              </div>
            </div>
          </div>
          <div class='accordion-item'>
            <h2 class='accordion-header' id='headingTwo'>
              <button
                class='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseTwo'
                aria-expanded='false'
                aria-controls='collapseTwo'
              >
                Ubicación
              </button>
            </h2>
            <div
              id='collapseTwo'
              class='accordion-collapse collapse'
              aria-labelledby='headingTwo'
              data-bs-parent='#accordionExample'
            >
              <div class='accordion-body'>
                <Lugares />
              </div>
            </div>
          </div>
          <div class='accordion-item'>
            <h2 class='accordion-header' id='headingThree'>
              <button
                class='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseThree'
                aria-expanded='false'
                aria-controls='collapseThree'
              >
                Productos
              </button>
            </h2>
            <div
              id='collapseThree'
              class='accordion-collapse collapse'
              aria-labelledby='headingThree'
              data-bs-parent='#accordionExample'
            >
              <div class='accordion-body'>
                <div className='adminestadisticas__product'>
                  <div className='adminestadisticas__productinputs'>
                    <div className='adminestadisticas__productssg'>
                      <div>
                        <input
                          type='checkbox'
                          onChange={() => handleCambios()}
                          name='presentation'
                          id='inputprod0'
                          className='form-check-input'
                        />
                        <p>Presentación</p>
                      </div>
                      <select className='form-select' id='inputprodselect0'>
                        <option selected>Seleccionar</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </select>
                    </div>
                    <div className='adminestadisticas__productssg'>
                      <div>
                        <input
                          type='checkbox'
                          onChange={() => handleCambios()}
                          className='form-check-input'
                          id='inputprod1'
                          name='marca'
                        />
                        <p>Marca</p>
                      </div>
                      <select className='form-select' id='inputprodselect1'>
                        <option selected>Seleccionar</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className='adminestadisticas__productssg'>
                      <div>
                        <input
                          type='checkbox'
                          onChange={() => handleCambios()}
                          className='form-check-input'
                          id='inputprod2'
                          name='category'
                        />
                        <p>Categoría</p>
                      </div>
                      <select className='form-select' id='inputprodselect2'>
                        <option selected>Seleccionar</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </select>
                    </div>
                    <div className='adminestadisticas__productssg'>
                      <div>
                        <input
                          type='checkbox'
                          onChange={() => handleCambios()}
                          className='form-check-input'
                          id='inputprod3'
                          name='subcategory'
                        />
                        <p>Sub categoría</p>
                      </div>
                      <select className='form-select' id='inputprodselect3'>
                        <option selected>Seleccionar</option>
                        <option value='1'>One</option>
                        <option value='2'>Two</option>
                        <option value='3'>Three</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-sm-12 col-md-12 col-lg-6 adminestadisticas__graficos d-grid'>
        <div className='adminusurio__contenedorgrafico sombra mb-4'>
          <div className='adminestadisticas__botones'>
            <button className='estadistica'>Volumen de venta</button>
            <button className='estadistica'>Ganancias</button>
            <button className='estadistica'>Cantidad de productos</button>
            <button className='estadistica'>Exportar a excel</button>
          </div>
          <div className='grafico'>
            <AdminEstadisticaGrafico />
          </div>
          <button>Actualizar</button>
        </div>
      </div>
    </div>
  );
};

export default AdminEstadisticasEvaluacionEvaluacion;
