import React, { useState } from "react";
import { fetchConToken } from "../../../api/consultas";
import { ToastContainer, toast } from "react-toastify";
import AdminUsuarioGrafico from "../../graficos/AdminUsuarioGrafico";
import Lugares from "../../select/Lugares";
import Tiempo from "../../select/Tiempo";
const AdminTiendas = () => {
  const [grafico] = useState(true);
  const [userGrafico, setUserGrafico] = useState({
    initDate: "",
    endDate: "",
  });
  const [dataChart, setDataChart] = useState([{ data: [20] }]);
  const { initDate, endDate } = userGrafico;

  const handleExportarExcel = () => {
    toast.success("Exportado correctamente", {
      position: toast.POSITION.TOP_RIGTH,
    });
  };
  const handleActualizar = () => {
    if ((initDate === "", endDate === "")) {
      toast.warning("¡Completar campos vacios!", {
        position: toast.POSITION.TOP_RIGTH,
      });
    } else {
      fetchConToken(
        "estadisticas/usuarios",
        {
          initDate: initDate,
          endDate: endDate,
          distritos: "1250, 250",
        },
        "POST"
      )
        .then((data) => data.json())
        .then((data) => {
          setDataChart(data);
        });
    }
  };
  return (
    <div className="container">
      <ToastContainer />
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
          <div className="adminusurio__contenedor sombra">
            <p className="text-center title">Evaluación de número de tiendas</p>
            <Lugares
              dateGrafico={userGrafico}
              setDateGrafico={setUserGrafico}
            />
          </div>
          <div className="adminusurio__contenedor sombra">
            <p className="adminusuario__p subtitle">Período de tiempo:</p>
            <Tiempo dateGrafico={userGrafico} setDateGrafico={setUserGrafico} />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
          <div className="adminusurio__contenedorgrafico sombra ai-center h-100">
            <p className="text-center title">
              Evaluación de números de tiendas en el tiempo
            </p>
            <div className="grafico">
              {grafico ? <AdminUsuarioGrafico dataChart={dataChart} /> : ""}
            </div>
            <div className="todos__botones">
              <button className="botones actualizar" onClick={handleActualizar}>
                Actualizar
              </button>
              <button
                className="botones exportar"
                onClick={handleExportarExcel}
              >
                Exportar a Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTiendas;
