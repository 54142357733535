import React, { useState } from 'react';
import logo from '../assets/icons/kampanee.png';
import logo2 from '../assets/icons/kampanee2.png';
import logo3 from '../assets/icons/kampanee2.png';
import playstore from '../assets/icons/play.png';
import style from '../css/login.module.css';
import styleSection from '../css/section.module.css';

import lacteos from '../assets/images/lacteos.png';
import abarrotes from '../assets/images/abarrotes.png';
import bebidas from '../assets/images/bebidas.png';

import cuidado from '../assets/images/cuidado.png';
import mascotas from '../assets/images/mascotas.png';
import limpieza from '../assets/images/limpieza.png';

import vender from '../assets/icons/carrito.png';
import historial from '../assets/icons/CANASTA.png';
import pedidos from '../assets/icons/TARJETA.png';
import credito from '../assets/icons/historial.png';
import afiliate from '../assets/icons/Elemento_5.png';

const Home = () => {
  const [open, setOpen] = useState(false);
  const funcionMenuOpen = () => {
    // const element = document.getElementById('abrirMenu');
    const primario = document.getElementById('login__menu');
    const secundario = document.getElementById('login__submenu');
    secundario.classList.toggle('primario');
    primario.classList.toggle('primario');
    setOpen(!open);
    // console.log(primario);
  };

  const redirectToPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.kampanee.kampanee','_blank')
  };

  const redirectToWeb = () => {
    window.open('https://web.kampanee.com/','_blank')
  };

  return (
    <>
      <header className={style.login__header} id='header'>
        <div className={style.login__loginimg}>
          <nav className={style.login__nav}>
            <div className={style.login__navbar} onClick={funcionMenuOpen}>
              <i id='abrirMenu' className='fa-solid fa-bars'></i>
            </div>
            <div className={style.login__bar}>
              <li>
                <a href='#header'>Inicio</a>
              </li>
              <li>
                <a href='#sobre'>Sobre Kampanee</a>
              </li>
              <li>
                <a href='#clientes'>Clientes</a>
              </li>
              <li>
                <a href='#contactanos'>Contáctanos</a>
              </li>
              <li className={style.playstore}>
                <img src={playstore} alt='' onClick={redirectToPlayStore} />
              </li>
              <li className={style.login__ingresar} onClick={redirectToWeb}>Ingresar</li>
            </div>

            <div
              id='login__menu'
              className={open ? style.login__menu : style.login__menu.primario}
              onClick={funcionMenuOpen}
            >
              <div id='login__submenu' className={style.login__submenu}>
                <i
                  id='login__submenui'
                  className={style.login__submenui}
                  onClick={funcionMenuOpen}
                ></i>
                <picture>
                  <img src={logo} alt='' />
                </picture>
                <div>
                  <button>Ingresar</button>
                  <button>Registro</button>
                </div>
                <ul>
                  <span>Menú</span>
                  <li>
                    <a href='#' alt='inicio'>
                      <i class='fa-solid fa-house'></i>
                      Inicio
                    </a>
                  </li>
                  <li>
                    <a href='#' alt='sobre yasta'>
                      <i class='fa-solid fa-heart'></i>Sobre Kampanee
                    </a>
                  </li>
                  <li>
                    <a href='#' alt=''>
                      <i class='fa-solid fa-users'></i>Clientes
                    </a>
                  </li>
                  <li>
                    <a href='#' alt=''>
                      <i class='fa-solid fa-phone'></i>Contáctanos
                    </a>
                  </li>
                  <li>
                    <a href='#' alt=''>
                      <i class='fa-solid fa-book-open'></i>Libro de
                      reclamaciones
                    </a>
                  </li>
                  <li>
                    <a href='#' alt=''>
                      <i class='fa-solid fa-shield'></i>Privacidad
                    </a>
                  </li>
                  <li>
                    <a href='#' alt=''>
                      <i class='fa-solid fa-clipboard'></i>Términos y
                      condiciones
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className={style.login__contenido}>
            <div className={style.login__text}>
              <img src={logo} alt='YASTA' />
              <br />
              <span>Mantén tu negocio</span>
              <br />
              <span>Aliado con nosotros</span>
              <br />
              <span>
                ¡Aumenta tus ventas teniendo un mejor control administrativo de
                tu negocio!
              </span>

              <div className={style.login__registra}>
                <i className='fa-solid fa-store'></i>
                <p onClick={redirectToWeb}>Registra tu negocio</p>
                <i className='fa-solid fa-angle-right' onClick={redirectToWeb}></i>
                
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id='sobre'>
        <div className={styleSection.section__sobre}>
          <h3>
            <span>Sobre</span> Kampanee
          </h3>
          <div>
            <img src={logo2} alt='yasta' />
          </div>
          <p>
            La primera app de tu bodega favorita, que cuenta con una gran
            variedad de productos y promociones para ti.
          </p>
        </div>
        <div>
          <div className={styleSection.section__contenido}></div>
          <div className={styleSection.section__imagenes}>
            <div>
              <picture>
                <img src={lacteos} alt='lacteos' />
                <span>Lácteos y huevos</span>
              </picture>
              <picture className={styleSection.section__rigth}>
                <img src={abarrotes} alt='abarrotes' />
                <span>Abarrotes</span>
              </picture>
              <picture>
                <img src={bebidas} alt='bebidas' />
                <span>Bebidas</span>
              </picture>
            </div>
            <div>
              <picture>
                <img src={cuidado} alt='cuidado' />
                <span>Cuidado de Bebé</span>
              </picture>
              <picture className={styleSection.section__left}>
                <img src={mascotas} alt='mascotas' />
                <span>Mascotas</span>
              </picture>
              <picture>
                <img src={limpieza} alt='limpieza' />
                <span>Limpieza</span>
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section id='clientes'>
        <div className={styleSection.section__negacio}>
          <h3>
            <span>¿Si tengo un negocio,</span> <br /> como lo administro?
          </h3>
          <span>En Kampanee</span>
          <p>
            Esta App también va a permitir que pequeños emprendedores puedan
            digitalizar su negocio para poder monitorear y hacer crecer las
            ganancias de su negocio.
          </p>
        </div>
        <div>
          <div className={styleSection.section__contentengocio}></div>

          <div className={styleSection.section__contentimagenes}>
            <div>
              <picture>
                <img src={vender} alt='lacteos' />
                <span>
                  Registra tus ventas,
                  <br /> deudas y gastos.
                </span>
              </picture>
              <picture>
                <img src={pedidos} alt='abarrotes' />
                <span>
                  Crea tu tienda virtual y vende <br /> en línea.
                </span>
              </picture>
            </div>
            <div>
              <picture>
                <img src={historial} alt='cuidado' />
                <span>Crea y gestiona tu inventario.</span>
              </picture>
              <picture>
                <img src={credito} alt='mascotas' />
                <span>
                  Recibe todos los medios de <br /> pago.
                </span>
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className={styleSection.section__clientes}>
          <h3>Clientes</h3>
          <span>
            En esta plataforma puedes encontrar los productos de las siguientes
            marcas
          </span>
        </div>
      </section>
      {/* <section>
        <div className={styleSection.section__employess}>
          <img src={aje} alt='' />
          <img src={ajinomoto} alt='' />
          <img src={alicorp} alt='' />
          <img src={backus} alt='' />
          <img src={gloria} alt='' />
          <img src={sanfernando} alt='' />
          <img src={banner} alt='' />
          <img src={banner} alt='' />
          <img src={banner} alt='' />
          <img src={banner} alt='' />
          <img src={banner} alt='' />
          <img src={banner} alt='' />
          <img src={banner} alt='' />
        </div>
      </section> */}
      <section id='contactanos'>
        <div className={styleSection.section__afiliate}>
          <div>
            <img
              className={styleSection.section__telefono}
              src={playstore}
              alt='yasta' 
            />
            <img src={afiliate} alt='' />
          </div>

          <div>
            <span>¡Afíliate a Kampanee !</span>
            <br />
            <p>
              Descarga la App y Aumenta tus ventas teniendo un mejor control
              administrativo de tu negocio.
            </p>
          </div>
          <picture>
            <img src={playstore} alt='yasta' onClick={redirectToPlayStore} />
          </picture>
        </div>
      </section>
      <footer>
        <div className={styleSection.section__footer}>
          <div className={styleSection.section__footerimg}>
            <img src={logo3} alt='' />
            <img src={playstore} alt='' onClick={redirectToPlayStore} />
          </div>
          <div>
            <span>Menú</span>
            <ul>
              <li>Inicio</li>
              <li>Sobre Kampanee</li>
              <li>Clientes</li>
              <li>Contáctanos</li>
            </ul>
          </div>
          <div>
            <span>Sobre Kampanee</span>
            <ul>
              <li>Libro de reclamaciones</li>
              <li>Privacidad</li>
              <li>Clientes</li>
              <li>Términos y condiciones</li>
            </ul>
          </div>
          <div>
            <span>Únete a Kampanee</span>
            <ul>
              <li>Registra tu negocio</li>
              <li>Se parte de Kampanee</li>
            </ul>
          </div>
        </div>
      </footer>
    </>

    // <>
    //   <nav className='navbar navbar-expand-lg navbar-light bg-light background__navbar'>
    //     <div className='container-fluid'>
    //       <a className='navbar-brand' href='/'>
    //         <img src={logo} alt='' width='60' height='60' />
    //       </a>
    //       <button
    //         className='navbar-toggler'
    //         type='button'
    //         data-bs-toggle='collapse'
    //         data-bs-target='#navbarSupportedContent'
    //         aria-controls='navbarSupportedContent'
    //         aria-expanded='false'
    //         aria-label='Toggle navigation'
    //       >
    //         <span className='navbar-toggler-icon'></span>
    //       </button>
    //       <div className='collapse navbar-collapse' id='navbarSupportedContent'>
    //         <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
    //           <li className='nav-item'>
    //             <a
    //               className='nav-link navbar__attta'
    //               aria-current='page'
    //               href='/'
    //             >
    //               Inicio
    //             </a>
    //           </li>
    //           <li className='nav-item'>
    //             <a className='nav-link navbar__attta' href='/'>
    //               Nosotros
    //             </a>
    //           </li>
    //           <li className='nav-item'>
    //             <a className='nav-link navbar__attta' href='/'>
    //               Clientes
    //             </a>
    //           </li>
    //           <li className='nav-item'>
    //             <a className='nav-link navbar__attta' href='/'>
    //               Contáctenos
    //             </a>
    //           </li>
    //           <li className='nav-item'>
    //             <a className='nav-link navbar__attta' href='/registrar'>
    //               Crear Bodega
    //             </a>
    //           </li>
    //           <Link to={'/login'} className='nav-item'>
    //             <p className='nav-link navbar__attta'>Ingresar</p>
    //           </Link>
    //         </ul>
    //       </div>
    //     </div>
    //   </nav>
    //   {/*  */}
    //   <div
    //     id='carouselExampleCaptions'
    //     className='carousel slide'
    //     data-bs-ride='carousel'
    //   >
    //     <div className='carousel-indicators'>
    //       <button
    //         type='button'
    //         data-bs-target='#carouselExampleCaptions'
    //         data-bs-slide-to='0'
    //         className='active'
    //         aria-current='true'
    //         aria-label='Slide 1'
    //       ></button>
    //       <button
    //         type='button'
    //         data-bs-target='#carouselExampleCaptions'
    //         data-bs-slide-to='1'
    //         aria-label='Slide 2'
    //       ></button>
    //       <button
    //         type='button'
    //         data-bs-target='#carouselExampleCaptions'
    //         data-bs-slide-to='2'
    //         aria-label='Slide 3'
    //       ></button>
    //     </div>
    //     <div className='carousel-inner'>
    //       <div className='carousel-item active'>
    //         <img
    //           src={banner1}
    //           className='d-block w-100'
    //           alt='...'
    //           style={{ height: '670px' }}
    //         />
    //         <div className='carousel-caption d-none d-md-block'>
    //           <h5>Contendido uno</h5>
    //           <p>
    //             Some representative placeholder content for the first slide.
    //           </p>
    //         </div>
    //       </div>

    //       <div className='carousel-item'>
    //         <img
    //           src={banner2}
    //           className='d-block w-100'
    //           alt='...'
    //           style={{ height: '670px' }}
    //         />
    //         <div className='carousel-caption d-none d-md-block'>
    //           <h5>Contendido dos</h5>
    //           <p>
    //             Some representative placeholder content for the third slide.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //     <button
    //       className='carousel-control-prev'
    //       type='button'
    //       data-bs-target='#carouselExampleCaptions'
    //       data-bs-slide='prev'
    //     >
    //       <span
    //         className='carousel-control-prev-icon'
    //         aria-hidden='true'
    //       ></span>
    //       <span className='visually-hidden'>Previous</span>
    //     </button>
    //     <button
    //       className='carousel-control-next'
    //       type='button'
    //       data-bs-target='#carouselExampleCaptions'
    //       data-bs-slide='next'
    //     >
    //       <span
    //         className='carousel-control-next-icon'
    //         aria-hidden='true'
    //       ></span>
    //       <span className='visually-hidden'>Next</span>
    //     </button>
    //   </div>
    //   {/*  */}
    //   <div className='container-fluid'>
    //     <div className='row'>
    //       {/* <div className="col-md-3"> */}
    //       <div className='homenosotros__contenedor my-5'>
    //         <div className='contenido'>
    //           <h2>Tu negocio en tu bolsillo</h2>

    //           <p>
    //             La primera app que permite a pequeños negocios, comerciantes y
    //             emprendedores digitalizar, monitorear y crecer las finanzas de
    //             su negocio.
    //           </p>
    //           <ul>
    //             <li>
    //               <i className='far fa-chart-bar'></i>
    //               Registra tus ventas, deudas y gastos.
    //             </li>
    //             <li>
    //               <i className='fas fa-box'></i>
    //               Crea y gestiona tu inventario.
    //             </li>
    //             <li>
    //               <i className='fas fa-shopping-basket'></i>
    //               Crea tu tienda virtual y vende en línea.
    //             </li>
    //             <li>
    //               <i className='far fa-credit-card'></i>
    //               Recibe todos los medios de pago.
    //             </li>
    //           </ul>
    //         </div>
    //         <div className='divcircle'>
    //           <img src={fondoprincipal} alt='YASTA' />
    //           <div className='circle'></div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='container mb-5'>
    //     <CompanyLogobasboard />
    //   </div>
    // </>
  );
};

export default Home;
