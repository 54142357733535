/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'react-router-dom';
import SidebarLogo from '../SidebarLogo';

const SidebarAdmin = () => {
  return (
    <aside className='main-sidebar sidebar-dark-primary dashboard__aside'>
      <div className='sidebar'>
        <SidebarLogo />
        <h5 style={{ color: 'white', textAlign: 'center', fontWeight: 600 }}>
          Administrador
        </h5>
        <hr style={{ color: 'white' }} />
        <nav className='mt-2'>
          <ul
            className='nav nav-pills nav-sidebar flex-column'
            data-widget='treeview'
            role='menu'
            data-accordion='false'
          >
            <li className='nav-item'>
              <NavLink to='/admin/usuario' className='nav-link'>
                <i className='nav-icon fas fa-user'></i>
                <p>Usuarios</p>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/admin/tienda' className='nav-link'>
                <i className='nav-icon fas fa-store'></i>
                <p>Tiendas</p>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/admin/productos' className='nav-link'>
                <i className='nav-icon fas fa-table'></i>
                <p>Productos</p>
              </NavLink>
            </li>
            {/* <li className='nav-item'>
              <NavLink to='/admin/proveedor' className='nav-link'>
                <i className='fas fa-truck'></i>
                <p>Proveedores</p>
              </NavLink>
            </li> */}
            <li className='nav-item'>
              <NavLink to='/admin/estadistica' className='nav-link'>
                <i className='nav-icon far fa-chart-bar'></i>
                <p>
                  Estadísticas
                  {/* <i className='right fas fa-angle-left'></i> */}
                </p>
              </NavLink>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <NavLink to='/admin/estadistica' className='nav-link'>
                    <i className='nav-icon fas fa-store-alt'></i>
                    <p>Bodegas</p>
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink
                    to='/admin/estadistica/evaluacion'
                    className='nav-link'
                  >
                    <i className='nav-icon far fa-clipboard'></i>
                    <p>Evaluación</p>
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to='/admin/estadistica/totales' className='nav-link'>
                    <i className='nav-icon fas fa-chart-line'></i>
                    <p>Totales</p>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className='nav-item'>
              <NavLink to='/admin/nosotros' className='nav-link'>
                <i className='nav-icon fas fa-users'></i>
                <p>Nosotros</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SidebarAdmin;
