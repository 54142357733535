import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const UploadAvatar = ({ setAvatar, avatar, datos }) => {
  const onDrop = useCallback(
    (acceptdFiles) => {
      const file = acceptdFiles[0];
      setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/png, image/jpg, image/jpeg",
    noKeyboard: true,
    onDrop,
  });
  return (
    <div className="image__upload" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <>
          <img
            // className={avatar ? 'image__avatar' : 'image__carga'}
            src=""
            alt=""
          />
          <p>precionar</p>
        </>
      ) : (avatar != null ? (
        <>
          <img
            className="image__avatar"
            src={avatar ? avatar.preview || avatar : ""}
            alt=""
            loading="lazy"
          />
        </>
      ) : (
        <>
          <img
            className="image__avatar"
            src={avatar ? avatar.preview || avatar : ""}
            alt=""
          />
          <span>Presionar aquí</span>
        </>
      ))}
    </div>
  );
};

export { UploadAvatar };
