import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fetchSinToken } from '../api/consultas';
import logo from '../assets/logo.png';


const ResetPassword = () => {
  const history = useHistory();
  const [correo, setCorreo] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if(correo === ''){
      toast.warning('Favor de completar el campo requerido!',{
        position: toast.POSITION.TOP_RIGHT
      })
    }else{
        fetchSinToken('reset-password', {email: correo},'POST')
        .then((response) => {
          if(response.status === 422){
            toast.error('Usuario no encontrado o error en el envío!', {
              position: toast.POSITION.TOP_RIGHT
            })
          }else{
            toast.success('Correo enviado correctamente', {
              position: toast.POSITION.TOP_RIGHT
            })
            setTimeout(() => {
              history.push('/login');
            },2000);
          }
        })
    }
  }
    return (
        <div className='container-fluid login__container'>
          <ToastContainer />
          <div className='row'>
            <div className='col-sm-3'>
            </div>
            <div className='col-sm-6 login__formulario mt-5'>
              <div className='cambiandologo '>
                <div className='login__img'>
                  <img src={logo} alt='yasta' />
                </div>
                <h4>Olvide mi contraseña</h4>
                <form onSubmit={handleSubmit}>
                  <div className='login__form'>
                    <input
                      type='text'
                      placeholder='Correo'
                      name='correo'
                      value={correo}
                      onChange={(e) => setCorreo(e.target.value)}
                    />
                  </div>
                  <div className='login__form'>
                    <div className='login__valid'>
                    </div>
                  </div>
                  <div className='login__form'>
                    <button type='submit' className='pt-2 pb-2'>
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
}

export default ResetPassword;