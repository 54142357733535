import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Navbar from '../components/dashboard/Navbar';
import SidebarAdmin from '../components/dashboard/admin/SidebarAdmin';
// import SubSidebar from '../components/dashboard/SubSidebar';
import ViewRutas from '../components/dashboard/ViewRutas';

const LayoutAdmin = ({ routes, history }) => {
  return (
    <div className='wrapper'>
      <Navbar />
      <SidebarAdmin />
      <div className='content-wrapper'>
        <ViewRutas history={history} />
        <section className='content px-3 pb-4'>
          <div className='container-fluid'>
            <div className='row'>
              <LoadRoutes routes={routes} />
            </div>
          </div>
        </section>
      </div>
      {/* <SubSidebar /> */}
    </div>
  );
};

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
}
export default LayoutAdmin;
