import React from 'react';
//import { useSelector } from 'react-redux';
const ViewRutas = ({ history }) => {
 /*  const { user} = useSelector(state => state.auth.user) */
  // console.log(history);
  // const ruta = history.location.pathname;
  // const pathname = ruta.slice(1);
  return (
    <div className='content-header'>
      <div className='container-fluid'>
        <div className='row py-3 px-2'>
          <div className='view__title ps-4'>
            <ol className='breadcrumb '>
              <li className='breadcrumb-item'>
                <li className='breadcrumb-item'>Bienvenido</li>
              </li>
              {/* <li className='breadcrumb-item active'>{pathname}</li> */}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRutas;
