import React from 'react';

export const AdminBodegaProductoContext = React.createContext()

const ProviderAdminBodegaProducto = (props) => {

    const [productosContext, setProductosContext] = React.useState([])

    return (
        <AdminBodegaProductoContext.Provider value={{ productosContext, setProductosContext }}>
            {props.children}
        </AdminBodegaProductoContext.Provider>
    )
}

export default ProviderAdminBodegaProducto
