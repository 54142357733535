/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SidebarLogo from "../SidebarLogo";

const SidebarAdminbodega = () => {
  const data = useSelector((state) => state);
  return (
    <aside className="main-sidebar sidebar-dark-primary dashboard__aside">
      <div className="sidebar">
        <SidebarLogo />
        <h5 style={{ color: "white", textAlign: "center", fontWeight: 600 }}>
          Administrador Bodega
        </h5>
        {data.auth.user &&
        data.auth.user.administrador[0].empresa.pagado !== 0 ? (
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink to="/adminbodega/tiendas" className="nav-link">
                  <i className="nav-icon fas fa-store"></i>
                  <p>Mis Tiendas</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/adminbodega/producto" className="nav-link">
                  <i className="nav-icon fas fa-table"></i>
                  <p>Productos</p>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink to="/adminbodega/trabajador" className="nav-link">
                  <i class="nav-icon fas fa-users"></i>
                  <p>Trabajadores</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/adminbodega/sugerencia" className="nav-link">
                  <i class="nav-icon far fa-lightbulb"></i>
                  <p>Sugerencias</p>
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink to="/adminbodega/estadistica" className="nav-link">
                  <i className="nav-icon far fa-chart-bar"></i>
                  <p>
                    Indicadores de Gestión
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </NavLink>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink
                      to="/adminbodega/estadistica/evaluacion"
                      className="nav-link"
                    >
                      <i className="nav-icon fas fa-store-alt"></i>
                      <p>Evaluación</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/adminbodega/estadistica/total"
                      className="nav-link"
                    >
                      <i className="nav-icon far fa-clipboard"></i>
                      <p>Vendidos</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink to="/adminbodega/nosotros" className="nav-link">
                  <i className="nav-icon fas fa-users"></i>
                  <p>Nosotros</p>
                </NavLink>
              </li>
            </ul>
          </nav>
        ) : (
          ""
        )}
      </div>
    </aside>
  );
};

export default SidebarAdminbodega;
