import { Route, Switch } from 'react-router-dom';
import Navbar from '../components/dashboard/Navbar';
import SidebarTienda from '../components/dashboard/tiendaAdmin/SidebarTienda';
import ViewRutas from '../components/dashboard/ViewRutas';

const LayoutAdminTienda = ({ routes }) => {
  return (
    <div>
      <Navbar />
      <SidebarTienda />
      <div className='content-wrapper'>
        <ViewRutas />
        <section className='content px-3'>
          <div className='container-fluid'>
            <div className='row'>
              <LoadRoutes routes={routes} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
}

export default LayoutAdminTienda;
