const baseUrl = process.env.REACT_APP_API_URL;

const signInApi = (endpoint, data, method) => {
  const url = `${baseUrl}/${endpoint}`;
  if (method === 'GET') {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchSinToken = (endpoint, data, method) => {
  const url = `${baseUrl}/${endpoint}`;
  if (method === 'GET') {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
};

const pruebametodo = (endpoint, data, method) => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem('token') || '';
  if (method === 'GET') {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        'content-type': 'application/json',
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchConToken = (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem('token') || '';
  try {
    if (method === 'GET') {
      return fetch(url, {
        method,
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } else {
      return fetch(url, {
        method,
        headers: {
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    }
  } catch (err) {
  }
};

const fetchConFornDataToken = (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem('token') || '';

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });
  }
};

const fetchSinTokenFornData = (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endpoint}`;

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } else {
    return fetch(url, {
      method,
      body: data,
    });
  }
};

const renovarToken = (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem('token') || '';

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
};

export {
  signInApi,
  fetchSinToken,
  fetchConToken,
  fetchConFornDataToken,
  pruebametodo,
  fetchSinTokenFornData,
  renovarToken,
  // fetchConFornDataTokenJson
};
