import React, { useState } from 'react';

import AdminEstadisticasEvaluacionEvaluacion from './AdminEstadisticasEvaluacionEvaluacion';
import AdminEstadisticasEvaluacionProductotop from './AdminEstadisticasEvaluacionProductotop';

const AdminEstadisticasEvaluacion = () => {
  const [counter, setCounter] = useState(1);

  const handleClick = (index) => {
    setCounter(index);
  };

  return (
    <div className='container'>
      <nav className='adminestadisticas__nav'>
        <div className='nav nav-tabs ' id='nav-tab' role='tablist'>
          <button
            className={counter === 1 ? 'nav-link active' : 'nav-link'}
            id='nav-home-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-home'
            type='button'
            role='tab'
            aria-controls='nav-home'
            aria-selected={counter === 1 ? 'true' : 'false'}
            onClick={() => handleClick(1)}
          >
            Evaluación de totales
          </button>
          <button
            className={counter === 2 ? 'nav-link active' : 'nav-link'}
            id='nav-profile-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-profile'
            type='button'
            role='tab'
            aria-controls='nav-profile'
            aria-selected={counter === 2 ? 'true' : 'false'}
            onClick={() => handleClick(2)}
          >
            Productos top
          </button>
        </div>
      </nav>

      <div className='tab-content ' id='nav-tabContent'>
        <div
          className={counter === 1 ? 'tab-pane fade show active' : 'tab-pane fade'}
          id='nav-home'
          role='tabpanel'
          aria-labelledby='nav-home-tab'
        >
          <AdminEstadisticasEvaluacionEvaluacion />
        </div>
        {/*  */}
        <div
          className={counter === 2 ? 'tab-pane fade show active' : 'tab-pane fade'}
          id='nav-profile'
          role='tabpanel'
          aria-labelledby='nav-profile-tab'
        >
          <AdminEstadisticasEvaluacionProductotop />
        </div>
      </div>
    </div>
  );
};

export default AdminEstadisticasEvaluacion;
