import { NavLink } from 'react-router-dom';
import SidebarLogo from '../SidebarLogo';

const SidebarTienda = () => {
    return (
        <aside className="main-sidebar sidebar-dark-primary dashboard__aside">
        <div className="sidebar">
          <SidebarLogo />
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink to="/tienda/admin/usuarios" className="nav-link">
                  <i className="nav-icon fas fa-user"></i>
                  <p>Usuarios</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tienda/admin/tiendas" className="nav-link">
                  <i className="nav-icon fas fa-store"></i>
                  <p>Mis tiendas</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tienda/admin/productos" className="nav-link">
                  <i className="nav-icon fas fa-table"></i>
                  <p>Productos</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tienda/admin/estadisticas" className="nav-link">
                  <i className="nav-icon fas fa-table"></i>
                  <p>Estadísticas</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/tienda/admin/nosotros" className="nav-link">
                  <i className="nav-icon fas fa-users"></i>
                  <p>Nosotros</p>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    )
}

export default SidebarTienda
