/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { startLogout } from '../../actions/auth';
import { fetchConToken } from '../../api/consultas';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const toggleOpen = () => {
    setToggle(!toggle);
  };

  const handleNewSale = (item) => {
    dispatch({
      type: 'pedidoData',
      payload: item,
    });
    setToggle(false);
    history.push('/bodega/ventas/delivery');
  };

  const handleLogout = () => {
    dispatch(startLogout());
    history.replace('/login');
  };

  useEffect(() => {
    if (user && user.encargado.length > 0) {
      try {
        document.getElementById('notification').style.display = '';
        fetchConToken('pedidos/bodega/getPendientes')
          .then((data) => data.json())
          .then((resp) => {
            const data = resp.data.filter((item) => item.delivery === 0);
            setPedidos(data);
          });
      } catch (err) {}
    }
  }, []);

  return (
    <nav className='main-header navbar navbar-expand navbar-white navbar-light navbarglobal'>
      <ul className='navbar-nav ps-3 pe-3'>
        <li className='nav-item'>
          <a
            className='nav-link btn-menu'
            data-widget='pushmenu'
            href='#'
            role='button'
          >
            <i className='fas fa-bars'></i>
          </a>
        </li>
        {/* </ul> */}
        <div style={{ display: 'flex' }}>
          {/* <ul className='navbar-nav ml-auto'> */}
          <li
            className='nav-item dropdown'
            id='notification'
            style={{ display: 'none' }}
          >
            <button
              className='nav-link'
              type='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
              onClick={toggleOpen}
            >
              <i className='far fa-bell'></i>
              <span className='badge badge-warning navbar-badge'>15</span>
            </button>
            {/* <span className='dropdown-item dropdown-header'>
                15 Notifications
              </span> */}
            {pedidos &&
              pedidos.map((data) => {
                return (
                  <div
                    className={
                      toggle
                        ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right show'
                        : 'dropdown-menu dropdown-menu-lg dropdown-menu-right'
                    }
                    aria-labelledby='dropdownMenuButton'
                  >
                    <div className='dropdown-divider'></div>
                    <a
                      onClick={() => handleNewSale(data)}
                      className='dropdown-item'
                    >
                      <i className='fas fa-envelope mr-2'></i> Pedido nuevo - #
                      {data.id}
                    </a>
                    <div className='dropdown-divider'></div>
                  </div>
                );
              })}
          </li>
          {/* <li className='nav-item'>
            <button
              className='nav-link'
              data-widget='fullscreen'
              href='!#'
              role='button'
            >
              <i className='fas fa-expand-arrows-alt'></i>
            </button>
          </li> */}
          {/* <li className='nav-item'>
            <button
              className='nav-link'
              data-widget='control-sidebar'
              data-slide='true'
              href='!#'
              role='button'
            >
              <i className='fas fa-th-large'></i>
            </button>
          </li> */}
          <li className='nav-item'>
            <a
              to='/'
              className='nav-link'
              style={{ cursor: 'pointer' }}
              onClick={handleLogout}
            >
              <i className='fas fa-sign-out-alt'></i>
            </a>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
