/* eslint-disable */
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import DataTable from "react-data-table-component";
import { UploadAvatar } from "../../../../helpers/UploadImage";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  fetchConToken,
  fetchConFornDataToken,
} from "../../../../api/consultas";

const MCreatePromocion = ({
  openModalCreatePromocion,
  setOpenModalCreatePromocion,
  productos,
  categorias,
}) => {
  const [promocion, setPromocion] = useState({
    precio_real: "",
    ganancia_real: "",
    stock: "",
    ganancia_promocion: "",
  });
  const [arrayProducts, setArrayProducts] = useState([]);
  const [detalle, setDetalle] = useState([]);
  const [changeproducts, setChangeproducts] = useState(productos);
  const [foto, setAvatar] = useState(null);
  const [promociones, setPromociones] = useState([]);
  const [locales, setLocales] = useState([]);
  const [idPromocion, setIdPromocion] = useState(0);
  const {
    local_id,
    nombre,
    precio_real,
    precio_promocion,
    ganancia_promocion,
    ganancia_real,
    stock,
  } = promocion;

  const getLocales = () => {
    fetchConToken("empresa/locales/getLocalesBodega")
      .then((data) => data.json())
      .then((resp) => {
        const data = resp.data.filter((item) => item.registro === "A");
        setLocales(data);
      });
  };

  const getPromociones = () => {
    fetchConToken("promociones/empresa/get")
      .then((data) => data.json())
      .then((resp) => {
        setPromociones(resp.data);
      });
  };

  const handleOnChange = (e) => {
    setPromocion(() => ({
      ...promocion,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === "precio_promocion") {
      const resta = parseInt(e.target.value) - promocion.precio_real;
      setPromocion(() => ({
        ...promocion,
        ganancia_promocion: e.target.value ? (resta > 0 ? resta : 0) : 0,
        precio_promocion: parseInt(e.target.value),
      }));
    }
  };

  const formatProducts = (array) => {
    const json = JSON.stringify(array);
    const data = JSON.parse(json);
    var precios_venta = 0;
    var precios_compra = 0;
    for (let x in data) {
      precios_venta += data[x].precios[0].precio_venta;
      precios_compra += data[x].precios[0].precio_compra;
    }
    const ganancia_real = precios_venta - precios_compra;
    setPromocion({
      ...promocion,
      precio_real: precios_venta,
      ganancia_real: ganancia_real > 0 ? ganancia_real : 0,
    });
  };

  const handleAddProduct = (e) => {
    const arreglos = [...arrayProducts];
    const detalles = [...detalle];
    detalles.push({
      producto_local_id: e.id,
      precio_id: e.precios[0].id,
      cantidad: 1,
    });
    arreglos.push({
      id: e.id,
      marca: e.producto.marca.nombre,
      foto: e.producto.foto,
      descripcion: e.producto.descripcion,
      stock: e.stock,
      precios: [
        {
          id: e.precios[0]?.id,
          precio_venta: e.precios[0]?.precio_venta,
          precio_compra: e.precios[0]?.precio_compra,
          producto_local_id: e.precios[0]?.producto_local_id,
        },
      ],
      lotes: [
        {
          vencimiento: e.lotes[e.lotes.length - 1]?.vencimiento,
          alerta_stock: e.lotes[e.lotes.length - 1]?.alerta_stock,
          alerta_vencimiento: e.lotes[e.lotes.length - 1]?.alerta_vencimiento,
        },
      ],
    });
    setArrayProducts([...arreglos]);
    setDetalle([...detalles]);
    formatProducts(arreglos);
    setChangeproducts(changeproducts.filter((item) => item.id !== e.id));
  };

  const handleRemove = (product) => {
    const position = arrayProducts.findIndex(
      (element) => element.id === product.id
    );
    const removedProduct = arrayProducts.splice(position, 1);
    const arrayTabla = changeproducts || [];
    arrayTabla.push(removedProduct[0]);
    setChangeproducts([...arrayTabla]);
    formatProducts(arrayProducts);
    setDetalle(
      arrayProducts.map((item) => {
        return {
          cantidad: item.stock,
          precio_id: item.precios[0]?.id,
          producto_local_id: item.precios[0]?.producto_local_id,
        };
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("local_id", parseInt(promocion.local_id));
    formData.set("nombre", promocion.nombre || "");
    formData.set("precio_real", promocion.precio_real || "");
    formData.set("precio_promocion", promocion.precio_promocion || "");
    formData.set("ganancia_promocion", promocion.ganancia_promocion || "");
    formData.set("ganancia_real", promocion.ganancia_real || "");
    formData.set("stock", promocion.stock || "");
    formData.set("foto_promocion", foto?.file);
    if (
      nombre === "" ||
      precio_real === "" ||
      precio_promocion === "" ||
      ganancia_promocion === "" ||
      ganancia_real === "" ||
      stock === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "!Error¡",
        text: "Los campos no deben estar vacios.",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cerrar",
      });
    } else {
      if (idPromocion) {
        detalle.map((d, index) => {
          formData.set(`detalle[${index}][id]`, Number(d.id));
          formData.set(`detalle[${index}][precio_id]`, Number(d.precio_id));
          formData.set(`detalle[${index}][cantidad]`, Number(d.cantidad));
          formData.set(`detalle[${index}][eliminar]`, Number(d.eliminar));
        });
        fetchConFornDataToken(
          `promociones/updatePromocionBodega/${idPromocion}`,
          formData,
          "POST"
        ).then(() => {
          toast.success("Promocion Actualizada!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          getPromociones();
        });
      } else {
        detalle.map((d, index) => {
          formData.set(
            `detalle[${index}][producto_local_id]`,
            Number(d.producto_local_id)
          );
          formData.set(`detalle[${index}][precio_id]`, Number(d.precio_id));
          formData.set(`detalle[${index}][cantidad]`, Number(d.cantidad));
        });
        fetchConFornDataToken(
          "promociones/createPromocionBodega",
          formData,
          "POST"
        ).then(() => {
          toast.success("Promocion creada!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          getPromociones();
        });
      }
      setIdPromocion(0);
    }
  };

  const closeModal = () => {
    setOpenModalCreatePromocion(false);
  };

  const createPromocion = (e) => {
    deselectActive(e);
    document.getElementById("create-promocion").classList.add("show");
    document.getElementById("list-promociones").classList.remove("show");
  };

  const listPromocion = (e) => {
    deselectActive(e);
    document.getElementById("list-promociones").classList.add("show");
    document.getElementById("create-promocion").classList.remove("show");
  };
  const handleDelete = (e) => {
    Swal.fire({
      title: "¿Desea anular la promoción?",
      text: `${e.nombre}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchConToken("promociones/anular/" + e.id);
        Swal.fire("Anulado!", "La promoción ha sido anulada.", "success");
        getPromociones();
      }
    });
  };

  const handleApprove = (e) => {
    Swal.fire({
      title: "¿Desea aprobar la promoción?",
      text: `${e.nombre}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aprobar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchConToken("promociones/aprobar/" + e.id);
        Swal.fire("Aprobado!", "La promoción ha sido aprobada.", "success");
      }
      getPromociones();
    });
  };

  const handleEditPromocion = (e, item) => {
    deselectActive(e);
    document.getElementById("create-promocion").classList.add("show");
    document.getElementById("list-promociones").classList.remove("show");
    setIdPromocion(item.id);
    setPromocion({
      ...promocion,
      local_id: item.local_id,
      nombre: item.nombre,
      precio_real: item.precio_real,
      precio_promocion: item.precio_promocion,
      ganancia_promocion: item.ganancia_promocion,
      ganancia_real: item.ganancia_real,
      stock: item.stock,
    });
    setAvatar(item.foto_promocion);
    setArrayProducts(
      item.detalle.map((item) => {
        const { lotes, precios, producto, id, stock } = item.producto_local;
        return {
          descripcion: producto.descripcion,
          foto: producto.foto,
          lotes,
          id: id,
          precios: precios,
          marca: producto.marca_id,
          stock,
          cantidad: item.cantidad
        };
      })
    );
    setDetalle(
      item.detalle.map((response) => ({
        id: response.id,
        cantidad: response.producto_local.stock,
        precio_id: response.producto_local.precios[0]?.id,
        producto_local_id: response.producto_local_id,
        eliminar: 0
      }))
    );
  };

  const deselectActive = (e) => {
    const tags = document.getElementsByClassName("tag");
    for (let i = 0; i < tags.length; i++) {
      tags[i].classList.remove("active");
    }
    e.target.classList.add("active");
  };

  const handleCantidad = (e, data) => {
    const arreglos = [...detalle];
    let position = arreglos.findIndex(
      (arreglo) => arreglo.producto_local_id === data.id
    );
    arreglos[position].cantidad = parseInt(e.target.value);
    setDetalle([...arreglos]);
  };

  useEffect(() => {
    getPromociones();
    getLocales();
  }, []);

  const columnas = [
    {
      name: "Marca",
      selector: (row) =>
        row && row.producto
          ? row.producto.marca.nombre
          : row.marca
          ? row.marca
          : "",
      sortable: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
      width: "15%",
    },
    {
      name: "Presentación",
      selector: (row) =>
        row && row.producto
          ? row.producto.descripcion
          : row.descripcion
          ? row.descripcion
          : "",
      sortable: true,
      //right: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
      maxWidth: "55%",
    },
    {
      name: "Stock",
      selector: (row) => (row && row.stock ? row.stock : ""),
      sortable: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
      center: true,
      width: "10%",
    },
    {
      name: "Accion",
      button: true,
      grow: 1,
      cell: (e) => (
        <button
          onClick={() => handleAddProduct(e)}
          className="table__tablebutton editar"
        >
          <i className="fas fa-exchange-alt"></i>
        </button>
      ),
      style: {
        borderBotton: "none",
      },
      width: "20%",
    },
  ];

  var d = new Date();

  const formatDate = (date) => {
    const formatted_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return formatted_date;
  };

  const diferenciaDias = (dia1, dia2) => {
    const fechaInicio = new Date(dia1).getTime();
    const fechaFin = new Date(dia2).getTime();
    const dias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24);
    return dias;
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.lotes &&
        diferenciaDias(
          formatDate(d),
          row.lotes[row.lotes.length - 1]?.vencimiento
        ) <= row.lotes[row.lotes.length - 1]?.alerta_vencimiento,
      style: {
        backgroundColor: "rgba(240, 185, 182, 0.3) !important",
        color: "rgba(240, 255, 255) !important",
      },
    },
    {
      when: (row) =>
        row.lotes && row.stock <= row.lotes[row.lotes.length - 1]?.alerta_stock,
      style: {
        backgroundColor: "rgba(246, 225, 203, 0.3) !important",
        color: "rgba(255, 255, 255) !important",
      },
    },
  ];

  return (
    <Modal
      isOpen={openModalCreatePromocion}
      onRequestClose={closeModal}
      className="modal modal__createpromocion"
      overlayClassName="modal-fondo"
      closeTimeoutMS={200}
      preventScroll={true}
      ariaHideApp={false}
    >
      <div>
        {/* Contenedor de title y del boton close */}
        <p className="title__modal">
          <span>CREAR PROMOCIÓN</span>
          {/* <br />
          Crear Promoción */}
        </p>
        <button className="close" onClick={closeModal}>
          X
        </button>
      </div>
      <div>
        <div className="content__tags-select">
          <div>
            <button className="tag active" onClick={(e) => createPromocion(e)}>
              Crear promoción
            </button>
          </div>
          <div>
            <button className="tag" onClick={(e) => listPromocion(e)}>
              Lista de promociones
            </button>
          </div>
        </div>
      </div>
      <div className="content__createPromocion show" id="create-promocion">
        <div className="content__primary">
          <div>
            <h2>Lista de productos próximas a la fecha de vencimiento</h2>
            <div className="select">
              <label>Categoría:</label>
              <select
                className=""
                style={{ width: "100%" }}
                name="categoria"
                onChange={handleOnChange}
              >
                <option key={0} value="">
                  Seleccionar
                </option>
                {categorias &&
                  categorias.map((data) => {
                    return (
                      <option key={data.id} value={data.id}>
                        {data.nombre}
                      </option>
                    );
                  })}
                ;
              </select>
            </div>
            <DataTable
              columns={columnas}
              data={changeproducts}
              style={{ overflowX: "hidden" }}
              fixedHeader
              fixedHeaderScrollHeight="400px"
              conditionalRowStyles={conditionalRowStyles}
              noDataComponent={<i className="fas fa-inbox table__icono"></i>}
            />
          </div>
          <div>
            <h2>Unir productos</h2>
            <div className="select-25">
              <label>Local:</label>
              <select
                name="local_id"
                value={local_id}
                onChange={handleOnChange}
                required
              >
                <option key={0} value="">
                  Seleccionar
                </option>
                {locales &&
                  locales.map((data) => {
                    return (
                      <option key={data.id} value={data.id}>
                        {data.local_nombre}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="select-25">
              <label>Nombre:</label>
              <input
                type="text"
                name="nombre"
                value={promocion.nombre}
                onChange={handleOnChange}
              />
            </div>
            <div className="select-25">
              <label>Stock:</label>
              <input
                type="text"
                name="stock"
                value={promocion.stock}
                onChange={handleOnChange}
              />
            </div>
            <div>
              <div className="d-grid-50">
                <div className="select-50">
                  <label>Precio real de venta:</label>
                  <input
                    type="text"
                    name="precio_real"
                    value={promocion.precio_real}
                    onChange={handleOnChange}
                    disabled
                  />
                </div>
                <div className="d-flex align-items-center justify-content-evenly">
                  <label>Ganancia:</label>
                  <input
                    type="text"
                    name="ganancia_real"
                    value={promocion.ganancia_real}
                    onChange={handleOnChange}
                    disabled
                  />
                </div>
              </div>
              <div className="d-grid-50">
                <div className="select-50">
                  <label>Precio de la promoción:</label>
                  <input
                    type="text"
                    name="precio_promocion"
                    value={promocion.precio_promocion}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-evenly">
                  <label>Ganancia:</label>
                  <input
                    type="text"
                    name="ganancia_promocion"
                    value={promocion.ganancia_promocion}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
            </div>

            <div className="content__cards-promocion my-5">
              {arrayProducts &&
                arrayProducts.map((data) => {
                  return (
                    <div
                      className="item-card sombra"
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <button onClick={() => handleRemove(data)}>x</button>
                      <h6>{data.descripcion}</h6>
                      <img
                        src={data.foto}
                        style={{ margin: "auto", display: "flex" }}
                        alt="Producto"
                      />
                      <input
                        type="text"
                        name="cantidad"
                        defaultValue={data.cantidad ? data.cantidad : 0}
                        onChange={(e) => handleCantidad(e, data)}
                      />
                    </div>
                  );
                })}
            </div>
            <div className="d-flex flex-column">
              <p>Logo Promoción: </p>
              <div style={{ width: "100%", height: "120px" }}>
                <UploadAvatar avatar={foto} setAvatar={setAvatar} />
              </div>
            </div>
          </div>
        </div>
        <div className="todos__botones mt-3">
          <button className="botones actualizar" onClick={handleSubmit}>
            Finalizar
          </button>
        </div>
      </div>
      <div className="content__listPromociones" id="list-promociones">
        {promociones &&
          promociones.map((data) => {
            return (
              <div className="card__promocion">
                <h2>{data.nombre}</h2>
                {data.estado === 1 && (
                  <div className="subtitle">
                    <h3>PENDIENTE DE APROBACION</h3>
                    <button onClick={() => handleApprove(data)}>x</button>
                  </div>
                )}
                <div className="d-flex align-items-end flex-column">
                  <span className="promocion_text-primary">
                    <strong>Productos</strong>
                  </span>
                  <div className="my-2 d-flex flex-column justify-content-end align-items-end">
                    {data.detalle &&
                      data.detalle.map((item) => {
                        return (
                          <span className="promocion_description text-black-50">
                            <label style={{ textAlign: "end" }}>
                              {item.nombre_producto}
                            </label>
                          </span>
                        );
                      })}
                  </div>
                </div>
                <div className="promocion_description mt-2">
                  <label>Precio de venta:</label>
                  <label>{data.precio_real}</label>
                </div>
                <div className="promocion_description">
                  <label>Ganancia real:</label>
                  <label>{data.ganancia_real}</label>
                </div>
                <div className="promocion_description">
                  <label>Ganancia promocion:</label>
                  <label>{data.ganancia_promocion}</label>
                </div>
                <div className="promocion_description">
                  <label>Stock:</label>
                  <label>{data.stock}</label>
                </div>
                <div className="todos__botones">
                  <button
                    className="botones cancelar"
                    onClick={() => handleDelete(data)}
                  >
                    Eliminar
                  </button>
                  <button
                    className="button-primary"
                    onClick={(e) => handleEditPromocion(e, data)}
                  >
                    Editar
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default MCreatePromocion;
