import { roles } from './roles';

import AdminEstadisticas from '../components/admin/estadistica/AdminEstadisticas';
import AdminProductos from '../components/admin/producto/AdminProductos';
import AdminTiendas from '../components/admin/tienda/AdminTiendas';
import AdminUsuarios from '../components/admin/usuario/AdminUsuarios';
import Error404 from '../components/Error404';

import Registro from '../components/registro/Registro';
import RegistroNegocio from '../components/registro/RegistroNegocio';
import PlanesPago from '../components/registro/PlanesPago';
import AdminBodegastiendas from '../components/adminbodega/tiendas/AdminBodegasTiendas';
import BodegasSugerencias from '../components/bodega/sugerencias/BodegasSugerencias';
import AdminBodegasTiendasNotificacion from '../components/adminbodega/tiendas/AdminBodegasTiendasNotificacion';
import AdminBodegasTiendasRepartidor from '../components/adminbodega/tiendas/AdminBodegasTiendasRepartidor';
import AdminBodegaProductos from '../components/adminbodega/productos/AdminBodegaProductos';
import AdminBodegaAgregarProducto from '../components/adminbodega/productos/AdminBodegaAgregarProducto';
import AdminBodegaCrearPromocion from '../components/adminbodega/productos/AdminBodegaCrearPromocion';
import AdminBodegaProductoPromocion from '../components/adminbodega/productos/AdminBodegaProductoPromocion';
import AdminBodegaEstadistica from '../components/adminbodega/estadisticas/AdminBodegaEstadistica';
//import AdminBodegaNosotros from '../components/adminbodega/nosotros/AdminBodegasNosotros';
// import { bodegasproductos } from '../data/bodegaproductos';
import AdminProveedor from '../components/admin/proveedor/AdminProveedor';
import Home from '../pages/Home';
import ResetPassword from '../pages/ResetPassword';

import LayoutAdmin from '../layouts/LayoutAdmin';
import LayoutAdminBodega from '../layouts/LayoutAdminBodega';
import LayoutBodega from '../layouts/LayoutBodega';
import LayoutUser from '../layouts/LayoutUser';

import Login from '../pages/Login';

import AdminEstadisticasEvaluacion from '../components/admin/estadistica/AdminEstadisticasEvaluacion';
import AdminEstadisticasTotal from '../components/admin/estadistica/AdminEstadisticasTodal';
import AdminBodegasSugerencias from '../components/adminbodega/sugerencias/AdminBodegasSugerencias';
import AdminBodegaEstadisticasEvaluacion from '../components/adminbodega/estadisticas/AdminBodegaEstadisticasEvaluacion';
import AdminBodegaEstadisticasTodal from '../components/adminbodega/estadisticas/AdminBodegaEstadisticasTodal';
import AdminBodegasTiendasNuevo from '../components/adminbodega/tiendas/AdminBodegasTiendasNuevo';
import AdminBodegaProductosCrear from '../components/adminbodega/productos/AdminBodegaProductosCrear';
import BodegasTiendasNuevo from '../components/bodega/tiendas/BodegasTiendasNuevo';
import LayoutAdminTienda from '../layouts/LayoutAdminTienda';
import Usuarios from '../components/adminTienda/Usuarios';
import Tiendas from '../components/adminTienda/Tiendas';
import Productos from '../components/adminTienda/Productos';
import Estadisticas from '../components/adminTienda/Estadisticas';
// import Nosotros from '../components/adminTienda/Nosotros';
import AdminNosotros from '../components/admin/nosotros/AdminNosotros';
import BodegaNuevaVenta from '../components/bodega/ventas/BodegaNuevaVenta';
import BodegaProductos from '../components/bodega/productos/BodegaProductos';
import PoliticaPrivacidad from '../components/registro/PoliticaPrivacidad';
// import BodegasNosotros from '../components/bodega/nosotros/BodegasNosotros';
import BodegaVentasDelivery from '../components/bodega/ventas/BodegaVentaDelivery1';
import GenerarVenta from '../components/bodega/ventas/delivery/GenerarVenta';
import BodegaVentaFinalizar from '../components/bodega/ventas/BodegaVentaFinalizar';
// import BodegaProductos from '../components/bodega/productos/BodegaProductos';
import NosotrosView from '../pages/NosotrosView';
import AdminTrabajadores from '../components/adminbodega/trabajadores/AdminTrabajadores';

const routes = [
   {
      path: '/admin',
      component: LayoutAdmin,
      exact: false,
      role: [roles.ADMIN],
      title: 'Dashboard',
      routes: [
         {
            path: '/admin/usuario',
            component: AdminUsuarios,
            exact: true,
            title: 'usuarios',
         },
         {
            path: '/admin/tienda',
            component: AdminTiendas,
            exact: true,
            title: 'tienda',
         },
         {
            path: '/admin/productos',
            component: AdminProductos,
            exact: true,
            title: 'productos',
         },
         {
            path: '/admin/estadistica',
            component: AdminEstadisticas,
            exact: true,
            title: 'estadistica',
         },
         {
            path: '/admin/estadistica/evaluacion',
            component: AdminEstadisticasEvaluacion,
            exact: true,
            title: 'evaluacion',
         },
         {
            path: '/admin/proveedor',
            component: AdminProveedor,
            exact: true,
            title: 'proveedor',
         },
         {
            path: '/admin/estadistica/totales',
            component: AdminEstadisticasTotal,
            exact: true,
            title: 'evaluacion',
         },
         {
            path: '/admin/nosotros',
            component: AdminNosotros,
            exact: true,
            title: 'evaluacion',
         },
         {
            component: Error404,
         },
      ],
   },
   {
      path: '/adminbodega',
      component: LayoutAdminBodega,
      exact: false,
      role: [roles.ADMINBODEGA],
      routes: [
         {
            path: '/adminbodega/registro',
            component: Registro,
            exact: true,
         },
         {
            path: '/adminbodega/planespago',
            component: PlanesPago,
            exact: true,
         },
         {
            path: '/adminbodega/sugerencia',
            component: AdminBodegasSugerencias,
            exact: true,
         },
         {
            path: '/adminbodega/tiendas',
            component: AdminBodegastiendas,
            exact: true,
         },
         {
            path: '/adminbodega/trabajador',
            component: AdminTrabajadores,
            exact: true,
         },
         {
            path: '/adminbodega/notificaciones',
            component: AdminBodegasTiendasNotificacion,
            exact: true,
         },
         {
            path: '/adminbodega/repartidor',
            component: AdminBodegasTiendasRepartidor,
            exact: true,
         },
         {
            path: '/adminbodega/producto',
            component: AdminBodegaProductos,
            exact: true,
         },
         {
            path: '/adminbodega/productocrear',
            component: AdminBodegaAgregarProducto,
            exact: true,
         },
         {
            path: '/adminbodega/productoagregar',
            component: AdminBodegaCrearPromocion,
            exact: true,
         },
         {
            path: '/adminbodega/crearproducto',
            component: AdminBodegaProductosCrear,
         },
         {
            path: '/adminbodega/producto/promocion',
            component: AdminBodegaProductoPromocion,
            exact: true,
         },
         {
            path: '/adminbodega/estadistica',
            component: AdminBodegaEstadistica,
            exact: true,
         },
         {
            path: '/adminbodega/estadistica/evaluacion',
            component: AdminBodegaEstadisticasEvaluacion,
            exact: true,
         },
         {
            path: '/adminbodega/estadistica/total',
            component: AdminBodegaEstadisticasTodal,
            exact: true,
         },
         {
            path: '/adminbodega/nuevolocal',
            component: AdminBodegasTiendasNuevo,
            exact: true,
         },
         {
            path: '/adminbodega/nosotros',
            component: NosotrosView,
            exact: true,
         },
         {
            component: Error404,
         },
      ],
   },
   {
      path: '/bodega',
      component: LayoutBodega,
      exact: false,
      role: [roles.BODEGA],
      routes: [
         {
            path: '/bodega/ventas',
            component: BodegaNuevaVenta,
            exact: true,
         },
         {
            path: '/bodega/sugerencias',
            component: BodegasSugerencias,
            exact: true,
         },
         {
            path: '/bodega/productos',
            component: BodegaProductos,
            exact: true,
         },
         {
            path: '/bodega/perfil',
            component: BodegasTiendasNuevo,
         },
         {
            path: '/bodega/nosotros',
            component: NosotrosView,
         },
         {
            path: '/bodega/ventas/delivery',
            component: BodegaVentasDelivery,
         },
         {
            path: '/bodega/ventas/nueva',
            component: GenerarVenta,
         },
         {
            path: '/bodega/ventas/finalizar',
            component: BodegaVentaFinalizar,
         },
         {
            component: Error404,
         },
      ],
   },
   {
      path: '/tienda/admin',
      component: LayoutAdminTienda,
      exact: false,
      routes: [
         {
            path: '/tienda/admin/usuarios',
            component: Usuarios,
            exact: true,
         },
         {
            path: '/tienda/admin/tiendas',
            component: Tiendas,
            exact: true,
         },
         {
            path: '/tienda/admin/productos',
            component: Productos,
            exact: true,
         },
         {
            path: '/tienda/admin/estadisticas',
            component: Estadisticas,
            exact: true,
         },
         {
            path: '/tienda/admin/nosotros',
            component: NosotrosView,
            exact: true,
         },
         {
            component: Error404,
         },
      ],
   },
   {
      path: '/',
      component: LayoutUser,
      exact: false,
      routes: [
         {
            path: '/',
            component: Home,
            exact: true,
         },
         {
            path: '/login',
            component: Login,
            exact: true,
         },
         {
            path: '/registrar',
            component: RegistroNegocio,
            exact: true,
         },
         {
            path: '/politicaprivacidad',
            component: PoliticaPrivacidad,
            exact: true,
         },
         {
            path: '/restartpassword',
            component: ResetPassword,
            exact: true,
         },
         {
            component: Error404,
         },
      ],
   },
];

export { routes };
