import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
//import { startChecking } from '../actions/auth';

import SidebarBodega from '../components/dashboard/bodega/SidebarBodega';
import Navbar from '../components/dashboard/Navbar';
// import SubSidebar from '../components/dashboard/SubSidebar';
import ViewRutas from '../components/dashboard/ViewRutas';
import Login from '../pages/Login';

const LayoutBodega = ({ routes }) => {
  // const dispatch = useDispatch();
  const datos = useSelector((state) => state.auth);
  const { checking } = datos;

  if (checking === true) {
    return (
      <>
        <Route path='/login' component={Login} />
        <Redirect to='/login' />
      </>
    );
  }
  // useEffect(() => {
  //   dispatch(startChecking());
  // }, [dispatch]);
  return (
    <div className='wrapper'>
      <Navbar />
      <SidebarBodega />
      <div className='content-wrapper'>
        <ViewRutas />
        <section className='content px-3'>
          <div className='container-fluid'>
            <div className='row'>
              <LoadRoutes routes={routes} />
            </div>
          </div>
        </section>
      </div>

      {/* <SubSidebar /> */}
    </div>
  );
};

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
}

export default LayoutBodega;
