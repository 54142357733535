//import jsPDF from 'jspdf';
/*eslint-disable */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { fetchConToken } from "../../../api/consultas";

import MPdfVenta from "./MPdfVenta";

export default function HistorialBodegaVenta() {
  const [busqueda, setBusqueda] = useState("");
  const [listProductos, setListProductos] = useState([]);
  const [pdf, setPdf] = useState("");
  const [viewPdf, setViewPdf] = useState(false);

  const handleOnChange = (e) => {
    setBusqueda(([e.target.name] = e.target.value));
  };

  const [products, setProducts] = useState([]);

  const getVentas = () => {
    fetchConToken("ventas/Encargado/get")
      .then((data) => data.json())
      .then((data) => {
        setProducts(data.data);
      });
  };

  const GenerarPdf = async (item) => {
    setPdf(item.comprobante);
    setViewPdf(true);
    /* const doc = new jsPDF('p','pt','a4')
        const data = await fetchSinToken('generarBoleta/' + item.id).then(res => {
            return res.text()
        });
        doc.html(data, {
            callback: function (pdf) {
                pdf.save('Venta.pdf')
            }
        }) */
  };

  useEffect(() => {
    getVentas();
  }, []);

  useEffect(() => {
    const filtrarElemento = () => {
      const search =
        products &&
        products.filter((data) => {
          return (
            data.cliente_razonsocial
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda) ||
            data.cliente.persona.nombre
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLocaleLowerCase()
              .includes(busqueda)
            /* data.producto.marca.nombre
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase()
                            .includes(busqueda) ||
                        data.producto.descripcion
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase()
                            .includes(busqueda) */
          );
        });
      setListProductos(search);
    };
    filtrarElemento();
  }, [busqueda, products]);

  const columnas = [
    {
      name: "Item",
      selector: (row, index) => (index += 1),
      sortable: true,
      center: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
      width: "60px",
    },
    {
      name: "Nombre",
      selector: (row) =>
        row && row.cliente ? row.cliente.persona.nombre : "CLIENTE",
      sortable: true,
      center: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Monto",
      selector: (row) =>
        row && row.valor_venta ? "S/." + " " + row.valor_venta : "",
      sortable: true,
      center: true,
      compact: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Fecha de emisión",
      selector: (row) =>
        row && row.fecha_de_emision ? row.fecha_de_emision : "",
      sortable: true,
      center: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
      },
    },
    {
      name: "Crédito",
      selector: (row) => (row && row.credito === 1 ? "Si" : "No"),
      sortable: true,
      compact: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
        justifyContent: "center",
      },
      width: "80px",
    },
    {
      name: "Delivery",
      selector: (row) => (row && row.delivery === 1 ? "Si" : "No"),
      sortable: true,
      compact: true,
      grow: 0.5,
      style: {
        color: "#8f9196",
        borderBotton: "none",
        justifyContent: "center",
      },
    },
    {
      name: "Detalles",
      cell: (e) => (
        <button
          onClick={() => GenerarPdf(e)}
          className="table__tablebutton editar"
        >
          <i className="fas fa-file-pdf"></i>
        </button>
      ),
      center: true,
      compact: true,
      style: {
        color: "#8f9196",
        borderBotton: "none",
        justifyContent: "center",
      },
    },
  ];

  // console.log("listProductos => ", listProductos);

  return (
    <React.Fragment>
      <div className="row d-flex justify-content-between">
        <div className="col-md-4">
          <h2>Historial de Ventas</h2>
          <div className="search" style={{ margin: "8% 0", width: "100%" }}>
            <i className="fas fa-search"></i>
            <input
              type="text"
              className="search__input"
              placeholder="Buscar productos por nombre..."
              name="busqueda"
              value={busqueda}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-end">
          <h5 style={{ display: "inline", marginRight: "10px" }}>
            Descargar Reporte
          </h5>
          <i className="fa fa-file-excel"></i>
        </div>
      </div>

      <DataTable
        columns={columnas}
        data={listProductos}
        pagination
        // paginationComponentOptions={paginacionOpciones}
        fixedHeader
        fixedHeaderScrollHeight="400px"
        noDataComponent={<i className="fas fa-inbox table__icono"></i>}
      />
      <MPdfVenta viewPdf={viewPdf} setViewPdf={setViewPdf} pdf={pdf} />
    </React.Fragment>
  );
}
