import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { fetchConToken } from '../../../../api/consultas';

const AsignarTransportista = ({ pedido }) => {
    const [repartidor, setRepartidor] = useState([]);
    const [data, setData] = useState([]);

    const getRepartidores = () => {
        fetchConToken('usuarios/transportista/local/get/' + 1) 
          .then((data) => data.json())
          .then((data) => setRepartidor(data.data));
    };

    const handleSubmit = async() => {
        if(data.length > 1){
          toast.warning('Seleccionar un solo repartidor',{
            position: toast.POSITION.TOP_RIGHT
          })
        }else{
            const item = {
              pedido_id: pedido.id,
              transportista_id: data[0].id
            }
            await fetchConToken('pedidos/bodega/asignarTransportista', item, 'POST')
            toast.success('Pedido asignado correctamente',{
              position: toast.POSITION.TOP_RIGHT
            })
        }
    }


    useEffect(() => {
        getRepartidores();
      }, []);
    
    const columnas = [
        {
            name: 'Item',
            selector: (row, index) => (index += 1),
            sortable: true,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
            width: '100px',
        },
        {
            name: 'Nombre ',
            selector: (row) =>
            row && row.persona_id ? row.usuario.persona.nombre : '',
            sortable: true,
            compact: true,
            // center: true,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Apellido paterno',
            selector: (row) =>
            row && row.persona_id ? row.usuario.persona.apellido_pat : ' ',
            sortable: true,
            compact: true,
            // center: true,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
        {
            name: 'Apellido materno',
            selector: (row) =>
            row && row.persona_id ? row.usuario.persona.apellido_mat : '',
            sortable: true,
            compact: true,
            // center: true,
            style: {
                color: '#8f9196',
                borderBotton: 'none',
            },
        },
    ];

    return(
        <div>
        <h4>Asignar Repartidor</h4>
          <div className='search'>
            <i className='fas fa-search'></i>
            <input
              type='text'
              className='search__input'
              placeholder='Buscar productos por nombre...'
              name='busqueda'
            />
          </div>
          <div className='my-3 '>
            <DataTable
              columns={columnas}
              data={repartidor}
              fixedHeader
              fixedHeaderScrollHeight='400px'
              noDataComponent={<i className='fas fa-inbox table__icono'></i>}
              onSelectedRowsChange={(e) => setData(e.selectedRows)}
              selectableRows
            />
          </div>
          <div className='todos__botones m-4'>
            <button onClick={handleSubmit} className='botones actualizar'>Enviar</button>
          </div>
        </div>
    );

};
export default AsignarTransportista;