/* eslint-disable */
import React, { useEffect } from 'react';
import config from './config.json';
import Quagga from 'quagga';
import musica from '../sounds/lector.mp3';
import { toast } from 'react-toastify';

const Scanner = (props) => {
  const { onDetected } = props;

  const onPermissionsAccept = () => {
    Quagga.init(config, (err) => {
      if (err) {
        toast.warning('Debe dar permisos para usar este módulo!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        Quagga.start();
        return () => {
          Quagga.stop();
        };
      }
    });

    //detecting boxes on stream
    Quagga.onProcessed((result) => {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            Number(drawingCanvas.getAttribute('width')),
            Number(drawingCanvas.getAttribute('height'))
          );
          result.boxes
            .filter(function (box) {
              return box !== result.box;
            })
            .forEach(function (box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: 'green',
                lineWidth: 2,
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: '#00F',
            lineWidth: 2,
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: 'x', y: 'y' },
            drawingCtx,
            { color: 'red', lineWidth: 3 }
          );
        }
      }
    });

    Quagga.onDetected(detected);
  };

  /* const onPermissionsReject = () => {
   } */

  useEffect(() => {
    /* navigator.mediaDevices.getUserMedia({ video: true}, function (stream) {
         if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
             //code for when none of the devices are available  
             onPermissionsAccept();                     
         } else {
            console.log('Tienes que dar permisos');
         }
      }, function (error) { 
         console.log('no funciona')
      }); */
    onPermissionsAccept();
  }, []);

  const detected = (result) => {
    onDetected(result.codeResult.code);
    const lectorsound = document.createElement('audio');
    lectorsound.src = musica;
    lectorsound.play();
  };

  return <div id='interactive' className='viewport' />;
};

export default Scanner;
