import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { fetchConToken } from '../../../api/consultas';
import MAdminBodegasTiendas from './MAdminBodegasTiendas';
import ProfileBodega from './ProfileBodega';
import { useDispatch } from 'react-redux';

const AdminBodegastiendas = ({ history }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isModal, setIsModal] = useState(false);
  const [modalProfile, setModalProfile] = useState(false);
  const [datos, setDatos] = useState([]);
  const [locales, setLocales] = useState([]);

  // TODO validacion de la cantidad de locales
  const valoresxd = user?.administrador[0]?.empresa?.cantidad_local;
  // const [valoresxd, setValoresxd] = useState();
  // setTimeout(() => {
  //   setValoresxd(user?.administrador[0]?.empresa?.cantidad_local);
  // }, 500);
  // // const [countLocal, setCountLocal] = useState(valoresxd);
  // const validationLocal = (value = 1) => {
  //   if (valoresxd > 0) {
  //     console.log(0);
  //     setValoresxd((prev) => Math.max(prev - value, 0));
  //   } else if (valoresxd < 0) {
  //     console.log(1);
  //     setValoresxd(0);
  //   }
  // };

  // useEffect(() => {
  //   validationLocal();
  // }, [valoresxd]);

  //console.log('validationLocales', valoresxd);

  const getListLocal = () => {
    fetchConToken('empresa/locales/getLocalesBodega')
      .then((data) => data.json())
      .then((resp) => setLocales(resp.data));
  };
  const handleAprobar = (e) => {
    dispatch({
      type: 'localData',
      payload: e,
    });
    history.push('/adminbodega/notificaciones');
  };

  const handleAddDelivery = (e) => {
    dispatch({
      type: 'localData',
      payload: e,
    });
    history.push('/adminbodega/repartidor');
  };

  const handleNuevoLocal = () => {
    setIsModal(true);
    setDatos([]);
  };

  const handleEditarLocal = (e) => {
    setIsModal(true);
    setDatos(e);
  };

  const handleDeleteLocal = (e) => {
    Swal.fire({
      title: '¿Desea cerrar el local?',
      text: `Cerrar el local`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        fetchConToken('empresa/locales/cerrar/' + e.id, null, 'PUT').then(
          () => {
            Swal.fire(
              'Deleted!',
              'Local cerrado satisfactoriamente.',
              'success'
            );
            getListLocal();
          }
        );
      }
    });
  };

  useEffect(() => {
    getListLocal();
  }, []);

  const handleCrearContrasenha = () => {
    setModalProfile(true);
  };

  const diasAtencionFormat = (atenciones) => {
    const dias = {
      L: 1,
      M: 2,
      X: 3,
      J: 4,
      V: 5,
      S: 6,
      D: 7,
    };
    const diasMapped = atenciones.map((item) => item.dia.id);
    return Object.entries(dias)
      .filter((item) => diasMapped.includes(item[1]))
      .map((value) => value[0])
      .join('-');
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='adminbodegatiendas__tiendasedicion'>
          <h3 className=''>
            {user?.administrador[0].empresa.nombre_comercial}
          </h3>
          <div className='adminproductos__actions'>
            <div className='adminproductos__btnactions'></div>
            {valoresxd === 0 ? (
              ''
            ) : (
              <p className='v__text-center'>
                Crear nuevo local
                <i
                  className='fas fa-plus-circle'
                  onClick={handleNuevoLocal}
                ></i>
              </p>
            )}
            <p className='v__text-center'>
              Cambiar contraseña
              <i className='fas fa-wrench' onClick={handleCrearContrasenha}></i>
            </p>
          </div>
        </div>
      </div>

      <div className='row  d-flex justify-content-between'>
        {locales &&
          locales.map((item, key) => {
            const {
              direccion,
              delivery,
              local_nombre,
              empresa,
              atenciones,
              registro,
            } = item;
            return (
              <div
                className='col-12 mt-3 col-sm-12 col-md-12 col-lg-6'
                key={key}
              >
                <div className='card mb-3 sombra'>
                  <img
                    src={item.foto_encargado}
                    style={{ height: '200px', objectFit: 'contain' }}
                    alt='...'
                  />
                  <div className='card-body'>
                    <h5 className='text-center'>
                      {item.empresa.nombre_comercial}:
                    </h5>
                    <table className='adminbodegatiendas__tiendas'>
                      <tbody>
                        <tr>
                          <td className='sombreado'>Local:</td>
                          <td className='sombreado1'>{local_nombre}</td>
                        </tr>
                        <tr>
                          <td className='sombreado'>Dirección:</td>
                          <td className='sombreado1'>{direccion}</td>
                        </tr>
                        <tr>
                          <td className='sombreado'>Representante legal:</td>
                          <td className='sombreado1'>
                            {empresa.representante_legal}
                          </td>
                        </tr>
                        <tr>
                          <td className='sombreado'>Delivery:</td>
                          <td className='sombreado1'>
                            {delivery === 0 ? 'No' : 'Si'}
                          </td>
                        </tr>
                        <tr>
                          <td className='sombreado'>Días de atención:</td>
                          <td className='sombreado1'>
                            {diasAtencionFormat(atenciones)}
                          </td>
                        </tr>
                        <tr>
                          <td className='sombreado'>Estado:</td>
                          <td className='sombreado1'>
                            {registro === 'A' ? 'Activo' : 'Inactivo'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className='todos__botones'>
                      <button
                        className='botones actualizar'
                        onClick={() => handleAprobar(item)}
                      >
                        Aprobar promociones
                      </button>
                      {item.delivery === 1 ? (
                        <button
                          className=' botones actualizar'
                          onClick={() => handleAddDelivery(item)}
                        >
                          Agregar Delivery
                        </button>
                      ) : (
                        ''
                      )}
                      <button
                        className=' botones delivery'
                        onClick={() => handleEditarLocal(item)}
                      >
                        <label>Editar</label>
                        <i className='fas fa-pencil-alt '></i>
                      </button>
                      <button
                        className=' botones cancelar'
                        onClick={() => handleDeleteLocal(item)}
                      >
                        <label>Eliminar</label>
                        <i className='far fa-trash-alt'></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {isModal && (
        <MAdminBodegasTiendas
          isModal={isModal}
          setIsModal={setIsModal}
          datos={datos}
          getListLocal={getListLocal}
        />
      )}

      {modalProfile && (
        <ProfileBodega
          modalProfile={modalProfile}
          setModalProfile={setModalProfile}
        />
      )}
    </div>
  );
};

export default AdminBodegastiendas;
