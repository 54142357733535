/* eslint-disable */
import React, { useState, useEffect } from 'react';
// import localizar from '../../../assets/mapa/mapa.png';
// import bodegapersonal from '../../../assets/mapa/bodegapersonal.jpeg';
// import tambo from '../../../assets/mapa/tambo.jpg';
import { fetchConToken } from '../../../api/consultas';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { UploadAvatar } from '../../../helpers/UploadImage';
import MapaMarket from '../../../helpers/MapaMarket';

const BodegasTiendasNuevo = ({ history }) => {
  const { encargado, persona } = useSelector(
    (state) => state.auth.user
  );
  const [atenciones, setAtenciones] = useState([]);
  const [local, setLocal]= useState({
    empresa_nombre: encargado[0].empresa.razon_social || '',
    ruc: encargado[0].empresa.ruc || '',
    representante: encargado[0].empresa.representante_legal || '',
    encargado_nombre: persona.nombre || '',
    encargado_apellido: persona.apellido_pat || '',
    encargado_dni: persona.numero_doc || '',
    direccion: encargado[0].local.direccion || '',
    delivery: encargado[0].local.delivery || '',
    distancia_delivery: encargado[0].local.distancia_delivery || '',
    atenciones: encargado[0].local.atenciones || '',
  });
  const [dataMapa] = useState({
    lat: parseFloat(encargado[0].local.latitud) || 0,
    lng: parseFloat(encargado[0].local.longitud || 0)
  });
  const [foto_encargado, setAvatar] = useState(encargado[0].local.foto_encargado || null);
  const [foto_local, setAvatarOne] = useState(encargado[0].local.foto_local || null);

  const getDataProfile = () => {
    fetchConToken('empresa/locales/get/' + encargado[0].local_id)
      .then((data) => data.json())
      .then((resp) => {
        setLocal({
          estado: resp.registro === 'A' ? true : false,
          atenciones: resp.atenciones|| '',
          nombre: resp.local_nombre || '',
          ingreso_producto: resp.encargados[0]?.ingreso_producto || '',
          creacion_promocion: resp.encargados[0]?.creacion_promocion || '',
          delivery: resp.delivery || '',
        });
        setAtenciones(resp.atenciones)
      })
  }

  const handleOnChange = (e) => {
    setLocal({estado: e.target.checked});
    if (e.target.name === 'status_local') {
      if (e.target.checked) {
        fetchConToken('empresa/locales/abrirLocalEncargado', {}, 'PUT');
      } else {
        fetchConToken('empresa/locales/cerrarLocalEncargado', {}, 'PUT');
      }
    }
  };

  // const diasAtencionFormat = () => {
  //   const dias = {
  //     L: 1,
  //     M: 2,
  //     X: 3,
  //     J: 4,
  //     V: 5,
  //     S: 6,
  //     D: 7,
  //   };
  //   if (local.atenciones) {
  //     const diasMapped = local.atenciones.map((item) => item.dia.id);
  //     return Object.entries(dias)
  //       .filter((item) => diasMapped.includes(item[1]))
  //       .map((value) => value[0])
  //       .join('-');
  //   }
  // };

  useEffect(() => {
    getDataProfile()
  },[])

  return (
    <div className="container">
      <ToastContainer />
      <form className="px-4 pt-4 pb-2 mb-4">
        <h3>Bodega {local.nombre}</h3>
        <label className="switch">
          <input
            name="status_local"
            checked={local.estado}
            onChange={handleOnChange}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
        <div className="row mb-4">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4 ">
            <div className="bodega__perfil sombra">
              <h6>Datos generales:</h6>
              <div className="bodega__tiendasalinear">
                <label>Nombre Comercial:</label>
                <input
                  type="text"
                  defaultValue={local.empresa_nombre}
                  disabled
                />
              </div>
              <div className="bodega__tiendasalinear">
                <label>RUC:</label>
                <input type="text" defaultValue={local.ruc} disabled />
              </div>
              <div className="bodega__tiendasalinear">
                <label>Representante legal:</label>
                <input
                  type="text"
                  defaultValue={local.representante}
                  disabled
                />
              </div>
            </div>
            {/*  */}
            {/* <div className="mt-4 bodega__perfil sombra">
              <h6>Crear usuario y contraseña:</h6>
              <div className="adminbodega__tiendasalinear">
                <label>Usuario:</label>
                <input type="text" />
              </div>
              <div className="adminbodega__tiendasalinear">
                <label>Contraseña:</label>
                <input type="password" />
              </div>
            </div> */}
            {/*  */}
            <div className="mt-4 bodega__perfil sombra">
              <h6>Datos del encargado:</h6>
              <div className="adminbodega__tiendasalinear">
                <label>Nombres y Apellidos:</label>
                <input
                  type="text"
                  defaultValue={
                    local.encargado_nombre + ' ' + local.encargado_apellido
                  }
                  disabled
                />
              </div>
              {/* <div className="adminbodega__tiendasalinear">
                <label>Apellido:</label>
                <input type="text" defaultValue={local.encargado_apellido} disabled />
              </div> */}
              <div className="adminbodega__tiendasalinear">
                <label>DNI:</label>
                <input
                  type="number"
                  defaultValue={local.encargado_dni}
                  disabled
                />
              </div>
            </div>
            {/*  */}
            <div className="mt-4 bodega__perfil sombra">
              <h6>Otorgar capacidad de decición del encargardo:</h6>
              <div className="adminbodega__tiendasalineartres">
                <label>Ingreso de productos: </label>
                <div>
                  <label>Si</label>
                  <input
                    type="radio"
                    value={1}
                    className="form-check-input"
                    checked={local.ingreso_producto === 1}
                  />
                </div>
                <div>
                  <label>No</label>
                  <input
                    type="radio"
                    value={0}
                    className="form-check-input"
                    checked={local.ingreso_producto === 0}
                  />
                </div>
              </div>
              <div className="adminbodega__tiendasalineartres">
                <label>Creación de promoción: </label>
                <div>
                  <label>Si</label>
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={local.creacion_promocion === 1}
                  />
                </div>
                <div>
                  <label>No</label>
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={local.creacion_promocion === 0}
                  />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="mt-4 bodega__perfil sombra">
              <h6>Localización:</h6>
              <div className="adminbodega__tiendasalinear">
                <label>Dirección</label>
                <input type="text" defaultValue={local.direccion} disabled />
              </div>
              <div>
                <label>Mi ubicación</label>
                <div className="adminbodega__tiendamapasax mt-3">
                  <MapaMarket dataMapa={dataMapa} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4">
            <div className=" bodega__perfil sombra">
              <h6 className="">Información para la atención</h6>
              <div className="adminbodega__tiendasalineartres">
                <label>Delivery:</label>
                <div>
                  <label>Si</label>
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={local.delivery === 1}
                  />
                </div>
                <div>
                  <label>No</label>
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={local.delivery === 0}
                  />
                </div>
              </div>
              <div className="bodega__tiendasalinearinputs">
                <label>Distancia (m)</label>
                <div>
                  <input
                    type="text"
                    defaultValue={local.distancia_delivery}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 bodega__perfil sombra">
              <h6>Días de atención:</h6>
              <div className="adminbodega__tiendasalineartres">
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled
                  />
                  <label>L</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled
                  />
                  <label>M</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled
                  />
                  <label>M</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled
                  />
                  <label>J</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled
                  />
                  <label>V</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled
                  />
                  <label>S</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled
                  />
                  <label>D</label>
                </div>
                {/* {diasAtencionFormat()} */}
              </div>
            </div>
            <div className="mt-4 bodega__perfil sombra">
              <div className="adminbodega__tiendasalinear">
                <h6>Fotografía del encargado de atención:</h6>
                <i className="fas fa-camera"></i>
              </div>
              <div className="adminbodega__tiendaimagen">
                <UploadAvatar avatar={foto_encargado} setAvatar={setAvatar} />
              </div>
            </div>
            <div className="mt-4 bodega__perfil sombra">
              <div className="adminbodega__tiendasalinear mt-3">
                <h6>Fotografía de la tienda:</h6>
                <i className="fas fa-camera"></i>
              </div>
              <div className="adminbodega__tiendaimagen mt-4">
                <UploadAvatar avatar={foto_local} setAvatar={setAvatarOne} />
              </div>
              <div className="adminbodegatiendas__botones mt-3"></div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BodegasTiendasNuevo;
