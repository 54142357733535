export const productos = [
  {
    id: 1,
    categoria: 'getbyte',
    subcategoria: 'as',
    marca: 'uytds',
    presentacion: 'toma muestra',
  },
  {
    id: 2,
    categoria: 'getbyte',
    subcategoria: 'asjajajajsdfjsdldfsadfsadfsdha',
    marca: 'udsj',
    presentacion: 'toma muestra',
  },
  {
    id: 3,
    categoria: 'pepe',
    subcategoria: 'asae',
    marca: 'jkdsj',
    presentacion: 'toma muestra',
  },
  {
    id: 4,
    categoria: 'getbyte',
    subcategoria: 'asaejhg',
    marca: 'jkdsj',
    presentacion: 'toma muestra',
  },
  {
    id: 5,
    categoria: 'getbyte',
    subcategoria: 'asafasdfasdfwerqweterbxcvbjhfgfuyuew',
    marca: 'fhkdsj',
    presentacion: 'toma muestra',
  },
  {
    id: 6,
    categoria: 'pedro',
    subcategoria: 'asafare',
    marca: 'hdsj',
    presentacion: 'toma muestra',
  },
  {
    id: 7,
    categoria: 'getbyte',
    subcategoria: 'asafda',
    marca: 'kjdsj',
    presentacion: 'toma muestra',
  },
  {
    id: 8,
    categoria: 'getbyte',
    subcategoria: 'asa',
    marca: 'fdsj',
    presentacion: 'toma muestra',
  },
  {
    id: 9,
    categoria: 'getbyte',
    subcategoria: 'adfassa',
    marca: 'jdsj',
    presentacion: 'toma muestra',
  },
  {
    id: 10,
    categoria: 'getbyte',
    subcategoria: 'as',
    marca: 'ds',
    presentacion: 'toma muestra',
  },
  {
    id: 11,
    categoria: 'getbyte',
    subcategoria: 'rqpweuiropkdjfañlqpiowerucnvzc',
    marca: 'uds',
    presentacion: 'toma muestra',
  },
  {
    id: 12,
    categoria: 'getbyte',
    subcategoria: 'pedro',
    marca: 'ytds',
    presentacion: 'toma muestra',
  },
  {
    id: 13,
    categoria: 'getbyte',
    subcategoria: 'jfgas',
    marca: 'urds',
    presentacion: 'toma muestra',
  },
  {
    id: 14,
    categoria: 'getbyte',
    subcategoria: 'jhfas',
    marca: 'ytrds',
    presentacion: 'toma muestra',
  },
  {
    id: 15,
    categoria: 'getbyte',
    subcategoria: 'fgás',
    marca: 'tyuds',
    presentacion: 'toma muestra',
  },
  {
    id: 16,
    categoria: 'getbyte',
    subcategoria: 'as',
    marca: 'rytds',
    presentacion: 'toma muestra',
  },
  {
    id: 17,
    categoria: 'getbyte',
    subcategoria: 'jgfas',
    marca: 'utds',
    presentacion: 'toma muestra',
  },
  {
    id: 18,
    categoria: 'getbyte',
    subcategoria: 'as',
    marca: 'uds',
    presentacion: 'toma muestra',
  },
  {
    id: 19,
    categoria: 'getbyte',
    subcategoria: 'hgas',
    marca: 'tyuds',
    presentacion: 'toma muestra',
  },
  {
    id: 20,
    categoria: 'getbyte',
    subcategoria: 'jjjas',
    marca: '76rds',
    presentacion: 'toma muestra',
  },
  {
    id: 21,
    categoria: 'getbyte',
    subcategoria: 'gjhas',
    marca: 'tr6tds',
    presentacion: 'toma muestra',
  },
  {
    id: 22,
    categoria: 'getbyte',
    subcategoria: 'jhgas',
    marca: '6rtuds',
    presentacion: 'toma muestra',
  },
  {
    id: 23,
    categoria: 'getbyte',
    subcategoria: 'ghas',
    marca: '76tds',
    presentacion: 'toma muestra',
  },
  {
    id: 24,
    categoria: 'getbyte',
    subcategoria: 'as',
    marca: '6t7ids',
    presentacion: 'toma muestra',
  },
  {
    id: 25,
    categoria: 'getbyte',
    subcategoria: 'fbas',
    marca: '76jhds',
    presentacion: 'toma muestra',
  },
  {
    id: 26,
    categoria: 'getbyte',
    subcategoria: '45fas',
    marca: 'tuhds',
    presentacion: 'toma muestra',
  },
  {
    id: 27,
    categoria: 'getbyte',
    subcategoria: 'ty5as',
    marca: 'ut7hds',
    presentacion: 'toma muestra',
  },
  {
    id: 28,
    categoria: 'getbyte',
    subcategoria: 'ndtyas',
    marca: 'dfds',
    presentacion: 'toma muestra',
  },
  {
    id: 29,
    categoria: 'getbyte',
    subcategoria: 'vas',
    marca: 'fyrdsfghd',
    presentacion: 'toma muestra',
  },
  {
    id: 30,
    categoria: 'getbyte',
    subcategoria: 'fghas',
    marca: 'tds',
    presentacion: 'toma muestra',
  },
];
