import React from 'react';
import localizar from '../../../assets/mapa/mapa.png';
import bodegapersonal from '../../../assets/mapa/bodegapersonal.jpeg';
import tambo from '../../../assets/mapa/tambo.jpg';
import { ToastContainer } from 'react-toastify';
const AdminBodegasTiendasNuevo = ({ history }) => {
  const handleNuevo = () => {
    history.push('/adminbodega/tiendas');
  };
  return (
    <div className="container">
      <ToastContainer />
      <form className="sombra" onSubmit={handleNuevo}>
        <div className="row py-3 px-3 ">
          <h3 className="adminusuario__h3">Bodegas de la curva</h3>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
            <div className="">
              <p className="adminusuario__p">Datos generales:</p>
              <div className="adminbodega__tiendasalinear">
                <label>Nombre Comercial:</label>
                <input type="text" />
              </div>
              <div className="adminbodega__tiendasalinear">
                <label>RUC:</label>
                <input type="text" />
              </div>
              <div className="adminbodega__tiendasalinear">
                <label>Representante legal:</label>
                <input type="text" />
              </div>
            </div>
            {/*  */}
            <div className="mt-4">
              <p className="adminusuario__p">Crear usuario y contraseña:</p>
              <div className="adminbodega__tiendasalinear">
                <label>Usuario:</label>
                <input type="text" />
              </div>
              <div className="adminbodega__tiendasalinear">
                <label>Contraseña:</label>
                <input type="password" />
              </div>
            </div>
            {/*  */}
            <div className="mt-4">
              <p className="adminusuario__p">Datos del encargado:</p>
              <div className="adminbodega__tiendasalinear">
                <label>Nombre:</label>
                <input type="text" />
              </div>
              <div className="adminbodega__tiendasalinear">
                <label>Apellido:</label>
                <input type="password" />
              </div>
              <div className="adminbodega__tiendasalinear">
                <label>DNI:</label>
                <input type="password" />
              </div>
            </div>
            {/*  */}
            <div className="mt-4">
              <p className="adminusuario__p">
                Otorgar capacidad de decición del encargardo:
              </p>
              <div className="adminbodega__tiendasalineartres">
                <label>Ingreso de productos: </label>
                <div>
                  <label>Si</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
                <div>
                  <label>No</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="mt-4">
              <h6>Localización:</h6>
              <div className="adminbodega__tiendasalinear">
                <label>Dirección</label>
                <input type="text" />
              </div>
              <div>
                <label>Mi ubicación</label>
                <div className="adminbodega__tiendaimagen mt-3">
                  <img
                    src={localizar}
                    alt=""
                    className=""
                    style={{ height: '600px' }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <div>
              <p className="adminusuario__p">Informacion para la atencion</p>
              <div className="adminbodega__tiendasalineartres">
                <label>Delivery:</label>
                <div>
                  <label>Si</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
                <div>
                  <label>No</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="mt-3">
              <p className="adminusuario__p">Distancia de delivery:</p>
              <div className="adminbodega__tiendasalinearinputs">
                <label>Delivery:</label>
                <div>
                  <input type="text" />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="mt-4">
              <p className="adminusuario__p">Horario de atención:</p>
              <div className="adminbodega__tiendasalineartres">
                <label>Delivery:</label>
                <div>
                  <input type="number" min="1" max="24" />
                  <input type="number" min="1" max="24" />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="mt-4">
              <p className="adminusuario__p">Días de atención:</p>
              <div className="adminbodega__tiendasalineartres">
                <div>
                  <label>L</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
                <div>
                  <label>M</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
                <div>
                  <label>M</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
                <div>
                  <label>J</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
                <div>
                  <label>V</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
                <div>
                  <label>S</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
                <div>
                  <label>D</label>
                  <input type="checkbox" class="form-check-input" />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="mt-4">
              <div className="adminbodega__tiendasalinear">
                <p className="adminusuario__p">
                  Fotografias del encargado de atención:
                </p>
                <i class="fas fa-camera"></i>
              </div>
              <div className="adminbodega__tiendaimagen">
                <img src={bodegapersonal} alt="" />
              </div>
              <div className="adminbodega__tiendaimagen mt-4">
                <img src={tambo} alt="" />
              </div>
              <div className="adminbodegatiendas__botones mt-3">
                <button className="eliminar">Cancelar</button>
                <button className="aprobar">crear</button>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminBodegasTiendasNuevo;
