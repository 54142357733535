import React from 'react';
import AdminBodegaProductoChild from './AdminBodegaProductoChild';
import ProviderAdminBodegaProducto from './ProviderAdminBodegaProducto';

const AdminBodegaProductos = () => {
  return (
    <ProviderAdminBodegaProducto>
      <AdminBodegaProductoChild />
    </ProviderAdminBodegaProducto>
  );
};

export default AdminBodegaProductos;
