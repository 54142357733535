import React from 'react';
import { toast } from 'react-toastify';
export default function ValidDocumento({ valueInput, tipo, setCreateEmployess, createEmployess }) {
    const [loadInput, setloadInput] = React.useState(false);
    const [btnText, setBtnText] = React.useState("Validar")

    const validarRuc = async (e) => {
        e.preventDefault()

        try {
            if (valueInput.trim().length === 0) {
                toast.error(`Complete el campo ${tipo}`, {
                    position: toast.POSITION.TOP_RIGTH,
                });
                return;
            }
            setBtnText('...');
            setloadInput(true);
            switch (tipo) {
                case 'RUC':
                    const ruc = await fetch(
                        `https://dniruc.apisperu.com/api/v1/ruc/${valueInput}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJpeWV5aW0zMTlAY2FycGV0ZC5jb20ifQ.NaXtG7Nokt0tybRlEkuffmsKPjX4Gk8k-2pX7Rgn3j0`
                    ).then((res) => res.json());
                    setCreateEmployess({
                        ...createEmployess,
                        nombre_comercial: ruc.nombreComercial === null ? ruc.razonSocial : ruc.nombreComercial,
                        razon_social: ruc.razonSocial || '',
                        direccion_legal: ruc.direccion || '',
                    });
                    break;

                case 'DNI':
                    const dni = await fetch(
                        `https://dniruc.apisperu.com/api/v1/dni/${valueInput}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJpeWV5aW0zMTlAY2FycGV0ZC5jb20ifQ.NaXtG7Nokt0tybRlEkuffmsKPjX4Gk8k-2pX7Rgn3j0`
                    ).then((res) => res.json());
                    setCreateEmployess({
                        ...createEmployess,
                        representante_legal_nombre: dni.nombres || '',
                        representante_legal_apellido_pat: dni.apellidoPaterno || '',
                        representante_legal_apellido_mat: dni.apellidoMaterno || '',
                    });
                    break;
                default:
                    await fetch(
                        `https://dniruc.apisperu.com/api/v1/dni/${valueInput}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJpeWV5aW0zMTlAY2FycGV0ZC5jb20ifQ.NaXtG7Nokt0tybRlEkuffmsKPjX4Gk8k-2pX7Rgn3j0`
                    ).then((res) => res.json());
                    break;

            }
            toast.success(`${tipo} validado`, {
                position: toast.POSITION.TOP_RIGTH,
            });
        } catch {
            toast.error(`${tipo} no encontrado`, {
                position: toast.POSITION.TOP_RIGTH,
            });
        } finally {
            setloadInput(false);
            setBtnText('Validar');
        }
    }
    return (
        <button
            className="adminvalidar__button"
            onClick={validarRuc}
            disabled={loadInput}
        >
            {btnText}
        </button>
    )


}
