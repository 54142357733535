import React, { useState, useEffect } from 'react';
import { fetchConToken } from '../api/consultas';
import { ToastContainer } from 'react-toastify';
import CompanyLogobasboard from '../pages/CompanyLogodasboard';

const AdminNosotros = () => {
  const [foto, setAvatar] = useState([]);
  const [yasta, setYasta] = useState({
    nosotros: '',
    mision: '',
    vision: '',
    facebook: '',
    instagram: '',
    whatsapp: '',
  });

  const { nosotros, mision, vision, facebook, instagram, whatsapp } = yasta;

  const getYasta = () => {
    fetchConToken('yasta/get')
      .then((data) => data.json())
      .then((resp) => {
        setYasta({
          nosotros: resp.quienes_somos,
          mision: resp.mision,
          vision: resp.vision,
          facebook: resp ? resp.redes_sociales[0].descripcion : '',
          instagram: resp ? resp.redes_sociales[1].descripcion : '',
          whatsapp: resp ? resp.redes_sociales[2].descripcion : '',
        });
        setAvatar(resp.logo);
      });
  };
  /* const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('quienes_somos', yasta.nosotros || '');
    formData.set('mision', yasta.mision || '');
    formData.set('logo', foto ? foto.file : '');
    formData.set('facebook', yasta.facebook || '');
    formData.set('instagram', yasta.instagram || '');
    formData.set('whatsapp', yasta.whatsapp || '');
    formData.set('vision', yasta.vision || '');
    fetchConFornDataToken('yasta/update', formData, 'POST')
      .then((data) => data.json())
      .then(() => {
        toast.success('Se ha actualizado correctamente!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }; */

  useEffect(() => {
    getYasta();
  }, []);

  const handleOnChange = (e) => {
    e.preventDefault();
    setYasta((yasta) => ({ ...yasta, [e.target.name]: e.target.value }));
  };

  return (
    <div className='container'>
      <ToastContainer />
      <div className='contenido-nosotros'>
        <div className='contenido-nosotros__img'>
          <img src={foto} alt='Yasta' />
        </div>
        <div className='contenido-nosotros__quienes'>
          <div className='contenido-nosotros__quienes-datos'>
            <h4>¿Quiénes somos?</h4>
            <i class='far fa-edit'></i>
          </div>
          <div className='contenido-nosotros__quienes-contenido'>
            <textarea
              value={nosotros}
              onChange={handleOnChange}
              name='nosotros'
              disabled
            ></textarea>
          </div>
        </div>
        {/*  */}
        <div className='contenido-nosotros__somos'>
          <div className='contenido-nosotros__somos-visionmision'>
            <div className='contenido-nosotros__somos-datos'>
              <h4>Misión</h4>
              <i class='far fa-edit'></i>
            </div>
            <div className='contenido-nosotros__somos-contenido'>
              <textarea
                value={vision}
                onChange={handleOnChange}
                name='vision'
                disabled
              ></textarea>
            </div>
          </div>
          <div className='contenido-nosotros__somos-visionmision'>
            <div className='contenido-nosotros__somos-datos'>
              <h4>Visión</h4>
              <i class='far fa-edit'></i>
            </div>
            <div className='contenido-nosotros__somos-contenido'>
              <textarea
                value={mision}
                onChange={handleOnChange}
                name='mision'
                disabled
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <CompanyLogobasboard />

      <div className='nosotros__marcas'>
        <div className='nosotros__editar'>
          <h3 className='nosotros__h3 mt-4'>Visita nuestras redes sociales</h3>
        </div>
        <div className='nosotros__contenido'>
          <div>
            <h4>
              <i class='fab fa-facebook-f'></i>Facebook
            </h4>
            <input
              type='text'
              value={facebook}
              onChange={handleOnChange}
              name='facebook'
              disabled
            />
          </div>
          <div>
            <h4>
              <i class='fab fa-instagram'></i>Instagram
            </h4>
            <input
              type='text'
              value={instagram}
              onChange={handleOnChange}
              name='instagram'
              disabled
            />
          </div>
          <div>
            <h4>
              <i class='fab fa-whatsapp'></i>Número de whatsapp
            </h4>
            <input
              type='text'
              value={whatsapp}
              onChange={handleOnChange}
              name='whatsapp'
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNosotros;
